import axios from "axios"

import { API } from "app/api"
import { ORDER_STATUS } from "app/constants"
import { acknowledgeUnreadResultAction } from "app/main/patient-orders/PatientOrdersOrderedTest/acknowledgeUnreadResult"
import { markOrderedTestResultAction } from "app/main/patient-orders/PatientOrdersOrderedTest/markOrderResultReviewed"
import { handleApiError, getApiBaseUrl, handleApiSuccess } from "app/utils"

export const GET_PATIENT = "[PATIENT ORDERS] GET PATIENT"
export const UPDATE_PATIENT = "[PATIENT ORDERS] UPDATE PATIENT"
export const GET_ORDERS = "[PATIENT ORDERS] GET ORDERS"
export const CLEAR_ORDERS = "[PATIENT ORDERS] RESET PATIENT ORDERS"
export const RESET = "[PATIENT ORDERS] RESET"
export const UPDATE_ORDER = "[PATIENT ORDERS] UPDATE ORDER"

export const GET_SCHEDULED_ORDERS = "[PATIENT ORDERS] GET SCHEDULED ORDERS"
export const CLEAR_SCHEDULED_ORDERS = "[PATIENT ORDERS] CLEAR ORDERS"

export const PATIENT_ORDERS_PAGE_SIZE = 5

export function getPatient(id) {
  const request = axios.get(getApiBaseUrl() + `/api/patient/${id}/`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_PATIENT,
          payload: response.data,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export function updatePatient(id, patient) {
  const request = API.Patient.patch(id, patient)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: UPDATE_PATIENT,
        payload: response.data,
      })
    )
}

export function getOrdersByPatient({ patientId, page, showDrafts, orderId }) {
  const offset = (page - 1) * PATIENT_ORDERS_PAGE_SIZE
  // Submitted isn't a real status, but it's supported by the orders API
  // to make it easier to show all orders that have been "submitted".
  const status = showDrafts ? ORDER_STATUS.DRAFT : "Submitted"
  const params = {
    limit: PATIENT_ORDERS_PAGE_SIZE,
    offset,
    status,
    use_offset_from_order: orderId || undefined,
  }

  const request = axios.get(
    getApiBaseUrl() + `/api/orderbypatient/${patientId}`,
    { params }
  )

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_ORDERS,
          payload: response.data,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export function getScheduledOrders({ patientId }) {
  const params = {
    // Make it large enough to return all scheduled orders
    limit: 100000,
    status: ORDER_STATUS.SCHEDULED,
  }

  const request = axios.get(
    getApiBaseUrl() + `/api/orderbypatient/${patientId}`,
    { params }
  )

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_SCHEDULED_ORDERS,
          payload: response.data,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export function updateOrder(orderId) {
  const request = API.Order.get(orderId)

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function cancelOrder(orderId, cancellationReason) {
  const request = API.Order.cancel(orderId, cancellationReason)

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function markOrderedTestResultReviewed(orderedTest, order) {
  return markOrderedTestResultAction(orderedTest, order, UPDATE_ORDER)
}

export function acknowledgeUnreadResult(orderedTest, order) {
  return acknowledgeUnreadResultAction(orderedTest, order, UPDATE_ORDER)
}

export function markResultsReviewed(order) {
  // If date_results_reviewed is undefined or empty string, set it to null so that we clear out date results reviewed on the backend
  const dateResultsReviewed = order.date_results_reviewed
    ? null
    : new Date().toISOString()

  // Optimistically update the ordered tests for order
  const optimisticOrderedTests = []
  for (const orderedTest of order.ordered_tests) {
    if (orderedTest.latest_ordered_result) {
      const optimisticOrderedTest = {
        ...orderedTest,
        latest_ordered_result: {
          ...orderedTest.latest_ordered_result,
          date_result_reviewed: dateResultsReviewed,
        },
      }
      optimisticOrderedTests.push(optimisticOrderedTest)
    }
  }

  const optimisticOrder = {
    ...order,
    ordered_tests: optimisticOrderedTests,
    date_results_reviewed: dateResultsReviewed,
    status: order.date_results_reviewed
      ? ORDER_STATUS.COMPLETE
      : ORDER_STATUS.RESULTS_REVIEWED,
  }

  const request = axios.post(
    getApiBaseUrl() + `/api/order/${order.id}/results-reviewed/`
  )

  return (dispatch) => {
    dispatch({
      type: UPDATE_ORDER,
      payload: optimisticOrder,
    })

    request
      .then((response) =>
        dispatch({
          type: UPDATE_ORDER,
          payload: {
            ...order,
            ...response.data,
          },
        })
      )
      .catch((error) => {
        dispatch(handleApiError(error))
        dispatch({
          type: UPDATE_ORDER,
          payload: order,
        })
      })
  }
}

export function clearOrders() {
  return (dispatch) =>
    dispatch({
      type: CLEAR_ORDERS,
    })
}

export function reset() {
  return (dispatch) =>
    dispatch({
      type: RESET,
    })
}

export function sendResultsToPatient(orderedTest, patient, onClose) {
  return (dispatch) =>
    API.OrderedTest.sendPatientResults(orderedTest.id)
      .then((response) => {
        dispatch(
          handleApiSuccess(
            `Results for ${orderedTest.lab_test.name} were securely emailed to ${patient.first_name}`,
            10000
          )
        )
        dispatch(updateOrder(orderedTest.order))
        onClose()
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function requestClinicalConsult(orderedTest) {
  return (dispatch) =>
    API.OrderedTest.requestConsult(orderedTest.id)
      .then((response) => {
        dispatch(
          handleApiSuccess(
            "We'll get a clinical consultation set up for you, and message you with more details within 24 hours.",
            20000
          )
        )
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function openInstantRequisitions(orderId) {
  return (dispatch) =>
    API.Order.getInstantRequisitions(orderId)
      .then((response) => {
        for (let url of response.data) {
          window.open(url)
        }
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function openLabCompanyRequisition(
  orderId,
  labCompanyId,
  orderedTestId
) {
  return (dispatch) =>
    API.Order.getLabCompanyRequisition(orderId, labCompanyId, orderedTestId)
      .then((response) => {
        window.open(response.data)
      })
      .catch((error) => dispatch(handleApiError(error)))
}
