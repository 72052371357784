import { styled } from "@material-ui/core"

import StateRestrictionIcon from "app/assets/icons/state-restriction.svg"
import BodyText from "app/components/design-system/BodyText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { LabTest } from "app/types"
import interleave from "app/utils/interleave"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

const StyledInfoTextTooltip = styled(InfoTextTooltip)({
  margin: "5px 0px 5px 5px",
  display: "flex",
  alignItems: "center",
})

const StyledIconImage = styled("img")({
  width: 18.81,
})

interface Props {
  stateOrderingRestrictions: LabTest["state_ordering_restrictions"]
}

const RESTRICTION_MAP = {
  NOT_ALLOWED: "Never",
  ALLOWED_WITH_WAIVER: "Allowed with waiver",
}

const StateRestrictions = ({ stateOrderingRestrictions }: Props) => {
  return (
    <>
      <BodyText size="sm">State Restrictions</BodyText>
      <BodyContainer>
        <StyledIconImage src={StateRestrictionIcon} alt="restrictions" />
        {Object.keys(stateOrderingRestrictions).length > 0 ? (
          <div className="flex flex-wrap items-center">
            {interleave(
              Object.keys(stateOrderingRestrictions).map(
                (key, index, array) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BodyText className="pl-1.5" weight="semibold">
                      {key} ({RESTRICTION_MAP[stateOrderingRestrictions[key]]})
                    </BodyText>
                    {stateOrderingRestrictions[key] ===
                      "ALLOWED_WITH_WAIVER" && (
                      <StyledInfoTextTooltip>
                        <BodyText size="sm">
                          Allowed with a waiver means this kit can be shipped to
                          that state but the patient must sign a waiver included
                          in the kit indicating they collected their samples in
                          a different state.
                        </BodyText>
                      </StyledInfoTextTooltip>
                    )}
                  </div>
                )
              ),
              (_, key) => (
                <span>,</span>
              )
            )}
          </div>
        ) : (
          <BodyText className="pl-1.5" weight="semibold">
            None
          </BodyText>
        )}
      </BodyContainer>
    </>
  )
}

export default StateRestrictions
