import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"
import _ from "lodash"

import { Dialog } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import Button from "app/components/design-system/Button"
import { FormTextField } from "app/components/forms/fields"
import { validatePhone } from "app/components/forms/validators"
import { ModalActions, ModalContent, ModalHeader } from "app/components/modals"
import * as Actions from "app/main/patient-orders/store/actions"
import { handleApiError } from "app/utils"
import isEmail from "validator/lib/isEmail"

export function EditPatientModal({ patient, open, onClose }) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="patient-modal-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <Form patient={patient} onClose={onClose} />
    </Dialog>
  )
}

function Form({ patient, onClose }) {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const [validationErrors, setValidationErrors] = useState({})
  const [submitPending, setSubmitPending] = useState(false)

  // useLayoutEffect to ensure the ref has been set when loadPatient is called.
  useLayoutEffect(() => {
    // Load initial values into form.
    if (patient && formRef.current) {
      formRef.current.reset({
        first_name: patient.first_name,
        last_name: patient.last_name,
        email: patient.user.email,
        phone_number: patient.phone_number,
      })
    }
  }, [dispatch, patient.id])

  const validateForm = (values) => {
    const errors = {}

    if (!isEmail(values.email)) {
      errors["email"] = "Please enter a valid email address"
    }

    if (values.phone_number) {
      errors["phone_number"] = validatePhone(values.phone_number)
    }

    return errors
  }

  const formSubmit = useCallback(
    async (data, resetForm, invalidateForm) => {
      const errors = validateForm(data)
      setSubmitPending(true)

      const isValidForm = (errors) => _.isEmpty(_.compact(_.values(errors)))

      if (isValidForm(errors)) {
        try {
          await dispatch(Actions.updatePatient(patient.id, data))
          onClose()
        } catch (error) {
          const errorData = error && error.response && error.response.data
          const submitErrors = _.pick(
            errorData.errors,
            _.keys(formRef.current.getModel())
          )

          if (!_.isEmpty(submitErrors)) {
            _.assign(errors, submitErrors)
          } else {
            dispatch(handleApiError(error))
          }
        }
      }

      // Update the validation error state before invalidating the form.
      setValidationErrors(errors)

      if (!isValidForm(errors)) {
        invalidateForm(errors)
      }

      setSubmitPending(false)
    },
    [dispatch, patient, onClose]
  )

  return (
    <Formsy
      onSubmit={formSubmit}
      validationErrors={validationErrors}
      ref={formRef}
    >
      <ModalHeader id="patient-modal-title" title={"Update Patient"} />

      <ModalContent>
        <Grid container spacing={2} direction={"column"} className="p-3">
          <Grid item>
            <FormTextField
              label="First Name"
              name="first_name"
              disabled={!patient.can_modify_name}
              tooltipTitle={
                "Please reach out to support through chat if you need to modify this name."
              }
            />
          </Grid>

          <Grid item>
            <FormTextField
              label="Last Name"
              name="last_name"
              disabled={!patient.can_modify_name}
              tooltipTitle={
                "Please reach out to support through chat if you need to modify this name."
              }
            />
          </Grid>

          <Grid item>
            <FormTextField
              label="Email"
              name="email"
              disabled={!patient.user.can_modify_email}
              tooltipTitle={
                "Please reach out to support through chat if you need to modify this email."
              }
            />
          </Grid>

          <Grid item>
            <FormTextField
              label="Phone Number"
              name="phone_number"
              required={false}
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions>
        <Button onClick={onClose} color={"secondary"} disabled={submitPending}>
          Cancel
        </Button>
        <Button loading={submitPending} className="ml-4" type="submit">
          Update Patient
        </Button>
      </ModalActions>
    </Formsy>
  )
}
