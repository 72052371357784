import { FuseMessage } from "@fuse"
import {
  Route as BasicRoute,
  Redirect,
  Switch,
  useRouteMatch,
} from "react-router-dom"

import { makeStyles, Theme } from "@material-ui/core"

import { isUserGuest } from "app/auth/util"
import AdblockBanner from "app/components/AdblockBanner"
import Footer from "app/components/Footer"
import GuestNavBar from "app/components/GuestNavBar"
import ImpersonatingBanner from "app/components/ImpersonatingBanner"
import OrderingAccessPage from "app/components/OrderingAccess/OrderingAccessPage"
import Promotions from "app/components/Promotions/Promotions"
import UserNavBar from "app/components/UserNavBar"
import FoodPlans from "app/food-plans/routes/FoodPlans"
import PatientFoodPlan from "app/food-plans/routes/PatientFoodPlan"
import useAppSelector from "app/hooks/useAppSelector"
import BloodLabDashboardsPatientPreview from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardsPatientPreview"
import BloodLabDashboardsSnapshotPatientPreview from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotPatientPreview/BloodLabDashboardsSnapshotPatientPreview"
import TestResultsPdf from "app/main/blood-lab-dashboards/TestResultsPdf"
import Callback from "app/main/callback/Callback"
import DiscoverLabs from "app/main/catalog/DiscoverLabs"
import Checkout from "app/main/checkout/Checkout"
import CreateOrder from "app/main/checkout/CreateOrder"
import CouponPromo from "app/main/coupon-promo/CouponPromo"
import Dashboard from "app/main/dashboard/Dashboard"
import Ecommerce from "app/main/ecommerce/Ecommerce"
import EcommerceAnalytics from "app/main/ecommerce/EcommerceAnalytics"
import EcommerceOrders from "app/main/ecommerce/EcommerceOrders"
import PublicStorefront from "app/main/ecommerce/public/PublicStorefront"
import Error404 from "app/main/errors/404/Error404Page"
import Error500 from "app/main/errors/500/Error500Page"
import PractitionerProfile from "app/main/find-a-practitioner/PractitionerProfile"
import InOfficeKitsPage from "app/main/in-office-kits/InOfficeKitsPage"
import Loader from "app/main/loader/Loader"
import Login from "app/main/login/Login"
import VerifyEmail from "app/main/login/VerifyEmail"
import MagicLogin from "app/main/magic-login/MagicLogin"
import PasswordInitModal from "app/main/modals/PasswordInitModal"
import Onboarding from "app/main/onboarding/Onboarding"
import PatientCheckout from "app/main/patient-checkout/PatientCheckout"
import PatientOrders from "app/main/patient-orders/PatientOrders"
import PatientPortalError from "app/main/patient-portal/PatientPortalError"
import ExternalPatientSignup from "app/main/patient-signup/ExternalPatientSignup"
import PatientPortalCallback from "app/main/patient-signup/PatientPortalCallback"
import PaymentCollection from "app/main/payment-collection/components/PaymentCollection"
import PhlebotomistMapPage from "app/main/phlebotomy-map/PhlebotomistMapPage"
import { ScheduleBloodDraw } from "app/main/schedule-blood-draw/ScheduleBloodDraw"
import BillingAndCustomFees from "app/main/settings/BillingAndCustomFees/BillingAndCustomFees"
import Bundles from "app/main/settings/Bundles"
import Clinic from "app/main/settings/Clinic"
import ConnectedAccounts from "app/main/settings/LabCompanyAccountConnections/ConnectedAccounts"
import Notifications from "app/main/settings/Notifications"
import PersonalSettings from "app/main/settings/PersonalSettings"
import Phlebotomists from "app/main/settings/Phlebotomists"
import RupaBloodDashboards from "app/main/settings/RupaBloodDashboards/RupaBloodDashboards"
import Settings from "app/main/settings/Settings"
import Snippets from "app/main/settings/Snippets"
import Team from "app/main/settings/Team"
import Signature from "app/main/signature/Signature"
import UniversityCatalog from "app/main/university/UniversityCatalog"
import UniversityLiveClass from "app/main/university/UniversityLiveClass"
import PatientPortal from "app/patient-portal/PatientPortal"
import PatientPortalLegacyRedirect from "app/patient-portal/PatientPortalLegacyRedirect"
import PatientPortalV0Redirect from "app/patient-portal/PatientPortalV0Redirect"
import { PatientPortalPaths as PatientPortalPathsLegacy } from "app/patient-portal/routes/paths"
import { colors } from "app/theme"
import { AuthUser, Role } from "app/types"
import { safariFullViewportHeightHack } from "app/utils"

import Route from "./Route"
import { AllowAnyPaths, GuestPaths, UserPaths } from "./paths"
import { PatientPortalPatientPaths } from "./paths/patient-portal-paths"
import { PatientPortalUserPaths } from "./paths/patient-portal-user-paths"
import { PatientPortalUserRoutes } from "./routes/PatientPortalUserRoutes"

const UserRoutes = () => (
  <>
    <Switch>
      <Route exact path={UserPaths.DASHBOARD}>
        <Redirect to={UserPaths.ROOT} />
      </Route>
      <Route exact path={UserPaths.ROOT}>
        <Dashboard />
      </Route>
      <Route exact path={UserPaths.ONBOARDING}>
        <Onboarding />
      </Route>
      <Route exact path={UserPaths.SIGNATURE}>
        <Signature />
      </Route>
      <Route exact path={UserPaths.SETTINGS}>
        <Settings />
      </Route>
      <Route exact path={UserPaths.ECOMMERCE_STORE}>
        <Ecommerce />
      </Route>
      <Route exact path={UserPaths.ECOMMERCE_STORE_ORDERS}>
        <EcommerceOrders />
      </Route>
      <Route exact path={UserPaths.ECOMMERCE_STORE_ANALYTICS}>
        <EcommerceAnalytics />
      </Route>
      <Route exact path={UserPaths.FIND_A_PRACTITIONER}>
        <PractitionerProfile />
      </Route>
      <Route exact path={UserPaths.IN_OFFICE_KITS}>
        <InOfficeKitsPage />
      </Route>
      <Route exact path={UserPaths.ACCOUNT}>
        <PersonalSettings />
      </Route>
      <Route exact path={UserPaths.PROFILE}>
        <PersonalSettings />
      </Route>
      <Route exact path={UserPaths.BUNDLES}>
        <Bundles />
      </Route>
      <Route exact path={UserPaths.SNIPPETS}>
        <Snippets />
      </Route>
      <Route path={UserPaths.ADD_SHARED_BUNDLE}>
        <Bundles />
      </Route>
      <Route exact path={UserPaths.CLINIC}>
        <Clinic />
      </Route>
      <Route exact path={UserPaths.TEAM}>
        <Team />
      </Route>
      <Route exact path={UserPaths.NOTIFICATIONS}>
        <Notifications />
      </Route>
      <Route exact path={UserPaths.BILLING_AND_CUSTOM_FEES}>
        <BillingAndCustomFees />
      </Route>
      <Route exact path={UserPaths.ORDERING_ACCESS}>
        <OrderingAccessPage />
      </Route>
      <Route exact path={UserPaths.FOOD_PLANS}>
        <FoodPlans />
      </Route>
      <Route exact path={UserPaths.PHLEBOTOMISTS}>
        <Phlebotomists />
      </Route>
      <Route exact path={UserPaths.PHLEBOTOMY_MAP}>
        <PhlebotomistMapPage />
      </Route>
      <Route exact path={UserPaths.PROMOTIONS}>
        <Promotions />
      </Route>
      <Route path={UserPaths.ORDER_CREATE}>
        <CreateOrder />
      </Route>
      <Route path={UserPaths.ORDER_CHECKOUT}>
        <Checkout />
      </Route>
      <Route path={UserPaths.CONVERSATIONS}>
        <Redirect to={{ pathname: UserPaths.ROOT, search: "?chat=1" }} />
      </Route>
      <Route path={UserPaths.ERRORS_404}>
        <Error404 />
      </Route>
      <Route path={UserPaths.ERRORS_500}>
        <Error500 />
      </Route>
      <Route path={UserPaths.LOADER}>
        <Loader />
      </Route>
      <Route path={UserPaths.PATIENT_DETAIL_FOOD_PLAN}>
        <PatientFoodPlan />
      </Route>
      <Route
        path={[
          UserPaths.PATIENT_REQUISITIONS,
          UserPaths.PATIENT_PENDING_PAYMENT,
          UserPaths.PATIENT_DETAIL,
        ]}
      >
        <PatientOrders />
      </Route>
      <Route exact path={UserPaths.UNIVERSITY_CATALOG}>
        <UniversityCatalog />
      </Route>
      <Route exact path={UserPaths.UNIVERSITY_LIVE_CLASS}>
        <UniversityLiveClass />
      </Route>
      <Route exact path={UserPaths.PHYSICIAN_SERVICES_ENABLE_MODAL}>
        <PersonalSettings shouldOpenPhysicianServicesEnableModalOnLoad={true} />
      </Route>
      <Route exact path={UserPaths.PHYSICIAN_SERVICES_TERMS}>
        <PersonalSettings shouldOpenPhysicianServicesEnableModalOnLoad={true} />
      </Route>
      <Route path={UserPaths.INSURANCE_ACCOUNT_SETTINGS}>
        <ConnectedAccounts />
      </Route>
      <Route exact path={UserPaths.RUPA_BLOOD_DASHBOARDS}>
        <RupaBloodDashboards />
      </Route>
      <Route exact path={UserPaths.RUPA_BLOOD_DASHBOARDS_PATIENT_PREVIEW}>
        <BloodLabDashboardsPatientPreview />
      </Route>
      <Route
        exact
        path={UserPaths.RUPA_BLOOD_DASHBOARDS_SNAPSHOT_PATIENT_PREVIEW}
      >
        <BloodLabDashboardsSnapshotPatientPreview />
      </Route>
      <Route exact path={UserPaths.TEST_PDF_GENERATION}>
        <TestResultsPdf />
      </Route>
    </Switch>
  </>
)

const GuestRoutes = () => (
  <Switch>
    <Route path={GuestPaths.CALLBACK}>
      <Callback />
    </Route>
    <Route path={[GuestPaths.LOGIN, GuestPaths.RESET]}>
      <Login />
    </Route>
    <Route path={GuestPaths.LOGIN_INTERNAL}>
      <Login />
    </Route>
    <Route path={GuestPaths.PATIENT_SIGNUP}>
      <ExternalPatientSignup />
    </Route>
    <Route path={GuestPaths.PATIENT_PORTAL_CALLBACK}>
      <PatientPortalCallback />
    </Route>
  </Switch>
)

const AllowAnyRoutes = ({ user }: { user?: AuthUser }) => (
  <Switch>
    <Route path={AllowAnyPaths.DISCOVER_LABS}>
      {user?.is_provider && <PasswordInitModal />}
      <DiscoverLabs />
    </Route>
    <Route path={AllowAnyPaths.PHLEBOTOMY_BOOKING}>
      <ScheduleBloodDraw />
    </Route>
    <Route path={AllowAnyPaths.PATIENT_CHECKOUT}>
      <PatientCheckout />
    </Route>
    <Route path={AllowAnyPaths.PAYMENT_COLLECTION}>
      <PaymentCollection />
    </Route>
    <Route path={AllowAnyPaths.ORDER_INSTRUCTIONS_ERROR}>
      <PatientPortalError />
    </Route>
    <Route path={AllowAnyPaths.SET_PASSWORD}>
      <Login />
    </Route>
    <Route path={AllowAnyPaths.PROMO}>
      <CouponPromo />
    </Route>
    <Route path={AllowAnyPaths.PUBLIC_ECOMMERCE_STOREFRONT}>
      <PublicStorefront />
    </Route>
    <Route path={AllowAnyPaths.MAGIC_LOGIN}>
      <MagicLogin user={user} />
    </Route>
    <Route path={AllowAnyPaths.VERIFY_EMAIL}>
      <VerifyEmail />
    </Route>
  </Switch>
)

const useStyles = makeStyles<Theme, { isContainerVertical: boolean }>(
  (theme) => ({
    container: {
      display: "flex",
      minHeight: "100vh",
      ...safariFullViewportHeightHack("minHeight"),
      width: "100%",
      background: colors.blueGray[100],

      flexFlow: "column",

      [theme.breakpoints.up("sm")]: {
        flexFlow: (props) => (props.isContainerVertical ? "column" : "row"),
      },
    },
    content: {
      display: "flex",
      flexFlow: "column",
      position: "relative",
      flex: 1,
      // Forces the `main` flex child to not grow beyond the parent
      minWidth: 0,
    },
  })
)

const userPaths = Object.values(UserPaths)
const userPathsWithSideNavbar = userPaths.filter(
  (path) =>
    ![
      UserPaths.RUPA_BLOOD_DASHBOARDS_PATIENT_PREVIEW,
      UserPaths.RUPA_BLOOD_DASHBOARDS_SNAPSHOT_PATIENT_PREVIEW,
    ].includes(path)
)
const guestPaths = Object.values(GuestPaths)
const allowAnyPaths = Object.values(AllowAnyPaths)
const patientPortalPaths = Object.values(PatientPortalPatientPaths)
const patientPortalUserPaths = Object.values(PatientPortalUserPaths)
const v0PatientPortalPaths = Object.values(PatientPortalPathsLegacy)
const footerPaths = [
  ...allowAnyPaths.filter(
    (path) =>
      ![
        AllowAnyPaths.DISCOVER_LABS,
        AllowAnyPaths.DISCOVER_LABS_SEARCH,
        AllowAnyPaths.SET_PASSWORD,
        AllowAnyPaths.PUBLIC_ECOMMERCE_STOREFRONT,
        AllowAnyPaths.MAGIC_LOGIN,
      ].includes(path)
  ),
  PatientPortalPathsLegacy.ORDER,
]

const footerPathOverride = [UserPaths.ECOMMERCE_STORE_ORDERS]
const collapsedNavBarPaths = [UserPaths.ORDER_CHECKOUT]

function Routes({ loadingProps }: { loadingProps?: object }) {
  const user = useAppSelector(({ auth }) => auth.user)
  const isGuestUser = isUserGuest(user)

  const isNavBarCollapsed = Boolean(useRouteMatch(collapsedNavBarPaths))
  const isFooterPathOverride = Boolean(useRouteMatch(footerPathOverride))
  const isFooterVisible =
    Boolean(useRouteMatch(footerPaths)) && !isFooterPathOverride

  const isContainerVertical = isFooterVisible || isGuestUser

  const { container, content } = useStyles({ isContainerVertical })

  return (
    <div className={content}>
      <AdblockBanner />
      <ImpersonatingBanner />
      <div className={container}>
        <Route path={AllowAnyPaths.DISCOVER_LABS}>
          {isGuestUser ? <GuestNavBar /> : <UserNavBar />}
        </Route>
        <Route exact path={userPathsWithSideNavbar}>
          <UserNavBar collapsed={isNavBarCollapsed} />
        </Route>
        <main className={content}>
          <Switch>
            <Route
              exact
              path={userPaths}
              permissions={[Role.STAFF, Role.PROVIDER]}
            >
              <PasswordInitModal>
                <UserRoutes />
              </PasswordInitModal>
            </Route>

            <Route
              exact
              path={[...patientPortalUserPaths, ...patientPortalPaths]}
              permissions={[Role.STAFF, Role.PROVIDER, Role.PATIENT]}
            >
              <PatientPortal>
                <>
                  <PatientPortalUserRoutes />
                  <Route exact path={patientPortalPaths}>
                    {/* Automatically redirects legacy v1 patient portal links */}
                    <PatientPortalLegacyRedirect />
                  </Route>
                </>
              </PatientPortal>
            </Route>
            <Route exact path={v0PatientPortalPaths}>
              {/* Automatically redirects v0 patient portal links */}
              <PatientPortalV0Redirect />
            </Route>
            <BasicRoute path={PatientPortalPathsLegacy.ROOT}>
              {/* Non authenticated empty page when redirects to patient portal login or account signup are being started */}
              <div />
            </BasicRoute>

            <Route exact path={guestPaths} permissions={[Role.GUEST]}>
              <GuestRoutes />
            </Route>
            <Route
              exact
              path={allowAnyPaths}
              permissions={[
                Role.GUEST,
                Role.STAFF,
                Role.PROVIDER,
                Role.PATIENT,
              ]}
            >
              <AllowAnyRoutes user={user} />
            </Route>
            <Route
              path="*"
              permissions={[
                Role.GUEST,
                Role.STAFF,
                Role.PROVIDER,
                Role.PATIENT,
              ]}
            >
              <Error404 />
            </Route>
          </Switch>
        </main>
        {isGuestUser && (
          <Route path={AllowAnyPaths.DISCOVER_LABS}>
            <Footer />
          </Route>
        )}

        {isFooterVisible && <Footer />}
        <FuseMessage />
      </div>
    </div>
  )
}

export default Routes
