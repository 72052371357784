import { useDispatch } from "react-redux"

import { sum } from "lodash"
import { useParams } from "react-router-dom"

import { IconButton, Theme, makeStyles } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import { API } from "app/api"
import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import CheckoutWarning from "app/components/CheckoutWarning"
import Tooltip from "app/components/Tooltip"
import DesignSystemButton from "app/components/design-system/Button"
import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import {
  LineItem,
  OrderIntent,
  StorefrontType,
  useResource,
  useResourceByIdSelector,
  useResourceSelector,
} from "app/hooks/use-resource"
import { useHasMixedAMLOrder } from "app/main/ecommerce/hooks/use-has-mixed-aml-order"
import { LINE_ITEM_INFO_CONFIG } from "app/main/patient-checkout/sections/OrderDetails"
import ProcessingAndOrderingCollapse from "app/main/patient-checkout/sections/ProcessingAndOrderingCollapse"
import { navy, primaryColor } from "app/theme"
import { CheckoutWarningTypes } from "app/types"
import { formatDollars, getNormalizedApiUrl, handleApiError } from "app/utils"

import { useHasMixedDHAAndLabcorpOrder } from "../../hooks/use-has-mixed-dha-and-labcorp-order"
import { CardOptions } from "../PublicStorefront"
import CartItemCard from "./CartItemCard"
import { PhysAuthFeeTooltip } from "./PriceBreakdown"

const styles = (theme: Theme) => ({
  cart: {
    position: "fixed" as unknown as undefined,
    right: 0,
    height: "100vh",
    overflowY: "scroll" as any,
    width: "450px",
    background: "white",
    boxShadow: "-10px 0px 144px rgba(0, 0, 0, 0.25)",
    zIndex: 200,
    padding: 16,
    display: "flex",
    flexDirection: "column" as unknown as undefined,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      paddingLeft: 31,
    },
  },
  cartTitle: {
    fontWeight: 400,
    fontSize: 17,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #CBD5E1",
    marginBottom: 16,
  },
  lineItems: {
    borderBottom: "1px solid #CBD5E1",
    borderTop: "1px solid #CBD5E1",
    marginTop: "auto",
    paddingBottom: 17,
  },
  lineItem: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 17,
    color: navy,
    "& p": {
      fontSize: 15,
      fontWeight: 400,
    },
  },
  savings: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 17,
    color: primaryColor,
    fontWeight: 600,
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 17,
    marginBottom: 17,
    color: navy,
    fontSize: 19,
    fontWeight: 700,
  },
})

const useStyles = makeStyles(styles)

type CartSidebarProps = {
  close: () => void
  storefront: StorefrontType | null
}

const getLineItemPriceByType = (lineItems?: LineItem[], ...types: string[]) => {
  return sum(
    lineItems
      ?.filter((item) => types.includes(item?.attributes.type))
      .map((item) => item.attributes.cost / 100)
  )
}

export const CartSidebar = ({ close, storefront }: CartSidebarProps) => {
  const { storefrontId } = useParams<{
    storefrontId: string
  }>()

  const storefrontCartInclude = [
    "line_items",
    "storefront_products.bundle.lab_tests.lab_company",
    "storefront_products.lab_test.lab_company",
  ]

  const { data: cart, updateCache: updateCart } = useResource<OrderIntent>(
    `storefronts/${storefrontId}/cart/`,
    { include: storefrontCartInclude }
  )

  const clinic = useResourceByIdSelector(
    "clinic",
    storefront?.relationships.clinic?.data.id
  )

  const dispatch = useDispatch()

  const handleRemoveCart = async (type: CardOptions, id: string) => {
    const relationship = "storefront_products"
    const existingRelationships =
      cart?.relationships?.[relationship]?.data ||
      new Array<{ id: string; type: string }>()

    const requestBody = {
      data: {
        id: cart?.id,
        type: "order_intent",
        ...cart,
        relationships: {
          ...cart?.relationships,
          storefront_products: {
            data: existingRelationships.filter((data) => data.id !== id),
          },
        },
      },
    }

    try {
      const response = await API.Storefront.updateCart(
        storefrontId,
        requestBody,
        storefrontCartInclude
      )
      updateCart(response.data)
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  const classes = useStyles()
  const lineItems = useResourceSelector((entities) =>
    cart?.relationships.line_items.data.map(
      ({ id }) => entities["order_intent_line_item"]?.[id] as LineItem
    )
  )

  const customFees = lineItems?.filter(
    (item) => item?.attributes.type === ORDER_LINE_ITEM_TYPE.CUSTOM_FEE
  )
  const customFeeSum =
    (customFees?.reduce((sum, curFee) => {
      return sum + curFee.attributes.cost
    }, 0) || 0) / 100

  const scarletBloodDrawPrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.SCARLET_BLOOD_DRAW_FEE
  )

  const labcorpBloodDrawPrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE
  )

  const vibrantMinimumPrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.VIBRANT_MINIMUM
  )

  const questBloodDrawPrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE
  )

  const questServiceFeePrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE
  )

  // Processing Fee
  const processingPrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.PROCESSING_FEE,
    ORDER_LINE_ITEM_TYPE.SHIPPING
  )

  const physicianServicesPrice = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_VENDOR_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_RUPA_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_KIT_RUPA_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_PANEL_RUPA_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_PROMOTIONAL_RUPA_FEE
  )

  // Sum(Rupa Discount)
  const savings = getLineItemPriceByType(
    lineItems,
    ORDER_LINE_ITEM_TYPE.RUPA_DISCOUNT
  )

  // Sum(Lab Tests)
  const labTestsPrice =
    getLineItemPriceByType(lineItems, ORDER_LINE_ITEM_TYPE.LAB_TEST) + savings // savings is negative

  const total =
    processingPrice +
    physicianServicesPrice +
    customFeeSum +
    labTestsPrice +
    scarletBloodDrawPrice +
    labcorpBloodDrawPrice +
    questBloodDrawPrice +
    questServiceFeePrice +
    vibrantMinimumPrice

  const checkout = () => {
    window.location.href = `${getNormalizedApiUrl()}/storefronts/${storefrontId}/redirect_cart_to_checkout/`
  }
  const showAMLWarning = useHasMixedAMLOrder(cart)

  const showDHAAndLabCorpWarning = useHasMixedDHAAndLabcorpOrder(cart)

  const cartIsEmpty =
    !Boolean(cart?.relationships?.lab_tests?.data?.length) &&
    !Boolean(cart?.relationships?.bundles?.data?.length) &&
    !Boolean(cart?.relationships?.storefront_products?.data?.length)

  const disableContinueToCheckout =
    cartIsEmpty || showAMLWarning || showDHAAndLabCorpWarning

  return (
    <div className={classes.cart}>
      <div className={classes.cartTitle}>
        <p>Your Cart</p>
        <IconButton aria-label="close" onClick={close} key="close-button">
          <CloseIcon />
        </IconButton>
      </div>
      {cart?.relationships?.storefront_products?.data.map(({ id }) => (
        <CartItemCard
          key={id}
          storefrontProductId={id}
          handleRemoveCart={
            () => handleRemoveCart(CardOptions.BUNDLE, id) // Note: using bundle as a placeholder until this param is deprecated
          }
          storefront={storefront}
        />
      ))}
      {showAMLWarning && (
        <CheckoutWarning type={CheckoutWarningTypes.MIXED_AML_ORDER_LABSHOP} />
      )}
      {showDHAAndLabCorpWarning && (
        <CheckoutWarning type={CheckoutWarningTypes.MIXED_DHA_LABCORP_ORDER} />
      )}
      <div className={classes.lineItems}>
        <div className={classes.lineItem}>
          <p>Lab Tests</p>
          <p>{formatDollars(labTestsPrice)}</p>
        </div>

        {Boolean(customFees?.length) &&
          customFees?.map((customFee) => (
            <div className={classes.lineItem}>
              <p>{`${customFee.attributes.title} going to ${clinic?.attributes.name}`}</p>
              <p>{formatDollars(customFee.attributes.cost / 100)}</p>
            </div>
          ))}
        {scarletBloodDrawPrice > 0 && (
          <div className={classes.lineItem}>
            <Tooltip
              title={
                LINE_ITEM_INFO_CONFIG[
                  ORDER_LINE_ITEM_TYPE.SCARLET_BLOOD_DRAW_FEE
                ]?.info ?? ""
              }
              placement="bottom"
              arrow
              interactive
            >
              <div
                className="flex flex-row justify-start"
                style={{ alignItems: "flex-start", paddingRight: 25 }}
              >
                Scarlet Blood Draw
                <img
                  src={InfoIcon}
                  alt=""
                  className="ml-2"
                  width={18}
                  style={{ position: "relative", top: 2 }}
                />
              </div>
            </Tooltip>
            <p>{formatDollars(scarletBloodDrawPrice)}</p>
          </div>
        )}
        {labcorpBloodDrawPrice > 0 && (
          <div className={classes.lineItem}>
            <Tooltip
              title={
                LINE_ITEM_INFO_CONFIG[
                  ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE
                ]?.info ?? ""
              }
              placement="bottom"
              arrow
              interactive
            >
              <div
                className="flex flex-row justify-start"
                style={{ alignItems: "flex-start", paddingRight: 25 }}
              >
                Blood Draw at Labcorp
                <img
                  src={InfoIcon}
                  alt=""
                  className="ml-2"
                  width={18}
                  style={{ position: "relative", top: 2 }}
                />
              </div>
            </Tooltip>
            <p>{formatDollars(labcorpBloodDrawPrice)}</p>
          </div>
        )}
        {questServiceFeePrice > 0 && (
          <div className={classes.lineItem}>
            <p>Quest Service Fee</p>
            <p>{formatDollars(questServiceFeePrice)}</p>
          </div>
        )}
        {questBloodDrawPrice > 0 && (
          <div className={classes.lineItem}>
            <p>Blood Draw</p>
            <p>{formatDollars(questBloodDrawPrice)}</p>
          </div>
        )}
        {vibrantMinimumPrice > 0 && (
          <div className={classes.lineItem}>
            <p>Vibrant Small Order Fee</p>
            <p>{formatDollars(vibrantMinimumPrice)}</p>
          </div>
        )}

        <div style={{ marginTop: 14 }}>
          <ProcessingAndOrderingCollapse
            header={
              <div className={classes.lineItem} style={{ marginTop: 4 }}>
                <p>Processing & Ordering</p>
                <p>{formatDollars(physicianServicesPrice + processingPrice)}</p>
              </div>
            }
          >
            {physicianServicesPrice > 0 && (
              <div className={classes.lineItem}>
                <Tooltip
                  title={<PhysAuthFeeTooltip />}
                  placement="bottom"
                  arrow
                  interactive
                >
                  <div
                    className="flex flex-row justify-start"
                    style={{ alignItems: "flex-start", paddingRight: 25 }}
                  >
                    Physician Services
                    <img
                      src={InfoIcon}
                      alt=""
                      className="ml-2"
                      width={18}
                      style={{ position: "relative", top: 2 }}
                    />
                  </div>
                </Tooltip>
                <p>{formatDollars(physicianServicesPrice)}</p>
              </div>
            )}
            <div className={classes.lineItem}>
              <p>Rupa Service Fee</p>
              <p>{formatDollars(processingPrice)}</p>
            </div>
          </ProcessingAndOrderingCollapse>
        </div>
      </div>
      <div className={classes.total}>
        <p>Total</p>
        <p>{formatDollars(total)}</p>
      </div>
      <DesignSystemButton
        disabled={disableContinueToCheckout}
        onClick={checkout}
        color="primary"
        type="submit"
        fullWidth
        size="large"
        id="continue-to-checkout"
      >
        Continue to Checkout
      </DesignSystemButton>
    </div>
  )
}
