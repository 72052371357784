import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { SimpleBodySystem } from "types/body-system"

export default function useBodySystems(userId: string) {
  const {
    data: bodySystemIdentifiersForDropdown,
    error: bodySystemsForDropdownError,
    isLoading: bodySystemsForDropdownLoading,
    isValidating: bodySystemsForDropdownValidating,
  } = useCollectionSWR<ResourceCollection<SimpleBodySystem>>(
    `/body_system/`,
    {
      params: {
        results_over_time: "true",
        user_id: userId,
      },
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const {
    data: bodySystemIdentifiersForTable,
    error: bodySystemsForTableError,
    isLoading: bodySystemsForTableLoading,
    isValidating: bodySystemsForTableValidating,
  } = useCollectionSWR<ResourceCollection<SimpleBodySystem>>(
    `/body_system/`,
    {
      params: {
        results_over_time: "true",
        user_id: userId,
        primary_body_system_only: "true",
      },
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const bodySystemsForDropdown = useCachedCollection<SimpleBodySystem>(
    bodySystemIdentifiersForDropdown
  )

  const bodySystemsForTable = useCachedCollection<SimpleBodySystem>(
    bodySystemIdentifiersForTable
  )

  return {
    bodySystemsForDropdown,
    bodySystemsForDropdownLoading,
    bodySystemsForDropdownValidating,
    bodySystemsForDropdownError,
    bodySystemsForTable,
    bodySystemsForTableLoading,
    bodySystemsForTableValidating,
    bodySystemsForTableError,
  }
}
