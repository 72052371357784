import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import classNames from "classnames"
import clsx from "clsx"

import { useDebounce } from "@fuse/hooks"
import { IconButton, makeStyles, Paper, Typography } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"
import InfoIcon from "@material-ui/icons/Info"

import SampleTypePill, {
  backgroundColorForColorGroup,
  colorGroupForName,
  textColorForColorGroup,
} from "app/components/SampleTypePill"
import useComboGroupConfigurationModal from "app/components/modals/ComboGroupModal/hooks/use-combo-group-configuration-modal"
import { getUnavailableReason } from "app/dataServices/orderingRights"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import * as Actions from "app/store/actions"
import { AnyLabTest, labTestLocation, Practitioner } from "app/types"

import Tooltip from "../../components/Tooltip"
import { ComboGroupConfigurationPayload } from "../combo-groups/constants"

const useStyles = makeStyles((theme) => ({
  testWrapper: ({ isSelected }: { isSelected: boolean }) => ({
    background: isSelected
      ? "linear-gradient(165.38deg, rgba(255, 255, 255, 0.2) 11.16%, rgba(255, 255, 255, 0) 84.19%)"
      : "linear-gradient(165.38deg, rgba(255, 255, 255, 0.8) 11.16%, rgba(255, 255, 255, 0) 84.19%), #F4F5F7",
    borderRadius: "10px",
    padding: "12px",
    minHeight: "135px",
  }),
  iconHover: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
  },
  iconHoverSelected: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.15)",
    },
  },
}))

interface LabTestCardSampleTypePillProps {
  name: string
  isSelected: boolean
  isAvailable: boolean
  shouldTruncateName?: boolean
}

const LabTestCardSampleTypePill = ({
  name,
  isSelected,
  isAvailable,
  shouldTruncateName,
}: LabTestCardSampleTypePillProps) => {
  const colorGroup = colorGroupForName(name)
  const textStyle = {
    color: isSelected
      ? "white"
      : !isAvailable
      ? "rgb(160, 160, 160)"
      : textColorForColorGroup(colorGroup),
  }
  const style = {
    backgroundColor: isSelected
      ? textColorForColorGroup(colorGroup)
      : !isAvailable
      ? "rgb(238, 238, 239)"
      : backgroundColorForColorGroup(colorGroup),
    marginLeft: 0,
    marginRight: 6,
    minWidth: 0,
  }

  return (
    <SampleTypePill
      name={shouldTruncateName ? name.slice(0, 2) : name}
      style={style}
      textStyle={textStyle}
      title={name}
    />
  )
}

interface LabTestCardProps {
  labTest: AnyLabTest
  practitioner: Practitioner
  signingPractitioner: Practitioner
  selectedLabTests: { [labTestId: string]: AnyLabTest }
  isFavorite: boolean
  onAdd: (labTest: AnyLabTest) => void
  onRemove: (labTest: AnyLabTest) => void
  onToggleFavorite: () => void
  openModal: () => void
  hideFavoritesButton?: boolean
  hideInfoButton?: boolean
  modalPage?: labTestLocation
  onAddComboGroup?: (
    labTest: AnyLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
}

export default function LabTestCard({
  labTest,
  practitioner,
  signingPractitioner,
  selectedLabTests,
  isFavorite,
  onAdd,
  onRemove,
  onToggleFavorite,
  openModal,
  hideFavoritesButton = false,
  hideInfoButton = false,
  modalPage = labTestLocation.CHECKOUT,
  onAddComboGroup,
}: LabTestCardProps) {
  const dispatch = useDispatch()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  // Show tooltip with button to override lab company restriction
  const [showOverrideOption] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )

  const isSelected = Boolean(selectedLabTests[labTest.id])

  const classes = useStyles({
    isSelected,
  })

  const overrideAccessAction = (includeClinicState: boolean) => {
    dispatch(
      Actions.setLabTestForOverride(
        labTest.lab_company,
        includeClinicState ? practitioner?.clinic?.state : undefined
      )
    )
  }

  const comboGroupConfigurationModal = useComboGroupConfigurationModal()

  const unavailableReason = getUnavailableReason({
    labTest,
    signingPractitioner,
    orderingPractitioner: practitioner,
    overrideAccessAction,
    showOverrideOption,
  })

  const isAvailable = !unavailableReason

  const testType =
    labTest.lab_test_types.length > 0 &&
    labTest.lab_test_types[0] &&
    labTest.lab_test_types[0].name.toLowerCase()

  const handleInfoClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    openModal()
  }

  const handleToggleFavorite = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onToggleFavorite()
  }

  const _handleClick = useCallback(() => {
    if (isSelected) {
      onRemove(labTest)
    } else if (isAvailable) {
      if (labTest.combo_group && onAddComboGroup) {
        comboGroupConfigurationModal.show({
          updating: false,
          initialTargetId: labTest.id,
          comboGroupId: labTest.combo_group,
          initialOptionIds: [],
          initialAddOnIds: [],
          onAddToCart: async (payload) => {
            await onAddComboGroup(labTest, payload)
            comboGroupConfigurationModal.hide()
          },
        })
      } else {
        onAdd(labTest)
      }
    }
  }, [isSelected, labTest, isAvailable, onRemove, onAdd])

  const handleClick = useDebounce(_handleClick, 300, {
    leading: true,
    trailing: false,
  })

  const handleTooltipOpen = () => {
    if (!isAvailable) {
      setTooltipOpen(true)
    }
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const colorGroup = colorGroupForName(testType)

  return (
    <>
      <Tooltip
        open={tooltipOpen}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        title={unavailableReason}
        placement="top"
        interactive
        arrow
      >
        <Paper
          className={clsx(
            classes.testWrapper,
            // bg-purple-500 bg-pink-500 bg-sky-500 bg-yellow-500 bg-tangerine-500 bg-cyan-500
            isSelected ? `bg-${colorGroup}-500` : "",
            "fs-unmask",
            "transition-150 transition-bg transition-shadow transition-ease-in",
            "flex flex-col justify-between",
            "shadow-md hover:shadow-lg",
            { "cursor-pointer": isAvailable || isSelected }
          )}
          elevation={0}
          onClick={handleClick}
          tabIndex={0}
        >
          <div className="flex justify-between">
            {labTest.lab_test_types.length === 1 && (
              <LabTestCardSampleTypePill
                name={labTest.lab_test_types[0].name}
                isAvailable={isAvailable}
                isSelected={isSelected}
              />
            )}
            {labTest.lab_test_types.length > 1 && (
              <div className="flex items-center flex-wrap">
                {labTest.lab_test_types.map((type) => {
                  return (
                    <LabTestCardSampleTypePill
                      name={type.name}
                      key={type.name}
                      shouldTruncateName={true}
                      isSelected={isSelected}
                      isAvailable={isAvailable}
                    />
                  )
                })}
              </div>
            )}
            <div className="flex items-start">
              {!hideFavoritesButton && (
                <IconButton
                  className={classNames(
                    isSelected
                      ? // hover:bg-purple-500 hover:bg-pink-500 hover:bg-sky-500 hover:bg-yellow-500 hover:bg-tangerine-500 hover:bg-cyan-500
                        `hover:bg-${colorGroup}-500`
                      : "hover:bg-gray-200",
                    "p-px rounded w-6"
                  )}
                  onClick={handleToggleFavorite}
                  aria-label="Favorite test"
                >
                  <FavoriteIcon
                    fontSize="small"
                    style={{
                      color: !isAvailable ? "rgb(185, 186, 186)" : undefined,
                    }}
                    className={
                      isSelected
                        ? isFavorite
                          ? "text-white"
                          : `text-${colorGroup}-500`
                        : isFavorite
                        ? "text-pink-500"
                        : "text-gray-400"
                    }
                  />
                </IconButton>
              )}

              {!hideInfoButton && (
                <IconButton
                  className={classNames(
                    isSelected
                      ? `hover:bg-${colorGroup}-500`
                      : "hover:bg-gray-200",
                    "p-px rounded w-6"
                  )}
                  onClick={handleInfoClick}
                  aria-label="Test info"
                >
                  <InfoIcon
                    fontSize="small"
                    style={{
                      color: isSelected
                        ? "#fff"
                        : isAvailable
                        ? "#4D9AD5"
                        : "rgba(0, 0, 0, 0.26)",
                    }}
                  />
                </IconButton>
              )}
            </div>
          </div>
          <div>
            <Typography variant="body1" className="font-semibold text-gray-800">
              <span
                style={{
                  color:
                    !isAvailable && !isSelected ? "rgb(81, 81, 81)" : undefined,
                }}
                className={isSelected ? "text-white" : "text-gray-800"}
              >
                {labTest.name}
              </span>{" "}
              <br />
              <span
                style={{
                  color:
                    !isAvailable && !isSelected
                      ? "rgb(177, 178, 178)"
                      : undefined,
                }}
                className={
                  // text-purple-100 text-pink-100 text-sky-100 text-yellow-100 text-tangerine-100 text-cyan-100
                  isSelected ? `text-${colorGroup}-100` : "text-gray-600"
                }
              >
                {labTest.lab_company && labTest.lab_company.short_name}
              </span>
            </Typography>
          </div>
        </Paper>
      </Tooltip>
    </>
  )
}
