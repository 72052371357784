import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { makeStyles } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import Markdown from "app/components/Markdown"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { SelectFormField } from "app/components/forms/fields"
import { Dialog, ModalActions, ModalContent } from "app/components/modals"
import { submitOnboardingSurvey } from "app/main/dashboard/store/actions"
import { useConfetti } from "app/main/onboarding/confetti/confetti-provider"
import { Practitioner } from "app/types"

import { CheckboxFormsy } from "../../../@fuse/components/formsy"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogPaper: {
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    minHeight: "690px",
    maxWidth: "555px",
  },
  infoIcon: {
    marginLeft: 8,
  },
  formLabel: {
    display: "inline-flex",
    alignItems: "center",
  },
  selectItem: {
    borderRadius: "10px",
  },
  selectFormField: ({ isMobile }: { isMobile: boolean }) => ({
    marginBottom: isMobile ? "5px" : "10px",
  }),
  displayText: {
    textAlign: "center",
    weight: "semibold",
    fontFamily: "'Josefin Sans'",
    fontSize: "20px",
    lineHeight: "150%",
  },
  subheaderText: {
    textAlign: "center",
    color: "#65738B",
  },
  dialogContent: ({ isMobile }: { isMobile: boolean }) => ({
    padding: isMobile ? "1 !important" : 22.5,
    marginTop: "20px",
  }),
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  formContent: ({ isMobile }: { isMobile: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    gap: 10,
    width: isMobile ? "100%" : "72%",
    backgroundColor: "white",
  }),
  actionsContent: ({ isMobile }: { isMobile: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    width: isMobile ? "100%" : "72%",
    backgroundColor: "white",
  }),
  radioHeader: {
    alignSelf: "left",
    marginRight: "50px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "135%",
  },
  radioItem: {
    marginTop: "5px",
  },
  divider: {
    border: "1px solid #E2E8F0;",
    width: "100%",
    marginBottom: 0,
    marginTop: "20px",
  },
  modalButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    gap: 10,
  },
  modalButton: {
    height: "40.3px",
    width: "100%",
  },
  squareRadio: {
    "& .MuiSvgIcon-root": {
      borderRadius: "4px",
    },
    "& .Mui-checked .MuiSvgIcon-root": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover .MuiSvgIcon-root": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  radioText: {
    height: "19px",
  },
  icon: {
    width: 16,
    height: 16,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderRadius: "4px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
  },
  checkedIcon: {
    width: 16,
    height: 16,
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderRadius: "4px",
  },
  checkboxRow: {
    display: "flex",
    alignItems: "center",
  },
  checkboxLabel: {
    flex: 1,
    cursor: "pointer",
    marginLeft: -16,
  },
}))

export default function TaskBasedOnboardingSurveyModal({
  open,
  practitioner,
}: {
  open: boolean
  practitioner: Practitioner
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const classes = useStyles({ isMobile })

  const onSubmit = useCallback(
    (data) => {
      setIsSubmitting(true)
      dispatch(submitOnboardingSurvey(data, practitioner))
    },
    [setIsSubmitting, practitioner]
  )

  const triggerConfetti = useConfetti()

  useEffect(() => {
    if (open) {
      triggerConfetti()
    }
  }, [open])

  return (
    <>
      <Dialog
        open={open}
        scroll="body"
        className={classes.root}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <MuiDialogContent className={classes.dialogContent}>
          <DisplayText
            id="onboarding-survey-title"
            className={classes.displayText}
          >
            Welcome to Rupa!
          </DisplayText>
          <BodyText
            className={classes.subheaderText}
            id="onboarding-survey-description"
          >
            Let's get you set up 🔥
          </BodyText>
        </MuiDialogContent>
        <Formsy
          onValidSubmit={onSubmit}
          onValid={() => setIsValid(true)}
          onInvalid={() => setIsValid(false)}
          className={classes.formContainer}
        >
          <ModalContent className={classes.formContent}>
            {practitioner.onboarding_survey_questions.map(
              ({ label, name, type, options, info }) => {
                if (name === "onboarding_survey_blood_draws" && options) {
                  return (
                    <span key={name}>
                      <div className={classes.radioHeader}>
                        How would you like to handle blood draws?
                      </div>
                      {options.map((option) => (
                        <div
                          key={name + option.label}
                          className={classes.checkboxRow}
                        >
                          <CheckboxFormsy
                            color="primary"
                            name={option.value}
                            id={option.value}
                          />
                          <label
                            htmlFor={option.value}
                            className={classes.checkboxLabel}
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}

                      <div className={classes.divider} />
                    </span>
                  )
                }

                if (options) {
                  return (
                    <SelectFormField
                      className={classes.selectFormField}
                      required={false}
                      label={
                        <span className={classes.formLabel}>
                          {label}
                          {info && (
                            <Tooltip
                              arrow
                              placement="bottom"
                              title={<Markdown>{info}</Markdown>}
                              interactive
                            >
                              <img
                                src={InfoIcon}
                                className={classes.infoIcon}
                                alt=""
                              />
                            </Tooltip>
                          )}
                        </span>
                      }
                      name={name}
                      value={null}
                      isMobile={isMobile}
                      key={name}
                      children={options.map(({ value, label }) => (
                        <option key={label} value={value}>
                          {label}
                        </option>
                      ))}
                    />
                  )
                }
                return null
              }
            )}
          </ModalContent>
          <ModalActions className={classes.actionsContent}>
            <div className={classes.modalButtonContainer}>
              <Button
                loading={isSubmitting}
                className={classes.modalButton}
                type="submit"
                disabled={!isValid}
              >
                Get Started
              </Button>
            </div>
          </ModalActions>
        </Formsy>
      </Dialog>
    </>
  )
}
