import * as React from "react"

import clsx from "clsx"

import { ReactComponent as DownCaretIcon } from "app/assets/icons/carets/down-caret.svg"
import { ReactComponent as CloseBoxIcon } from "app/assets/icons/close-box.svg"
import { colors, primaryColor, white } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0.875, 1.25),
    borderRadius: 46,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: colors.blue[100],
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
  },
  containerDark: {
    backgroundColor: primaryColor,
    color: white,
    border: `1px solid ${primaryColor}`,
  },
  typeIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 6,
    width: 15,
    height: 18,
  },
  caretIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fill: "currentColor",
    marginLeft: theme.spacing(1.0),
    width: 15,
    height: 15,
  },
  removeIconButton: {
    marginLeft: theme.spacing(1),
    padding: 0,
    margin: 0,
    display: "inline-flex",
  },
}))

export default function SelectedPill({
  caret = false,
  fill = false,
  icon,
  label,
  onRemove,
}: {
  caret?: boolean
  fill?: boolean
  label: string
  icon: React.ReactNode
  onRemove: () => void
}) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, fill && classes.containerDark)}>
      <div className={classes.typeIcon}>{icon}</div>
      {label}
      {caret && <DownCaretIcon className={classes.caretIcon} />}
      <button
        className={classes.removeIconButton}
        onClick={onRemove}
        type="button"
      >
        <CloseBoxIcon
          fill={fill ? white : primaryColor}
          className="mb-[1px]"
          width={15}
          height={15}
        />
      </button>
    </div>
  )
}
