import Tooltip from "@material-ui/core/Tooltip"

import { ReactComponent as BiomarkerGroupIcon } from "app/assets/icons/biomarker-grouping.svg"
import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import {
  BiomarkerGroupingIdentifier,
  BiomarkerGrouping,
} from "types/biomarker_grouping"

import SelectedPill from "./SelectedPill"

const useStyles = makeAppStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: navy,
    fontSize: theme.typography.pxToRem(14),
    textAlign: "left",
    padding: 16,
    paddingTop: 8,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderRadius: 8,
    maxWidth: "none",
  },
  tooltipContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateRows: "repeat(6, min-content)",
    columnGap: 24,
    height: "fit-content",
  },
  tooltipBiomarkerIcon: {
    marginRight: theme.spacing(1),
  },
  tooltipItem: {
    marginTop: 8,
  },
}))

export default function BiomarkerGroupingPill({
  biomarkerGroupingIdentifier,
  onRemove,
}: {
  biomarkerGroupingIdentifier: BiomarkerGroupingIdentifier
  onRemove: () => void
}) {
  const classes = useStyles()
  const biomarkerGrouping = useCachedResource<BiomarkerGrouping>(
    biomarkerGroupingIdentifier
  )
  const biomarkers = useCachedCollection<Biomarker>(
    biomarkerGrouping?.relationships.biomarkers.data
  )

  if (!biomarkerGrouping) {
    return null
  }

  const tooltipBiomarkers = biomarkers.length ? (
    biomarkers.map((biomarker) => (
      <TooltipBiomarkerItem label={biomarker.attributes.short_name} />
    ))
  ) : (
    <>No biomarkers</>
  )

  const tooltipContent = (
    <div className={classes.tooltipContainer}>{tooltipBiomarkers}</div>
  )

  return (
    <Tooltip
      arrow={false}
      interactive
      placement="bottom"
      title={tooltipContent}
      disableHoverListener={!Boolean(biomarkers.length)}
      disableFocusListener={!Boolean(biomarkers.length)}
      disableTouchListener={!Boolean(biomarkers.length)}
      classes={{ tooltip: classes.tooltip }}
    >
      <div>
        <SelectedPill
          caret
          fill={false}
          icon={<BiomarkerGroupIcon fill="currentColor" />}
          label={`${biomarkerGrouping.attributes.name}`}
          onRemove={onRemove}
        />
      </div>
    </Tooltip>
  )
}

function TooltipBiomarkerItem({ label }: { label: string }) {
  const classes = useStyles()
  return (
    <div className={classes.tooltipItem}>
      <BiomarkerIcon className={classes.tooltipBiomarkerIcon} />
      {label}
    </div>
  )
}
