import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"

import Loading from "app/components/Loading"
import { arraysEqual } from "app/main/comparison/utils"
import useUpdateOrder from "app/main/patient-checkout/hooks/use-update-order"
import {
  PHLEBOTOMY_BOOKING_EVENTS,
  trackPhlebotomyBookingEvent,
} from "app/services/segment"
import { PHLEBOTOMY_PROVIDERS, PhlebotomyProviderName } from "app/types"

import { CombinedBloodDraw } from "./CombinedBloodDraw"
import { SelectProvider } from "./SelectProvider"
import {
  DHA_BODY_TEXT,
  FILLER_BODY_TEXT,
  LABCORP_BODY_TEXT,
  QUEST_BODY_TEXT,
} from "./constants"
import usePhlebotomyProviderAvailability, {
  AXLE_UNAVAILABLE_NOTIFICATIONS,
  EMPIRE_UNAVAILABLE_NOTIFICATION,
} from "./use-phlebotomy-provider-availability"
import {
  bloodDrawErrorMessage,
  bloodDrawWarningMessage,
  isSelectable,
  updateProviderList,
} from "./utils"

interface BloodDrawProps {
  patientBirthday: string | null
  zipCode: string | null
  checkoutToken: string
  isDhaPhlebIncludedAndLabCorpPhlebNotIncluded: boolean
  phlebProviders: PhlebotomyProviderName[]
  labCompanyNamesBloodDrawNotIncluded: string[]
  requiredNumberOfPhlebotomyProviders: number
  onBloodDrawModified: () => void
  onBloodDrawConfirmed: () => void
  allowAxle: boolean
}

export const BloodDraw = ({
  patientBirthday,
  zipCode,
  checkoutToken,
  isDhaPhlebIncludedAndLabCorpPhlebNotIncluded,
  phlebProviders,
  labCompanyNamesBloodDrawNotIncluded,
  requiredNumberOfPhlebotomyProviders,
  onBloodDrawModified,
  onBloodDrawConfirmed,
  allowAxle,
}: BloodDrawProps) => {
  const dispatch = useDispatch()

  const { isLoading, availableProviders, axleMessage } =
    usePhlebotomyProviderAvailability(
      zipCode,
      checkoutToken,
      patientBirthday,
      allowAxle
    )

  const includesLabcorp = phlebProviders.includes(PHLEBOTOMY_PROVIDERS.LABCORP)

  const includesQuest = phlebProviders.includes(PHLEBOTOMY_PROVIDERS.QUEST)

  const { updateOrder, isUpdatingOrder } = useUpdateOrder()

  useEffect(() => {
    if (zipCode && !isLoading) {
      trackPhlebotomyBookingEvent(
        PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_OPTIONS_SHOWN,
        { zip_code: zipCode, options: availableProviders }
      )
    }
  }, [zipCode, isLoading])

  const updatePhlebotomyProviders = useCallback(
    (providers: PhlebotomyProviderName[], message?: string) => {
      updateOrder({ phlebotomy_providers: providers })
        .then(() => {
          onBloodDrawConfirmed()
          if (message) {
            dispatch(bloodDrawWarningMessage(message))
          }
        })
        .catch(() => {
          dispatch(bloodDrawErrorMessage())
        })
    },
    []
  )

  // Update phlebotomy providers when available providers change
  useEffect(() => {
    if (isLoading || isUpdatingOrder) return

    let newProviders = [...phlebProviders]

    // Keep track of a message to show in case we have to remove their current
    // selection b/c it is no longer available
    let notificationMessage: string | undefined

    const updateNotificationMessage = (provider: PhlebotomyProviderName) => {
      if (provider === PHLEBOTOMY_PROVIDERS.AXLE) {
        notificationMessage =
          axleMessage ?? AXLE_UNAVAILABLE_NOTIFICATIONS.NOT_IN_AREA
      } else if (provider === PHLEBOTOMY_PROVIDERS.EMPIRE) {
        notificationMessage = EMPIRE_UNAVAILABLE_NOTIFICATION
      }
    }

    // Remove any unavailable providers (including patient_chosen)
    newProviders.forEach((provider, idx) => {
      if (isSelectable(provider) && !availableProviders.includes(provider)) {
        updateNotificationMessage(provider)
        newProviders.splice(idx, 1)
      }
    })

    // Add patient_chosen if no other providers are available
    if (
      zipCode &&
      availableProviders.length <= 1 &&
      newProviders?.length < requiredNumberOfPhlebotomyProviders
    ) {
      newProviders = updateProviderList(
        PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN,
        newProviders
      )
    }

    if (!arraysEqual(newProviders, phlebProviders)) {
      updatePhlebotomyProviders(newProviders, notificationMessage)
    }
  }, [availableProviders, isLoading, isUpdatingOrder])

  let body = <></>
  if (isLoading) {
    body = <Loading ariaLabel="Loading blood draw options" />
  } else if (requiredNumberOfPhlebotomyProviders > 1) {
    body = (
      <CombinedBloodDraw
        zipCode={zipCode}
        phlebProviders={phlebProviders}
        availableProviders={availableProviders}
        onBloodDrawModified={onBloodDrawModified}
        onBloodDrawConfirmed={onBloodDrawConfirmed}
        labCompanyNamesBloodDrawNotIncluded={
          labCompanyNamesBloodDrawNotIncluded
        }
        requiredNumberOfPhlebotomyProviders={
          requiredNumberOfPhlebotomyProviders
        }
      />
    )
  } else if (includesLabcorp) {
    if (isDhaPhlebIncludedAndLabCorpPhlebNotIncluded) {
      body = (
        <IncludedProviderBody
          drawSiteCompanyName={"Labcorp"}
          providerText={DHA_BODY_TEXT}
        />
      )
    } else {
      body = (
        <IncludedProviderBody
          drawSiteCompanyName={"Labcorp"}
          providerText={LABCORP_BODY_TEXT}
        />
      )
    }
  } else if (includesQuest) {
    body = (
      <IncludedProviderBody
        drawSiteCompanyName={"Quest"}
        providerText={QUEST_BODY_TEXT}
      />
    )
  } else if (!zipCode) {
    body = <div>{FILLER_BODY_TEXT}</div>
  } else if (!patientBirthday) {
    body = (
      <div>
        Your order requires a blood draw. Please enter your birthday above to
        see the options.
      </div>
    )
  } else if (zipCode && !isLoading && !(availableProviders.length > 1)) {
    body = (
      <div>
        <div>
          Your order requires a blood draw. <i>Not included in price.</i>
        </div>
        <div className="mt-8">
          <span className="font-semibold">How does this work? </span>We’ll email
          you instructions on how to find and schedule your blood draw. You will
          pay a separate fee directly to the blood draw site (typically
          $30-$100, varies per location).
        </div>
      </div>
    )
  } else if (zipCode && !isLoading && availableProviders.length > 1) {
    body = (
      <div>
        Your order requires a blood draw.
        <br></br>
        <br></br>
        <div className="font-semibold">Select an option below:</div>
        <br></br>
        <SelectProvider
          phlebProviders={phlebProviders}
          availableProviders={availableProviders}
          onFormIsModified={onBloodDrawModified}
          onFormIsConfirmed={onBloodDrawConfirmed}
        />
      </div>
    )
  } else {
    body = <div>Something went wrong.</div>
  }

  return (
    <div className="p-6 rounded-lg border border-slate-200 bg-white shadow">
      <div className="text-body text-2xl font-title font-semibold">
        Blood Draw
      </div>
      <div className="mt-8 text-body text-base15">{body}</div>
    </div>
  )
}

interface IncludedProviderBodyProps {
  drawSiteCompanyName: string
  providerText: string
}

export const IncludedProviderBody = ({
  drawSiteCompanyName,
  providerText,
}: IncludedProviderBodyProps) => {
  return (
    <div>
      <div>
        Your order requires a blood draw at a {drawSiteCompanyName} draw site.
      </div>
      <div className="mt-8">
        <span className="font-semibold">How does this work? </span>{" "}
        {providerText}
      </div>
    </div>
  )
}
