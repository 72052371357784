import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGroupingIdentifier } from "types/biomarker_grouping"

import { TYPE_KEYS } from "../constants"
import BiomarkerGroupingPill from "./BiomarkerGroupingPill"
import BiomarkerPill from "./BiomarkerPill"

const useStyles = makeAppStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
  },
}))

export default function SelectionPills({
  selectedItems,
  onRemove,
}: {
  selectedItems: (BiomarkerGroupingIdentifier | BiomarkerIdentifier)[]
  onRemove: (
    identifier: BiomarkerGroupingIdentifier | BiomarkerIdentifier
  ) => void
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {selectedItems.map((identifier) =>
        identifier.type === TYPE_KEYS.BIOMARKER ? (
          <BiomarkerPill
            biomarkerIdentifier={identifier}
            onRemove={() => onRemove(identifier)}
          />
        ) : (
          <BiomarkerGroupingPill
            biomarkerGroupingIdentifier={identifier}
            onRemove={() => onRemove(identifier)}
          />
        )
      )}
    </div>
  )
}
