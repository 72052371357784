import { useEffect, useState } from "react"

import { pdfjs } from "react-pdf"
import { Document, Page } from "react-pdf"

import {
  CircularProgress,
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core"

import { ReactComponent as CloseBoxIcon } from "app/assets/icons/close-box.svg"
import { ReactComponent as ZoomInIcon } from "app/assets/icons/zoom-in.svg"
import { ReactComponent as ZoomOutIcon } from "app/assets/icons/zoom-out.svg"
import DisplayText from "app/components/design-system/DisplayText"
import ViewerLoadingError from "app/results-summary/components/ResultsPDFViewer/ViewerLoadingError"
import ViewerLoadingPlaceholder from "app/results-summary/components/ResultsPDFViewer/ViewerLoadingPlaceholder"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor, red } from "app/theme"
import interleave from "app/utils/interleave"
import { Order } from "types/order"
import { OrderedResult } from "types/ordered-result"

import ResultsPDFThumbnail from "./ResultsPDFThumbnail"

// needed to prevent an error while loading the PDF.
// error: Error: Setting up fake worker failed: "Cannot read properties of undefined (reading 'WorkerMessageHandler')"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    padding: theme.spacing(3.0, 2.5),
  },
  documentContainer: {
    backgroundColor: colors.blueGray[100],
    width: "100%",
    flexGrow: 2,
    borderRadius: 7,
    overflow: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    gap: 10,
  },
  zoomButtons: {
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 8,
    height: 35,
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    padding: 8,
    gap: 8,
  },
  zoomButtonDivider: {
    width: 1,
    backgroundColor: colors.blueGray[300],
  },
  zoomButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  placeholder: {
    width: 86,
    height: 40,
    flexShrink: 10,
  },
  thumbnails: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    // add a fade out on left and right edge. works cross-browser.
    "-webkit-mask-image": `-webkit-gradient(linear, left center, right center,
        color-stop(0, transparent),
        color-stop(.07, black),
        color-stop(.93, black),
        color-stop(1, transparent))`,
    paddingRight: 15,
    paddingLeft: 15,
  },
  thumbnailDivider: {
    margin: "0px 5px",
  },
  pdfPage: {
    marginBottom: 5,
    backgroundColor: "white",
  },
  pdfDocument: {
    gap: 10,
  },
  pdfPlaceholder: {
    maxWidth: 540,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    gap: theme.spacing(2.0),
    padding: theme.spacing(4.0),
  },
  documentErrorIcon: {
    maxWidth: "100%",
    width: 120,
    fill: red,
    height: "auto",
  },
  pdfLoader: {
    maxWidth: "100%",
    height: "auto",
  },
}))

const DocumentError = () => {
  const classes = useStyles()
  return (
    <div className={classes.pdfPlaceholder}>
      <CloseBoxIcon className={classes.documentErrorIcon} viewBox="0 0 14 13" />
      <DisplayText size="2xl" variant="h1">
        File Not Found
      </DisplayText>
    </div>
  )
}

const ResultsPDFViewerContent = ({ order }: { order: Order }) => {
  const MIN_ZOOM_LEVEL = 0.25
  const MAX_ZOOM_LEVEL = 4.0
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const classes = useStyles({ isMobile })
  const [numPages, setNumPages] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(1.0)

  const orderedResultIdentifiers = order?.relationships.ordered_results.data
  const [selectedOrderedResultId, setSelectedOrderedResultId] =
    useState<string>()

  useEffect(() => {
    if (Boolean(order) && !selectedOrderedResultId) {
      setSelectedOrderedResultId(orderedResultIdentifiers[0]?.id)
    }
  }, [order])

  const selectedOrderedResultIdentifier = selectedOrderedResultId
    ? {
        type: "ordered_result",
        id: selectedOrderedResultId,
      }
    : undefined
  const selectedOrderedResult = useCachedResource<OrderedResult>(
    selectedOrderedResultIdentifier
  )

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function zoomIn() {
    if (zoomLevel < MAX_ZOOM_LEVEL) {
      setZoomLevel(zoomLevel * 2)
    }
  }

  function zoomOut() {
    if (zoomLevel > MIN_ZOOM_LEVEL) {
      setZoomLevel(zoomLevel / 2)
    }
  }

  function onThumbnailClick(id: string) {
    setSelectedOrderedResultId(id)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.zoomButtons}>
          <ZoomOutIcon
            fill={zoomLevel > MIN_ZOOM_LEVEL ? primaryColor : colors.gray[300]}
            viewBox="0 0 18 18"
            onClick={zoomOut}
            className={classes.zoomButton}
          />
          <div className={classes.zoomButtonDivider} />
          <ZoomInIcon
            fill={zoomLevel < MAX_ZOOM_LEVEL ? primaryColor : colors.gray[300]}
            viewBox="0 0 18 18"
            onClick={zoomIn}
            className={classes.zoomButton}
          />
        </div>

        <div className={classes.thumbnails}>
          {interleave(
            orderedResultIdentifiers.map((identifier) => (
              <ResultsPDFThumbnail
                orderedResultIdentifier={identifier}
                onThumbnailClick={onThumbnailClick}
                isActive={selectedOrderedResultId === identifier.id}
                key={identifier.id}
              />
            )),
            (_: any, index: number) => (
              <Divider
                className={classes.thumbnailDivider}
                flexItem
                orientation="vertical"
                variant="middle"
              />
            )
          )}
        </div>
        <div className={classes.placeholder} />
      </div>
      <div className={classes.documentContainer}>
        <Document
          className={classes.pdfDocument}
          error={() => <DocumentError />}
          file={selectedOrderedResult?.attributes.result_file}
          loading={() => (
            <div className={classes.pdfPlaceholder}>
              <CircularProgress size={24} className={classes.pdfLoader} />
            </div>
          )}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              className={classes.pdfPage}
              pageNumber={index + 1}
              scale={zoomLevel}
            />
          ))}
        </Document>
      </div>
    </div>
  )
}

export interface ResultsPDFViewerProps {
  orderSwr: UseResultsVisualizerResourcesHook["orderSwr"]
}

export default function ResultsPDFViewer({ orderSwr }: ResultsPDFViewerProps) {
  const { data: order, error: loadingError, isLoading } = orderSwr

  if (loadingError && !order) {
    return <ViewerLoadingError error={loadingError} />
  }

  if (isLoading || !order) {
    return <ViewerLoadingPlaceholder />
  }

  return <ResultsPDFViewerContent order={order} />
}
