import { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import { useLocation } from "react-router-dom"

import { Theme, useMediaQuery } from "@material-ui/core"

import { AllowAnyPaths, UserPaths } from "app/Routes"
import CatalogIconActive from "app/assets/icons/catalog-light-blue.svg"
import CatalogIcon from "app/assets/icons/catalog-white.svg"
import StoreIconActive from "app/assets/icons/ecommerce-store-blue.svg"
import StoreIcon from "app/assets/icons/ecommerce-store.svg"
import FoodPlanActiveIcon from "app/assets/icons/food-plan-light-blue.svg"
import FoodPlanIcon from "app/assets/icons/food-plan-white.svg"
import FindAPractitionerIconActive from "app/assets/icons/globe-light-blue.svg"
import FindAPractitionerIcon from "app/assets/icons/globe-white.svg"
import DashboardIconActive from "app/assets/icons/house-light-blue.svg"
import DashboardIcon from "app/assets/icons/house-white.svg"
import MessageBubble from "app/assets/icons/message-bubble.svg"
import PhlebotomyMapIconActive from "app/assets/icons/phlebotomy-map-active.svg"
import PhlebotomyMapIcon from "app/assets/icons/phlebotomy-map.svg"
import PromotionsTagIconActive from "app/assets/icons/promotions-tag-active.svg"
import PromotionsTagIcon from "app/assets/icons/promotions-tag-white.svg"
import UniversityIconActive from "app/assets/icons/rupa-university-light-blue.svg"
import UniversityIcon from "app/assets/icons/rupa-university.svg"
import ShieldIconActive from "app/assets/icons/shield-light-blue.svg"
import ShieldIcon from "app/assets/icons/shield-white.svg"
import TestTubesIconActive from "app/assets/icons/test-tubes-blue.svg"
import TestTubesIcon from "app/assets/icons/test-tubes.svg"
import CogIconActive from "app/assets/images/cog-icon-blue.svg"
import CogIcon from "app/assets/images/cog-icon.svg"
import { isUserPractitioner } from "app/auth/util"
import BetaChip from "app/components/design-system/BetaChip"
import { REFERENCE_GUIDE_URL } from "app/constants"
import useIsFoodPlansFeature from "app/food-plans/hooks/use-is-food-plans-feature"
import useAppSelector from "app/hooks/useAppSelector"
import useOnboardingSWR from "app/main/onboarding/hooks/use-onboarding-swr"
import { OnboardingMenuIcon } from "app/main/onboarding/onboarding-menu-icon"
import {
  FeatureFlag,
  FeatureFlagContext,
} from "app/providers/FeatureFlagProvider"
import { getPractitioner, getStripeConnectAccounts } from "app/store/actions"

import AccountMenu from "./AccountMenu/AccountMenu"
import NavBar from "./NavBar"
import NavBarDivider from "./NavBar/NavBarDivider"
import NavBarItem from "./NavBar/NavBarItem"
import NavBarItemGroup from "./NavBar/NavBarItemGroup"

interface Props {
  collapsed?: boolean
}

function UserNavBar({ collapsed }: Props) {
  const [mobileMenuToggled, setMobileMenuToggled] = useState(false)
  const location = useLocation()

  // Close the mobile menu when changing location
  useEffect(() => {
    if (mobileMenuToggled) {
      setMobileMenuToggled(false)
    }
  }, [location.pathname])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const user = useAppSelector(({ auth }) => auth.user)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [featureFlags] = useContext(FeatureFlagContext)
  const taskBasedOnboarding = featureFlags[FeatureFlag.TaskBasedOnboarding]
  const inOfficeKitsV2 = featureFlags[FeatureFlag.InOfficeKitsV2]
  const isPractitionerProfileEnabled =
    featureFlags[FeatureFlag.PractitionerProfiles]
  const isFoodPlansFeature = useIsFoodPlansFeature()

  const { data: onboardingData } = useOnboardingSWR()

  const [showInOfficeKits, setShowInOfficeKits] = useState(false)

  useEffect(() => {
    if (practitioner?.clinic?.clinic_features) {
      const showIOK = practitioner.clinic.clinic_features.includes(
        "allow_in_office_kits"
      )
      setShowInOfficeKits(showIOK && inOfficeKitsV2)
    }
  }, [practitioner])

  const showGettingStarted =
    taskBasedOnboarding && onboardingData?.show_onboarding

  const dispatch = useDispatch()

  const refreshKey = user.id

  useEffect(() => {
    if (refreshKey && isUserPractitioner(user)) {
      dispatch(getStripeConnectAccounts())
      dispatch(getPractitioner())
    }
  }, [refreshKey])

  const scaleText = isPractitionerProfileEnabled ? "Scale" : "Program"

  return (
    <NavBar
      collapsed={collapsed}
      footer={
        practitioner?.user && (
          <AccountMenu
            className={clsx("navbar-item", "navbar__account-menu")}
            firstName={practitioner.user.first_name}
            lastName={practitioner.user.last_name}
          />
        )
      }
    >
      <NavBarItemGroup>
        {showGettingStarted && (
          <>
            <NavBarItem
              customIcon={<OnboardingMenuIcon />}
              to={UserPaths.ONBOARDING}
            >
              Getting Started
            </NavBarItem>
            <NavBarDivider />
          </>
        )}
        <NavBarItem
          icon={DashboardIcon}
          activeIcon={DashboardIconActive}
          to={UserPaths.ROOT}
        >
          Dashboard
        </NavBarItem>
        <NavBarItem
          icon={CatalogIcon}
          activeIcon={CatalogIconActive}
          to={AllowAnyPaths.DISCOVER_LABS_SEARCH}
        >
          Lab Catalog
        </NavBarItem>
        <NavBarItem
          icon={UniversityIcon}
          activeIcon={UniversityIconActive}
          href={REFERENCE_GUIDE_URL}
          target="_self"
        >
          Learn About Labs
        </NavBarItem>
        {showInOfficeKits && (
          <NavBarItem
            icon={TestTubesIcon}
            activeIcon={TestTubesIconActive}
            to={UserPaths.IN_OFFICE_KITS}
          >
            In-Office Supplies
          </NavBarItem>
        )}
        <NavBarItem
          icon={CogIcon}
          activeIcon={CogIconActive}
          to={isMobile ? UserPaths.SETTINGS : UserPaths.ACCOUNT}
        >
          Settings
        </NavBarItem>
      </NavBarItemGroup>

      <NavBarItemGroup title={scaleText}>
        {isPractitionerProfileEnabled && (
          <NavBarItem
            icon={FindAPractitionerIcon}
            activeIcon={FindAPractitionerIconActive}
            to={UserPaths.FIND_A_PRACTITIONER}
          >
            Find a Practitioner
          </NavBarItem>
        )}
        <NavBarItem
          icon={StoreIcon}
          activeIcon={StoreIconActive}
          to={UserPaths.ECOMMERCE_STORE}
        >
          LabShop
        </NavBarItem>
      </NavBarItemGroup>

      <NavBarItemGroup title="RESOURCES">
        <NavBarItem
          icon={PromotionsTagIcon}
          activeIcon={PromotionsTagIconActive}
          to={UserPaths.PROMOTIONS}
        >
          Promotions
        </NavBarItem>
        <NavBarItem
          icon={PhlebotomyMapIcon}
          activeIcon={PhlebotomyMapIconActive}
          to={UserPaths.PHLEBOTOMY_MAP}
        >
          Phlebotomy Map
        </NavBarItem>
        <NavBarItem
          icon={ShieldIcon}
          activeIcon={ShieldIconActive}
          to={UserPaths.ORDERING_ACCESS}
        >
          Lab Ordering Access
        </NavBarItem>
        {isFoodPlansFeature && (
          <NavBarItem
            icon={FoodPlanIcon}
            activeIcon={FoodPlanActiveIcon}
            to={UserPaths.FOOD_PLANS}
          >
            <>
              Food Plans&nbsp;&nbsp;
              <BetaChip
                backgroundColor="rgba(255, 255, 255, 0.15)"
                color="white"
              />
            </>
          </NavBarItem>
        )}
        <NavBarItem
          icon={MessageBubble}
          activeIcon={MessageBubble}
          onClick={() => {
            window.Intercom("show")
          }}
        >
          Support
        </NavBarItem>
      </NavBarItemGroup>
    </NavBar>
  )
}

export default UserNavBar
