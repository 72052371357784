import clsx from "clsx"

import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodyText from "app/components/design-system/BodyText"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { colors } from "app/theme"

export default function BiomarkerValueSection({
  value,
  alternateValue,
  unit,
  status,
  small,
}: {
  value: string | undefined
  alternateValue: string | undefined
  unit: string
  status?: BiomarkerStatus
  small?: boolean
}) {
  const valueStyle = {
    [BiomarkerStatus.HIGH]: "text-red-900",
    [BiomarkerStatus.LOW]: "text-red-900",
    [BiomarkerStatus.ABNORMAL]: "text-red-900",
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: "text-red-700",
    [BiomarkerStatus.LOW]: "text-red-700",
    [BiomarkerStatus.ABNORMAL]: "text-red-700",
  }

  const displayValue = value ? value : alternateValue ?? ""

  const valueStyling = clsx(
    "text-base font-bold ml-1",
    small ? "text-sm" : "text-base"
  )

  const unitStyling = clsx(
    "text-xs ml-[1px]",
    small ? "text-[8px] mt-[4px]" : "mt-[6px] text-xs"
  )

  return (
    // Split into two sections to align on the units
    <div className={"flex justify-center font-mono w-auto md:w-full"}>
      <div className={"text-right flex basis-1/2 justify-end items-center"}>
        {status === BiomarkerStatus.HIGH && (
          <FontAwesomeIcon
            className={clsx("mr-1", small ? "text-sm mt-[1px]" : "")}
            icon={faArrowUp}
            color={colors.rose[500]}
          />
        )}
        {status === BiomarkerStatus.LOW && (
          <FontAwesomeIcon
            className={clsx("mr-1", small ? "text-sm mt-[1px]" : "")}
            icon={faArrowDown}
            color={colors.rose[500]}
          />
        )}
        {status !== BiomarkerStatus.LOW && status !== BiomarkerStatus.HIGH && (
          <div className="mr-1 w-[11.25px] h-1"></div>
        )}
        <BodyText
          className={clsx(valueStyling, status ? valueStyle[status] : "")}
        >
          {displayValue}
        </BodyText>
      </div>
      <div className="text-left flex basis-1/2">
        {displayValue && (
          <div>
            <div
              className={clsx(
                unitStyling,
                status && statusStyle[status]
                  ? statusStyle[status]
                  : "text-slate-400"
              )}
            >
              {unit}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
