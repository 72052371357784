import { useMemo } from "react"

import clsx from "clsx"
import { differenceInMonths, parseISO } from "date-fns"
import { useHistory, useParams } from "react-router-dom"

import { Button } from "@rupahealth/design"

import AllOrdersIconActive from "app/assets/icons/all-orders-light-blue.svg"
import AllOrdersIcon from "app/assets/icons/all-orders.svg"
import { ReactComponent as ChartIcon } from "app/assets/icons/chart-sharp.svg"
import { ReactComponent as FileIcon } from "app/assets/icons/file.svg"
import LabTestIconActive from "app/assets/icons/lab-test-light-blue.svg"
import LabTestIcon from "app/assets/icons/lab-test-white.svg"
import MessageBubbleActiveIcon from "app/assets/icons/message-bubble-light-blue.svg"
import MessageBubbleIcon from "app/assets/icons/message-bubble.svg"
import PeopleIconWhite from "app/assets/icons/onboarding/people-white.svg"
import authService from "app/auth/services/simplejwt-auth-service"
import AccountMenu from "app/components/AccountMenu/AccountMenu"
import AccountMenuItem from "app/components/AccountMenu/AccountMenuItem"
import NavBar, { NavBarItem } from "app/components/NavBar"
import NavBarDivider from "app/components/NavBar/NavBarDivider"
import NavBarItemGroup from "app/components/NavBar/NavBarItemGroup"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors } from "app/theme"
import formatDateForHuman from "app/utils/format-date-for-human"

import { PatientPortalUser } from "./types"
import usePatientPortalUserOrders from "./user-orders/hooks/use-patient-portal-user-orders"
import usePatientPortalUsers from "./users/hooks/use-patient-portal-users"
import {
  buildPatientPortalUserSupportUrl,
  buildPatientPortalUserUrl,
  buildPatientPortalUserTrendsUrl,
  buildPatientPortalUserDocumentsUrl,
} from "./util"

const PatientPortalUserNavBar = () => {
  const [patientPortalTrendsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalTrends
  )
  const [patientPortalDocumentsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalDocuments
  )
  const { userId } = useParams<{
    userId: string
  }>()

  const user = useCachedResource<PatientPortalUser>({
    id: userId,
    type: "patient_portal_user",
  })

  const { users } = usePatientPortalUsers()
  const userToShow = user
    ? {
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
      }
    : null

  const { orders } = usePatientPortalUserOrders(userId)
  const ordersToShow = useMemo(() => {
    const today = new Date()

    return orders.filter((order, index) => {
      if (!order.attributes.date_patient_checkout) {
        return false
      }

      const isFirstOrder = index === 0
      const isIncomplete = order.attributes.status !== "Complete"
      const isWithin6Months =
        differenceInMonths(
          today,
          parseISO(order.attributes.date_patient_checkout)
        ) <= 6

      return isFirstOrder || (isIncomplete && isWithin6Months)
    })
  }, [orders])
  const history = useHistory()

  return (
    <NavBar
      floating
      footer={
        <>
          <NavBarDivider />
          {userToShow ? (
            <AccountMenu
              className={clsx("navbar-item", "navbar__account-menu")}
              firstName={userToShow.firstName}
              lastName={userToShow.lastName}
              floating
            >
              {users.length > 1 && (
                <AccountMenuItem
                  icon={PeopleIconWhite}
                  onClick={() => {
                    history.push(buildPatientPortalUserUrl())
                  }}
                  label="Select a Patient"
                  floating
                />
              )}
            </AccountMenu>
          ) : (
            <Button
              variant={null}
              className="text-white w-fit font-semibold"
              onClick={() => authService.logout()}
            >
              Sign Out
            </Button>
          )}
        </>
      }
    >
      <NavBarItemGroup>
        <BodyText
          size="md"
          className="text-white/60 font-semibold mt-1.5 sm:mt-0"
        >
          Welcome to your Patient Portal
          {userToShow && (
            <>
              ,
              <BodyText size="md" weight="bold" className="text-white inline">
                {` ${userToShow.firstName}`}
              </BodyText>
            </>
          )}
          .
        </BodyText>
        <NavBarDivider />
        {ordersToShow.length > 0 && (
          <>
            {ordersToShow.map((order) => (
              <NavBarItem
                icon={LabTestIcon}
                activeIcon={LabTestIconActive}
                iconInContainer
                to={buildPatientPortalUserUrl(userId, order.id)}
                activeRequiresExact
                key={order.id}
              >
                Lab Order
                <BodyText size="sm" className="text-white">
                  {formatDateForHuman(order.attributes.date_patient_checkout)}
                </BodyText>
              </NavBarItem>
            ))}
            <NavBarDivider />
          </>
        )}
        {patientPortalTrendsEnabled && userId && (
          <NavBarItem
            icon={<ChartIcon fill="white" />}
            activeIcon={<ChartIcon fill={colors.cyan[300]} />}
            to={buildPatientPortalUserTrendsUrl(userId)}
            activeRequiresExact
          >
            Trends
          </NavBarItem>
        )}
        {patientPortalDocumentsEnabled && userId && (
          <NavBarItem
            icon={<FileIcon fill="white" />}
            activeIcon={<FileIcon fill={colors.cyan[300]} />}
            to={buildPatientPortalUserDocumentsUrl(userId)}
            activeRequiresExact
          >
            Documents
          </NavBarItem>
        )}
      </NavBarItemGroup>
      {userId && (
        <div className="h-full flex flex-col">
          <div className="mt-auto">
            <NavBarItem
              icon={MessageBubbleIcon}
              activeIcon={MessageBubbleActiveIcon}
              to={buildPatientPortalUserSupportUrl(userId)}
              activeRequiresExact
            >
              Get Support
            </NavBarItem>
            <NavBarItem
              icon={AllOrdersIcon}
              activeIcon={AllOrdersIconActive}
              to={buildPatientPortalUserUrl(userId)}
              activeRequiresExact
            >
              Past Orders
            </NavBarItem>
          </div>
        </div>
      )}
    </NavBar>
  )
}

export default PatientPortalUserNavBar
