import { ORDERED_TEST_STATUS } from "app/constants.typed"
import DownloadRequisitionButton from "app/patient-portal/order/components/DownloadRequisitionButton"
import DownloadResultsButton from "app/patient-portal/order/components/DownloadResultsButton"
import { PatientPortalOrderedTest } from "app/patient-portal/types"

export interface PatientPortalOrderKitListKitTestActionProps {
  orderedTest: PatientPortalOrderedTest
}

export default function PatientPortalOrderKitListKitTestAction({
  orderedTest,
}: PatientPortalOrderKitListKitTestActionProps) {
  switch (orderedTest.attributes.status) {
    case ORDERED_TEST_STATUS.COMPLETE: {
      return <DownloadResultsButton orderedTest={orderedTest} />
    }
    case ORDERED_TEST_STATUS.ORDERED_WITH_LAB: {
      return <DownloadRequisitionButton orderedTest={orderedTest} />
    }
    case ORDERED_TEST_STATUS.PAID:
      if (orderedTest.attributes.requisition) {
        return <DownloadRequisitionButton orderedTest={orderedTest} />
      }
      return null
    default:
      return null
  }
}
