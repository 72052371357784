import { ReactComponent as CircleCheckIcon } from "app/assets/icons/check-circle.svg"
import { ReactComponent as CircleCrossIcon } from "app/assets/icons/circular-cross.svg"
import SampleTypePill from "app/components/SampleTypePill"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { BIOMARKERS_TO_SHOW_SAMPLE_TYPES } from "../../constants"
import { PanelLabTestType } from "../../types"

const useStyles = makeAppStyles((theme) => ({
  sectionColumn: {
    minWidth: 364,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  columnItem: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  columnItemIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
}))

export default function PanelDetailsSectionColumn({
  sectionItems,
}: {
  sectionItems: {
    name: string
    included?: boolean
    sample_types?: PanelLabTestType[]
  }[]
}) {
  const classes = useStyles()

  return (
    <div className={classes.sectionColumn}>
      {sectionItems.map((items) => (
        <div className={classes.columnItem}>
          {items.included !== undefined ? (
            items.included ? (
              <CircleCheckIcon
                fill={colors.green[500]}
                viewBox="0 0 14 14"
                className={classes.columnItemIcon}
              />
            ) : (
              <CircleCrossIcon
                fill={colors.red[500]}
                viewBox="0 0 23 23"
                className={classes.columnItemIcon}
              />
            )
          ) : (
            ""
          )}
          {items.name}
          {items.sample_types &&
            items.sample_types.map(
              (sample_type) =>
                BIOMARKERS_TO_SHOW_SAMPLE_TYPES[items.name] && (
                  <SampleTypePill name={sample_type.key} />
                )
            )}
        </div>
      ))}
    </div>
  )
}
