import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import Link from "@material-ui/core/Link"

import AddIcon from "app/assets/icons/add-blue.svg"
import CheckIcon from "app/assets/icons/blue-checkmark.svg"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import {
  ORDERING_ISSUE_KEYS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { getUnavailableReason } from "app/dataServices/orderingRights"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import {
  ComboGroupConfigurationPayload,
  ComboGroupEvents,
} from "app/main/combo-groups/constants"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { trackEventWithProperties } from "app/services/segment.typed"
import * as Actions from "app/store/actions"
import { colors, primaryColor } from "app/theme"
import { AnyLimitedLabTest, Practitioner, labTestLocation } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import Tooltip from "../Tooltip"
import Button from "../design-system/Button"
import useComboGroupConfigurationModal from "../modals/ComboGroupModal/hooks/use-combo-group-configuration-modal"
import { SelectedLabTests } from "./types"

const useStyles = makeAppStyles<{ isInCart: boolean }>({
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    width: "100%",
    gap: 12,
    flexWrap: "wrap",
  },
  detailsButton: {
    flex: "1 1 auto",
  },
  toggleButton: {
    flex: "1 1 180px",

    "& button": {
      display: "flex",
      background: (props) => (props.isInCart ? colors.lightBlue[50] : "white"),
      border: (props) =>
        `1px solid ${props.isInCart ? primaryColor : colors.blueGray[300]}`,
      alignItems: "center",
      width: "100%",
    },
  },
  toggleIcon: {
    marginTop: -3,
    width: 12,
  },
  link: {
    color: primaryColor,
    cursor: "pointer",
  },
})

interface Props {
  labTest: AnyLimitedLabTest
  location: labTestLocation
  setLabTestInModal: (labTest: AnyLimitedLabTest) => void
  selectedLabTests?: SelectedLabTests
  onAddLabTest?: (labTest: AnyLimitedLabTest) => void
  onAddComboGroup?: (
    labTest: AnyLimitedLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
  onRemoveLabTest?: (labTest: AnyLimitedLabTest) => void
  signingPractitioner?: Practitioner
  practitioner?: Practitioner
  createBundleClick?: () => void
  // This is a prop specific only to LabShops at the moment (but would be useful elsewhere).
  // If true, the LabShop is using phys services, else it is using the signingPractitioner's ordering rights
  vendorPhysicianServicesActivated?: boolean
}

function LabTestButtons(props: Props) {
  const dispatch = useDispatch()

  const isInCart = props.selectedLabTests
    ? Boolean(props.selectedLabTests[props.labTest.id])
    : false

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  // Show tooltip with button to override lab company restriction
  const [showOverrideOption] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )

  const [isInternationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )

  // handle bug where modal opens and tooltip still shows
  const [hideTooltip, setHideTooltip] = useState(true)

  let alreadyAddedCopy = ""
  let addCopy = ""

  if (props.location === labTestLocation.CATALOG) {
    // The toggle button isn't render on catalog
    alreadyAddedCopy = ""
    addCopy = ""
  } else if (props.location === labTestLocation.CHECKOUT) {
    alreadyAddedCopy = "In Cart"
    addCopy = "Add to Cart"
  } else if (props.location === labTestLocation.BUNDLES) {
    alreadyAddedCopy = "In Bundle"
    addCopy = "Add to Bundle"
  } else if (props.location === labTestLocation.COMPARISON) {
    alreadyAddedCopy = "In Comparison"
    addCopy = "Add to Comparison"
  } else if (props.location === labTestLocation.ECOMMERCE) {
    alreadyAddedCopy = "In Store"
    addCopy = "Add to Store"
  } else {
    // This will only happen if we add a new location without
    // updating the copy
    alreadyAddedCopy = "Added"
    addCopy = "Add"
  }
  const toggleCopy =
    props.selectedLabTests && props.selectedLabTests[props.labTest.id]
      ? alreadyAddedCopy
      : addCopy

  const styles = useStyles({ isInCart })

  const comboGroupConfigurationModal = useComboGroupConfigurationModal()

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const physicianServicesText = "You must add "

  const overrideAccessAction = (includeClinicState: boolean) => {
    dispatch(
      Actions.setLabTestForOverride(
        props.labTest.lab_company,
        includeClinicState ? props.practitioner?.clinic?.state : undefined
      )
    )
    setHideTooltip(true)
  }

  const getAddOnMissingParentErrorComponent = (
    labTest: AnyLimitedLabTest,
    createBundleClick?: () => void
  ) => {
    const parentTestNames = labTest.parents.map((test) => test.name).join(", ")
    if (labTest.parents.length > 1) {
      return (
        <span>
          This test is an add-on. For the best client experience in your
          LabShop, please{" "}
          <Link style={{ cursor: "pointer" }} onClick={createBundleClick}>
            create a bundle
          </Link>{" "}
          with the one of the following parent tests: {parentTestNames}, plus
          the add-on.
        </span>
      )
    } else {
      return (
        <span>
          This test is an add-on. For the best client experience in your
          LabShop, please{" "}
          <Link style={{ cursor: "pointer" }} onClick={createBundleClick}>
            create a bundle
          </Link>{" "}
          with the parent test {parentTestNames}, plus the add-on.
        </span>
      )
    }
  }

  const unavailableReason = useMemo(() => {
    if (
      isInternationalClinicsEnabled &&
      props.practitioner?.clinic?.is_international_clinic &&
      !props.vendorPhysicianServicesActivated
    ) {
      return (
        <span>
          {physicianServicesText}
          <span
            id="physician-services-link"
            onClick={() => {
              physicianServicesOptInModal.show({
                onClose: () => {
                  physicianServicesOptInModal.remove()
                },
              })
            }}
            className={styles.link}
          >
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
          </span>{" "}
          to order as a practitioner outside of the US.
        </span>
      )
    }

    // custom component for add on missing parent error for labshops only
    if (
      [labTestLocation.ECOMMERCE, labTestLocation.ECOMMERCE_BUNDLES].includes(
        props.location
      ) &&
      !props.labTest.ordering_rights_status?.allowed &&
      props.labTest.ordering_rights_status?.error_ordering_issues[0].key ===
        ORDERING_ISSUE_KEYS.ADD_ON_MISSING_PARENT
    ) {
      return getAddOnMissingParentErrorComponent(
        props.labTest,
        props.createBundleClick
      )
    }
    // New Ordering Rights code
    return getUnavailableReason({
      labTest: props.labTest,
      signingPractitioner: props.signingPractitioner,
      orderingPractitioner: props.practitioner,
      overrideAccessAction,
      showOverrideOption,
    })
  }, [props.labTest, props.signingPractitioner])

  const handleOnAddToCart = async (payload: ComboGroupConfigurationPayload) => {
    if (props.onAddComboGroup) {
      await props.onAddComboGroup(props.labTest, payload)
      comboGroupConfigurationModal.hide()
    }
  }

  return (
    <div className={styles.buttons}>
      <Button
        className={styles.detailsButton}
        color={
          props.location === labTestLocation.CATALOG ? "secondary" : "noaction"
        }
        onClick={() => {
          props.setLabTestInModal(props.labTest)

          if (props.labTest.combo_group) {
            trackEventWithProperties(
              ComboGroupEvents.COMBO_GROUP_LAB_TEST_DETAILS_CLICKED,
              {
                comboGroupLabTestId: props.labTest.id,
                practitionerId: practitioner.id,
                comboGroupId: props.labTest.combo_group,
              }
            )
          }
        }}
        aria-label="Lab test details"
      >
        Details
      </Button>
      {props.selectedLabTests && (
        <Tooltip
          title={hideTooltip ? "" : unavailableReason}
          placement="top"
          arrow
          interactive
          open={!hideTooltip}
          // disableHoverListener is a little flaky in e2e tests, so we use this instead.
          onOpen={() => unavailableReason && setHideTooltip(false)}
          onClose={() => unavailableReason && setHideTooltip(true)}
        >
          <div className={styles.toggleButton}>
            <Button
              disabled={Boolean(unavailableReason)}
              color="secondary"
              aria-label={toggleCopy}
              startIcon={
                ((props.location !== labTestLocation.COMPARISON &&
                  props.location !== labTestLocation.ECOMMERCE) ||
                  isInCart) &&
                !unavailableReason && (
                  <img
                    src={isInCart ? CheckIcon : AddIcon}
                    className={styles.toggleIcon}
                    alt=""
                  />
                )
              }
              onClick={() => {
                // For type safety
                if (
                  !props.selectedLabTests ||
                  !props.onAddLabTest ||
                  !props.onRemoveLabTest
                ) {
                  return
                }

                if (isInCart) {
                  props.onRemoveLabTest(props.labTest)
                } else {
                  if (props.labTest.combo_group) {
                    comboGroupConfigurationModal.show({
                      updating: false,
                      initialTargetId: props.labTest.id,
                      comboGroupId: props.labTest.combo_group,
                      initialOptionIds: [],
                      initialAddOnIds: [],
                      onAddToCart: handleOnAddToCart,
                    })
                    trackEventWithProperties(
                      ComboGroupEvents.COMBO_GROUP_LAB_TEST_ADD_TO_CART_CLICKED,
                      {
                        comboGroupLabTestId: props.labTest.id,
                        practitionerId: practitioner.id,
                        comboGroupId: props.labTest.combo_group,
                      }
                    )
                  } else {
                    props.onAddLabTest(props.labTest)
                  }
                }
              }}
            >
              {toggleCopy}
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default LabTestButtons
