import { useMemo } from "react"

import { isEmpty } from "lodash"

import { ReactComponent as ListIcon } from "app/assets/icons/list.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import SampleTypeChip from "app/components/design-system/SampleTypeChip"
import { KIT_GROUP_TYPE } from "app/constants.typed"
import PatientOrdersOrderedTestPanelTooltip from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestPanelTooltip"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardLabCompany } from "types/dashboard/dashboard-lab-company"
import { DashboardOrderedTest } from "types/dashboard/dashboard-ordered-test"

const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    maxWidth: 300,
    wordBreak: "break-word",
  },
  names: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.25),
  },
  sampleTypes: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.25),
  },
  testListTooltip: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
    cursor: "default",
  },
  testList: {
    padding: theme.spacing(0, 0, 0, 2.0),
    textAlign: "left",
  },
}))

const TestNamesTooltip = ({
  label,
  orderedTests,
}: {
  label: string
  orderedTests: DashboardOrderedTest[]
}) => {
  const classes = useStyles()
  return (
    <div>
      <Tooltip
        arrow
        className={classes.testListTooltip}
        interactive
        placement="bottom-start"
        title={
          <ul className={classes.testList}>
            {orderedTests.map((orderedTest) => (
              <li key={orderedTest.id}>{orderedTest.attributes.name}</li>
            ))}
          </ul>
        }
      >
        <div>
          <BodyText color="primary" weight="semibold">
            {label}
          </BodyText>
          <ListIcon />
        </div>
      </Tooltip>
    </div>
  )
}

const TableRowDetailPanelNames = ({ kit }: { kit: DashboardKit }) => {
  const orderedTests = useCachedCollection<DashboardOrderedTest>(
    kit.relationships.ordered_tests?.data
  )

  if (isEmpty(orderedTests)) {
    return null
  }

  return (
    <PatientOrdersOrderedTestPanelTooltip
      panelLabTestNames={orderedTests.map(
        (orderedTest) => orderedTest.attributes.name
      )}
    />
  )
}

const TableRowDetailAddonNames = ({ kit }: { kit: DashboardKit }) => {
  const addonOrderedTestIdentifiers = useMemo(() => {
    const allTests = kit.relationships.ordered_tests?.data
    const primaryTest = kit.relationships.primary_ordered_test?.data
    return allTests?.filter((test) => test.id !== primaryTest?.id)
  }, [
    kit.relationships.ordered_tests?.data,
    kit.relationships.primary_ordered_test?.data,
  ])

  const addonOrderedTests = useCachedCollection<DashboardOrderedTest>(
    addonOrderedTestIdentifiers
  )

  if (isEmpty(addonOrderedTests)) {
    return null
  }

  return <TestNamesTooltip label="Add-ons" orderedTests={addonOrderedTests} />
}

export default function TableRowDetailKitName({ kit }: { kit: DashboardKit }) {
  const classes = useStyles()
  const labCompany = useCachedResource<DashboardLabCompany>(
    kit.relationships.lab_company?.data
  )
  const labTestTypes = kit.attributes.lab_test_types
  return (
    <div className={classes.root}>
      <div className={classes.names}>
        <BodyText size="base" weight="semibold">
          {kit.attributes.display_name}
        </BodyText>
        {labCompany && <BodyText>{labCompany.attributes.name}</BodyText>}
      </div>

      {labTestTypes && (
        <div className={classes.sampleTypes}>
          {labTestTypes.map((labTestType, idx) => {
            const testType = labTestType.toLowerCase()
            return <SampleTypeChip key={`${testType}-${idx}`} name={testType} />
          })}
        </div>
      )}

      {kit.attributes.group_type === KIT_GROUP_TYPE.PANEL && (
        <TableRowDetailPanelNames kit={kit} />
      )}
      {kit.attributes.group_type === KIT_GROUP_TYPE.PARENT && (
        <TableRowDetailAddonNames kit={kit} />
      )}
    </div>
  )
}
