import * as React from "react"

import { IconButton, styled } from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { Add } from "@material-ui/icons"
import CloseIcon from "@material-ui/icons/Close"
import FavoriteIcon from "@material-ui/icons/Favorite"

import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import CompareTestsButton from "app/main/comparison/components/CompareTestsButton"
import { colors, navy, favoritedColor } from "app/theme"
import { AnyLabTest, labTestLocation, Practitioner } from "app/types"
import { getStartDraftText } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
  width: "auto",

  [theme.breakpoints.up("md")]: {
    width: 840,
    justifyContent: "space-between",
    alignItems: "center",
    flexFlow: "row",
    padding: theme.spacing(3, 3, 2.25),
  },
}))

const TitleAndFavoriteButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginRight: 24,
  alignItems: "center",
  width: "60%",
})

const TitleButton = styled(Button)(() => ({
  alignItems: "center",
  width: "100%",
}))

const useStyles = makeAppStyles<{
  isFavorited: boolean
}>((theme) => ({
  actionsContainer: {
    flex: "1 1 auto",
    display: "flex",
    flexFlow: "column-reverse",
    alignItems: "stretch",
    justifyContent: "flex-end",
    gap: theme.spacing(2.0),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  favoriteIcon: {
    width: 15,
    height: 15,
    color: (props) =>
      props.isFavorited ? favoritedColor : colors.blueGray[300],

    [theme.breakpoints.up("sm")]: {
      width: 20,
      height: 20,
    },
  },
}))

const StyledIconButton = styled(IconButton)({
  padding: 6,
})

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: "absolute",
  top: 1,
  right: 12,
  marginRight: -12,
  fontSize: 24,

  [theme.breakpoints.up("sm")]: {
    position: "relative",
    right: 0,
  },
}))

const pageToTitle: { [k in labTestLocation]: string } = {
  [labTestLocation.BUNDLES]: "Add to Bundle",
  [labTestLocation.CATALOG]: "Start an Order",
  [labTestLocation.CHECKOUT]: "Add to Cart",
  [labTestLocation.COMPARISON]: "Add to Comparison",
  [labTestLocation.ECOMMERCE]: "Add to Store",
  [labTestLocation.ECOMMERCE_BUNDLES]: "Add to Store",
  [labTestLocation.BUNDLES_SETTINGS]: "Add to Bundles",
}

const getTitleButtonText = (
  location: labTestLocation,
  practitioner?: Practitioner
) => {
  if (!practitioner || location !== labTestLocation.CATALOG) {
    return pageToTitle[location]
  }

  return getStartDraftText(practitioner)
}

interface Props {
  labTest?: AnyLabTest
  title: string
  location: labTestLocation
  unavailableReason: React.ReactChild
  onClose: () => void
  isFavorited: boolean
  shouldShowTitleButton: boolean
  shouldShowFavoriteButton: boolean
  onClickTitleButton?: () => void
  onClickFavorite: (labTest: AnyLabTest) => void
  hideTooltip: boolean
  handleHiddenTooltip: () => void
  practitioner?: Practitioner
}

const TitleSection = ({
  labTest,
  title,
  location,
  unavailableReason,
  onClose,
  isFavorited,
  shouldShowTitleButton,
  shouldShowFavoriteButton,
  onClickTitleButton,
  onClickFavorite,
  hideTooltip,
  handleHiddenTooltip,
  practitioner,
}: Props) => {
  const titleButtonText = getTitleButtonText(location, practitioner)
  const isBundleLocation = location === labTestLocation.BUNDLES
  const isComparisonLocation = location === labTestLocation.COMPARISON
  const renderCompareTestsButton = !isBundleLocation && !isComparisonLocation
  const styles = useStyles({
    isFavorited,
  })

  return (
    <StyledMuiDialogTitle disableTypography>
      <TitleAndFavoriteButtonContainer>
        <DisplayText className="mt-1" weight="semibold" size="lg">
          {title}
        </DisplayText>
        {shouldShowFavoriteButton && (
          <StyledIconButton
            aria-label="Favorite lab test"
            className="ml-1"
            onClick={() => labTest && onClickFavorite(labTest)}
          >
            <FavoriteIcon className={styles.favoriteIcon} />
          </StyledIconButton>
        )}
      </TitleAndFavoriteButtonContainer>
      <div className={styles.actionsContainer}>
        {renderCompareTestsButton && <CompareTestsButton labTest={labTest} />}
        {shouldShowTitleButton && titleButtonText && onClickTitleButton && (
          <Tooltip
            title={hideTooltip ? "" : unavailableReason}
            placement="top"
            arrow
            disableHoverListener={!unavailableReason}
            key="title-button-and-tooltip"
            interactive
          >
            <div onMouseOver={handleHiddenTooltip}>
              <TitleButton
                onClick={onClickTitleButton}
                color="primary"
                startIcon={<Add />}
                size="small"
                disabled={Boolean(unavailableReason)}
              >
                {titleButtonText}
              </TitleButton>
            </div>
          </Tooltip>
        )}
        {onClose && (
          <CloseButton aria-label="close" onClick={onClose} key="close-button">
            <CloseIcon fontSize="inherit" />
          </CloseButton>
        )}
      </div>
    </StyledMuiDialogTitle>
  )
}

export default TitleSection
