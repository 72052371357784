import { useState } from "react"
import * as React from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { Dialog, ModalHeader, ModalContent } from "app/components/modals"
import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 150,
    marginTop: 16,
    marginBottom: 16,
    marginRight: 22.5,
  },
  content: {
    marginRight: 6.5,
  },
  dialog: {
    width: "100%",
  },
  textField: {
    backgroundColor: "white",
  },
  cancelButton: {
    color: navy,
  },
  textContent: {
    marginTop: 22,
    marginBottom: 22,
  },
}))

interface NamePanelModalProps {
  onClose: () => void
  onSubmit: (name: string, shareWithClinic: boolean) => void
  existingName?: string
}

const NamePanelModal = ({
  open,
  onClose,
  existingName,
  onSubmit,
}: {
  open: boolean
  onClose: () => void
  existingName?: string
  onSubmit: (name: string, shareWithClinic: boolean) => void
}) => {
  const classes = useStyles()
  const [panelName, setPanelName] = useState(existingName)
  const [shareWithClinic, setShareWithClinic] = useState(false)

  const title = existingName ? "Confirm Panel Update" : "Create Your Panel"
  const subtitle = existingName
    ? "Updating this panel will completely change its contents. Are you sure you want to update it?"
    : undefined

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="name-panel-modal"
        open={open}
        classes={{ paper: classes.dialog }}
        maxWidth="sm"
      >
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalContent>
          <BodyText size="sm" weight="semibold">
            Panel Name
          </BodyText>
          <div className={classes.content}>
            <TextField
              data-cy="panel-name-input"
              fullWidth
              variant="outlined"
              margin="dense"
              className={classes.textField}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPanelName(e.target.value)
              }
              value={panelName}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  panelName && onSubmit(panelName, shareWithClinic)
                }
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={shareWithClinic}
                  onChange={(event) => {
                    setShareWithClinic(event.target.checked)
                  }}
                  color="primary"
                  size="small"
                />
              }
              label="Share with Clinic"
            />
          </div>
        </ModalContent>
        <div className={classes.buttonContainer}>
          {existingName && (
            <Button
              className={classes.button}
              onClick={onClose}
              color="secondary"
            >
              <div className={classes.cancelButton}>No, Go Back</div>
            </Button>
          )}
          <Button
            className={classes.button}
            onClick={() => panelName && onSubmit(panelName, shareWithClinic)}
            disabled={!Boolean(panelName)}
            color={existingName ? "secondary" : "primary"}
            data-cy="panel-name-submit"
          >
            {existingName === undefined ? "Create Panel" : "Yes, Update"}
          </Button>
        </div>
      </Dialog>
    </>
  )
}

const Modal = NiceModal.create<NamePanelModalProps>((props) => {
  return (
    <NamePanelModal
      open
      onClose={props.onClose}
      existingName={props.existingName}
      onSubmit={props.onSubmit}
    />
  )
})

export default function useNamePanelModal() {
  return useModal(Modal)
}
