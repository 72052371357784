import { useState } from "react"

import { InputAdornment, makeStyles } from "@material-ui/core"

import { primaryColor } from "app/theme"

import BodyText from "../design-system/BodyText"
import TextField from "./TextField"

const ClickToEditTextField = (props) => {
  const [readOnly, setReadOnly] = useState(true)
  const classes = useStyles({ active: !readOnly })

  return (
    <TextField
      {...props}
      // Focus input when in edit mode
      inputRef={(input) => (input && !readOnly ? input.focus() : null)}
      InputProps={{
        ...props.InputProps,
        readOnly: readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <button
              className={classes.button}
              disabled={!readOnly && !props.value}
              // When not in edit mode (readOnly==true), can always switch mode to edit
              // When it edit mode (readOnly==false), can only save when there is not a validation error
              onClick={() =>
                setReadOnly(
                  readOnly ? !readOnly : props.error ? readOnly : !readOnly
                )
              }
            >
              <BodyText weight="semibold" size="sm">
                {readOnly ? "Edit" : "Save"}
              </BodyText>
            </button>
          </InputAdornment>
        ),
      }}
    />
  )
}

interface Props {
  active: boolean
}

const useStyles = makeStyles({
  button: {
    "& > p": {
      color: (props: Props) => (props.active ? "white" : primaryColor),
    },
    background: (props: Props) => (props.active ? primaryColor : "transparent"),
    padding: (props: Props) => (props.active ? "4px 8px" : "0"),
    borderRadius: 3,
  },
})

export default ClickToEditTextField
