import { useSWRConfig } from "swr"

import { ReactComponent as AirplaneBlueIcon } from "app/assets/images/airplane-blue.svg"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useHandleApiSuccess from "app/hooks/use-handle-api-success"
import { PatientOrderEvent } from "app/main/patient-orders/constants"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import { writeToCache } from "app/swr/helpers/swr"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

import { ActionDropdownMenuItem } from "./ActionDropdownMenuButton"

export interface SendResultsDropdownMenuActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function SendResultsDropdownMenuAction({
  kit,
  order,
}: SendResultsDropdownMenuActionProps) {
  const modal = useConfirmationModal()
  const sendResultsToPatient = useSendResultsToPatient({ kit, order })

  const patient = useCachedResource<DashboardPatient>(
    order.relationships.patient?.data
  )

  const sendText = kit.attributes.date_results_sent_to_patient
    ? "Re-send"
    : "Send"

  const onSelect = useEventCallback(() => {
    modal.show({
      title: `${sendText} Results to ${patient?.attributes.full_name}`,
      message: `Email ${patient?.attributes.full_name} a secure link to their results.`,
      backButtonTitle: "Cancel",
      confirmButtonTitle: `${sendText} Results`,
      confirmButtonColor: "primary",
      handleConfirm: async () => {
        await sendResultsToPatient()

        modal.hide()
      },
      loading: false,
      onClose: modal.hide,
    })
  })

  return (
    <ActionDropdownMenuItem
      icon={
        <AirplaneBlueIcon
          fill="currentColor"
          height={15}
          width={15}
          viewBox="0 0 18 18"
        />
      }
      label={`${sendText} to ${patient?.attributes.full_name}`}
      onSelect={onSelect}
    />
  )
}

export function useSendResultsToPatient({
  kit,
  order,
}: SendResultsDropdownMenuActionProps) {
  const globalConfig = useSWRConfig()
  const patient = useCachedResource<DashboardPatient>(
    order.relationships.patient?.data
  )
  const handleApiError = useHandleApiError()
  const handleApiSuccess = useHandleApiSuccess()

  return useEventCallback(async () => {
    try {
      const { data, included = [] } = await resourceRequest<
        ResourceResponse<DashboardKit>
      >({
        url: `/dashboard/orders/${order.id}/kits/${kit.id}/send_patient_results/`,
        method: "POST",
        include: ["order"],
      })

      // Update the cache with the new data
      await writeToCache(globalConfig, data, ...included)

      handleApiSuccess(
        `Results for ${kit.attributes.display_name} were securely emailed to ${patient?.attributes.full_name}`,
        10000
      )

      trackPractitionerDashboardAction(
        PatientOrderEvent.SEND_RESULTS,
        order.relationships.practitioner?.data?.id as string
      )
    } catch (error) {
      handleApiError(error)
    }
  })
}
