// These values are arbitrary
export const PROGRESS_PERCENTAGES = {
  DRAFT: 0,
  PAUSED: 0,
  CANCELED: 0,
  AWAITING_PATIENT_CHECKOUT: 10,
  PAID: 25,
  PENDING_PHYSICIAN_AUTHORIZATION: 25,
  ORDERED_WITH_LAB: 50,
  NEW_SPECIMEN_ISSUE: 50,
  PROCESSING_SPECIMEN_ISSUE_ACTION: 75,
  RECOLLECTION_IN_PROGRESS: 75,
  AT_LAB: 75,
  RESULTS_IN: 80,
  COMPLETE: 100,
} as const
