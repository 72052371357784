import { useEffect, useMemo } from "react"

import { SimpleBodySystem } from "types/body-system"

import useTrends from "../hooks/use-results-over-time"
import {
  InRangeOption,
  ResultsOverTimeBiomarkerData,
  ResultsOverTimeDateGrouping,
} from "../types/types"
import BiomarkerResultRow from "./BiomarkerResultRow"
import BiomarkerResultRowSkeleton from "./BiomarkerResultRowSkeleton"
import BodySystemHeaderRow from "./BodySystemHeaderRow"

interface Props {
  primaryBodySystem: SimpleBodySystem | null
  groupedBy: ResultsOverTimeDateGrouping
  userId: string
  clinicName?: string
  availableDates: string[]
  inRangeValue: InRangeOption
  dropdownBodySystemId: string
  biomarkerSearchValue: string
  tableWidth?: number
  handleSetEmptySearchResults: (bodySystemId: string, add: boolean) => void
  showHighLowDescriptions: boolean
}

const BiomarkerResultRowsContainer = ({
  userId,
  primaryBodySystem,
  groupedBy,
  clinicName,
  availableDates,
  inRangeValue,
  dropdownBodySystemId,
  biomarkerSearchValue,
  tableWidth,
  handleSetEmptySearchResults,
  showHighLowDescriptions,
}: Props) => {
  const { resultsOverTime, error, isLoading } = useTrends(
    userId,
    primaryBodySystem ? primaryBodySystem.id : dropdownBodySystemId,
    groupedBy,
    inRangeValue,
    dropdownBodySystemId
  )

  const bodySystemId = primaryBodySystem
    ? primaryBodySystem.id
    : dropdownBodySystemId

  const doesBiomarkerIncludeSearchQuery = (
    biomarker: ResultsOverTimeBiomarkerData
  ) => {
    return (
      biomarkerSearchValue === "" ||
      biomarker.long_name.toLowerCase().includes(biomarkerSearchValue) ||
      biomarker.short_name.toLowerCase().includes(biomarkerSearchValue)
    )
  }

  useEffect(() => {
    if (biomarkerSearchValue === "") {
      handleSetEmptySearchResults(bodySystemId, false)
      return
    }

    const biomarkersRendered = resultsOverTime?.biomarkers.filter(
      (biomarker) =>
        biomarker.long_name.toLowerCase().includes(biomarkerSearchValue) ||
        biomarker.short_name.toLowerCase().includes(biomarkerSearchValue)
    )

    if (biomarkersRendered?.length === 0) {
      handleSetEmptySearchResults(bodySystemId, true)
    } else {
      handleSetEmptySearchResults(bodySystemId, false)
    }
  }, [biomarkerSearchValue])

  // Only show sample types for a biomarker if there are multiple biomarkers with the same id
  const biomarkersToShowSampleTypesFor: string[] = useMemo(() => {
    const seenBiomarkers: string[] = []
    const duplicatedBiomarkerIds: string[] = []

    resultsOverTime?.biomarkers.forEach((biomarker) => {
      if (seenBiomarkers.includes(biomarker.id)) {
        duplicatedBiomarkerIds.push(biomarker.id)
      }

      seenBiomarkers.push(biomarker.id)
    })

    return duplicatedBiomarkerIds
  }, [resultsOverTime])

  // TODO: Get designs from Jordan on what this behavior should look like: https://linear.app/rupa-health/issue/PROD-664/handle-results-over-time-api-errors
  if (error) {
    return <div>There was an error fetching the results over time data.</div>
  }

  return (
    <>
      {/* We only want to show the body system header if we are not searching
      or we are viewing the table from the "all" body system dropdown option. */}
      {biomarkerSearchValue === "" &&
        dropdownBodySystemId === "all" &&
        Boolean(resultsOverTime?.biomarkers.length) && (
          <BodySystemHeaderRow
            primaryBodySystemName={primaryBodySystem?.attributes.name}
            colSpan={availableDates.length}
          />
        )}

      {isLoading ? (
        <BiomarkerResultRowSkeleton
          availableDates={availableDates}
          tableWidth={tableWidth}
        />
      ) : (
        resultsOverTime?.biomarkers.map((biomarker, index) =>
          doesBiomarkerIncludeSearchQuery(biomarker) ? (
            <BiomarkerResultRow
              key={index}
              biomarker={biomarker}
              availableDates={availableDates}
              groupedBy={groupedBy}
              clinicName={clinicName}
              showSampleTypes={biomarkersToShowSampleTypesFor.includes(
                biomarker.id
              )}
              tableWidth={tableWidth}
              showHighLowDescriptions={showHighLowDescriptions}
            />
          ) : null
        )
      )}
    </>
  )
}

export default BiomarkerResultRowsContainer
