import { ComponentProps, forwardRef } from "react"
import { useDispatch } from "react-redux"

import { useModal } from "@ebay/nice-modal-react"
import { Button as MuiButton } from "@material-ui/core"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { ReactComponent as DownloadBlueWithNotification } from "app/assets/icons/dashboard/download-with-notification.svg"
import { ReactComponent as LinearChartIcon } from "app/assets/icons/results-summary/linear-chart.svg"
import { ReactComponent as SettingsRoundedSquareWithNotificationIcon } from "app/assets/icons/rupa-blood-dashboards/settings-rounded-square-notification.svg"
import { ReactComponent as SettingsRoundedSquareIcon } from "app/assets/icons/rupa-blood-dashboards/settings-rounded-square.svg"
import { ReactComponent as DownloadBlue } from "app/assets/images/download-blue.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import BodyText from "app/components/design-system/BodyText"
import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import BloodLabDashboardsModal from "app/main/blood-lab-dashboards/BloodLabDashboardsModal/BloodLabDashboardsModal"
import BloodLabDashboardsSnapshotModal from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotModal/BloodLabDashboardsSnapshotModal"
import useBloodReportActionsEnabledForOrderedResult from "app/main/blood-lab-dashboards/hooks/use-blood-report-actions-enabled-for-ordered-result"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"
import { colors, primaryColor, shadows } from "app/theme"
import { OrderedTest, PatientOrdersOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useDownloadIconButtonStyles = makeAppStyles((theme) => ({
  actionButton: {
    height: 36,
    width: 36,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    padding: "5px 8px",
    minWidth: 0,
    marginRight: 8,
    backgroundColor: "white",
    boxShadow: shadows.sm,
    "&:disabled": {
      opacity: 0.5,
    },
    icon: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      marginRight: "auto",
    },
  },
}))

interface DownloadIconButtonProps extends ComponentProps<typeof MuiButton> {
  onClick?: () => void
  orderedTest: OrderedTest
}

const DownloadIconButton = forwardRef<HTMLButtonElement, any>(
  ({ onClick, orderedTest, ...props }: DownloadIconButtonProps, ref) => {
    const styles = useDownloadIconButtonStyles()
    return (
      <MuiButton
        {...props}
        ref={ref}
        className={styles.actionButton}
        disabled={!orderedTest.results}
        key="download-button"
        onClick={onClick}
      >
        {orderedTest.is_ordered_test_result_unread ? (
          <DownloadBlueWithNotification
            className={styles.icon}
            viewBox="0 0 20 23"
          />
        ) : (
          <DownloadBlue className={styles.icon} />
        )}
      </MuiButton>
    )
  }
)

const LegacyDownloadAction = (props: {
  onClick: () => void
  orderedTest: OrderedTest
  buttonIcon: JSX.Element
  tooltipText?: string
}) => {
  const { results } = props.orderedTest
  const styles = useDownloadIconButtonStyles()

  let tooltipTextToShow = props.tooltipText
    ? props.tooltipText
    : "Download Results"

  return (
    <NavyTooltip
      arrow
      placement="top"
      title={
        results ? (
          tooltipTextToShow
        ) : (
          <>
            {tooltipTextToShow} <br />
            (Not Available Yet)
          </>
        )
      }
    >
      <div>
        <MuiButton
          onClick={props.onClick}
          disabled={!results}
          key="download-button"
          data-cy="download-button"
          className={styles.actionButton}
        >
          {props.buttonIcon}
        </MuiButton>
      </div>
    </NavyTooltip>
  )
}

const useDownloadActionDropdownMenuStyles = makeAppStyles((theme) => ({
  dropdownContent: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.5),
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "14px 19px",
    cursor: "pointer",
    fontSize: 15,

    "&:focus, &:hover": {
      "& .checkbox": {
        borderColor: primaryColor,
      },
    },
  },
  itemLabel: {
    marginLeft: theme.spacing(1.0),
    whiteSpace: "nowrap",
  },
  itemIcon: {
    minWidth: 15,
    width: 15,
    height: 15,
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: "85%",
  },
}))

const DownloadActionDropdownMenu = (props: {
  downloadResults: () => void
  openResultsVisualizerModal: () => void
  order: PatientOrdersOrder
  orderedTest: OrderedTest
}) => {
  const styles = useDownloadActionDropdownMenuStyles()

  return (
    <DropdownMenu.Root modal={false}>
      <NavyTooltip
        arrow
        placement="top"
        title={
          !props.orderedTest.results ? (
            <>
              Download Results <br />
              (Not Available Yet)
            </>
          ) : (
            "Download Results"
          )
        }
      >
        <span>
          <DropdownMenu.Trigger asChild>
            <DownloadIconButton orderedTest={props.orderedTest} />
          </DropdownMenu.Trigger>
        </span>
      </NavyTooltip>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={styles.dropdownContent}
          sideOffset={5}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          {/* Download Results */}
          <DropdownMenu.Item
            className={styles.item}
            onSelect={props.downloadResults}
          >
            <DownloadBlue className={styles.itemIcon} viewBox="0 0 18 18" />
            <BodyText
              className={styles.itemLabel}
              color="primary"
              weight="semibold"
            >
              Download Results
            </BodyText>
          </DropdownMenu.Item>

          <DropdownMenu.Separator className={styles.separator} />

          {/* Summarize Results */}
          {props.order.has_ordered_results && (
            <DropdownMenu.Item
              className={styles.item}
              onSelect={() => {
                props.openResultsVisualizerModal()
              }}
            >
              <LinearChartIcon
                className={styles.itemIcon}
                viewBox="0 0 17 15"
              />
              <BodyText
                className={styles.itemLabel}
                color="primary"
                weight="semibold"
              >
                Create Results Summary
              </BodyText>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default function PatientOrdersOrderedTestDownloadActions(props: {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  acknowledgeUnreadResult: Function
  appliedFilters: any
}) {
  const styles = useDownloadIconButtonStyles()
  const dispatch = useDispatch()
  const [resultsVisualizerEnabled] = useFeatureFlag(
    FeatureFlag.ResultsVisualizer
  )
  const [isBloodLabDashboardSnapshotOn] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )

  const { isBloodReportActionEnabled } =
    useBloodReportActionsEnabledForOrderedResult({
      supportsBloodReport:
        props?.orderedTest?.latest_ordered_result?.supports_blood_report,
      hasSnapshots:
        !!props?.orderedTest?.latest_ordered_result
          ?.latest_blood_lab_dashboard_snapshot_report_id,
      clinicFeatures: props.order?.practitioner?.clinic?.clinic_features,
    })

  const { results } = props.orderedTest

  const resultsSummaryModal = useResultsSummaryModal()
  const openResultsVisualizerModal = useEventCallback(() =>
    resultsSummaryModal.show({ orderId: props.order.id })
  )

  const bloodReportModalLegacy = useModal(BloodLabDashboardsModal)
  const bloodReportModal = useModal(BloodLabDashboardsSnapshotModal)

  const openBloodReports = useEventCallback(async () => {
    if (isBloodLabDashboardSnapshotOn) {
      if (
        props.orderedTest.latest_ordered_result
          ?.latest_blood_lab_dashboard_snapshot_report_id
      ) {
        bloodReportModal.show({
          onClose: () => {
            bloodReportModal.remove()
          },
          snapshotId:
            props.orderedTest.latest_ordered_result
              .latest_blood_lab_dashboard_snapshot_report_id,
          kitSentToPatient: Boolean(
            props.orderedTest.date_results_sent_to_patient
          ),
        })
      }
    } else {
      if (props.orderedTest.latest_ordered_result?.id) {
        bloodReportModalLegacy.show({
          onClose: () => {
            bloodReportModalLegacy.remove()
          },
          orderedResultId: String(props.orderedTest.latest_ordered_result?.id),
          kitSentToPatient: Boolean(
            props.orderedTest.date_results_sent_to_patient
          ),
        })
      }
    }
  })

  const downloadResults = () => {
    dispatch(
      props.acknowledgeUnreadResult(
        props.orderedTest,
        props.order,
        props.appliedFilters
      )
    )
    window.open(results || undefined, "_blank")
  }

  if (isBloodReportActionEnabled) {
    return (
      <div title="Results" data-cy="blood-lab-dashboard-action-button">
        <LegacyDownloadAction
          onClick={openBloodReports}
          orderedTest={props.orderedTest}
          buttonIcon={
            props.orderedTest.is_ordered_test_result_unread ? (
              <SettingsRoundedSquareWithNotificationIcon
                data-cy="download-button-cleared"
                fill="currentColor"
              />
            ) : (
              <SettingsRoundedSquareIcon
                data-cy="download-button-cleared"
                fill="currentColor"
              />
            )
          }
          tooltipText="See Results"
        />
      </div>
    )
  }

  return (
    <div title="Results">
      {resultsVisualizerEnabled ? (
        <DownloadActionDropdownMenu
          downloadResults={downloadResults}
          openResultsVisualizerModal={openResultsVisualizerModal}
          order={props.order}
          orderedTest={props.orderedTest}
        />
      ) : (
        <LegacyDownloadAction
          onClick={downloadResults}
          orderedTest={props.orderedTest}
          buttonIcon={
            props.orderedTest.is_ordered_test_result_unread ? (
              <DownloadBlueWithNotification
                className={styles.icon}
                viewBox="0 0 20 23"
                data-cy="download-button-notification"
              />
            ) : (
              <DownloadBlue
                className={styles.icon}
                data-cy="download-button-cleared"
              />
            )
          }
        />
      )}
    </div>
  )
}
