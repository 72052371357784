import ResultsExpectedDate from "app/components/StatusComponents/ResultsExpectedDate"
import { KIT_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ kit }: StatusConfigurationProps) => {
  if (kit.attributes.date_sample_received_at_lab) {
    return (
      <>{`Sample Arrived at Lab on ${formatDateForHuman(
        kit.attributes.date_sample_received_at_lab
      )}`}</>
    )
  }

  return <>Sample Arrived at Lab</>
}

const SubTitle = ({
  kit,
  labCompany,
  labCompanyAlert,
}: StatusConfigurationProps) => {
  if (kit.attributes.estimated_results_date) {
    return (
      <ResultsExpectedDate
        dateResultsExpectedFromLab={kit.attributes.estimated_results_date}
        resultsDelayTitle={labCompanyAlert?.attributes?.title}
        resultsDelaySubtitle={labCompanyAlert?.attributes?.subtitle}
        resultsDelayReason={kit.attributes.results_delayed_reason}
        labProvidesResultsEta={labCompany?.attributes.provides_results_eta}
        labCompanyName={labCompany?.attributes.short_name}
      />
    )
  } else if (labCompany) {
    return (
      <>{`${labCompany.attributes.short_name} doesn't share estimates for when results will come in.`}</>
    )
  }

  return null
}

const sampleAtLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      kitStatus: KIT_STATUS.AT_LAB,
    },
    progressPercentage: PROGRESS_PERCENTAGES.AT_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default sampleAtLabStatusConfiguration
