import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"
import _ from "lodash"

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core"

function CheckboxFormsy(props) {
  const importedProps = _.pick(props, [
    "checkedIcon",
    "classes",
    "color",
    "disabled",
    "disableRipple",
    "icon",
    "id",
    "indeterminate",
    "indeterminateIcon",
    "inputProps",
    "inputRef",
    "onChange",
    "variant",
  ])

  // An error message is returned only if the component is invalid
  const errorMessage = props.getErrorMessage()
  const value = props.getValue()

  function changeValue(event) {
    props.setValue(event.target.checked)
    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <FormControl error={Boolean(errorMessage)} className={props.className}>
      <FormControlLabel
        control={
          <Checkbox
            {...importedProps}
            type="checkbox"
            checked={value}
            onChange={changeValue}
          />
        }
        label={props.label}
      />
      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default memo(withFormsy(CheckboxFormsy))
