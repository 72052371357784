import { useLayoutEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { differenceInYears, parseISO } from "date-fns"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"
import _ from "lodash"
import * as moment from "moment-timezone"

import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import {
  Dialog,
  OutlinedInput,
  Typography,
  withStyles,
  styled,
  Divider,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import * as Actions from "app/main/checkout/store/actions"
import PatientCheckoutBanner from "app/main/patient-checkout/PatientCheckoutBanner"
import { getOrderPatientLabel } from "app/utils/order-utils"
import format from "date-fns/format"
import isValid from "date-fns/isValid"

import {
  CheckboxFormsy,
  SelectFormsy,
  TextFieldFormsy,
} from "../../../../@fuse/components/formsy"
import { LoadingButton } from "../../../components/LoadingButton"
import DatePickerFormsy from "../../../components/formsy/DatePickerFormsy"
import { PO_BOX_REGEX } from "../../../constants"
import { navy, veryLightGray } from "../../../theme"
import { handleApiError } from "../../../utils"
import {
  US_STATES,
  BLOCKED_SHIPPINGS_STATES,
} from "../../patient-checkout/utils/usStates"

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: veryLightGray,
    fontSize: 14,
    padding: "10px 12px",
  },
}))

export function PatientInfoModal({
  patient,
  orderHasInstantRequisitions,
  open,
  onClose,
  requiresVendorPhysicianAuthorization,
}) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="patient-info-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <Form
        patient={patient}
        orderHasInstantRequisitions={orderHasInstantRequisitions}
        onClose={onClose}
        requiresVendorPhysicianAuthorization={
          requiresVendorPhysicianAuthorization
        }
      />
    </Dialog>
  )
}

function Form({
  patient,
  orderHasInstantRequisitions,
  onClose,
  requiresVendorPhysicianAuthorization,
}) {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const [validationErrors, setValidationErrors] = useState({})
  const [submitPending, setSubmitPending] = useState(false)
  const [isUnder18, setIsUnder18] = useState(() => {
    if (!patient?.birthday) {
      return false
    }

    return differenceInYears(new Date(), parseISO(patient.birthday)) < 18
  })

  const loadPatient = () => {
    // Load initial values into form.
    if (patient && formRef.current) {
      // The form reset method needs an object of the form {"field_name": value}. It can't decompose nested structures
      // so the keys have to be manually mapped.
      const default_shipping_address = _.mapKeys(
        patient.default_shipping_address,
        (value, key) => "default_shipping_address." + key
      )

      const patientClone = _.cloneDeep(patient)
      if (patient.birthday) {
        // To ensure that the date is displayed correctly for the users local timezone
        // convert the date string to a timezone aware date. This prevents off by one date issues.
        patientClone.birthday = moment(patientClone.birthday).toDate()
      }

      const email = { email: patient.user.email }

      formRef.current.reset({
        ...patientClone,
        ...email,
        ...default_shipping_address,
      })
    }
  }

  // useLayoutEffect to ensure the ref has been set when loadPatient is called.
  useLayoutEffect(() => {
    loadPatient()
  }, [dispatch, patient.id])

  const validateForm = (values) => {
    const errors = {}
    const requiredErrorText = "This field is required"

    // Unclear why individual validation isn't working on the Select fields, but for now it is done manually here.
    if (!values.biological_sex) {
      errors["biological_sex"] = requiredErrorText
    }

    if (
      values.default_shipping_address &&
      !values.default_shipping_address.state
    ) {
      errors["default_shipping_address.state"] = requiredErrorText
    }

    if (
      values.default_shipping_address &&
      BLOCKED_SHIPPINGS_STATES.includes(values.default_shipping_address.state)
    ) {
      errors[
        "default_shipping_address.state"
      ] = `Practitioner pay cannot be used for patients based in ${
        US_STATES[values.default_shipping_address.state]
      }`
    }

    if (
      values.default_shipping_address &&
      PO_BOX_REGEX.test(values.default_shipping_address.zipcode)
    ) {
      errors["default_shipping_address.zipcode"] = "We cannot ship to PO Boxes"
    }

    if (
      values.default_shipping_address &&
      PO_BOX_REGEX.test(values.default_shipping_address.street_1)
    ) {
      errors["default_shipping_address.street_1"] = "We cannot ship to PO Boxes"
    }

    if (
      values.default_shipping_address?.street_2 &&
      PO_BOX_REGEX.test(values.default_shipping_address.street_2)
    ) {
      errors["default_shipping_address.street_2"] = "We cannot ship to PO Boxes"
    }

    if (!values.birthday) {
      errors["birthday"] = requiredErrorText
    } else if (!isValid(values.birthday)) {
      errors["birthday"] = "Please enter a valid date"
    } else if (new Date(values.birthday) >= new Date()) {
      errors["birthday"] = "Date of birth cannot be in the future"
    }

    // Set this to be null to ensure the error message is updated
    errors["given_consent"] = values.given_consent
      ? null
      : "You must agree to continue"

    return errors
  }

  /**
   * Normalize data for submission
   * @param data: data to be submitted
   */
  const normalizeData = (data) => {
    const newData = { ...data }

    // Convert birthday format
    // It's important to strip the timezone information from the date string first, to ensure that the correct
    // date is stored.
    const dateWithoutTimezone = new Date(data.birthday).toDateString()
    newData.birthday = format(new Date(dateWithoutTimezone), "yyyy-MM-dd")

    return newData
  }

  const formSubmit = async (data, resetForm, invalidateForm) => {
    const errors = validateForm(data)
    setSubmitPending(true)

    if (_.isEmpty(_.compact(_.values(errors)))) {
      try {
        await dispatch(Actions.updatePatient(patient.id, normalizeData(data)))
        onClose()
      } catch (error) {
        const errorData = error && error.response && error.response.data
        const submitErrors = _.pick(
          errorData.errors,
          _.keys(formRef.current.getModel())
        )

        if (!_.isEmpty(submitErrors)) {
          _.assign(errors, submitErrors)
        } else {
          dispatch(handleApiError(error))
        }
      }
    }

    setValidationErrors(errors)

    if (!_.isEmpty(_.compact(_.values(errors)))) {
      invalidateForm(errors)
    }

    setSubmitPending(false)
  }

  const formChange = (currentValues) => {
    const formData = currentValuesToData(currentValues)
    const errors = validateForm(formData)
    setValidationErrors(errors)

    const birthday = formData?.birthday

    setIsUnder18(!birthday || differenceInYears(new Date(), birthday) < 18)
  }

  /**
   * Converts Formsy current values (which include ".") into a nested object
   * e.g. {"shipping_address.state": "NY"} -> {"shipping_address": {"state": "NY"}}
   * @param {*} currentValues
   */
  function currentValuesToData(currentValues) {
    return _.keys(currentValues).reduce((result, key) => {
      return _.set(result, key, currentValues[key])
    }, {})
  }

  return (
    <Formsy
      onSubmit={formSubmit}
      validationErrors={validationErrors}
      onChange={formChange}
      ref={formRef}
    >
      <div className="pb-5 pt-6 px-6">
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ fontSize: 24 }}
          id="patient-info-title"
        >
          {`${patient.first_name}'s Information`}
        </Typography>
      </div>

      <div style={{ backgroundColor: "#3758700D" }} className="p-6">
        <PatientInfoFields patient={patient} isUnder18={isUnder18} />
        <Consent
          className="mt-6"
          requiresVendorPhysicianAuthorization={
            requiresVendorPhysicianAuthorization
          }
        />
      </div>
      <div className="flex justify-end p-6">
        <Button
          onClick={onClose}
          disabled={submitPending}
          variant="contained"
          style={{
            backgroundColor: "white",
            color: navy,
            border: "1px solid #D4DCE4",
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={submitPending}
          color="primary"
          className="py-2 ml-4 fs-exclude"
          type="submit"
        >
          Update {patient.first_name}'s Information
        </LoadingButton>
      </div>
    </Formsy>
  )
}

function PatientInfoFields({ className, patient, isUnder18 }) {
  return (
    <div className={className}>
      <div>
        <PatientCheckoutBanner
          className="mt-3 mb-6"
          type="info"
          text="Please make sure this information matches the person collecting the sample."
          icon={faUserCheck}
        />
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Preferred First Name"
                name="first_name"
                disabled={!patient.can_modify_name}
                tooltipTitle={
                  "Please reach out to support through chat if you need to modify this name."
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Last Name"
                name="last_name"
                disabled={!patient.can_modify_name}
                tooltipTitle={
                  "Please reach out to support through chat if you need to modify this name."
                }
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <SelectFormField
                label="Sex assigned at birth"
                name="biological_sex"
                infoText={<GenderInfoText />}
              >
                <option value="F">Female</option>
                <option value="M">Male</option>
              </SelectFormField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormDateField label="Date of Birth" name="birthday" />
            </Grid>
          </Grid>

          {isUnder18 && (
            <>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Divider className="mb-6 mt-3" />
                  <BodyText size="sm" weight="semibold">
                    Please enter the information of a parent or legal guardian
                    over the age of 18.
                  </BodyText>
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    label="Parent/Guardian's First Name"
                    name="guardian_first_name"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    label="Parent/Guardian's Last Name"
                    name="guardian_last_name"
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField label="Phone Number" name="phone_number" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Email"
                name="email"
                disabled={!patient.user.can_modify_email}
                tooltipTitle={
                  "Please reach out to support through chat if you need to modify this email."
                }
              />
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item xs={12}>
              <FormTextField
                label="Patient address"
                name="default_shipping_address.street_1"
                placeholder="Address line 1"
              />
              <FormTextField
                required={false}
                name="default_shipping_address.street_2"
                placeholder="Address line 2"
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="City"
                name="default_shipping_address.city"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFormField
                label="State"
                name="default_shipping_address.state"
              >
                {Object.entries(US_STATES).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </SelectFormField>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Zip Code"
                name="default_shipping_address.zipcode"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export function Consent(props) {
  const orderPatientLabel = getOrderPatientLabel(
    props.requiresVendorPhysicianAuthorization
  )

  return (
    <div {...props}>
      <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
        Consent
      </Typography>
      <CheckboxFormsy
        className="my-8"
        color="primary"
        name="given_consent"
        value={false}
        label={
          <div>
            <Typography color="textPrimary" className="inline">
              My {orderPatientLabel} verbally consents to the Rupa Health Team
              ordering and handling lab work. Rupa Health can communicate with
              my patient via email regarding their health information.
            </Typography>
            <Typography color="error" className="font-semibold inline">
              {" *"}
            </Typography>
          </div>
        }
      />
    </div>
  )
}

const GenderInfoText = () => (
  <Typography>
    Labs require a sex for setting reference ranges for results. Please select a
    sex you'd like your test processed as.
  </Typography>
)

const FormFieldLabel = ({ infoText, label, required = true }) => (
  <div className="mb-2">
    <Typography color="textPrimary" className="font-semibold inline">
      {label}
    </Typography>
    {required && (
      <Typography color="error" className="font-semibold inline">
        {" *"}
      </Typography>
    )}
    {infoText && (
      <FormFieldInfoTextTooltip>{infoText}</FormFieldInfoTextTooltip>
    )}
  </div>
)

const FormFieldInfoTextTooltip = styled(InfoTextTooltip)(({ theme }) => ({
  display: "inline-block",
  marginLeft: theme.spacing(0.5),
  verticalAlign: "middle",
}))

const FormTextField = ({
  children,
  infoText,
  label,
  name,
  type,
  placeholder,
  tooltipTitle,
  required = true,
  disabled = false,
}) => {
  const classes = useStyles({})

  const disableTooltip = !disabled || !tooltipTitle

  return (
    <div>
      <FormFieldLabel required={required} infoText={infoText} label={label} />
      <Tooltip
        arrow
        interactive
        placement="bottom"
        disableHoverListener={disableTooltip}
        disableFocusListener={disableTooltip}
        disableTouchListener={disableTooltip}
        title={tooltipTitle}
      >
        <div>
          <TextFieldFormsy
            className="fs-exclude"
            InputProps={{ classes }}
            type={type || "text"}
            name={name}
            required={required}
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            disabled={disabled}
          >
            {children}
          </TextFieldFormsy>
        </div>
      </Tooltip>
    </div>
  )
}

const FormDateField = ({ infoText, label, name }) => {
  const classes = useStyles({})

  return (
    <div>
      <FormFieldLabel infoText={infoText} label={label} />
      <DatePickerFormsy
        name={name}
        fullWidth
        required
        InputProps={{ classes }}
      />
    </div>
  )
}

const SelectInput = withStyles((theme) => ({
  root: {
    backgroundColor: veryLightGray,
    fontSize: 14,
  },
  input: {
    padding: "10px 12px",
  },
}))(OutlinedInput)

const SelectFormField = ({ children, infoText, label, name }) => {
  return (
    <div>
      <FormFieldLabel infoText={infoText} label={label} />
      <SelectFormsy
        className="fs-exclude w-full"
        input={<SelectInput />}
        name={name}
        required
        label=""
        value=""
        variant="outlined"
        native
      >
        <option value="">Select</option>
        {children}
      </SelectFormsy>
    </div>
  )
}
