import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { useMount, usePrevious, useUpdateEffect } from "react-use"

import {
  CircularProgress,
  TablePagination,
  useMediaQuery,
} from "@material-ui/core"

import { API } from "app/api"
import useLabTestsPage from "app/components/LabTests/hooks/use-lab-tests-page"
import {
  LAB_TEST_SEARCH_CTR_ACTION,
  ORDERING_RIGHTS_ACTIONS,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useLabTestQueryModifiers from "app/hooks/use-lab-test-query-modifiers"
import useAppSelector from "app/hooks/useAppSelector"
import useRefreshOrderingRights from "app/main/checkout/hooks/use-refresh-ordering-rights"
import { ComboGroupConfigurationPayload } from "app/main/combo-groups/constants"
import { trackLabTestSearchCTR } from "app/services/segment"
import * as Actions from "app/store/actions"
import {
  AnyLimitedLabTest,
  Order,
  Practitioner,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import LabTestModal from "../modals/LabTestModal"
import OverrideAccessModal from "../modals/OverrideAccessModal"
import Filters from "./Filters"
import LabTestListDesktop from "./LabTestListDesktop"
import LabTestListMobile from "./LabTestListMobile"
import LabTestNoResults from "./LabTestNoResults"
import Search from "./Search"
import VibrantSearchWarning from "./VibrantSearchWarning"
import useDetailModal from "./hooks/use-detail-modal"
import useLabTestQuery from "./hooks/use-lab-tests-query"
import { NonFilterNames, SelectedLabTests } from "./types"

const useStyles = makeAppStyles({
  container: {
    position: "relative",
  },
  search: {
    marginBottom: 14,
    width: "100%",
    // This adds a margin so that scrollIntoView leaves a gap
    // NOTE: We need to explictly add `px` as JSS doesn't support this
    // prop (at least in the version we're using).
    scrollMarginTop: "24px",
  },
  filters: {
    marginBottom: 14,
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 8,
  },
  pendingContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
})

const HandleAddOnEventSource = {
  DIRECT: "direct",
  DETAILS_MODAL: "details_modal",
  LABSHOP: "labshop",
}

interface Props {
  autoFocusSearch?: boolean

  location: labTestLocation

  // If true, the query will be synced with the URL. Defaults false.
  syncWithUrl?: boolean

  // Used for determining favorites
  practitioner?: Practitioner
  // Used for determining ordering rights
  signingPractitioner?: Practitioner

  // A map of selected lab tests by ID. Used to indicate which tests are
  // currently in the order or the bundle, depending on where it's rendered.
  // Can be undefined in the case of the bundle editor within settings and
  // the catalog.
  selectedLabTests?: SelectedLabTests
  onAddLabTest?: (labTest: AnyLimitedLabTest) => void
  onRemoveLabTest?: (labTest: AnyLimitedLabTest) => void
  order?: Order
  createBundleClick?: () => void
  // If used for labshops or normal checkout, determins if we use
  // Physician Services or the Practitioner's ordering rights.
  vendorPhysicianServicesActivated?: boolean
  onAddComboGroup?: (
    labTest: AnyLimitedLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
  storefrontId?: string
}

function LabTests({
  autoFocusSearch,
  location,
  practitioner,
  signingPractitioner,
  selectedLabTests,
  onAddLabTest,
  onRemoveLabTest,
  syncWithUrl = false,
  order,
  createBundleClick,
  vendorPhysicianServicesActivated,
  onAddComboGroup,
  storefrontId,
}: Props) {
  const dispatch = useDispatch()
  const styles = useStyles()

  // Show tooltip with button to override lab company restriction
  const [showOverrideOption] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )
  const [showSigningPracFilter] = useFeatureFlag(
    FeatureFlag.CatalogSigningPractitionerFilter
  )
  const showCatalogOrderingRights = useMemo(() => {
    return showSigningPracFilter && location === labTestLocation.CATALOG
  }, [showSigningPracFilter, location])

  const [labTestInModal, setLabTestInModal] =
    useState<AnyLimitedLabTest | null>(null)

  const orderingRightsStatusForModal = useMemo(() => {
    if (showCatalogOrderingRights) return labTestInModal?.ordering_rights_status
  }, [showCatalogOrderingRights, labTestInModal?.ordering_rights_status])

  const [labTestInModalIndex, setLabTestInModalIndex] = useState<number | null>(
    null
  )

  const {
    query,
    queryMap,
    shouldSetPending,
    onSelectFilterOption,
    onSetQueryParams,
    onResetFilter,
  } = useLabTestQuery(syncWithUrl)
  useLabTestQueryModifiers(location, onSetQueryParams)

  const {
    count,
    isPending,
    page,
    results: labTests,
  } = useLabTestsPage(query, shouldSetPending)
  const previousPage = usePrevious(page)

  const {
    useRefreshOrderingRightsBlocking,
    useRefreshOrderingRightsBackground,
  } = useRefreshOrderingRights({ order, storefrontId })

  const [isExperimentActive, setIsExperimentActive] = useState(false)
  const [isClinicInExperiment, setIsClinicInExperiment] = useState(false)
  const [isClinicInVariant, setIsClinicInVariant] = useState(false)

  useMount(async () => {
    try {
      const {
        is_clinic_in_experiment,
        is_clinic_in_variant,
        is_experiment_active,
      } = (await API.Features.getClinicABStatus()).data

      setIsExperimentActive(is_experiment_active)
      setIsClinicInExperiment(is_clinic_in_experiment)
      setIsClinicInVariant(is_clinic_in_variant)
    } catch (error) {
      // Just continue with defaults of experiment off
    }
  })

  const setQueryParams = (shouldSetPending: boolean) => {
    let queryParams: { queryName: NonFilterNames; value: string }[] = []

    let orderingRightsAction: string | null = null
    if (
      [labTestLocation.BUNDLES, labTestLocation.ECOMMERCE_BUNDLES].includes(
        location
      )
    ) {
      orderingRightsAction = ORDERING_RIGHTS_ACTIONS.ADD_TO_BUNDLE
    } else if (location === labTestLocation.ECOMMERCE) {
      orderingRightsAction = ORDERING_RIGHTS_ACTIONS.ADD_TO_LABSHOP
    } else if (location === labTestLocation.CHECKOUT) {
      orderingRightsAction = ORDERING_RIGHTS_ACTIONS.ADD_TO_CART
    } else if (location === labTestLocation.CATALOG) {
      orderingRightsAction = ORDERING_RIGHTS_ACTIONS.CATALOG
    }
    if (orderingRightsAction) {
      queryParams = queryParams.concat([
        {
          queryName: "action",
          value: orderingRightsAction,
        },
      ])
    }

    // If we are adding to a bundle, then we disregard the context that would
    // contribute to ordering right issues.
    if (
      order &&
      orderingRightsAction !== ORDERING_RIGHTS_ACTIONS.ADD_TO_BUNDLE
    ) {
      queryParams = queryParams.concat([
        { queryName: "order_id", value: order.id.toString() },
      ])
    }

    // if this is a labshop, we need to pass in the labshop id
    if (
      [labTestLocation.ECOMMERCE, labTestLocation.ECOMMERCE_BUNDLES].includes(
        location
      ) &&
      storefrontId
    ) {
      queryParams = queryParams.concat([
        { queryName: "storefront_id", value: storefrontId },
      ])
    }
    // TODO https://linear.app/rupa-health/issue/PROD-143/labtest-catalog-cleanup: Remove unnecessary query params
    onSetQueryParams(queryParams, shouldSetPending)
  }

  useRefreshOrderingRightsBlocking(() => {
    setQueryParams(true)
  })

  useRefreshOrderingRightsBackground(() => {
    // When triggering a refresh because of a change in the selected lab tests in cart,
    // do not set pending so we don't show the loading state.
    setQueryParams(false)
  })

  // State to determine whether or not to show the override modal
  const labTestOverride = useAppSelector(
    ({ overrideLabTest }) => overrideLabTest
  )

  // Maintains the lab-test-id query parameter to support a detail page.
  useDetailModal({ labTests, labTestInModal, setLabTestInModal, syncWithUrl })

  const handleClickFavorite = useCallback(
    (labTestToFavorite: AnyLimitedLabTest) => {
      if (!practitioner) {
        return
      }

      if (practitioner.favorite_lab_test_id_set?.has(labTestToFavorite.id)) {
        dispatch(Actions.removeFavoriteTest(practitioner, labTestToFavorite))
      } else {
        dispatch(Actions.addFavoriteTest(practitioner, labTestToFavorite))
      }
    },
    [practitioner]
  )

  const searchRef = useRef<HTMLDivElement>(null)

  // Scroll to the top of the component into view when the page changes
  useEffect(() => {
    if (page && previousPage && page !== previousPage) {
      requestAnimationFrame(() => {
        if (location === labTestLocation.CATALOG) {
          // On the catalog, the scrolling happens in the parent of the sticky header. This
          // means the scrollIntoView for the search element will be incorrectly calculated.
          // Rather than manually calculating the offset (which changes depending on screen size)
          // we simply scroll to the top on the catalog. There isn't anything else above the lab
          // test search so it's probably the best behaviour.
          window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
          // On the checkout, the search bar is below the favorites, so we need to scroll that element
          // specifically into view. As the scrolling happens within the content div that doesn't include
          // the header, the offset calculation is correct. In the bundle modal, the scrolling happens
          // within a modal, so we can't scroll the window, so instead we scroll the search element into view.
          // The calculation is correct as there isn't any stick elements.
          if (searchRef.current) {
            searchRef.current.scrollIntoView({ behavior: "smooth" })
          }
        }
      })
    }
  }, [page, previousPage, location])

  useUpdateEffect(() => {
    if (labTestInModal && query) {
      // We want to track the CTR of Detail button clicks during lab test searches.
      // If a user clicks on a details button and has made a search query, we should
      // log it in segment for CTR analysis dashboard for here:
      // https://analytics.rupahealth.com/dashboard/205-lab-test-search-ctr
      const searchParams = new URLSearchParams(
        query.map((param) => [param.queryName, param.value])
      )
      searchParams.set("lab_test_clicked", labTestInModal.id)
      searchParams.set("lab_test_search_index", `${labTestInModalIndex}`)
      searchParams.set("click_action", LAB_TEST_SEARCH_CTR_ACTION.DETAILS)

      if (isExperimentActive && isClinicInExperiment) {
        searchParams.set("flag_name", "temp-is-search-ctr-ordering-enabled")
        searchParams.set(
          "flag_is_variant",
          isClinicInVariant ? "true" : "false"
        )
      }
      trackLabTestSearchCTR(searchParams)
    }
  }, [labTestInModal])

  const searchTexts = useMemo(() => {
    if (!query) {
      return []
    }
    const searchMatch = query
      .filter((param) => param.queryName === "search")
      .map((param) => param.value)
    const biomarkerMatch = query
      .filter((param) => param.queryName === "biomarker")
      .map((param) => param.label)
    const labCompanyMatch = query
      .filter((param) => param.queryName === "lab_company")
      .map((param) => param.label)
    const healthCategoryMatch = query
      .filter((param) => param.queryName === "health_category")
      .map((param) => param.label)
    return [
      ...searchMatch,
      ...biomarkerMatch,
      ...labCompanyMatch,
      ...healthCategoryMatch,
    ].flatMap((f) => (f ? [f] : []))
  }, [query])

  const handleOnAddLabTest = (
    labTest: AnyLimitedLabTest,
    addOnEventSource: string,
    index?: number
  ) => {
    if (query) {
      // We want to track the CTR of Add to Cart button clicks during lab test searches.
      // If a user clicks on a add to cart button and has made a search query, we should
      // log it in segment for CTR analysis dashboard for here:
      // https://analytics.rupahealth.com/dashboard/205-lab-test-search-ctr
      const searchParams = new URLSearchParams(
        query.map((param) => [param.queryName, param.value])
      )
      searchParams.set("lab_test_clicked", labTest.id)
      searchParams.set("lab_test_search_index", `${index}`)

      if (location !== labTestLocation.ECOMMERCE) {
        searchParams.set("click_action", LAB_TEST_SEARCH_CTR_ACTION.ADD_TO_CART)
        searchParams.set("source", addOnEventSource)

        if (isExperimentActive && isClinicInExperiment) {
          searchParams.set("flag_name", "temp-is-search-ctr-ordering-enabled")
          searchParams.set(
            "flag_is_variant",
            isClinicInVariant ? "true" : "false"
          )
        }

        trackLabTestSearchCTR(searchParams)
      }
    }

    if (onAddLabTest) {
      onAddLabTest(labTest)
    }
  }

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const noResults = !labTests || labTests.length < 1

  if (!query) {
    return (
      <div className={styles.pendingContainer}>
        <CircularProgress aria-label="Loading lab tests" />
      </div>
    )
  }

  return (
    <div className={`${styles.container} fs-unmask`}>
      <div className={styles.search} ref={searchRef}>
        <Search
          autoFocus={autoFocusSearch}
          query={query}
          queryMap={queryMap}
          onSelectFilterOption={onSelectFilterOption}
          onSetQueryParams={onSetQueryParams}
        />
      </div>
      <div className={styles.filters}>
        <Filters
          location={location}
          filtersMap={queryMap.filters}
          onSelectFilterOption={onSelectFilterOption}
          onResetFilter={onResetFilter}
        />
      </div>
      {
        // Show the vibrant search warning for logged in pracs
        practitioner && <VibrantSearchWarning searchTexts={searchTexts} />
      }
      {noResults && !isPending ? (
        <LabTestNoResults />
      ) : isMobile ? (
        <LabTestListMobile
          labTests={labTests}
          selectedLabTests={selectedLabTests}
          onAddLabTest={(labTest: AnyLimitedLabTest, index: number) =>
            handleOnAddLabTest(labTest, HandleAddOnEventSource.DIRECT, index)
          }
          onRemoveLabTest={onRemoveLabTest}
          onToggleFavorite={handleClickFavorite}
          practitioner={practitioner}
          signingPractitioner={signingPractitioner}
          setLabTestInModal={(labTest: AnyLimitedLabTest, index: number) => {
            setLabTestInModal(labTest)
            setLabTestInModalIndex(index)
          }}
          isPending={isPending}
          location={location}
          createBundleClick={createBundleClick}
          vendorPhysicianServicesActivated={vendorPhysicianServicesActivated}
          onAddComboGroup={onAddComboGroup}
        />
      ) : (
        <LabTestListDesktop
          labTests={labTests}
          selectedLabTests={selectedLabTests}
          onAddLabTest={(labTest: AnyLimitedLabTest, index: number) =>
            handleOnAddLabTest(labTest, HandleAddOnEventSource.DIRECT, index)
          }
          onRemoveLabTest={onRemoveLabTest}
          onToggleFavorite={handleClickFavorite}
          practitioner={practitioner}
          signingPractitioner={signingPractitioner}
          setLabTestInModal={(labTest: AnyLimitedLabTest, index: number) => {
            setLabTestInModal(labTest)
            setLabTestInModalIndex(index)
          }}
          isPending={isPending}
          location={location}
          createBundleClick={createBundleClick}
          vendorPhysicianServicesActivated={vendorPhysicianServicesActivated}
          onAddComboGroup={onAddComboGroup}
        />
      )}
      <div className={styles.paginationContainer}>
        {isPending && (
          <div className={styles.paginationPending}>
            <CircularProgress aria-label="Loading lab tests" />
          </div>
        )}
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          rowsPerPage={20}
          count={count}
          page={page - 1}
          onChangePage={(_, newPage) =>
            onSetQueryParams([
              { queryName: "page", value: (newPage + 1).toString() },
            ])
          }
          className={styles.pagination}
        />
      </div>
      {labTestInModal && (
        <LabTestModal
          labTest={labTestInModal}
          orderingRightsStatus={orderingRightsStatusForModal}
          practitioner={practitioner}
          signingPractitioner={signingPractitioner}
          isOpen={!!labTestInModal}
          onClose={() => {
            setLabTestInModal(null)
            setLabTestInModalIndex(null)
          }}
          location={location}
          showTitleButton={!!practitioner}
          onAddLabTest={(labTest: AnyLimitedLabTest, index?: number) =>
            handleOnAddLabTest(
              labTest,
              HandleAddOnEventSource.DETAILS_MODAL,
              index
            )
          }
          onRemoveLabTest={onRemoveLabTest}
          selectedLabTests={selectedLabTests}
          createBundleClick={createBundleClick}
          orderId={order?.id}
        />
      )}

      {showOverrideOption && Boolean(labTestOverride.labCompany) && (
        <OverrideAccessModal
          open={Boolean(labTestOverride.labCompany)}
          title={`Confirm Ordering Access for ${labTestOverride.labCompany?.name}`}
          licenseType={practitioner?.primary_practitioner_type?.name}
          state={labTestOverride.unavailableState}
          labCompany={labTestOverride.labCompany}
          practitionerId={practitioner?.id}
          orderId={order?.id}
        />
      )}
    </div>
  )
}

export default LabTests
