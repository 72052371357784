import React from "react"

import _ from "lodash"

import * as Sentry from "@sentry/react"

import PractitionerTypeIssueTooltip from "app/components/OrderingAccess/PractitionerTypeIssueTooltip"
import {
  BUNDLE_TYPES,
  ORDERING_ISSUE_KEYS,
  ORDERING_ISSUE_TYPES,
  OrderingIssueKey,
} from "app/constants"
import {
  AnyLimitedLabTest,
  LabTest,
  LabTestBundle,
  Order,
  OrderedTest,
  OrderingIssue,
  Practitioner,
} from "app/types"
import orderContainsLabTestFromCompany from "app/utils/order-contains-lab-test-from-company"

export const STATE_ORDERING_ISSUE_KEYS = [
  ORDERING_ISSUE_KEYS.LAB_TEST_SAMPLE_COLLECTION_STATE_NOT_ALLOWED,
  ORDERING_ISSUE_KEYS.LAB_TEST_SHIPPING_STATE_NOT_ALLOWED,
  ORDERING_ISSUE_KEYS.LAB_TEST_CLINIC_STATE_NOT_ALLOWED,
  ORDERING_ISSUE_KEYS.LAB_COMPANY_CLINIC_STATE_NOT_ALLOWED,
  ORDERING_ISSUE_KEYS.LAB_COMPANY_PHYSICIAN_SERVICES_CLINIC_STATE_NOT_ALLOWED,
] as OrderingIssueKey[]

export const COUNTRY_ORDERING_ISSUE_KEYS = [
  ORDERING_ISSUE_KEYS.LAB_COMPANY_CLINIC_COUNTRY_NOT_ALLOWED,
] as OrderingIssueKey[]

export const DOCTORS_DATA_GENOVA_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED = [
  "NY",
  "NJ",
  "RI",
]
export const MOSAIC_DSL_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED = [
  "NJ",
  "RI",
]

// A type with only the necessary fields for getting the unavailable reason
type LabTestForOrderingRights = Pick<
  AnyLimitedLabTest,
  "ordering_rights_status" | "physician_authorization_approved"
>

export function getUnavailableReason({
  labTest,
  signingPractitioner,
  orderingPractitioner,
  overrideAccessAction,
  showOverrideOption = true,
}: {
  labTest: LabTestForOrderingRights
  signingPractitioner?: Practitioner
  orderingPractitioner?: Practitioner
  overrideAccessAction?: (showClinicState: boolean) => void
  showOverrideOption?: boolean
}): React.ReactChild {
  const orderingRightsStatus = labTest.ordering_rights_status

  if (!orderingRightsStatus) {
    return ""
  }

  if (orderingRightsStatus.allowed) {
    return ""
  }

  // If it is not allowed but no error issues, then this is a bug,
  // so we should show a generic error message
  if (orderingRightsStatus.error_ordering_issues.length === 0) {
    Sentry.captureException(
      new Error("Ordering Rights status is not allowed but no error issues")
    )
    return "Not available, but we're unsure why. Please contact support!"
  }

  const primary_error_issue = orderingRightsStatus.error_ordering_issues[0]

  // custom component returned for primary practitioner type issue
  if (
    primary_error_issue.type === ORDERING_ISSUE_TYPES.PRIMARY_PRACTITIONER_TYPE
  ) {
    return getPractitionerTypeTooltip(
      primary_error_issue,
      labTest,
      signingPractitioner,
      orderingPractitioner,
      overrideAccessAction,
      showOverrideOption
    )
  }

  return primary_error_issue.message
}

function getPractitionerTypeTooltip(
  primary_error_issue: OrderingIssue,
  labTest: LabTestForOrderingRights,
  signingPractitioner?: Practitioner,
  orderingPractitioner?: Practitioner,
  overrideAccessAction?: (showClinicState: boolean) => void,
  showOverrideOption: boolean = true
) {
  return (
    <PractitionerTypeIssueTooltip
      allowOverride={
        [
          ORDERING_ISSUE_KEYS.LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_NOT_ALLOWED_OVERRIDE_ALLOWED,
          ORDERING_ISSUE_KEYS.LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED_OVERRIDE_ALLOWED,
        ].includes(primary_error_issue.key) && showOverrideOption
      }
      message={primary_error_issue.message}
      signingPractitioner={signingPractitioner}
      signingPractitionerAndOrderingPractitionerSame={
        signingPractitioner?.id === orderingPractitioner?.id
      }
      labTestVendorPhysAuthApproved={labTest.physician_authorization_approved}
      overrideAccessAction={overrideAccessAction}
      showClinicState={[
        ORDERING_ISSUE_KEYS.LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED,
        ORDERING_ISSUE_KEYS.LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED_OVERRIDE_ALLOWED,
      ].includes(primary_error_issue.key)}
    />
  )
}

/**
 * Returns all the ordering issues that are not related to the shipping state
 * @param orderingIssues
 * @returns
 */
export function getNonShippingStateOrderingIssues(
  orderingIssues: OrderingIssue[]
): OrderingIssue[] {
  return _.filter(
    orderingIssues,
    (issue) => issue.type !== ORDERING_ISSUE_TYPES.SHIPPING_STATE
  )
}

/**
 * Determines the primary state ordering issue from a list of ordering issues
 * @param orderingIssues
 * @returns {
 * shippingStateError: boolean - whether the resulting issue is an error or just a warning
 * primaryStateOrderingIssue: OrderingIssue | undefined - the primary ordering issue, if any
 * }
 */
export function getPrimaryStateOrderingIssue(orderingIssues: OrderingIssue[]): {
  shippingStateError: boolean
  primaryStateOrderingIssue: OrderingIssue | undefined
} {
  const shippingStateIssues = _.filter(
    orderingIssues,
    (issue) => issue.type === ORDERING_ISSUE_TYPES.SHIPPING_STATE
  )

  if (shippingStateIssues.length === 0) {
    return { shippingStateError: false, primaryStateOrderingIssue: undefined }
  }

  // The allowed with waiver is a warning in this context, not an error
  const errorLevelIssues = _.filter(
    shippingStateIssues,
    (issue) =>
      issue.key !==
      ORDERING_ISSUE_KEYS.LAB_TEST_SHIPPING_STATE_ALLOWED_WITH_WAIVER
  )

  const error = errorLevelIssues.length > 0

  const primaryIssue =
    errorLevelIssues.length > 0 ? errorLevelIssues[0] : shippingStateIssues[0]

  return { shippingStateError: error, primaryStateOrderingIssue: primaryIssue }
}

/**
 * Gets the unavailable reason for a bundle
 * @param bundle
 * @returns
 */
export function getUnavailableReasonForBundle(bundle: LabTestBundle): string {
  const orderingRightsStatus = bundle.ordering_rights_status
  if (!orderingRightsStatus) {
    return ""
  }

  if (orderingRightsStatus.allowed) {
    return ""
  }

  // If it is not allowed but no error issues, then this is a bug,
  // so we should show a generic error message
  if (orderingRightsStatus.error_ordering_issues.length === 0) {
    Sentry.captureException(
      new Error("Ordering Rights status is not allowed but no error issues")
    )
    return "Not available, but we're unsure why. Please contact support!"
  }

  const unavailableLabTestIds = orderingRightsStatus.error_ordering_issues.map(
    (issue) => issue.source
  )
  const unavailableLabTestNames = bundle.lab_tests
    .filter((labTest) => unavailableLabTestIds.includes(labTest.id))
    .map((labTest) => labTest.name)

  const bundleType =
    bundle.bundle_type === BUNDLE_TYPES.PANEL ? "panel" : "bundle"

  // Remove the "" objects and combine the test names using ", "
  const unavailableLabTestNamesCombined = unavailableLabTestNames
    .filter(Boolean)
    .join(", ")

  if (unavailableLabTestNamesCombined) {
    return `The following tests are currently unavailable: ${unavailableLabTestNamesCombined}. Please edit this ${bundleType}.`
  }

  return ""
}

/**
 * Determines if an ordered test is orderable and if it is just a warning not error
 * @param orderedTestOrLabTest: could be an OrderedTest or a LabTest. Will only be a lab test when coming from the BundleSidebar.
 * @returns error: if there is a warning OR error (the naming is confusing, but keeping this for consistency with the old code)
 * @returns errorText: the error/warning text to display
 * @returns warningOnly: if there is a warning (not an error)
 */
export function isOrderedTestOrderable(
  orderedTestOrLabTest: OrderedTest | LabTest
): {
  error: boolean
  errorText: string
  warningOnly: boolean
} {
  const orderingRightsStatus = orderedTestOrLabTest.ordering_rights_status
  const DEFAULT_EMPTY_ORDERING_ISSUE = {
    error: false,
    errorText: "",
    warningOnly: false,
  }

  if (!orderingRightsStatus) {
    return DEFAULT_EMPTY_ORDERING_ISSUE
  }

  if (
    orderingRightsStatus.allowed &&
    orderingRightsStatus.warning_ordering_issues.length === 0
  ) {
    return DEFAULT_EMPTY_ORDERING_ISSUE
  }

  if (orderingRightsStatus.error_ordering_issues.length === 0) {
    return {
      error: true,
      errorText: orderingRightsStatus.warning_ordering_issues[0].message,
      warningOnly: true,
    }
  }

  return {
    error: true,
    errorText: orderingRightsStatus.error_ordering_issues[0].message,
    warningOnly: false,
  }
}

export function orderedTestHasOrderingIssues(
  orderedTest: OrderedTest,
  issueKeys: OrderingIssueKey[]
): boolean {
  return issueKeys.some((issue) =>
    orderedTest.ordering_rights_status?.error_ordering_issues
      .map((ors) => ors.key)
      .includes(issue)
  )
}

export function orderHasOneOfLabCompaniesAndClinicState(
  order: Order,
  labCompanyKeys: string[],
  states: string[]
): boolean {
  return (
    orderContainsLabTestFromCompany(order, labCompanyKeys) &&
    states.includes(order.clinic?.state)
  )
}
