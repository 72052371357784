import { forwardRef, ComponentProps } from "react"

import { isEmpty } from "lodash"

import { Typography } from "@material-ui/core"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { ReactComponent as FoodPlanListItemIcon } from "app/assets/images/food-plan-list-item.svg"
import Button from "app/components/design-system/Button"
import useRedirectToEditFoodPlan from "app/food-plans/hooks/use-redirect-to-edit-food-plan"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceIdentifier } from "app/swr/types"
import { colors, shadows, textPrimaryColor } from "app/theme"
import formatDateForHuman from "app/utils/format-date-for-human"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { FoodPlan } from "types/food-plan"
import { Patient } from "types/patient"

const useStyles = makeAppStyles((theme) => ({
  dropdownTrigger: {
    alignSelf: "flex-start",
  },
  dropdownContent: {
    width: "calc(100vw - 32px)",
    display: "flex",
    flexDirection: "column",
    background: "white",
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 8,
    boxShadow: shadows.lg,
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    gap: theme.spacing(1.25),
    cursor: "pointer",
    fontSize: 15,
    fontWeight: 600,
    whiteSpace: "nowrap",

    "&:hover, &:focus, &:active": {
      background: colors.blue[50],
    },

    "& > svg": {
      fill: "currentColor",
      minWidth: 34,
    },
  },
  labels: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  labelFoodPlan: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: -2,
    color: textPrimaryColor,
    lineHeight: 1.35,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  labelEditedDate: {
    fontSize: 14,
    fontWeight: 400,
    color: textPrimaryColor,
    lineHeight: 1.35,
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: `calc(100% - ${theme.spacing(3.0)}px)`,
    padding: theme.spacing(0.0, 1.5),
  },
}))

const FoodPlanDropdownMenuItem = ({
  identifier,
  onSelect,
}: {
  identifier: ResourceIdentifier
  onSelect: (foodPlan: FoodPlan) => void
}) => {
  const classes = useStyles()
  const foodPlan = useCachedResource<FoodPlan>(identifier)
  const patient = useCachedResource<Patient>(
    foodPlan?.relationships.patient.data
  )
  if (!foodPlan || !patient) {
    return null
  }

  return (
    <DropdownMenu.Item
      className={classes.item}
      onSelect={() => onSelect(foodPlan)}
    >
      <FoodPlanListItemIcon viewBox="0 0 34 42" />

      <div className={classes.labels}>
        <Typography
          className={classes.labelFoodPlan}
          variant="h6"
          title={patient.attributes.full_name}
        >
          {patient.attributes.full_name}
        </Typography>
        <Typography
          className={classes.labelEditedDate}
          component="span"
          variant="body1"
        >
          {foodPlan.attributes.created_at &&
            formatDateForHuman(foodPlan.attributes.created_at)}
        </Typography>
      </div>
    </DropdownMenu.Item>
  )
}

const DropdownMenuTriggerButton = forwardRef<HTMLButtonElement, any>(
  (props: ComponentProps<typeof Button>, ref) => {
    const classes = useStyles()
    return (
      <Button
        {...props}
        color="text"
        ref={ref}
        className={classes.dropdownTrigger}
      >
        See Recent Plans
      </Button>
    )
  }
)

export interface FoodPlanListItemDropdownMenuProps {
  foodPlanIdentifiers: ResourceIdentifier[]
}

export default function FoodPlanListItemDropdownMenu() {
  const classes = useStyles()

  const { data: foodPlanIdentifiers } = useCollectionSWR(
    "/food_plans/",
    {
      include: ["patient.user"],
      params: {
        "page[limit]": "10",
      },
    },
    {
      revalidateOnMount: true,
    }
  )

  const redirectToEditFoodPlan = useRedirectToEditFoodPlan()

  if (isEmpty(foodPlanIdentifiers)) {
    return null
  }

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <DropdownMenuTriggerButton />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="center"
          className={classes.dropdownContent}
          sideOffset={10}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          {interleave(
            foodPlanIdentifiers.map((foodPlanIdentifier) => (
              <FoodPlanDropdownMenuItem
                key={foodPlanIdentifier.id}
                identifier={foodPlanIdentifier}
                onSelect={(foodPlan) => {
                  redirectToEditFoodPlan(
                    foodPlan.relationships.patient.data.id,
                    foodPlan.id
                  )
                }}
              />
            )),
            (_, key) => (
              <DropdownMenu.Separator
                key={`sep_${key}`}
                className={classes.separator}
              />
            )
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
