import moment from "moment"

import AlertTriangleRed from "app/assets/images/alert-triangle-red.svg"
import Tooltip from "app/components/Tooltip"
import Underline from "app/components/design-system/Underline"

import { LateResultsMessage } from "./LateResultsMessage"

const ResultsExpectedDate = ({
  dateResultsExpectedFromLab,
  resultsDelayTitle,
  resultsDelaySubtitle,
  resultsDelayReason,
  labProvidesResultsEta,
  labCompanyName,
  isRecollection,
}: {
  dateResultsExpectedFromLab: string
  resultsDelayTitle?: string
  resultsDelaySubtitle?: string
  resultsDelayReason?: string
  labProvidesResultsEta?: boolean
  labCompanyName?: string
  isRecollection?: boolean
}) => {
  const daysSinceResultsExpected = dateResultsExpectedFromLab
    ? moment().diff(moment(dateResultsExpectedFromLab), "days")
    : null

  if (daysSinceResultsExpected && daysSinceResultsExpected > 0) {
    return (
      <LateResultsMessage
        daysSinceResultsExpected={daysSinceResultsExpected}
        resultsDelayReason={resultsDelayReason}
        labCompanyName={labCompanyName}
      />
    )
  }

  let date: React.ReactNode = moment(dateResultsExpectedFromLab).format(
    "MMMM D, YYYY"
  )

  if (resultsDelayTitle && resultsDelaySubtitle) {
    date = (
      <Tooltip
        interactive
        title={<p>{`${resultsDelayTitle}. ${resultsDelaySubtitle}.`}</p>}
        placement="bottom"
        arrow
      >
        <Underline>
          <p className="font-semibold">{date}</p>
          <img src={AlertTriangleRed} alt="Results delay" className="ml-1" />
        </Underline>
      </Tooltip>
    )
  }

  const estimateSourceText = `${
    labProvidesResultsEta
      ? `${labCompanyName || "The lab"} estimates`
      : "We estimate"
  } ${isRecollection ? "recollection results" : "results"} to arrive on`

  return (
    <p>
      {estimateSourceText} {date}
    </p>
  )
}

export default ResultsExpectedDate
