import useDebounceState from "app/hooks/use-debounce-state"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

import DashboardFilter from "../DashboardFilter"

function useDashboardPatients({
  debouncedInputValue,
  includeStorefront = false,
  value,
}: {
  debouncedInputValue?: string
  includeStorefront?: boolean
  value?: string
}) {
  const valuePatient = useCachedResource<DashboardPatient>(
    value
      ? {
          type: "dashboard_patient",
          id: value,
        }
      : undefined
  )

  const search =
    debouncedInputValue &&
    // Exclude searching in this case as this means the patient is already selected and would end up returning only itself.
    debouncedInputValue !== valuePatient?.attributes.full_name
      ? debouncedInputValue
      : undefined

  const {
    data: patientIdentifiers,
    meta,
    isLoading,
  } = useCollectionSWR(
    "/dashboard/patients/",
    {
      params: {
        "filter[include_storefront]": includeStorefront ? "true" : "false",
        "filter[search]": search,
        "page[limit]": "20",
        order_by: "first_name,last_name",
      },
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
    }
  )

  const patients = useCachedCollection<DashboardPatient>(patientIdentifiers)

  // If the value is not yet loaded, then we retrieve it independently
  useCollectionSWR(value && !valuePatient ? `/dashboard/patients/` : null, {
    params: {
      "filter[include_storefront]": includeStorefront ? "true" : "false",
      "filter[id.in]": value,
      "page[limit]": "1",
    },
  })

  return {
    isLoading,
    isMore: meta?.pagination?.count > meta?.pagination?.limit,
    patients,
    valuePatient,
  }
}

export interface DashboardPatientFilterProps {
  includeStorefront?: boolean
  onFilterChange: (value?: string) => void
  value?: string
}

export default function DashboardPatientFilter({
  includeStorefront = false,
  onFilterChange,
  value,
}: DashboardPatientFilterProps) {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounceState(
    "",
    250
  )
  const { isLoading, isMore, patients, valuePatient } = useDashboardPatients({
    debouncedInputValue,
    includeStorefront,
    value,
  })

  return (
    <DashboardFilter
      getOptionLabel={(option) => option.attributes.full_name}
      getOptionValue={(option) => option.id}
      inputValue={inputValue}
      isLoading={isLoading}
      isMore={isMore}
      isMoreText="Only showing the first 20 patients. Please refine your search to find the patient you are looking for."
      label="Patient"
      onChange={onFilterChange}
      onInputChange={setInputValue}
      options={patients}
      value={valuePatient}
    />
  )
}
