import clsx from "clsx"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as CircleArrowIcon } from "app/assets/icons/circle-arrow.svg"
import Button from "app/components/design-system/Button"
import { primaryColor, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ detailsOpen: boolean }>((theme) => ({
  detailsContainer: {
    width: 70,
    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer",
    color: primaryColor,
    fontWeight: 600,
  },
  buttonContainer: {
    width: "45%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 8,
  },
  buttonClicked: {
    backgroundColor: colors.lightBlue[50],
    border: `1px solid ${primaryColor}`,
  },
  circleArrowIcon: {
    marginLeft: 4,
    marginTop: 3,
    transform: ({ detailsOpen }) =>
      detailsOpen ? "rotate(180deg)" : "rotate(0deg)",
  },
}))

export default function PanelItemDetailsButton({
  onClick,
  detailsOpen,
  isLoading,
}: {
  onClick: () => void
  detailsOpen: boolean
  isLoading: boolean
}) {
  const classes = useStyles({ detailsOpen })
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))

  const content = isMobile ? (
    <Button
      color="secondary"
      onClick={onClick}
      className={clsx(
        classes.buttonContainer,
        detailsOpen && classes.buttonClicked
      )}
    >
      Details
    </Button>
  ) : (
    <span className={classes.detailsContainer} onClick={onClick}>
      Details <CircleArrowIcon className={classes.circleArrowIcon} />
    </span>
  )

  return <>{isLoading ? <Skeleton>{content}</Skeleton> : content}</>
}
