import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { useHistory, useLocation } from "react-router-dom"
import { useMount } from "react-use"

import {
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"

import { UserPaths } from "app/Routes"
import { API } from "app/api"
import StoreIconGrey from "app/assets/icons/ecommerce-store-grey.svg"
import PageToolbar from "app/components/PageToolbar"
import { StorefrontType } from "app/hooks/use-resource"
import { trackLabShopEvent, trackPage } from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"
import { Theme } from "app/theme"

import EcommerceStorefrontWrapper from "./EcommerceStorefrontWrapper"
import EcommerceWelcome from "./EcommerceWelcome"
import RemoveEcommerceModal from "./components/RemoveEcommerceModal"
import SideNav from "./components/SideNav"
import {
  INTERCOM_REQUEST_PRAC_LICENSE_LABSHOP_MESSAGE,
  LabShopEvent,
} from "./constants"

const styles = (theme: Theme) => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  container: {
    overflowY: "scroll" as any,
    height: "calc(100vh - 3px)",
  },
  headerContainer: {
    display: "flex",
    "flex-direction": "row",
    [theme.breakpoints.down("md")]: {
      "flex-direction": "column",
    },
    width: "100%",
  },
  iconContainer: {
    marginRight: 10,
  },
  divider: {
    marginTop: 45,
    marginBottom: 35,
  },
  emptyLabTests: {
    display: "flex",
    "flex-direction": "column",
    background: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px 64px",
    borderRadius: 8,
    marginTop: 35,
  },
  labTestsContainer: {
    display: "flex",
    "flex-direction": "row",
    marginTop: 35,
  },
  labTestCard: {
    marginRight: 15,
  },
})

const useStyles = makeStyles(styles)

const Ecommerce = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const urlParams = new URLSearchParams(window.location.search)
  const history = useHistory()
  const { search } = useLocation()

  const [loading, setLoading] = useState<boolean>(true)
  const [selectedStorefrontId, setSelectedStorefrontId] = useState<string>(
    urlParams.get("storefrontId") || ""
  )
  const [selectedStorefrontName, setSelectedStorefrontName] =
    useState<string>("")
  const [storefrontToRemove, setStorefrontToRemove] = useState<StorefrontType>()
  const [storefronts, setStorefronts] = useState<StorefrontType[]>([])
  const [isDeleteStorefrontModalOpen, setIsDeleteStorefrontModalOpen] =
    useState(false)
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)
  const [isStorefrontDirty, setIsStorefrontDirty] = useState(false)

  const fetchStorefronts = async (overrideSelectedId?: boolean) => {
    const response = await API.Storefront.retrieveStorefronts()

    const data = response.data

    setStorefronts(data.data)

    // If no length, clinic has not created a storefront
    if (!data.data.length) {
      setSelectedStorefrontId("")
    } else if (selectedStorefrontId === "" || overrideSelectedId) {
      setSelectedStorefrontId(data.data[0].id)
    }
  }

  useMount(async () => {
    await fetchStorefronts()

    if (search) {
      if (search.includes("?requestLicenseUse=true")) {
        window.Intercom(
          "showNewMessage",
          INTERCOM_REQUEST_PRAC_LICENSE_LABSHOP_MESSAGE
        )
      }
    }

    if (!storefronts.length && !search.includes("?welcome=true")) {
      history.push({
        search: "welcome=true",
      })
    }

    if (urlParams.get("storefrontId")) {
      urlParams.delete("storefrontId")
      history.push({
        search: urlParams.toString(),
      })
    }

    trackPage(window.location.pathname)

    setLoading(false)
  })

  const onCreateStorefront = async () => {
    const response = await API.Storefront.createStorefront()
    setSelectedStorefrontId(response.data.data.id)
    setStorefronts([...storefronts, response.data.data])

    trackLabShopEvent(LabShopEvent.CREATE_NEW_LABSHOP, {
      storefrontId: response.data.data.id,
    })
  }

  const onHandleStorefrontUpdate = (storefront: StorefrontType) => {
    const newStorefronts = storefronts.map((prevStorefront) => {
      if (prevStorefront.id === storefront.id) {
        return storefront
      }

      return prevStorefront
    })

    setStorefronts(newStorefronts)
  }

  const onHandleStorefrontDelete = () => {
    setStorefrontToRemove(
      storefronts.find((s) => s.id === selectedStorefrontId)
    )
    setIsDeleteStorefrontModalOpen(true)
    trackLabShopEvent(LabShopEvent.OPEN_DELETE_LABSHOP_MODAL, {
      storefrontId: storefrontToRemove?.id,
    })
  }

  const handleCloseDeleteStorefrontModal = () => {
    setStorefrontToRemove(undefined)
    setIsDeleteStorefrontModalOpen(false)
  }

  const deleteStorefront = async () => {
    await API.Storefront.archiveStorefront(selectedStorefrontId)

    trackLabShopEvent(LabShopEvent.CONFIRM_DELETE_LABSHOP, {
      storefrontId: selectedStorefrontId,
    })

    setStorefrontToRemove(undefined)

    await fetchStorefronts(true)

    setIsDeleteStorefrontModalOpen(false)
    dispatch(
      showMessage({
        message: "LabShop deleted",
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        autoHideDuration: 5000,
      })
    )
  }

  const handleSelectedStorefront = (storefrontId: string) => {
    if (isStorefrontDirty) {
      if (
        window.confirm(
          "Are you sure you want to leave this page, you have unsaved changes"
        )
      ) {
        setSelectedStorefrontId(storefrontId)
      }
    } else {
      setSelectedStorefrontId(storefrontId)
    }
  }

  useEffect(() => {
    if (selectedStorefrontId) {
      const storefront = storefronts.find((s) => s.id === selectedStorefrontId)
      setSelectedStorefrontName(
        storefront?.attributes.name
          ? storefront?.attributes.name
          : storefront?.attributes.draft_name
          ? storefront?.attributes.draft_name
          : "LabShop"
      )
      setShowSidebarOnMobile(false)
    }
  }, [selectedStorefrontId])

  useEffect(() => {
    if (storefronts.length && search.includes("?welcome=true")) {
      history.replace({
        search: "",
      })
    }
  }, [storefronts])

  if (loading) {
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress ingaria-label="Loading" size={60} />
      </div>
    )
  }

  return (
    <Grid container>
      {!storefronts.length ? (
        <>
          <Grid item xs={12} md={12}>
            <PageToolbar title="My LabShop" fullBorder={true} />
          </Grid>
          <Grid item xs={12} md={12}>
            <EcommerceWelcome onContinueClick={onCreateStorefront} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={3}>
            {isMobile && (
              <>
                <PageToolbar
                  breadcrumbs={[
                    {
                      to: UserPaths.ECOMMERCE_STORE,
                      icon: StoreIconGrey,
                      label: "LabShop",
                      onClick: () => setShowSidebarOnMobile(true),
                    },
                    {
                      label: selectedStorefrontName,
                    },
                  ]}
                />
                {showSidebarOnMobile && (
                  <SideNav
                    storefronts={storefronts}
                    createNewLabShop={onCreateStorefront}
                    selectedStorefrontId={selectedStorefrontId}
                    selectStorefront={handleSelectedStorefront}
                    isMobile={isMobile}
                  />
                )}
              </>
            )}
            {!isMobile && (
              <>
                <Grid item xs={12} md={12}>
                  <PageToolbar title="LabShop" fullBorder={true} />
                </Grid>
                <SideNav
                  storefronts={storefronts}
                  createNewLabShop={onCreateStorefront}
                  selectedStorefrontId={selectedStorefrontId}
                  selectStorefront={handleSelectedStorefront}
                  isMobile={isMobile}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={9} className={classes.container}>
            {selectedStorefrontId && (
              <EcommerceStorefrontWrapper
                storefrontId={selectedStorefrontId}
                handleStorefrontUpdate={onHandleStorefrontUpdate}
                handleStorefrontDelete={onHandleStorefrontDelete}
                handleIsDirty={(isDirty: boolean) =>
                  setIsStorefrontDirty(isDirty)
                }
              />
            )}
          </Grid>
        </>
      )}
      <RemoveEcommerceModal
        open={isDeleteStorefrontModalOpen}
        onCloseModal={handleCloseDeleteStorefrontModal}
        storefrontName={
          storefrontToRemove?.attributes.name
            ? storefrontToRemove.attributes.name
            : storefrontToRemove?.attributes.draft_name
            ? storefrontToRemove?.attributes.draft_name
            : "LabShop"
        }
        confirmDelete={deleteStorefront}
      />
    </Grid>
  )
}

export default Ecommerce
