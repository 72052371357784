import { useState } from "react"
import * as React from "react"
import { connect } from "react-redux"

import {
  styled,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
} from "@material-ui/core"

import MailIcon from "app/assets/icons/envelope/email.svg"
import FacebookIcon from "app/assets/icons/facebook.svg"
import LinkIcon from "app/assets/icons/link.svg"
import ShareIcon from "app/assets/icons/share.svg"
import DesignSystemButton from "app/components/design-system/Button"
import * as Actions from "app/store/actions"
import { primaryColor, titleFontFamily, colors, shadows } from "app/theme"
import { Practitioner, LabTestBundle } from "app/types"

interface StyledSystemDesignButtonProps {
  isXsMobile?: boolean
}
const StyledDesignSystemButton = styled(({ isXsMobile, ...other }) => (
  <DesignSystemButton {...other} />
))({
  padding: "8px 12px",
  width: ({ isXsMobile }: StyledSystemDesignButtonProps) => {
    return isXsMobile ? "100%" : "auto"
  },
  "& .MuiButton-label": {
    marginTop: 3,
    marginBottom: 2,
  },
  "& .MuiButton-startIcon": {
    marginTop: -2,
    marginBottom: 1,
  },
})

interface StyledMenuProps {
  isXsMobile?: boolean
}
const StyledMenu = styled(({ isXsMobile, ...other }) => <Menu {...other} />)({
  "& .MuiMenu-paper": {
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    shadow: shadows.lg,
    marginTop: 5,
    marginLeft: ({ isXsMobile }: StyledMenuProps) => {
      return isXsMobile ? "0" : "-15"
    },
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
})

interface StyledMenuItemProps {
  borderBottom?: boolean
}

const StyledMenuItem = styled(({ borderBottom, ...other }) => (
  <MenuItem {...other} />
))({
  marginLeft: 10,
  marginRight: 10,
  borderBottom: ({ borderBottom }: StyledMenuItemProps) => {
    return borderBottom ? `1px solid ${colors.blueGray[200]}` : "none"
  },
  backgroundColor: "white !important",
})

const StyledListItemText = styled<typeof ListItemText>(ListItemText)({
  marginRight: 20,
  color: primaryColor,
  fontSize: 15,
  fontFamily: titleFontFamily,
  "& .MuiListItemText-primary": {
    fontWeight: 600,
  },
})

const StyledListItemIcon = styled<typeof ListItemIcon>(ListItemIcon)({
  minWidth: 0,
})

const IconImage = styled<any>("img")({
  width: 16,
})

function ShareBundleButton({
  bundle,
  practitioner,
  handleClickCopyLink,
  handleClickEmailLink,
  handleClickFacebookLink,
}: {
  bundle: LabTestBundle
  practitioner: Practitioner
  handleClickCopyLink: Function
  handleClickEmailLink: Function
  handleClickFacebookLink: Function
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isXsMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  )

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const onClickCopyLink = (): void => {
    handleClose()
    handleClickCopyLink(bundle)
  }

  const onClickEmailLink = (): void => {
    handleClose()
    handleClickEmailLink(bundle, practitioner)
  }

  const onClickFacebookLink = (): void => {
    handleClose()
    handleClickFacebookLink(bundle)
  }

  const styledMenuPaperProps = isXsMobile
    ? {
        style: {
          right: 42,
        },
      }
    : {}
  return (
    <>
      <StyledDesignSystemButton
        color="secondary"
        onClick={handleClick}
        startIcon={ShareIcon}
        size="xsmall"
        isXsMobile={isXsMobile}
      >
        Share Bundle
      </StyledDesignSystemButton>
      <StyledMenu
        PaperProps={styledMenuPaperProps}
        isXsMobile={isXsMobile}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isXsMobile ? "left" : "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isXsMobile ? "left" : "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
      >
        <StyledMenuItem
          onClick={onClickCopyLink}
          disableGutters={true}
          borderBottom={true}
        >
          <StyledListItemText primary="Copy Bundle Link" />
          <StyledListItemIcon>
            <IconImage src={LinkIcon} alt="link-icon" />
          </StyledListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickEmailLink}
          disableGutters={true}
          borderBottom={true}
        >
          <StyledListItemText primary="Email" />
          <StyledListItemIcon>
            <IconImage src={MailIcon} alt="mail-icon" />
          </StyledListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onClickFacebookLink}
          disableGutters={true}
          borderBottom={true}
        >
          <StyledListItemText primary="Facebook" />
          <StyledListItemIcon>
            <IconImage src={FacebookIcon} alt="facebook-icon" />
          </StyledListItemIcon>
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleClickCopyLink: (bundle) => {
    // Create the share bundle and copy the link
    dispatch(Actions.copyBundleLink(bundle.id))
  },

  handleClickEmailLink: (
    bundle: LabTestBundle,
    practitioner: Practitioner
  ): void => {
    // Create the share bundle and open the mailto link
    dispatch(
      Actions.shareBundleViaEmail(bundle, practitioner, (mailToUrl) => {
        // Opening the mailto URL will open the client's mail app
        window.location.href = mailToUrl
      })
    )
  },

  handleClickFacebookLink: (bundle: LabTestBundle): void => {
    // Create the share bundle and open the mailto link
    dispatch(Actions.shareBundleViaFacebook(bundle.id))
  },
})

export { ShareBundleButton }

export default connect(null, mapDispatchToProps)(ShareBundleButton)
