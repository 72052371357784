import { memo } from "react"

import { range } from "lodash"

import { ComboGroupConfigurationPayload } from "app/main/combo-groups/constants"
import { AnyLimitedLabTest, labTestLocation, Practitioner } from "app/types"

import { LabTestListMobileItem } from "./LabTestListMobileItem"
import { SelectedLabTests } from "./types"

interface Props {
  practitioner?: Practitioner
  signingPractitioner?: Practitioner
  labTests: AnyLimitedLabTest[] | null
  setLabTestInModal: (labTest: AnyLimitedLabTest, index: number) => void
  onToggleFavorite: (labTest: AnyLimitedLabTest) => void
  isPending: boolean
  selectedLabTests?: SelectedLabTests
  onAddLabTest?: (labTest: AnyLimitedLabTest, index: number) => void
  onRemoveLabTest?: (labTest: AnyLimitedLabTest) => void
  location: labTestLocation
  createBundleClick?: () => void
  vendorPhysicianServicesActivated?: boolean
  onAddComboGroup?: (
    labTest: AnyLimitedLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
}

/*
 * Renders the list of lab tests for mobile sizes.
 */
function LabTestListMobile({
  practitioner,
  signingPractitioner,
  labTests,
  setLabTestInModal,
  onToggleFavorite,
  isPending,
  selectedLabTests,
  onAddLabTest,
  onRemoveLabTest,
  location,
  createBundleClick,
  vendorPhysicianServicesActivated,
  onAddComboGroup,
}: Props) {
  return (
    <div>
      {labTests
        ? labTests.map((labTest, index) => (
            <LabTestListMobileItem
              key={labTest.id}
              labTest={labTest}
              practitioner={practitioner}
              signingPractitioner={signingPractitioner}
              onToggleFavorite={onToggleFavorite}
              isPending={isPending}
              setLabTestInModal={(labTest: AnyLimitedLabTest) => {
                setLabTestInModal && setLabTestInModal(labTest, index)
              }}
              selectedLabTests={selectedLabTests}
              onAddLabTest={(labTest: AnyLimitedLabTest) => {
                onAddLabTest && onAddLabTest(labTest, index)
              }}
              onRemoveLabTest={onRemoveLabTest}
              location={location}
              createBundleClick={createBundleClick}
              vendorPhysicianServicesActivated={
                vendorPhysicianServicesActivated
              }
              onAddComboGroup={onAddComboGroup}
            />
          ))
        : range(5).map((number) => (
            <LabTestListMobileItem key={number} isPending location={location} />
          ))}
    </div>
  )
}

export default memo(LabTestListMobile)
