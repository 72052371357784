import React, {
  useRef,
  useEffect,
  useState,
  ReactElement,
  useMemo,
} from "react"
import { useDispatch } from "react-redux"

import html2pdf from "html2pdf.js"
import { PDFDocument } from "pdf-lib"

import {
  trackPdfGenerationEvent,
  PDF_GENERATION_EVENTS,
} from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"

interface UsePDFGeneratorOptions {
  margins?: [number, number, number, number]
  scale?: number
}

type FooterGenerator = (pdfDoc: PDFDocument) => Promise<void>

/**
 * The usePDFGenerator hook is used to generate a PDF from a React component.
 * It takes the react component to render, the filename for the PDF, and a footer generator function.
 *
 * The footer generator function is an async function that takes a PDFDocument and adds a footer to the document.
 *
 * Options are passed to the hook to set the margins and scale of the PDF. The scale is used to scale the content before rendering
 * which directly affects the quality of the images in the PDF. Be careful setting the value too high because for some devices it can
 * result in empty images in the PDF for large PDFs.
 *
 * The hook returns a RenderContent component, a boolean indicating if the PDF content is ready, and a function to generate the PDF.
 * The RenderContent component should be rendered in the component tree to be able to generate the PDF.
 *
 */
const usePDFGenerator = (
  component: ReactElement,
  filename: string,
  footerGenerator: FooterGenerator,
  options: UsePDFGeneratorOptions = {},
  metadata: Record<string, any> = {}
) => {
  const { margins = [0, 0, 0, 0], scale = 3.5 } = options
  const contentRef = useRef<HTMLDivElement>(null)
  const [isReady, setIsReady] = useState(false)
  const dispatch = useDispatch()

  function generateUniqueId() {
    const timestamp = new Date().getTime()
    const random = Math.random().toString().slice(2).substring(0, 5)
    return `${timestamp}-${random}`.toLowerCase()
  }

  function downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = filename
    link.click()
    URL.revokeObjectURL(url)
  }

  // Memoize the image processing function
  const processImages = useMemo(() => {
    return async () => {
      const toDataURL = async (url: string): Promise<string> => {
        try {
          const response = await fetch(url)
          if (!response.ok) throw new Error("Network response was not ok")
          const blob = await response.blob()
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result as string)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
        } catch (error) {
          console.error("Error in toDataURL:", error)
          throw error
        }
      }

      try {
        const images = contentRef.current?.querySelectorAll("img") || []
        const promises = Array.from(images).map(async (img) => {
          if (img.src) {
            try {
              const dataUrl = await toDataURL(img.src)
              img.src = dataUrl
            } catch (error) {
              console.error("Error processing image:", error)
            }
          }
        })
        await Promise.all(promises)
        setIsReady(true)
      } catch (error) {
        console.error("Error processing images:", error)
      }
    }
  }, [contentRef])

  useEffect(() => {
    processImages()
  }, [processImages])

  const generatePDF = async () => {
    const generationId = generateUniqueId()
    trackPdfGenerationEvent(PDF_GENERATION_EVENTS.PDF_GENERATION_STARTED, {
      generation_id: generationId,
      margins: margins,
      scale: scale,
      is_ready: isReady,
      metadata: metadata,
    })
    if (!contentRef.current || !isReady) return
    const startTime = performance.now()

    // Open new tab for PDF (needed for safari, also hides when we make the PDF content visible for rendering)
    const pdfTab = window.open(
      "https://embed.rupahealth.com/loading-interpretation",
      "_blank"
    )

    const element = contentRef.current

    // Helper functions to show/hide content for rendering
    function makeContentVisible() {
      element.style.opacity = "1"
      element.style.position = "static"
    }
    function makeContentInvisible() {
      element.style.opacity = "0"
      element.style.position = "absolute"
    }

    const opt = {
      margin: margins,
      filename: filename,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: {
        scale: scale,
        logging: true,
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
        allowTaint: false,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }

    try {
      // Make PDF content visible for rendering
      makeContentVisible()

      // Create PDF blob from HTML content
      const pdfBlob = await html2pdf().set(opt).from(element).outputPdf("blob")

      // Hide PDF content after rendering
      makeContentInvisible()

      // Load PDF blob into PDFDocument for adding footer
      const arrayBuffer = await pdfBlob.arrayBuffer()
      const pdfDoc = await PDFDocument.load(arrayBuffer)
      const pageCount = pdfDoc.getPages().length

      // Add footer to PDFDocument
      if (footerGenerator) {
        await footerGenerator(pdfDoc)
      }

      // Save PDFDocument to PDF bytes and download
      const pdfBytes = await pdfDoc.save()
      const blob = new Blob([pdfBytes], { type: "application/pdf" })
      downloadBlob(blob, filename)

      // close the PDF tab
      pdfTab?.close()

      // Trigger event
      const endTime = performance.now()
      const generationTime = endTime - startTime
      trackPdfGenerationEvent(PDF_GENERATION_EVENTS.PDF_GENERATION_COMPLETED, {
        generation_id: generationId,
        margins: margins,
        scale: scale,
        is_ready: isReady,
        generation_duration: generationTime,
        page_count: pageCount,
        metadata: metadata,
      })

      // Show success message
      dispatch(
        showMessage({
          message: "Lab Results Downloaded",
          variant: "success",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
          autoHideDuration: 6000,
        })
      )
    } catch (error) {
      // Clean up
      makeContentInvisible()
      pdfTab?.close()

      // Trigger event
      const endTime = performance.now()
      const generationTime = endTime - startTime
      trackPdfGenerationEvent(PDF_GENERATION_EVENTS.PDF_GENERATION_FAILED, {
        generation_id: generationId,
        margins: margins,
        scale: scale,
        is_ready: isReady,
        generation_duration: generationTime,
        metadata: metadata,
        error_message: String(error),
      })

      // Show error message
      dispatch(
        showMessage({
          message: "There was an issue downloading these results.",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
          autoHideDuration: 6000,
        })
      )
    }
  }

  // Memoize the RenderContent component
  const RenderContent = useMemo(() => {
    return () => (
      <div
        ref={contentRef}
        style={{
          position: "absolute",
          left: "-9999px",
          top: "-9999px",
          width: "210mm",
          opacity: 0,
        }}
      >
        <div className="pdf-content">
          <div className="pdf-page">{component}</div>
        </div>
        <style>
          {`
            .pdf-content {
              box-sizing: border-box;
              width: 100%;
              max-width: 190mm;
              margin: 0 auto;
            }

            .pdf-page {
              page-break-after: always;
            }

            @media print {
              .pdf-page {
                page-break-after: always;
              }
            }

            .pdf-page:last-child {
              page-break-after: auto;
            }
          `}
        </style>
      </div>
    )
  }, [component])

  return { RenderContent, isReady, generatePDF }
}

export default usePDFGenerator
