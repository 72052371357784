import {
  EMPIRE_DRAW_SITES_URL,
  LAB_COMPANIES_WE_CAN_GENERATE_REQUISITIONS_FOR,
  LAB_COMPANIES_WE_GENERATE_REQUISITIONS_FOR_IOK,
  LAB_COMPANY_KEY,
  LABCORP_COMPANY_KEYS,
  LABCORP_DRAW_SITES_URL,
  QUEST_COMPANY_KEYS,
  QUEST_DRAW_SITES_URL,
} from "app/constants"
import { SampleCollectionCoordinator } from "app/constants.typed"

/**
 * Returns the URL for a patient to book in-house phlebotomy based on the lab company.
 */
export function labCompanyScheduleBloodDrawUrl(
  labCompanyKey: string | null | undefined
) {
  if (!labCompanyKey) return undefined

  if (LABCORP_COMPANY_KEYS.includes(labCompanyKey)) {
    return LABCORP_DRAW_SITES_URL
  }
  if (QUEST_COMPANY_KEYS.includes(labCompanyKey)) {
    return QUEST_DRAW_SITES_URL
  }
  if (labCompanyKey === LAB_COMPANY_KEY.EMPIRE_CITY_LABS) {
    return EMPIRE_DRAW_SITES_URL
  }

  return undefined
}

export function requisitionGenerationAllowed({
  labCompanyKey,
  sampleCollectionCoordinator,
}: {
  labCompanyKey: string | null | undefined
  sampleCollectionCoordinator: SampleCollectionCoordinator | null | undefined
}) {
  if (!labCompanyKey || !sampleCollectionCoordinator) return false

  switch (sampleCollectionCoordinator) {
    case "CLINIC":
      return LAB_COMPANIES_WE_GENERATE_REQUISITIONS_FOR_IOK.includes(
        labCompanyKey
      )
    case "LAB":
      return LAB_COMPANIES_WE_CAN_GENERATE_REQUISITIONS_FOR.includes(
        labCompanyKey
      )
    case "PATIENT":
      // We currently don't allow generating requisitions for drop-shipped kits / orders
      // This is a product decision and can be changed in the future
      return false
    default:
      return false
  }
}
