import { useState } from "react"
import { useDispatch } from "react-redux"

import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import { showMessage } from "app/store/actions/fuse"
import { replaceBundle } from "app/store/actions/practitionerBundles.actions"
import * as Actions from "app/store/actions/shareBundles.actions"
import {
  LabTestBundleWithoutPractitioner,
  LabTestBundleWithoutPractitionerAndWithLabTestIds,
} from "app/types"

/**
 * Provide bundles a set of mutators for sharing and unsharing a bundle with their clinic.
 *
 * @returns mutators to share and unshare a bundle with a clinic and the loading state of the requests.
 * @example
 *
 * const { shareWithClinic, clinicShareIsLoading } = useClinicSharedBundles()
 *
 * function onClick(bundle: LabTestBundleWithoutPractitioner) {
 *   shareWithClinic(bundle)
 * }
 *
 * <Button onClick={onClick} disabled={clinicShareIsLoading} loading={clinicShareIsLoading}>Share with Clinic</Button>
 */
export default function useClinicSharedBundles() {
  const [clinicShareIsLoading, setClinicShareIsLoading] = useState(false)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const dispatch = useDispatch()

  const shareWithClinic = useEventCallback(
    async (bundle: LabTestBundleWithoutPractitioner) => {
      setClinicShareIsLoading(true)
      await dispatch(
        Actions.shareBundleWithClinic(
          bundle.id,
          async (
            responseData: LabTestBundleWithoutPractitionerAndWithLabTestIds
          ) => {
            dispatch(replaceBundle(responseData, bundle.lab_tests))
            setClinicShareIsLoading(false)
            dispatch(
              showMessage({
                message: "Bundle successfully shared with Clinic.",
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              })
            )
          }
        )
      )
    }
  )

  const unshareWithClinic = useEventCallback(
    async (bundle: LabTestBundleWithoutPractitioner) => {
      if (bundle.shared_by !== practitioner.id) {
        dispatch(
          showMessage({
            message:
              "You cannot remove a bundle from the Clinic if you did not create it.",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )
        return
      }

      setClinicShareIsLoading(true)
      await dispatch(
        Actions.unshareBundleWithClinic(
          bundle.id,
          async (
            responseData: LabTestBundleWithoutPractitionerAndWithLabTestIds
          ) => {
            dispatch(replaceBundle(responseData, bundle.lab_tests))
            setClinicShareIsLoading(false)
            dispatch(
              showMessage({
                message: "Bundle successfully removed from Clinic.",
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              })
            )
          }
        )
      )
    }
  )

  return {
    shareWithClinic,
    unshareWithClinic,
    clinicShareIsLoading,
  }
}
