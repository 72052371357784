export const IMAGE_MIME = "image/*,image/heic,image/heif"
export const IMAGE_OR_PDF_MIME = `application/pdf,${IMAGE_MIME}`

export const PRACTITIONER_LICENSE_ALLOWED_FILE_TYPES =
  ".jpg,.jpeg,.png,.heic,.heif,.pdf,.png,.doc,.docx,.tiff"

/**
 * Validate MIME type of the file being uploaded. Note that this provides no
 * security - it needs to be done on the backend as well to do anything.
 *
 * Attempts to replicate the behavior of the "accept" attribute on the "file"
 * html input.
 *
 * Taken from dropzone:
 * https://gitlab.com/meno/dropzone/-/blob/master/src/dropzone.js#L2574
 *
 * @param file - the file to validate
 * @param accept - "accept" string passed to input.
 */
export function validateFileMime(file: File, accept?: string) {
  if (!accept) return true

  const acceptedFiles = accept.split(",")

  const mimeType = file.type
  const baseMimeType = mimeType.replace(/\/.*$/, "")

  for (let validType of acceptedFiles) {
    validType = validType.trim()
    if (validType.startsWith(".")) {
      if (
        file.name
          .toLowerCase()
          .includes(
            validType.toLowerCase(),
            file.name.length - validType.length
          )
      ) {
        return true
      }
    } else if (validType.endsWith("/*")) {
      // This is something like a image/* mime type
      if (baseMimeType === validType.replace(/\/.*$/, "")) {
        return true
      }
    } else {
      if (mimeType === validType) {
        return true
      }
    }
  }

  return false
}

/**
 * Format bytes as human-readable text.
 * https://stackoverflow.com/a/14919494
 *
 * @param bytes - Number of bytes.
 * @param si - True to use metric (SI) units, aka powers of 1000. False to use
 *             binary (IEC), aka powers of 1024.
 * @param dp - Number of decimal places to display.
 *
 * @returns Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}

export function humanFileType(file: File | undefined) {
  const parts = file?.name.split(".")
  if (!parts) return ""
  return parts[parts.length - 1].toUpperCase()
}
