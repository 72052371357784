import { useMemo } from "react"

import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGroupingIdentifier } from "types/biomarker_grouping"

export default function useExistingBiomarkers(
  requested_biomarkers_ids?: string[],
  requested_biomarker_groupings_ids?: string[]
): (BiomarkerIdentifier | BiomarkerGroupingIdentifier)[] {
  // If the value is not yet loaded, then we retrieve it independently
  const { data: existingBiomarkerIdentifiers, isLoading: isLoadingBiomarkers } =
    useCollectionSWR<ResourceCollection<BiomarkerIdentifier>>(
      requested_biomarkers_ids?.length ? "/biomarkers/" : null,
      {
        params: {
          "filter[id.in]": requested_biomarkers_ids?.join(),
          "page[limit]": requested_biomarkers_ids
            ? `${requested_biomarkers_ids.length}`
            : "0",
        },
      }
    )

  const {
    data: existingBiomarkerGroupingIdentifiers,
    isLoading: isLoadingBiomarkerGroupings,
  } = useCollectionSWR<ResourceCollection<BiomarkerGroupingIdentifier>>(
    requested_biomarker_groupings_ids?.length ? "/biomarker_groupings/" : null,
    {
      params: {
        "filter[id.in]": requested_biomarker_groupings_ids?.join(),
        "page[limit]": requested_biomarker_groupings_ids
          ? `${requested_biomarker_groupings_ids.length}`
          : "0",
      },
    }
  )

  return useMemo(() => {
    if (isLoadingBiomarkerGroupings || isLoadingBiomarkers) {
      return []
    }
    return [
      ...(existingBiomarkerGroupingIdentifiers || []),
      ...(existingBiomarkerIdentifiers || []),
    ]
  }, [
    existingBiomarkerIdentifiers,
    existingBiomarkerGroupingIdentifiers,
    isLoadingBiomarkerGroupings,
    isLoadingBiomarkers,
  ])
}
