import clsx from "clsx"

import { Theme, useMediaQuery } from "@material-ui/core"

import { ReactComponent as RightCarrotIcon } from "app/assets/icons/labshops/carrot-right.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import NumericBiomarkerGraphic from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import {
  getBiomarkerStatus,
  parseStringValue,
} from "app/patient-portal/blood-lab-dashboard/utils"
import {
  PatientPortalClinic,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { Clinic } from "types/clinic"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { Practitioner } from "types/practitioner"

import useDiscreteResultDetailModal from "../modals/use-discrete-result-detail-modal"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    background: "white",
    display: "flex",
    gap: 30,
    borderRadius: 10,
    border: `1px solid ${colors.blueGray[200]}`,
    boxShadow: shadows.sm,
    padding: 14,
    transition: "all 0.2s ease-in-out",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
      border: "1px solid #60A5FA",
      boxShadow:
        "0px 0.99042px 1.98083px 0px rgba(0, 0, 0, 0.06), 0px 0.99042px 2.97125px 0px rgba(0, 0, 0, 0.10)",
      transform: "scale(1.03, 1.03)",
      transition: "all 0.2s ease-in-out",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  biomarkerValue: {
    fontSize: 22,
    fontWeight: 600,
    color: navy,
  },
  biomarkerUnits: {
    fontSize: 13,
    color: colors.blueGray[400],
    fontWeight: 600,
  },
  detailsText: {
    width: 233,
    minWidth: 233,
    display: "block",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  detailsRangeBar: {
    width: "100%",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: 4,
    width: 285,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  redValueColor: {
    color: colors.red[900],
  },
  yellowValueColor: {
    color: colors.yellow[900],
  },
  greenValueColor: {
    color: colors.emerald[900],
  },
  redStatusColor: {
    color: colors.red[700],
  },
  yellowStatusColor: {
    color: colors.yellow[700],
  },
  greenStatusColor: {
    color: colors.emerald[700],
  },
}))

interface Props {
  discreteResult: DiscreteResult
  useOptimalRanges?: boolean
  getMatchingOptimalRangeByBiomarkerId: (
    biomarkerId: string
  ) => LabCompanyBiomarkerRange | undefined
  practitioner?: PatientPortalPractitioner | Practitioner
  clinic?: PatientPortalClinic | Clinic
}

const PatientPortalBloodLabDashboardOutOfRangeCard = ({
  discreteResult,
  useOptimalRanges,
  getMatchingOptimalRangeByBiomarkerId,
  practitioner,
  clinic,
}: Props) => {
  const classes = useStyles()

  const optimalRange = getMatchingOptimalRangeByBiomarkerId(
    discreteResult.relationships.biomarker.data?.id
  )

  const biomarker = useCachedResource<Biomarker>(
    discreteResult.relationships.biomarker.data
  )

  const biomarkerStatus = getBiomarkerStatus(
    discreteResult,
    optimalRange,
    useOptimalRanges
  )

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const discreteResultDetailModal = useDiscreteResultDetailModal()

  const valueStyle = {
    [BiomarkerStatus.HIGH]: classes.redValueColor,
    [BiomarkerStatus.LOW]: classes.redValueColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.NORMAL]: classes.greenValueColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenValueColor,
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: classes.redStatusColor,
    [BiomarkerStatus.LOW]: classes.redStatusColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.NORMAL]: classes.greenStatusColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenStatusColor,
  }

  if (!biomarker) {
    return null
  }

  return (
    <div
      className={classes.container}
      onClick={() =>
        discreteResultDetailModal.show({
          discreteResult,
          optimalRange,
          biomarkerStatus,
          practitionerId: practitioner?.id,
          clinicName: clinic?.attributes.name || "Practitioner's Clinic",
          useOptimalRanges,
          onClose: () => discreteResultDetailModal.hide(),
        })
      }
    >
      <div className={classes.detailsText}>
        <BodyText size="md" weight="bold">
          {biomarker?.attributes.short_name}
        </BodyText>
        <DesignSystemButton
          color="text"
          loading={false}
          variant="text"
          endIcon={
            <RightCarrotIcon
              fill={primaryColor}
              width={8}
              height={13}
              viewBox="0 0 8 15"
            />
          }
        >
          Details
        </DesignSystemButton>
      </div>

      <div className={classes.statusContainer}>
        <BloodLabDashboardsBiomarkerStatusIndicator
          biomarkerStatus={biomarkerStatus}
        />{" "}
        <div>
          <span
            className={clsx(
              classes.biomarkerValue,
              biomarkerStatus ? valueStyle[biomarkerStatus] : ""
            )}
          >
            {discreteResult.attributes.value}
          </span>
          <span
            className={clsx(
              classes.biomarkerUnits,
              biomarkerStatus ? statusStyle[biomarkerStatus] : ""
            )}
          >
            {discreteResult.attributes.units}
          </span>
        </div>
      </div>
      <div className={classes.detailsRangeBar}>
        <NumericBiomarkerGraphic
          value={parseStringValue(discreteResult.attributes.value)}
          normalMax={
            discreteResult?.attributes.normal_max
              ? discreteResult?.attributes.normal_max
              : useOptimalRanges
              ? optimalRange?.attributes.standard_range_max || ""
              : ""
          }
          normalMin={
            discreteResult?.attributes.normal_min
              ? discreteResult?.attributes.normal_min
              : useOptimalRanges
              ? optimalRange?.attributes.standard_range_min || ""
              : ""
          }
          optimalMax={
            useOptimalRanges && optimalRange?.attributes.optimal_range_max
              ? optimalRange?.attributes.optimal_range_max
              : ""
          }
          optimalMin={
            useOptimalRanges && optimalRange?.attributes.optimal_range_min
              ? optimalRange?.attributes.optimal_range_min
              : ""
          }
          singleLabel={isMobile}
          status={biomarkerStatus || BiomarkerStatus.NORMAL}
        />
      </div>
    </div>
  )
}

export default PatientPortalBloodLabDashboardOutOfRangeCard
