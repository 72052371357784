import { useEffect, useState } from "react"

import _ from "lodash"

import { Dialog, ModalHeader, ModalContent } from "app/components/modals"
import {
  trackPanelBuilderEvent,
  PANEL_BUILDER_TRACKING_EVENTS,
} from "app/services/segment"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGroupingIdentifier } from "types/biomarker_grouping"

import BiomarkerSearch from "./BiomarkerSearch/BiomarkerSearch"
import Panels from "./Panels/Panels"
import SelectionPills from "./SelectionPills/SelectionPills"
import useExistingBiomarkers from "./hooks/use-existing-biomarkers"
import usePanelBuilderModalCloseConfirmation from "./use-panel-builder-modal-close-confirmation"

const useStyles = makeAppStyles((theme) => ({
  content: {
    minHeight: "80vh",
  },
  dialog: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      margin: 0,
      width: "100vw",
    },
  },
  headerContent: {
    maxWidth: 820,
  },
}))

export const PanelBuilderModal = ({
  open,
  onClose,
  existingBundleId,
  existingBundleName,
  existingBundleRequestedBiomarkerIds,
  existingBundleRequestedBiomarkerGroupingIds,
  existingPanelLabCompanyKey,
}: {
  open: boolean
  onClose: () => void
  existingBundleId?: string
  existingBundleName?: string
  existingBundleRequestedBiomarkerIds?: string[]
  existingBundleRequestedBiomarkerGroupingIds?: string[]
  existingPanelLabCompanyKey?: string
}) => {
  // We default to searching if there is are existing biomarkers selected. Otherwise, we default to not searching.
  const [isSearching, setIsSearching] = useState<boolean>(
    () =>
      Boolean(existingBundleRequestedBiomarkerIds?.length) ||
      Boolean(existingBundleRequestedBiomarkerGroupingIds?.length)
  )
  const classes = useStyles()
  const [
    selectedBiomarkersAndBiomarkerGroupings,
    setSelectedBiomarkersAndBiomarkerGroupings,
  ] = useState<(BiomarkerIdentifier | BiomarkerGroupingIdentifier)[]>([])

  function handleSelectBiomarkerOrGrouping(
    identifier: BiomarkerIdentifier | BiomarkerGroupingIdentifier
  ) {
    if (identifier.type === "biomarker") {
      trackPanelBuilderEvent(
        PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_BIOMARKER_SELECTED,
        { biomarker_id: identifier.id }
      )
    } else {
      trackPanelBuilderEvent(
        PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_BIOMARKER_GROUPING_SELECTED,
        { biomarker_grouping_id: identifier.id }
      )
    }
    // Don't add duplicates
    if (
      selectedBiomarkersAndBiomarkerGroupings.some(
        (obj) => obj.id === identifier.id
      )
    ) {
      return
    }
    setSelectedBiomarkersAndBiomarkerGroupings([
      ...selectedBiomarkersAndBiomarkerGroupings,
      identifier,
    ])
  }

  function handleRemoveBiomarkerOrGrouping(
    identifier: BiomarkerIdentifier | BiomarkerGroupingIdentifier
  ) {
    setSelectedBiomarkersAndBiomarkerGroupings(
      selectedBiomarkersAndBiomarkerGroupings.filter(
        (b) => b.id !== identifier.id
      )
    )
  }

  const closeConfirmationModal = usePanelBuilderModalCloseConfirmation()
  function handleClose() {
    if (!Boolean(selectedBiomarkersAndBiomarkerGroupings.length)) {
      onClose()
      return
    }
    // Show warning modal
    closeConfirmationModal.show({
      onClose: () => {
        closeConfirmationModal.remove()
      },
      handleConfirm: () => {
        closeConfirmationModal.remove()
        onClose()
      },
    })
  }

  const requestedBiomarkersAndBiomarkerGroupings = useExistingBiomarkers(
    existingBundleRequestedBiomarkerIds,
    existingBundleRequestedBiomarkerGroupingIds
  )

  const isDirty = !_.isEqual(
    requestedBiomarkersAndBiomarkerGroupings,
    selectedBiomarkersAndBiomarkerGroupings
  )

  useEffect(() => {
    if (_.isEmpty(requestedBiomarkersAndBiomarkerGroupings)) return
    if (!_.isEmpty(selectedBiomarkersAndBiomarkerGroupings)) return
    // Add to selected biomarkers and biomarker groupings only if they are not already there
    setSelectedBiomarkersAndBiomarkerGroupings(
      requestedBiomarkersAndBiomarkerGroupings
    )
  }, [requestedBiomarkersAndBiomarkerGroupings])

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="lab-test-panel-builder-title"
        open={open}
        className={classes.dialog}
        classes={{ paper: classes.dialog }}
        maxWidth="lg"
      >
        <ModalHeader
          title={"Create Blood Panel"}
          onClose={handleClose}
          size={"xl"}
          subtitle={
            <div className={classes.headerContent}>
              Choose multiple biomarkers, compare custom panels from our various
              labs, and save them as a custom blood panel for easy one-click
              ordering from your Favorites.
            </div>
          }
        />
        <ModalContent>
          <div className={classes.content}>
            <BiomarkerSearch
              autoFocus={true}
              handleSelectItem={handleSelectBiomarkerOrGrouping}
              selectedIdentifiers={selectedBiomarkersAndBiomarkerGroupings}
            />
            <SelectionPills
              selectedItems={selectedBiomarkersAndBiomarkerGroupings}
              onRemove={handleRemoveBiomarkerOrGrouping}
            />
            <Panels
              existingBundleId={existingBundleId}
              existingBundleName={existingBundleName}
              isSearching={isSearching}
              onClose={onClose}
              selectedItems={selectedBiomarkersAndBiomarkerGroupings}
              setIsSearching={setIsSearching}
              existingPanelLabCompanyKey={existingPanelLabCompanyKey}
              isDirty={isDirty}
            />
          </div>
        </ModalContent>
      </Dialog>
    </>
  )
}
