import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"
import _ from "lodash"

import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from "@material-ui/core"

function RadioGroupFormsy(props) {
  const importedProps = _.pick(props, [
    "children",
    "name",
    "onBlur",
    "onChange",
    "onKeyDown",
    "variant",
  ])

  // An error message is returned only if the component is invalid
  const errorMessage = props.getErrorMessage()
  const value = props.getValue()

  function changeValue(event, value) {
    props.setValue(value)
    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <FormControl error={Boolean(errorMessage)} className={props.className}>
      <FormControl
        component="fieldset"
        required={props.required}
        error={Boolean(errorMessage)}
      >
        {props.label && <FormLabel component="legend">{props.label}</FormLabel>}
        <RadioGroup {...importedProps} value={value} onChange={changeValue} />
        {Boolean(errorMessage) && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    </FormControl>
  )
}

export default memo(withFormsy(RadioGroupFormsy))
