import copy from "copy-to-clipboard"

import { API } from "app/api"
import {
  trackBundleSharedViaLink,
  trackBundleSharedViaEmail,
  trackBundleSharedViaFacebook,
} from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"
import { practitionerBundleCreatedAction } from "app/store/actions/practitionerBundles.actions"
import { AppThunkAction, LabTestBundle, Practitioner } from "app/types"
import {
  handleApiSuccess,
  handleApiError,
  generateShareLink,
  bundleDisplayName,
} from "app/utils"

export const GET_SHARE_BUNDLE = "GET_SHARE_BUNDLE"

/**
 * Create a share bundle and copy the link to the clipboard.
 * In addition, track the event in MixPanel.
 * @param {string} bundleId The ID of the bundle to be shared.
 */
export function copyBundleLink(bundleId: string): AppThunkAction<void> {
  return async (dispatch, getState) => {
    await dispatch(
      createShareBundle(bundleId, (shareBundleId: string) => {
        if (shareBundleId) {
          trackBundleSharedViaLink(bundleId, shareBundleId)
          dispatch(
            showMessage({
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              variant: "success",
              message: "Bundle link copied!",
            })
          )
          copy(generateShareLink(shareBundleId))
        } else {
          dispatch(handleShareFailure())
        }
      })
    )
  }
}

/**
 * Generic handler for bundle sharing failures.
 * Displays a message to the user.
 */
const handleShareFailure = (): AppThunkAction<void> => {
  return (dispatch, getState) => {
    dispatch(
      showMessage({
        message: "Could not share the bundle. Please try again later.",
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    )
  }
}

/**
 * Create a shared bundle, and open a mailto link with pre-filled
 * sharing content.
 * @param {LabTestBundle} bundle The bundle to share
 * @param {Practitioner} practitioner The practitioner sharing the bundle
 * @param {Function} onSuccess The callback function when the share bundle is created successfully.
 */
export function shareBundleViaEmail(
  bundle: LabTestBundle,
  practitioner: Practitioner,
  onSuccess: Function
): AppThunkAction<void> {
  return (dispatch, getState) => {
    dispatch(
      createShareBundle(bundle.id, (shareBundleId: string) => {
        if (shareBundleId) {
          trackBundleSharedViaEmail(bundle.id, shareBundleId)
          const link = generateShareLink(shareBundleId)
          const bundleName = bundleDisplayName(bundle.name)
          const subject = `${bundleName} on Rupa Health`
          const practitionerOrClinicName = practitioner.is_clinic_staff
            ? practitioner.clinic.name
            : practitioner.titled_full_name
          const body = `Check out ${practitionerOrClinicName}'s bundle on Rupa Health: ${link}`
          const url = `mailto:?subject=${subject}&body=${body}`
          onSuccess(url)
        } else {
          dispatch(handleShareFailure())
        }
      })
    )
  }
}

/**
 * Create a shared bundle and open the Facebook share dialog.
 * @param {number} bundleId The bundle ID to shrae
 */
export function shareBundleViaFacebook(bundleId: string): AppThunkAction<void> {
  return (dispatch, getState) => {
    dispatch(
      createShareBundle(bundleId, (shareBundleId: string) => {
        if (shareBundleId) {
          // @ts-ignore
          window.FB.ui(
            {
              method: "share",
              href: generateShareLink(shareBundleId),
            },
            (response) => {
              // If there's no error_message, we can assume that it was posted successfully.
              // https://developers.facebook.com/docs/javascript/reference/FB.ui/
              if (response && !response.error_message) {
                trackBundleSharedViaFacebook(bundleId, shareBundleId)
              }
            }
          )
        } else {
          dispatch(handleShareFailure())
        }
      })
    )
  }
}

/**
 * Add a share bundle to the current practitioner's account.
 * @param {string} labTestSharebundleId The share bundle ID.
 * @param {Function} onSuccess The function to call when the share bundle is added.
 * @returns
 */
export function addShareBundle(
  labTestSharebundleId: string,
  onSuccess: Function
): AppThunkAction<void> {
  const request = API.ShareBundle.copy(labTestSharebundleId)

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Custom bundle added!"))

        dispatch(practitionerBundleCreatedAction(response.data))

        onSuccess()
      })
      .catch((error) => dispatch(handleApiError(error)))
}

/**
 * Load a share bundle.
 * @param {string} labTestShareBundleId The share bundle ID to load.
 * @param {Function} onLoad The function to call when the bundle is successfully loaded.
 */
export function loadShareBundle(
  labTestShareBundleId: string,
  onLoad: Function
): AppThunkAction<void> {
  const request = API.ShareBundle.get(labTestShareBundleId)

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        await dispatch({
          type: GET_SHARE_BUNDLE,
          payload: response.data,
        })
        onLoad()
      })
      .catch((error) => dispatch(handleApiError(error)))
}

/**
 * Create a share bundle via the API.
 * @param {string} labTestBundleId The lab test bundle to create a share bundle from.
 * @param {Function} onSuccess The function to call when the share bundle is created.
 */
function createShareBundle(
  labTestBundleId: string,
  onSuccess: Function
): AppThunkAction<void> {
  return (dispatch, getState) => {
    const existingBundleRequest =
      API.ShareBundle.getSharedBundle(labTestBundleId)
    existingBundleRequest
      .then(async (response) => {
        if (response.data && response.data.id) {
          // The share bundle already exists, just return it.
          onSuccess(response.data.id)
        } else {
          // The share bundle does not exist - call the creation method.
          const createShareBundleRequest = API.ShareBundle.create({
            lab_test_bundle: labTestBundleId,
          })
          createShareBundleRequest
            .then(async (response) => {
              onSuccess(response.data.id)
            })
            .catch((error) => dispatch(handleApiError(error)))
        }
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

/**
 * Share a bundle with a clinic.
 * @param {string} labTestBundleId The lab test bundle to share with a clinic.
 * @param {Function} onSuccess The function to call after the bundle is shared.
 */
export function shareBundleWithClinic(
  labTestBundleId: string,
  onSuccess: Function
): AppThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      const response = await API.Bundle.clinicShare(labTestBundleId)
      onSuccess(response.data)
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

/**
 * Un-share a bundle with a clinic.
 * @param {string} labTestBundleId The lab test bundle to remove sharing from the clinic.
 * @param {Function} onSuccess The function to call when the bundle has been removed from the clinic.
 */
export function unshareBundleWithClinic(
  labTestBundleId: string,
  onSuccess: Function
): AppThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      const response = await API.Bundle.clinicUnshare(labTestBundleId)
      onSuccess(response.data)
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
