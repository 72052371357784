import React, { useCallback, useMemo, useRef, useState } from "react"

import useFileUpload from "./useFileUpload"

export interface FileInputChildrenProps {
  isDragging: boolean
  handleClick(e: React.MouseEvent): void
}

export interface BaseFileInputProps {
  children:
    | React.ReactNode
    | ((props: FileInputChildrenProps) => React.ReactNode)
  onFilesAdded?: (files: File[]) => void
  className?: string
  multiple?: boolean
  disabled?: boolean
  /**
   * File type specifier. See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  allowedFileTypes?: string
  /**
   * Maximum file size in bytes.
   */
  maxFileSize?: number
}

const BaseFileInput: React.FC<BaseFileInputProps> = ({
  children,
  onFilesAdded,
  className,
  multiple = false,
  disabled = false,
  allowedFileTypes,
  maxFileSize,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [dragCounter, setDragCounter] = useState(0)

  const { handleUpload } = useFileUpload({
    onFilesAdded,
    maxFileSize,
    allowedFileTypes,
  })

  // when the input is clicked directly
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) return
      handleUpload(e.currentTarget.files)

      // in case it is removed & uploaded again
      if (inputRef.current) {
        inputRef.current.value = ""
      }
    },
    [handleUpload]
  )

  // trigger uploead when a file is dropped
  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter(0)
      handleUpload(e.dataTransfer.files)
    },
    [handleUpload]
  )

  // highlight the dropzone when a file is dragged over it
  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter((dc) => dc + 1)
  }, [])
  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter((dc) => dc - 1)
  }, [])

  // need this for dropping to work
  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleClick = useCallback((e: React.MouseEvent) => {
    if (e.currentTarget === inputRef.current) return
    inputRef.current?.click()
  }, [])

  const inner: React.ReactNode = useMemo(() => {
    if (typeof children === "function")
      return children({ isDragging: !!dragCounter, handleClick })
    return children
  }, [children, dragCounter])

  return (
    <div
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onClick={(e) => !disabled && handleClick(e)}
      className={className}
    >
      <input
        type="file"
        ref={inputRef}
        onChange={handleChange}
        className="hidden FileInput"
        accept={allowedFileTypes}
        multiple={multiple}
      />
      {inner}
    </div>
  )
}

export default BaseFileInput
