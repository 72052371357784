import { format, parseISO } from "date-fns"

export default function formatDateForHuman(date: string) {
  if (!date) return ""
  return format(parseISO(date), "MMMM d, yyyy")
}

export function safeParseDate(date: Date | string | undefined) {
  if (!date) return undefined
  if (date instanceof Date) return date

  const timestamp = Date.parse(date)
  if (isNaN(timestamp)) {
    return undefined
  }

  return new Date(date)
}
