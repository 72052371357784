import { useEffect, useState } from "react"
import * as React from "react"
import { useDispatch } from "react-redux"

import { Grow, makeStyles, Link, InputAdornment, Grid } from "@material-ui/core"

import { API } from "app/api"
import { ReactComponent as CircleCashIcon } from "app/assets/images/circle-cash-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import TextField from "app/components/forms/TextField"
import { FormFieldLabel } from "app/components/forms/fields"
import { cancelRed, colors } from "app/theme"
import { CustomFeeLineItem, Order, StripeConnectAccount } from "app/types"
import { formatDollars, handleApiError } from "app/utils"

import * as Actions from "../../store/actions"

const useStyles = makeStyles({
  customFeeContainer: {
    borderRadius: 6,
    padding: "13px 9px",
    minHeight: 48,
    alignItems: "center",
    boxShadow: "0px 2px 4px #D4DCE4",
    borderWidth: "1px 1px 3px 1px",
    borderStyle: "solid",
    borderColor: colors.green[600],
    justifyContent: "space-between",
    display: "flex",
    marginTop: 18,
  },
  editCustomFeeContainer: {
    background: "#FBFFFD",
    padding: "10px 13px",
    borderRadius: 6,
    boxShadow: "0px 2px 4px #D4DCE4",
    borderWidth: "1px 1px 3px 1px",
    borderStyle: "solid",
    borderColor: colors.green[600],
    marginTop: 18,
  },
  cashIconContainer: {
    padding: "11px 11.5px 6px",
    background: colors.green[100],
    borderRadius: 8,
  },
  customFeeInfoContainer: {
    marginLeft: 10,
  },
  customFeePriceContainer: {
    textAlign: "right",
  },
  link: {
    fontWeight: 600,
    cursor: "pointer",
  },
  inputContainer: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
      borderColor: colors.blueGray[400],
      marginTop: -8,
      width: "100%",
    },
    "& .MuiInputAdornment-root": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      background: colors.blueGray[100],
      color: colors.blueGray[400],
      padding: "19px 12px",
      borderRight: `1px solid ${colors.blueGray[400]}`,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.blueGray[400],
    },
  },
  adornmentText: {
    color: colors.blueGray[400],
    fontWeight: 600,
    fontSize: 15,
  },
  saveButtonContainer: {
    display: "flex",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
  },
  saveButton: {
    padding: "4px 8px;",
    borderRadius: 3,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 14,
  },
  errorText: {
    color: cancelRed,
  },
})

interface CustomFeeFormState {
  name: string
  price: number
}

interface Props {
  customFeeLineItem: CustomFeeLineItem | undefined
  stripeConnectAccount: StripeConnectAccount
  order: Order
  openWithEdit: boolean
}

const CustomFeeCard = ({
  customFeeLineItem,
  stripeConnectAccount,
  order,
  openWithEdit,
}: Props) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [showEdit, setShowEdit] = useState(openWithEdit)
  const [priceError, setPriceError] = useState("")
  const [nameError, setNameError] = useState("")
  const [customFeeVals, setCustomFeeVals] = useState({
    name: customFeeLineItem?.name,
    price: customFeeLineItem ? customFeeLineItem.price / 100 : 0,
  })

  const handleEditClick = () => {
    setShowEdit(true)
  }

  const validateName = (value: string) => {
    const rupaRegex = /^((?!rupa).)*$/gi

    if (value === "") {
      setNameError("A Custom Fee must have a name set.")
    } else if (!value.match(rupaRegex)) {
      setNameError("Fee name cannot reference Rupa.")
    } else if (nameError !== "") {
      setNameError("")
    }
  }

  const validatePrice = (value: string) => {
    if (
      parseInt(value) > stripeConnectAccount.max_price / 100 ||
      parseInt(value) < 1
    ) {
      setPriceError(
        `A Custom Fee can be between $1 to ${formatDollars(
          stripeConnectAccount.max_price / 100
        )}`
      )
    } else if (value === "") {
      setPriceError("You must set a Custom Fee.")
    } else if (priceError !== "") {
      setPriceError("")
      return
    }
  }

  useEffect(() => {
    if (showEdit) {
      validateName(customFeeVals.name ? customFeeVals.name : "")
      validatePrice(customFeeVals.price.toString())
    }
  }, [showEdit])

  // Handler function for when user clicks remove link on a Custom Fee Card
  const handleRemoveClick = async () => {
    dispatch(Actions.removeCustomFeeLineItem(order.id, customFeeLineItem?.id))
  }

  // Creates a new Custom Fee line item and refreshes the order for latest pricing & line items
  const createCustomFeeLineItem = async (jsonData) => {
    try {
      const newLineItem = await API.CustomFeeLineItem.create(
        order.id,
        null,
        jsonData
      )
      await dispatch(
        Actions.refreshCustomFeeLineItems(order.id, newLineItem.data.id, {
          name: newLineItem.data.name,
          price: newLineItem.data.price,
        })
      )
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  // Handler function for when user clicks save button when editing/adding a Custom Fee
  const handleSaveClick = async () => {
    const customFeeLineItemUpdateData = {
      ...customFeeVals,
      // Need to round to an integer since the backend expects an integer (number of cents)
      // and floats can create tiny imprecisions when multiplying by 100 (e.g. add a .0000001)
      price: Math.round(customFeeVals.price * 100),
    }

    if (customFeeLineItem) {
      await dispatch(
        Actions.refreshCustomFeeLineItems(
          order.id,
          customFeeLineItem.id,
          customFeeLineItemUpdateData
        )
      )
    } else {
      await createCustomFeeLineItem({
        custom_fee_name: customFeeVals.name,
        custom_fee_price: customFeeVals.price * 100,
      })
    }

    setShowEdit(false)
  }

  // Handler function for when user types in the custom fee name or price text fields.
  // Also validates the inputs as user types.
  const handleChange =
    (prop: keyof CustomFeeFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomFeeVals({ ...customFeeVals, [prop]: event.target.value })

      if (prop === "name") {
        validateName(event.target.value)
      }

      if (prop === "price") {
        validatePrice(event.target.value)
      }
    }

  return (
    <Grow in={true} style={{ transformOrigin: "50% 0 0" }} timeout={250}>
      {showEdit ? (
        <div className={classes.editCustomFeeContainer}>
          <Grid container>
            <Grid item xs={12}>
              <FormFieldLabel label="Custom Fee Name" required={true} />
              <TextField
                autoFocus
                className={classes.inputContainer}
                placeholder="Custom Fee Name"
                name="custom-fee-name"
                type="text"
                margin="dense"
                variant="outlined"
                fullWidth
                value={customFeeVals.name}
                onChange={handleChange("name")}
                startadornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              {nameError !== "" && (
                <BodyText className={classes.errorText}>{nameError}</BodyText>
              )}
            </Grid>
            <Grid item xs={8} className="mt-2">
              <FormFieldLabel label="Custom Fee Price" required={true} />
              <TextField
                autoFocus
                value={customFeeVals.price}
                onChange={handleChange("price")}
                placeholder="Custom Fee Price"
                name="price"
                type="number"
                margin="dense"
                variant="outlined"
                fullWidth
                className={classes.inputContainer}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.adornmentText}>$</div>
                    </InputAdornment>
                  ),
                }}
              />
              {priceError !== "" && (
                <BodyText className={classes.errorText}>{priceError}</BodyText>
              )}
            </Grid>
            <Grid item xs={4} className={classes.saveButtonContainer}>
              <DesignSystemButton
                color="primary"
                size="small"
                className={classes.saveButton}
                onClick={handleSaveClick}
                disabled={priceError !== "" || nameError !== ""}
              >
                Save
              </DesignSystemButton>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.customFeeContainer}>
          <div
            className={"flex mr-2 items-top"}
            style={{ alignItems: "center" }}
          >
            <div className={classes.cashIconContainer}>
              <CircleCashIcon width={21} />
            </div>
            <div className={classes.customFeeInfoContainer}>
              <BodyText weight="semibold">{customFeeVals?.name}</BodyText>
              <Link onClick={handleEditClick} className={classes.link}>
                Edit
              </Link>
            </div>
          </div>
          <div className={classes.customFeePriceContainer}>
            <BodyText weight="bold">
              {formatDollars(customFeeVals?.price)}
            </BodyText>
            <Link onClick={handleRemoveClick} className={classes.link}>
              Remove
            </Link>
          </div>
        </div>
      )}
    </Grow>
  )
}

export default CustomFeeCard
