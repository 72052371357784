import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Divider, makeStyles } from "@material-ui/core"

import { API } from "app/api"
import AddBlueIcon from "app/assets/icons/add-blue.svg"
import ArrowOutIcon from "app/assets/icons/arrow-top-right.svg"
import GreenDollarCircleIcon from "app/assets/icons/green-dollar-circle.svg"
import TealMessageBubbleIcon from "app/assets/icons/teal-message-bubble.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import TextField from "app/components/forms/TextField"
import {
  Dialog,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "app/components/modals"
import { CUSTOM_FEE_TYPE } from "app/constants"
import {
  StorefrontCustomFeeType,
  StorefrontProductType,
  useResourceByIdSelector,
  useResourceSelector,
} from "app/hooks/use-resource"
import useAppSelector from "app/hooks/useAppSelector"
import CustomFeesOnboardingModal from "app/main/settings/CustomFees/CustomFeesOnboardingModal"
import SignInToStripeLink from "app/main/settings/CustomFees/SignInToStripeLink"
import {
  CREATE_CUSTOM_FEE,
  CREATE_STRIPE_CONNECT_ACCOUNT,
  UPDATE_CUSTOM_FEE,
  getCustomFees,
  getStripeConnectAccounts,
} from "app/store/actions"
import { colors } from "app/theme"
import { CustomFee, StripeConnectAccount } from "app/types"

import { MAX_CUSTOM_FEE_PRICE_LAB_SHOPS } from "../constants"
import { calculateTotalTestPricing } from "../utils"
import CustomFeeForm from "./CustomFeeForm"

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    "flex-direction": "column",
    gap: 16,
    alignItems: "start",
  },
  card: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    padding: 15,
    gap: 16,
    background: "#fff",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderRadius: 4,
  },
  cardHeader: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    gap: 10,
  },
  customFeeIcon: {
    width: 43,
    height: 43,
    borderRadius: 8,
    background: colors.green[100],
    padding: 10,
  },
  customNoteIcon: {
    width: 43,
    height: 43,
    borderRadius: 8,
    background: colors.lightBlue[100],
    padding: 10,
  },
  cardHeaderText: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-start",
    gap: 3,
  },
  customFeeEditBtn: {
    paddingLeft: 0,
    justifyContent: "start",
  },
  customFeeNameContainer: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-start",
    gap: 10,
  },
  setupBtnContainer: {
    width: "fit-content",
  },
}))

interface Props {
  open: boolean
  existingCustomFees: StorefrontCustomFeeType[]
  onCloseModal: () => void
  product: StorefrontProductType | null | undefined
  updateProduct: (productAttributes) => Promise<void>
  hidePhysServicesPricing: boolean
}

const CustomizeProductModal = ({
  open,
  existingCustomFees,
  onCloseModal,
  product,
  updateProduct,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  const labTest = useResourceByIdSelector(
    "lab_test",
    product?.relationships.lab_test.data?.id
  )
  const bundle = useResourceByIdSelector(
    "lab_test_bundle",
    product?.relationships.bundle.data?.id
  )
  const bundleLabTests = useResourceSelector((entities) => {
    if (!bundle?.relationships.lab_tests) {
      return []
    }

    return bundle?.relationships?.lab_tests.data.map((ref) => {
      return entities?.lab_test?.[ref.id]
    })
  })
  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee.data?.id
  )

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  let totalTestPrice = 0
  if (bundle) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing,
      bundleLabTests
    )
  } else if (labTest) {
    totalTestPrice = calculateTotalTestPricing(hidePhysServicesPricing, [
      labTest,
    ])
  }

  const [isProductSaving, setIsProductSaving] = useState(false)
  const [isCustomFeeLoading, setIsCustomFeeLoading] = useState(false)
  const [usingTempCustomFee, setUsingTempCustomFee] = useState(false)
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStripeConnectAccounts())
  }, [])

  const stripeConnectAccount = useAppSelector((state) => {
    const customFees = state.customFees
    if (
      Array.isArray(customFees.stripeConnectAccounts) &&
      customFees.stripeConnectAccounts.length
    ) {
      return customFees.stripeConnectAccounts[0]
    }
  })

  useEffect(() => {
    if (stripeConnectAccount) {
      dispatch(getCustomFees(stripeConnectAccount.id))
    }
  }, [stripeConnectAccount])

  const createOrUpdateCustomFee = async (
    account: StripeConnectAccount,
    customFee: CustomFee | undefined,
    { price, name }: Omit<Omit<CustomFee, "id">, "application_fee_percentage">
  ) => {
    if (!customFee) {
      const response = await API.CustomFee.post(
        account.id,
        name,
        price,
        CUSTOM_FEE_TYPE.WHOLE_ORDER
      )
      dispatch({
        type: CREATE_CUSTOM_FEE,
        payload: response.data,
      })
    } else {
      const response = await API.CustomFee.put(customFee.id, name, price)

      dispatch({
        type: UPDATE_CUSTOM_FEE,
        payload: response.data,
      })
    }
  }

  const [customFeeNameError, setCustomFeeNameError] = useState("")
  const [customFeePriceError, setCustomFeePriceError] = useState("")

  const [productAttributes, setProductAttributes] = useState({
    customFeeId: customFee ? customFee.id : null,
    customFeeName: customFee ? customFee.attributes.name : "",
    customFeePrice: customFee ? customFee.attributes.price : 0,
    customNotes: product?.attributes.notes,
    removeCustomFee: false,
    removeCustomFeeId: "",
  })

  useEffect(() => {
    setProductAttributes({
      customFeeId: customFee ? customFee.id : null,
      customFeeName: customFee ? customFee.attributes.name : "",
      customFeePrice: customFee ? customFee.attributes.price : 0,
      customNotes: product?.attributes.notes,
      removeCustomFee: false,
      removeCustomFeeId: "",
    })
    setIsCustomFeeLoading(false)

    if (customFee) {
      setUsingTempCustomFee(false)
    }
  }, [customFee, product])

  const handleCustomFeeUpdate = (name: string, price: number) => {
    setProductAttributes({
      ...productAttributes,
      customFeeName: name,
      customFeePrice: price,
    })

    validateName(name)
    validatePrice(price)
  }

  const handleRemoveCustomFee = () => {
    if (productAttributes.customFeeId) {
      setProductAttributes({
        ...productAttributes,
        removeCustomFee: true,
        removeCustomFeeId: productAttributes.customFeeId,
        customFeeId: null,
        customFeeName: "",
        customFeePrice: 0,
      })
    } else {
      setProductAttributes({
        ...productAttributes,
        customFeeName: "",
        customFeePrice: 0,
      })
    }

    setUsingTempCustomFee(false)
  }

  const handleAddCustomFee = () => {
    if (existingCustomFees.length) {
      const latestCustomFee = existingCustomFees[existingCustomFees.length - 1]
      setProductAttributes({
        ...productAttributes,
        customFeeName: latestCustomFee.attributes.name,
        customFeePrice: latestCustomFee.attributes.price,
      })
    } else {
      setProductAttributes({
        ...productAttributes,
        customFeeName: "My Custom Fee",
        customFeePrice: 2000,
      })
    }

    setUsingTempCustomFee(true)
  }

  const handleProductUpdate = async () => {
    setIsProductSaving(true)
    await updateProduct(productAttributes)
    setIsProductSaving(false)
  }

  const validateName = (value: string) => {
    const rupaRegex = /^((?!rupa).)*$/gi

    if (value === "") {
      setCustomFeeNameError("A Custom Fee must have a name set.")
    } else if (!value.match(rupaRegex)) {
      setCustomFeeNameError("Fee name cannot reference Rupa.")
    } else if (customFeeNameError !== "") {
      setCustomFeeNameError("")
    }
  }

  const validatePrice = (value: number | null) => {
    if (!value || value <= 0) {
      setCustomFeePriceError("A Custom Fee must be greater than zero.")
    } else if (value && value > MAX_CUSTOM_FEE_PRICE_LAB_SHOPS) {
      setCustomFeePriceError(
        `A Custom Fee cannot be more than $${
          MAX_CUSTOM_FEE_PRICE_LAB_SHOPS / 100
        }.`
      )
    } else if (customFeePriceError !== "") {
      setCustomFeePriceError("")
    }
  }

  const createStripeConnectAccount =
    async (): Promise<StripeConnectAccount> => {
      const response = await API.StripeConnectAccount.post()

      dispatch({
        type: CREATE_STRIPE_CONNECT_ACCOUNT,
        payload: response.data,
      })

      return response.data
    }

  const getDashboardLinkWithLabshopReturn = async (
    account: StripeConnectAccount
  ): Promise<string> => {
    const response = await API.StripeConnectAccount.dashboardLink(
      account.id,
      true
    )
    return response.data.link
  }

  const getCustomFeeAction = () => {
    if (productAttributes.customFeeId || usingTempCustomFee) {
      return (
        <CustomFeeForm
          customFeeName={productAttributes.customFeeName}
          customFeePrice={productAttributes.customFeePrice}
          priceWithoutCustomFee={totalTestPrice}
          updateCustomFeeAttributes={handleCustomFeeUpdate}
          removeCustomFee={handleRemoveCustomFee}
          loading={isCustomFeeLoading}
          customFeeNameError={customFeeNameError}
          customFeePriceError={customFeePriceError}
          hidePhysServicesPricing={hidePhysServicesPricing}
        />
      )
    }

    if (
      !stripeConnectAccount ||
      practitioner?.clinic?.custom_fees_disabled_reason
    ) {
      return (
        <div>
          <Tooltip
            title={practitioner?.clinic?.custom_fees_disabled_reason ?? ""}
            disableHoverListener={
              !Boolean(practitioner?.clinic?.custom_fees_disabled_reason)
            }
            disableFocusListener={
              !Boolean(practitioner?.clinic?.custom_fees_disabled_reason)
            }
            disableTouchListener={
              !Boolean(practitioner?.clinic?.custom_fees_disabled_reason)
            }
            placement="bottom"
            arrow
          >
            <div className={classes.setupBtnContainer}>
              <DesignSystemButton
                color="text"
                onClick={() => setOnboardingModalOpen(true)}
                endIcon={
                  !Boolean(
                    practitioner?.clinic?.custom_fees_disabled_reason
                  ) && (
                    <img
                      src={ArrowOutIcon}
                      alt="arrow-top-right"
                      style={{ position: "relative", top: "-2px" }}
                    />
                  )
                }
                disabled={Boolean(
                  practitioner?.clinic?.custom_fees_disabled_reason
                )}
              >
                Setup Custom Fees
              </DesignSystemButton>
            </div>
          </Tooltip>
        </div>
      )
    }

    if (stripeConnectAccount.status === "Active") {
      return (
        <div>
          <DesignSystemButton
            color="text"
            onClick={handleAddCustomFee}
            startIcon={
              <img
                src={AddBlueIcon}
                alt="add blue icon"
                style={{ position: "relative", top: "-2px" }}
              />
            }
            loading={isCustomFeeLoading}
          >
            Add Custom Fee
          </DesignSystemButton>
        </div>
      )
    }

    return (
      <SignInToStripeLink
        getDashboardLink={() =>
          getDashboardLinkWithLabshopReturn(stripeConnectAccount)
        }
        text="Finish activating your Stripe account to enable Custom Fees"
      />
    )
  }

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <ModalHeader
          title={`Customize ${labTest ? "Test" : "Bundle"}`}
          onClose={onCloseModal}
          ariaLabelId={"remove-coupon-modal-title"}
        />
        <ModalContent>
          <div className={classes.contentContainer}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>
                <div className={classes.customFeeIcon}>
                  <img
                    src={GreenDollarCircleIcon}
                    alt="green dollar icon"
                    width="100%"
                  />
                </div>
                <div className={classes.cardHeaderText}>
                  <BodyText weight="semibold">Custom Fee</BodyText>
                  <BodyText
                    size="sm"
                    weight="regular"
                  >{`Optionally add a fee for ${
                    labTest ? labTest.attributes.name : bundle?.attributes.name
                  }`}</BodyText>
                </div>
              </div>
              <Divider />
              {getCustomFeeAction()}
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>
                <div className={classes.customNoteIcon}>
                  <img
                    src={TealMessageBubbleIcon}
                    alt="teal message icon"
                    width="100%"
                  />
                </div>
                <div className={classes.cardHeaderText}>
                  <BodyText weight="semibold">Custom Note</BodyText>
                  <BodyText size="sm" weight="regular">
                    Optionally add a note underneath the description of the test
                    for your client.
                  </BodyText>
                </div>
              </div>
              <Divider />
              {labTest && (
                <BodyText>
                  {labTest.attributes.patient_description
                    ? labTest.attributes.patient_description
                    : labTest.attributes.details}
                </BodyText>
              )}
              <TextField
                value={productAttributes.customNotes}
                fullWidth
                multiline
                onChange={(e) =>
                  setProductAttributes({
                    ...productAttributes,
                    customNotes: e.target.value,
                  })
                }
                placeholder="Custom note."
                variant="outlined"
                className="rounded fs-exclude bg-white"
                rows={5}
              />
            </div>
          </div>
        </ModalContent>
        <ModalActions>
          <DesignSystemButton
            onClick={handleProductUpdate}
            color="primary"
            loading={isProductSaving}
            disabled={customFeeNameError !== "" || customFeePriceError !== ""}
          >
            Save
          </DesignSystemButton>
        </ModalActions>
      </Dialog>
      <CustomFeesOnboardingModal
        open={onboardingModalOpen}
        onClose={() => setOnboardingModalOpen(false)}
        customFee={undefined}
        stripeConnectAccount={stripeConnectAccount}
        createStripeConnectAccount={createStripeConnectAccount}
        createOrUpdateCustomFee={createOrUpdateCustomFee}
        getDashboardLink={getDashboardLinkWithLabshopReturn}
        isClinicLevelSetup={false}
      />
    </>
  )
}

export default CustomizeProductModal
