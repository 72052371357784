import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"

import clsx from "clsx"

import { Snackbar, IconButton, Icon, SnackbarContent } from "@material-ui/core"
import { green, amber, blue } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"

import * as Actions from "app/store/actions"

const useStyles = makeStyles((theme) => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: "#FFFFFF",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: blue[600],
    color: "#FFFFFF",
  },
  warning: {
    backgroundColor: amber[600],
    color: "#FFFFFF",
  },
}))

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
}

function FuseMessage(props) {
  const dispatch = useDispatch()
  const state = useSelector(({ fuse }) => fuse.message.state)
  const options = useSelector(({ fuse }) => fuse.message.options)

  const classes = useStyles()

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={() => dispatch(Actions.hideMessage())}
      classes={{
        root: classes.root,
      }}
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div",
        },
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <Icon className="mr-2" color="inherit">
                {variantIcon[options.variant]}
              </Icon>
            )}
            {/* Noticed occasionally an object was passed in for message but not sure the source. This protects from an unrecoverable error. */}
            {typeof options.message === "string" ? options.message : ""}
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(Actions.hideMessage())}
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default memo(FuseMessage)
