import SpecimenIssueDescription from "app/components/SpecimenIssues/SpecimenIssueDescription"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { KIT_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { KitStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const TitleAddonResultsTooltip = ({
  orderedTests,
}: Pick<StatusConfigurationProps, "orderedTests">) => {
  if (!orderedTests) {
    return null
  }

  const awaitingResults = orderedTests.filter(
    (orderedTest) => !orderedTest.attributes.date_results_received_from_lab
  )

  if (!awaitingResults) {
    return null
  }

  return (
    <InfoTextTooltip>
      <p className="font-semibold text-left">Tests Awaiting Results:</p>

      {awaitingResults.map((orderedTest) => (
        <p
          key={orderedTest.id}
          className="font-semibold text-sm text-left mt-2"
        >
          {orderedTest.attributes.name}

          <br />

          {orderedTest.attributes.date_results_expected_from_lab && (
            <span className="text-slate-500 text-xs">
              {` (estimated ${formatDateForHuman(
                orderedTest.attributes.date_results_expected_from_lab
              )})`}
            </span>
          )}
        </p>
      ))}
    </InfoTextTooltip>
  )
}

const Title = ({
  kit,
  orderedTests,
  orderedResult,
}: StatusConfigurationProps) => {
  const { total_results_expected, total_results_received } = kit.attributes

  if (total_results_received >= total_results_expected) {
    if (orderedResult?.attributes.date_result_reviewed) {
      return <>Results Reviewed</>
    }

    return <>Results In</>
  }

  return (
    <>
      {`Results In (${total_results_received} of ${total_results_expected})`}{" "}
      <TitleAddonResultsTooltip orderedTests={orderedTests} />
    </>
  )
}

const SubTitle = ({
  kit,
  patient,
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: StatusConfigurationProps) => {
  if (kit.attributes.date_results_sent_to_patient) {
    return (
      <div>
        <span>
          {`Sent to ${patient?.attributes.full_name} on ${formatDateForHuman(
            kit.attributes.date_results_sent_to_patient
          )}`}
        </span>
        {specimenIssue && (
          <SpecimenIssueDescription
            description={specimenIssue.description}
            showMore={specimenIssueShowMore}
            setShowMore={setSpecimenIssueShowMore}
          />
        )}
      </div>
    )
  }
  return null
}

const LastUpdatedAt = ({ kit }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    kit.attributes.date_results_received_from_lab ||
    kit.attributes.last_updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const progressPercentage = ({ kit }: StatusConfigurationProps) =>
  kit.attributes.status === KIT_STATUS.COMPLETE
    ? PROGRESS_PERCENTAGES.COMPLETE
    : PROGRESS_PERCENTAGES.RESULTS_IN

const resultsInStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({ kitStatus }: KitStatuses) =>
      kitStatus === KIT_STATUS.RESULTS_IN || kitStatus === KIT_STATUS.COMPLETE,
    lastUpdatedAt: LastUpdatedAt,
    progressPercentage: progressPercentage,
    title: Title,
    subTitle: SubTitle,
  }

export default resultsInStatusConfiguration
