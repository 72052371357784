import { useMemo } from "react"

import _ from "lodash"

import { makeStyles, useMediaQuery, Grid, styled } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import AddWhiteIcon from "app/assets/icons/add-white.svg"
import AddIcon from "app/assets/icons/add.svg"
import BundleIcon from "app/assets/icons/bundle-grey.svg"
import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import Panel from "app/components/Panel"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import { BUNDLE_TYPES } from "app/constants"
import { getUnavailableReasonForBundle as getUnavailableReasonForBundleV2 } from "app/dataServices/orderingRights"
import LabTestCard from "app/main/checkout/LabTestCard"
import ShareBundleButton from "app/main/settings/ShareBundleButton"
import { navy, colors } from "app/theme"
import { labTestLocation } from "app/types"

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
})

const useStyles = makeStyles(styles)

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "stretch",
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    flexFlow: "row",
  },
}))

const TestsContainer = styled("div")(({ isMobile }) => ({
  flex: 1,
  position: "relative",
  width: isMobile ? "auto" : "700px",
}))

const StyledContent = styled(MuiDialogContent)(({ isMobile }) => ({
  paddingBottom: isMobile ? 12 : 22.5,
  paddingTop: isMobile ? 12 : 22.5,
  backgroundColor: colors.coolGray[100],
}))

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: isMobile ? "wrap" : "nowrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
}))

const BundleActionsContainer = styled(MuiDialogActions)({
  margin: 0,
  padding: "8px 20px",
})

const TitleButton = styled(({ isXsMobile, ...other }) => <Button {...other} />)(
  {
    width: ({ isXsMobile }) => {
      return isXsMobile ? "100%" : "auto"
    },
    padding: "8px 12px",
    "& .MuiButton-label": {
      marginTop: 3,
      marginBottom: 2,
    },
    "& .MuiButton-startIcon": {
      marginTop: -1,
      marginBottom: 1,
    },
  }
)

const StyledShareBundleButton = styled("div")(({ isMobile, isXsMobile }) => ({
  marginTop: isMobile ? 6 : 0,
  marginRight: isXsMobile ? 0 : 6,
  whiteSpace: "nowrap",
}))

const TitleButtonAndCloseButtonContainer = styled("div")({
  display: "flex",
  flexGrow: "1",
})

const TitleAndInfoContainer = styled("div")(({ isMobile, isXsMobile }) => ({
  width: isXsMobile ? "100%" : "auto",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
}))

const CloseButton = styled(IconButton)(({ isXsMobile }) => ({
  color: navy,
  position: isXsMobile ? "absolute" : "relative",
  marginTop: isXsMobile ? "0" : "-6px",
  top: 1,
  right: isXsMobile ? 24 : 0,
  marginRight: -12,
  fontSize: 24,
}))

const IconImage = styled("img")({
  width: 17.42,
  marginRight: 3,
  marginTop: 3,
})

const BundleInfoModal = ({
  open,
  practitioner,
  onClose,
  handleToggleFavorite,
  openModalWithLabTest,
  handleAddBundle,
  handleAdd,
  handleRemove,
  openConfirmationModal,
  openEditBundleModal,
  selectedLabTests,
  bundle,
  signingPractitioner = practitioner,
  showTitleButton = true,
  isSharedBundle = false,
  handleAddShareBundle,
}) => {
  const classes = useStyles(styles)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const unavailableReason = useMemo(
    () => getUnavailableReasonForBundleV2(bundle),
    [bundle]
  )

  // Sort bundle lab tests by lab company name and then by lab test name(within a lab company)
  const bundleLabTestsSorted = useMemo(
    () => _.orderBy(bundle.lab_tests, ["lab_company.short_name", "name"]),
    [bundle]
  )

  const total = useMemo(
    () =>
      _.sumBy(bundle.lab_tests, function (lab_test) {
        return parseFloat(lab_test.rupa_price)
      }),
    [bundle]
  )

  const isInCart = useMemo(
    () => bundle.lab_tests.every((labTest) => selectedLabTests[labTest.id]),
    [bundle, selectedLabTests]
  )

  function onAddBundle() {
    handleAddBundle(bundle)
    onClose()
  }

  const bundleActionButtons = isSharedBundle ? (
    <DesignSystemButton
      color="primary"
      startIcon={AddWhiteIcon}
      onClick={handleAddShareBundle}
    >
      {bundle.bundle_type === BUNDLE_TYPES.PANEL
        ? "Add to my Panels"
        : "Add to my Bundles"}
    </DesignSystemButton>
  ) : (
    <>
      <DesignSystemButton
        color="destructiveSecondary"
        onClick={openConfirmationModal}
      >
        Delete Bundle
      </DesignSystemButton>
      <DesignSystemButton color="secondary" onClick={openEditBundleModal}>
        Edit Bundle
      </DesignSystemButton>
    </>
  )
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="lab-test-bundle-title"
        open={open}
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        maxWidth="md"
      >
        <TitleSection
          title={bundle.name}
          practitioner={practitioner}
          bundle={bundle}
          unavailableReason={unavailableReason}
          shouldShowTitleButton={!isInCart && showTitleButton}
          isSharedBundle={isSharedBundle}
          onClose={onClose}
          onClickTitleButton={onAddBundle}
          isMobile={isMobile}
        />
        <StyledContent isMobile={isMobile}>
          <Container>
            <TestsContainer isMobile={isMobile}>
              <DescriptionSection description={bundle.description} />
              <Panel className="mb-8">
                <div className="flex items-end pt-4 pb-3 mx-7 rounded-t-lg">
                  <IconImage src={BundleIcon} alt="bundle-icon" />
                  <BodyText weight="semibold" className="ml-2">
                    ${total.toFixed(2)} Bundle
                  </BodyText>
                </div>

                <Grid container className="py-3 px-6 mt-0 " spacing={2}>
                  {bundleLabTestsSorted.map((lab_test) => {
                    let isFavorite = false
                    if (practitioner.favorite_lab_test_id_set) {
                      isFavorite = practitioner.favorite_lab_test_id_set.has(
                        lab_test.id
                      )
                    }

                    return (
                      <Grid item xs={12} sm={6} lg={4} key={lab_test.id}>
                        <LabTestCard
                          modalPage={labTestLocation.BUNDLES}
                          labTest={lab_test}
                          practitioner={practitioner}
                          signingPractitioner={signingPractitioner}
                          selectedLabTests={selectedLabTests}
                          isFavorite={isFavorite}
                          onAdd={handleAdd}
                          onRemove={handleRemove}
                          onToggleFavorite={() =>
                            handleToggleFavorite(lab_test, isFavorite)
                          }
                          openModal={() => openModalWithLabTest(lab_test)}
                          hideFavoritesButton={false}
                          hideInfoButton={false}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Panel>
            </TestsContainer>
          </Container>
        </StyledContent>
        <BundleActionsContainer>{bundleActionButtons}</BundleActionsContainer>
      </Dialog>
    </>
  )
}

const InfoIconImage = styled("img")({
  width: 14.6,
  height: 14.6,
})
function DescriptionSection({ description }) {
  if (!description) {
    return null
  }

  return (
    <div
      className="rounded-lg p-5 border-gray-300 bg-white mb-4"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 8px",
      }}
    >
      <BodyText size="sm" className="mb-3">
        Details
      </BodyText>
      <div
        style={{
          display: "flex",
        }}
      >
        <InfoIconImage
          src={InfoIcon}
          alt="info-icon"
          style={{
            marginTop: "4px",
            marginRight: "6px",
          }}
        />
        {description}
      </div>
    </div>
  )
}

const TitleSection = ({
  title,
  unavailableReason,
  shouldShowTitleButton,
  onClose,
  onClickTitleButton,
  isSharedBundle = false,
  bundle,
  practitioner,
  isMobile,
}) => {
  const isXsMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"))

  const titleButtonAndToolTip = shouldShowTitleButton && onClickTitleButton && (
    <Grid item xs={isXsMobile ? 12 : "auto"}>
      <Tooltip
        title={unavailableReason}
        placement="top"
        arrow
        disableHoverListener={!unavailableReason}
        key="title-button-and-tooltip"
        interactive
      >
        <div>
          <TitleButton
            autoFocus
            onClick={onClickTitleButton}
            color="primary"
            startIcon={AddIcon}
            size="xsmall"
            disabled={Boolean(unavailableReason)}
            isXsMobile={isXsMobile}
          >
            Add Bundle to Cart
          </TitleButton>
        </div>
      </Tooltip>
    </Grid>
  )

  const closeButton = onClose && (
    <CloseButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      isXsMobile={isXsMobile}
    >
      <CloseIcon fontSize="inherit" />
    </CloseButton>
  )

  // Only show the share button if this is a non-shared bundle and the feature toggle is enabled.
  const showShareButton = !isSharedBundle
  const shareButton = showShareButton ? (
    <Grid item xs={isXsMobile ? 12 : "auto"}>
      <StyledShareBundleButton isXsMobile={isXsMobile}>
        <ShareBundleButton
          key="share-bundle-button"
          bundle={bundle}
          practitioner={practitioner}
        />
      </StyledShareBundleButton>
    </Grid>
  ) : null

  const actionButtons = (
    <Grid
      key="title-container-action-buttons"
      container
      spacing={isXsMobile ? 1 : null}
      style={{
        marginRight: isXsMobile ? "0" : "6px",
        marginTop: isXsMobile ? "8px" : "0",
        justifyContent: isXsMobile ? "flex-start" : "flex-end",
      }}
    >
      {shareButton}
      {titleButtonAndToolTip}
    </Grid>
  )
  return (
    <StyledMuiDialogTitle disableTypography isMobile={isMobile}>
      <TitleAndInfoContainer isXsMobile={isXsMobile}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </TitleAndInfoContainer>
      <TitleButtonAndCloseButtonContainer>
        {[actionButtons, closeButton]}
      </TitleButtonAndCloseButtonContainer>
    </StyledMuiDialogTitle>
  )
}

export default BundleInfoModal
