/**
 * The UploadLicense component renders an AutoUpload instance with a Dropzone.
 * This provides the basic structure for uploading licenses via the UploadLicenseModal.
 */
import { useState } from "react"
import { useDispatch } from "react-redux"

import { DialogContent, styled, Typography } from "@material-ui/core"
import * as Sentry from "@sentry/react"

import DesignSystemButton from "app/components/design-system/Button"
import AutoUpload from "app/components/upload/AutoUpload"
import Dropzone from "app/components/upload/dropzone/Dropzone"
import { IMAGE_OR_PDF_MIME } from "app/components/upload/helpers"
import { showMessage } from "app/store/actions/fuse"
import { buttonSuccessGreen } from "app/theme"

const StyledContainer = styled("div")({
  "& .MuiDialogContent-root": {
    padding: 0,
  },
})

interface Props {
  fullVerificationComplete: boolean
  onUploadComplete: () => void
  onUploadFail: () => void
  onUploadStart: () => void
}

const StyledDropzoneButton = styled(DesignSystemButton)({
  // Override some display features so we can have DesignSystemButton's
  // display properly in the dropzone.
  fontWeight: "normal",
  marginTop: "-3px",
  size: "small",
})

const StyledDropZoneSuccessButton = styled(StyledDropzoneButton)({
  color: buttonSuccessGreen,
  textAlign: "left",
})

export default function UploadLicense({
  fullVerificationComplete,
  onUploadComplete,
  onUploadFail,
  onUploadStart,
}: Props) {
  const dispatch = useDispatch()
  const [uploadComplete, setUploadComplete] = useState(false)
  const handleUploadSuccess = (): void => {
    setUploadComplete(true)
    onUploadComplete()
  }

  // We are "processing" if they've uploaded, but we haven't received the :thumbsup: from
  // the verification status endpoint yet.
  const processing = !fullVerificationComplete && uploadComplete
  const handleUploadFail = () => {
    onUploadFail()
    Sentry.captureMessage("License upload failed.", {
      level: "error",
    })
    dispatch(
      showMessage({
        message: "Unable to upload license. Please try again.",
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    )
  }

  const dropzoneLabel = fullVerificationComplete ? (
    <StyledDropZoneSuccessButton color="text" variant="text">
      <Typography>Your License Was Uploaded Successfully</Typography>
    </StyledDropZoneSuccessButton>
  ) : (
    <StyledDropzoneButton color="text" loading={processing} variant="text">
      <Typography>Upload Your License</Typography>
    </StyledDropzoneButton>
  )
  return (
    <StyledContainer>
      <DialogContent>
        <div className="fs-exclude py-6 items-center">
          <AutoUpload
            fileContentFieldName="file"
            handleUploadFail={handleUploadFail}
            handleUploadStart={onUploadStart}
            handleUploadSuccess={handleUploadSuccess}
            uploadUrl="/api/practitionerlicenses/"
            allowedFileTypes={IMAGE_OR_PDF_MIME}
          >
            {(props) => (
              <Dropzone
                {...props}
                disabled={processing}
                children={dropzoneLabel}
                success={fullVerificationComplete}
              />
            )}
          </AutoUpload>
        </div>
      </DialogContent>
    </StyledContainer>
  )
}
