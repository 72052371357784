import { useDispatch } from "react-redux"

import { cn } from "@rupahealth/design"

import RupaLoadingButton from "app/components/RupaLoadingButton"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useSignatureAndLicenseUpload from "app/hooks/useSignatureAndLicenseUpload"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { getPractitioner } from "app/store/actions"
import { Practitioner } from "app/types"

import SignatureAndLicenseModal from "./SignatureAndLicenseModal"
import CredentialModalButton, {
  CredentialModalButtonProps,
} from "./SignatureAndLicenseModalV2/CredentialModalButton"

// Temporary component to switch between V1 and V2 Credential upload
// flow based on feature flag. TODO: Remove with PROD-1898
interface Props extends CredentialModalButtonProps {
  autoClose?: boolean
  returnLinkText?: string
}

const SignatureAndLicenseModalButton: React.FC<Props> = ({
  returnLinkText,
  onClose,
  ...props
}) => {
  const [credentialUploadV2Enabled] = useFeatureFlag(
    FeatureFlag.CredentialUploadV2
  )

  const dispatch = useDispatch()

  const handleClose = (fullVerificationComplete: boolean) => {
    if (fullVerificationComplete) dispatch(getPractitioner())
    onClose?.(fullVerificationComplete)
  }

  if (credentialUploadV2Enabled) {
    return <CredentialModalButton onClose={handleClose} {...props} />
  }
  return (
    <SignatureAndLicenseModalButtonV1
      returnLinkText={returnLinkText}
      onClose={handleClose}
      {...props}
    />
  )
}

const SignatureAndLicenseModalButtonV1: React.FC<Props> = ({
  onClose,
  autoClose,
  returnLinkText,
  children = "Sign Now",
  variant = "outline-primary",
  className,
  handleClick,
  ...buttonProps
}) => {
  const onModalClose = (fullVerificationComplete: boolean) => {
    onClose?.(fullVerificationComplete)
  }

  const {
    handleSignatureAndLicenseModalClose,
    handleSignatureAndLicenseModalOpen,
    onClickHelloSign,
    showSignatureAndLicenseUpload,
    signatureClientLoading,
  } = useSignatureAndLicenseUpload(onModalClose)

  const onClick = () => {
    if (buttonProps.disabled) return

    handleClick?.(handleSignatureAndLicenseModalOpen) ?? onClickHelloSign()
  }

  return (
    <>
      <SignatureAndLicenseModal
        autoClose={autoClose}
        onClose={handleSignatureAndLicenseModalClose}
        onOpen={handleSignatureAndLicenseModalOpen}
        open={showSignatureAndLicenseUpload}
        returnLinkText={returnLinkText}
      />
      <RupaLoadingButton
        loading={signatureClientLoading}
        variant={variant}
        className={cn("shadow-sm whitespace-break-spaces h-auto", className)}
        onClick={onClick}
        {...buttonProps}
      >
        {children}
      </RupaLoadingButton>
    </>
  )
}

export function signatureAndLicenseButtonTitle(
  practitioner?: Pick<Practitioner, "has_license" | "has_signature">
) {
  if (practitioner?.has_license && !practitioner.has_signature) {
    return "Provide E-Signature"
  } else if (practitioner?.has_signature && !practitioner.has_license) {
    return "Upload Credentials"
  }
  return "Upload License & E-Signature"
}

export default SignatureAndLicenseModalButton
