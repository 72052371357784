import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import ResultsExpectedDate from "app/components/StatusComponents/ResultsExpectedDate"

// While we could pass a whole LabCompany and SpecimenIssue object,
// due to the fact that this component is used in contexts where the shape of those objects can vary,
// we instead extract the fields we need and pass them to this component
interface SpecimenIssueRecollectionDetailsProps {
  is_requisition_provided: boolean
  is_requisition_ready: boolean
  is_shipped_kit: boolean
  date_recollection_ordered_with_lab?: string | null
  estimated_recollection_arrival_date?: string | null
  recollection_shipment_tracking_number?: string | null
  date_recollection_sample_received_at_lab?: string | null
  estimated_recollection_results_date?: string | null
  lab_company_notifies_sample_at_lab?: boolean
  lab_company_provides_results_eta?: boolean
  lab_company_short_name?: string
  resultsDelayTitle?: string
  resultsDelaySubtitle?: string
  resultsDelayReason?: string
}

export default function SpecimenIssueRecollectionDetails({
  is_requisition_provided,
  is_shipped_kit,
  is_requisition_ready,
  date_recollection_ordered_with_lab,
  estimated_recollection_arrival_date,
  recollection_shipment_tracking_number,
  date_recollection_sample_received_at_lab,
  estimated_recollection_results_date,
  lab_company_notifies_sample_at_lab,
  lab_company_provides_results_eta,
  lab_company_short_name,
}: SpecimenIssueRecollectionDetailsProps) {
  if (date_recollection_sample_received_at_lab) {
    if (estimated_recollection_results_date) {
      return (
        <ResultsExpectedDate
          dateResultsExpectedFromLab={estimated_recollection_results_date}
          labProvidesResultsEta={lab_company_provides_results_eta}
          labCompanyName={lab_company_short_name}
        />
      )
    }
    return (
      <span>{`Recollection sample arrived at lab on ${date_recollection_sample_received_at_lab}.`}</span>
    )
  }

  if (is_requisition_provided || !is_shipped_kit) {
    return (
      <span>{`Recollection requisition is ${
        is_requisition_ready ? "ready" : "processing"
      }.`}</span>
    )
  }

  if (
    estimated_recollection_arrival_date ||
    recollection_shipment_tracking_number
  ) {
    return (
      <KitShipmentTrackingDescription
        estimated_arrival_date={estimated_recollection_arrival_date}
        shipment_tracking_link={recollection_shipment_tracking_number}
        lab_company_notifies_sample_at_lab={lab_company_notifies_sample_at_lab}
        lab_company_short_name={lab_company_short_name}
        is_recollection={true}
      />
    )
  }

  if (date_recollection_ordered_with_lab) {
    return (
      <span>
        {"The recollection kit does not have an expected delivery date."}
      </span>
    )
  }

  return <span>{"We're processing your request for recollection!"}</span>
}
