import NiceModal, { useModal } from "@ebay/nice-modal-react"

import ConfirmationModal from "app/components/modals/ConfirmationModal"

interface ConfirmationModalProps {
  onClose: () => void
  handleConfirm: () => void
}

const Modal = NiceModal.create<ConfirmationModalProps>((props) => {
  return (
    <ConfirmationModal
      open
      title={"Are You Sure?"}
      message={
        "Are you sure you want to leave this page? You will lose your progress if you proceed."
      }
      onClose={props.onClose}
      loading={false}
      handleConfirm={props.handleConfirm}
      confirmButtonTitle={"Leave Anyway"}
      confirmButtonColor="primary"
    />
  )
})

export default function usePanelBuilderModalCloseConfirmation() {
  return useModal(Modal)
}
