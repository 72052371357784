import { useMemo } from "react"

import { useParams } from "react-router-dom"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { BloodLabDashboardPdf } from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/BloodLabDashboardPdf"
import useBundleResultsPdfPayload from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/use-bundle-results-pdf-payload"
import useBiomarkerCustomDescriptions from "app/main/blood-lab-dashboards/hooks/use-biomarker-custom-descriptions"
import useBloodLabDashboardsSnapshot from "app/main/blood-lab-dashboards/hooks/use-blood-lab-dashboards-snapshot"
import { isProduction } from "app/settings"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Clinic } from "types/clinic"
import { Patient } from "types/patient"
import { Practitioner } from "types/practitioner"

const TestResultsPdf = () => {
  const { snapshotId } = useParams<{
    snapshotId: string
  }>()

  const [bloodLabDashboardsPdfPrintEnabled] = useFeatureFlag(
    FeatureFlag.BloodLabDashboardsPdfPrint
  )

  const {
    allBiomarkers,
    snapshot,
    bloodReportResults,
    labTests,
    getBloodReportResultsByLabTest,
    userResults,
    orderedResult,
    labCompany,
    usesClinicOptimalRange,
  } = useBloodLabDashboardsSnapshot(snapshotId)

  const { biomarkerCustomDescriptionsMapping } = useBiomarkerCustomDescriptions(
    allBiomarkers.map((biomarker) => biomarker.id)
  )

  const patient = useCachedResource<Patient>(
    snapshot?.relationships.patient.data
  )

  const practitioner = useCachedResource<Practitioner>(
    patient?.relationships.practitioner.data
  )

  const clinic = useCachedResource<Clinic>(
    practitioner?.relationships.clinic.data
  )

  const abnormalBloodReportResults = useMemo(() => {
    return bloodReportResults.filter(
      (bloodReportResult) => bloodReportResult.attributes.out_of_standard_range
    )
  }, [bloodReportResults])

  const pdfPayload = useBundleResultsPdfPayload({
    outOfRangeBloodReportResults: abnormalBloodReportResults,
    bloodReportResultsByLabTest: labTests.map((labTest) => ({
      labTestName: labTest.attributes.name,
      bloodReportResults: getBloodReportResultsByLabTest(labTest),
    })),
    userResults: userResults,
    orderedResult: orderedResult,
    labCompany: labCompany,
    patient: patient,
    practitioner: practitioner,
    clinic: clinic,
    usesClinicOptimalRange,
    snapshotId,
    biomarkerCustomDescriptionsMapping,
  })

  if (
    isProduction ||
    !bloodLabDashboardsPdfPrintEnabled ||
    !snapshot ||
    !bloodReportResults
  ) {
    return null
  }

  return (
    <div className="flex items-center justify-center">
      <div className="fs-unmask bg-white w-[800px] p-6">
        <BloodLabDashboardPdf payload={pdfPayload} />
      </div>
    </div>
  )
}

export default TestResultsPdf
