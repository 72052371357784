import { ComponentType, cloneElement } from "react"
import * as React from "react"

import clsx from "clsx"

import { CircularProgress, Paper } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextField"
import { colors, navy, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ filterApplied?: boolean }>((theme) => ({
  autocomplete: {
    "& .MuiButtonBase-root": {
      // So autocomplete icon does not rotate when clicked
      transform: "rotate(0deg)",
    },
    "& button.MuiButtonBase-root": {
      visibility: (props) => (props.filterApplied ? "visible" : "hidden"),
    },
    minWidth: 200,
    maxWidth: 300,
    fontSize: 14,
  },
  autocompleteEndAdornment: {
    position: "absolute",
    right: 9,
    pointerEvents: "none",
  },
  textField: {
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      border: (props) =>
        props.filterApplied ? `2px solid ${primaryColor}` : "1px solid #D3DCE3",
    },
  },
  placeholder: {
    "&::placeholder": {
      color: navy,
      opacity: 1,
    },
    color: (props) => (props.filterApplied ? primaryColor : "inherit"),
  },
  listbox: {
    padding: 0,
  },
  dropdownOptionText: {
    padding: "14px 10px",
  },
  dropdownOption: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  dropdownContent: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    "& .MuiAutocomplete-listbox": {
      padding: 0,
    },
    "& .MuiAutocomplete-option": {
      padding: 0,
    },
  },
  separator: {
    background: colors.blueGray[200],
    height: 1,
    width: "100%",
  },
  popupIconSpacer: {
    width: 16,
    height: 16,
  },
  isMoreText: {
    backgroundColor: colors.blueGray[50],
    padding: theme.spacing(2.0),
    textAlign: "center",
  },
}))

export interface DashboardFilterProps {
  getOptionLabel: (option: any) => string
  getOptionValue: (option: any) => string
  icon?: React.ReactElement
  inputValue?: string
  isLoading?: boolean
  isMore?: boolean
  isMoreText?: string
  label?: string
  onChange: (value?: string) => void
  onInputChange: (inputValue: string) => void
  options: any[]
  value?: any
}

const PaperComponent: ComponentType = ({ children }) => {
  const classes = useStyles({})
  return <Paper className={classes.dropdownContent}>{children}</Paper>
}

const ListboxComponent: ComponentType = React.forwardRef<HTMLUListElement, any>(
  function ListboxComponent(props, ref) {
    const { className, children, filterApplied, isMore, isMoreText, ...other } =
      props
    const classes = useStyles({ filterApplied })

    return (
      <ul ref={ref} className={clsx(className, classes.listbox)} {...other}>
        {children}
        {isMore && (
          <>
            <div className={classes.separator}></div>
            <BodyText className={classes.isMoreText}>
              {isMoreText ||
                "Please refine your search to find the result you are looking for."}
            </BodyText>
          </>
        )}
      </ul>
    )
  }
)

export default function DashboardFilter({
  getOptionLabel,
  getOptionValue,
  icon,
  inputValue,
  isMore,
  isMoreText,
  isLoading,
  label,
  onChange,
  onInputChange,
  options,
  value,
}: DashboardFilterProps) {
  const classes = useStyles({ filterApplied: Boolean(value) })

  if (!icon) {
    icon = (
      <SearchIcon
        width={16}
        height={16}
        fill={primaryColor}
        className={classes.autocompleteEndAdornment}
      />
    )
  } else {
    icon = cloneElement(icon as React.ReactElement, {
      className: classes.autocompleteEndAdornment,
    })
  }

  return (
    <>
      <Autocomplete
        // Force re-mount of the Autocomplete internal state upon value change.
        // Workaround to fix the issue where the input value is not updated when the value prop is changed.
        key={value ? getOptionValue(value) : "autocomplete"}
        autoHighlight={true}
        className={classes.autocomplete}
        filterOptions={(options, _state) => options}
        freeSolo={false}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) =>
          value && getOptionValue(option) === getOptionValue(value)
        }
        inputValue={inputValue}
        loading={isLoading}
        noOptionsText={`No ${label?.toLowerCase()} found`}
        onChange={(_event, nextOption) => {
          onChange(nextOption ? getOptionValue(nextOption) : undefined)
        }}
        onInputChange={(_event, newInputValue) => {
          onInputChange(newInputValue)
        }}
        openOnFocus
        options={options}
        popupIcon={<div className={classes.popupIconSpacer}></div>}
        PaperComponent={PaperComponent}
        ListboxProps={{ filterApplied: Boolean(value), isMore, isMoreText }}
        ListboxComponent={ListboxComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            aria-label={label}
            className={classes.textField}
            fullWidth
            placeholder={label}
            size="small"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: { input: classes.placeholder },
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {isLoading ? (
                    <CircularProgress
                      className={classes.autocompleteEndAdornment}
                      color="primary"
                      size={16}
                    />
                  ) : (
                    icon
                  )}
                </>
              ),
            }}
            // If value is set make the input focused
            // Otherwise don't pass prop to avoid overriding default behavior
            {...(value ? { focused: Boolean(value) } : {})}
          />
        )}
        renderOption={(option, _state) => {
          const isLast =
            getOptionValue(option) === getOptionValue(options?.slice(-1)?.[0])
          return (
            <div className={classes.dropdownOption}>
              <BodyText
                className={classes.dropdownOptionText}
                weight="semibold"
              >
                {getOptionLabel(option)}
              </BodyText>
              {!isLast && <div className={classes.separator}></div>}
            </div>
          )
        }}
        value={value}
      />
    </>
  )
}
