import { useMemo } from "react"

import { Dialog, Typography } from "@material-ui/core"
import { Link, styled } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import InstantReqCheckbox from "app/assets/images/instant-req.svg"
import DesignSystemButton from "app/components/design-system/Button"

import {
  isInAccessMedicalLabsPanel,
  isInBostonHeartPanel,
} from "../../../dataServices/labTestDataService"

const useStyles = makeStyles({
  root: {
    paddingTop: "64px",
    paddingBottom: "48px",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  button: {
    marginTop: 24,
    fontWeight: 600,
    fontSize: 15,
  },
  link: {
    marginTop: 12,
  },
  checkbox: {
    maxHeight: 100,
  },
  dialog: {
    borderRadius: 12,
  },
})

const LearnMoreIcon = styled("img")({
  marginLeft: 4,
})

function getInstantRequisitionTestNames(order) {
  const instantRequisitionTestNamesSet = new Set()
  const dropShipTestNamesSet = new Set()
  order.ordered_tests.forEach((orderedTest) => {
    if (orderedTest.instant_requisition) {
      if (isInBostonHeartPanel(orderedTest.lab_test)) {
        instantRequisitionTestNamesSet.add("Boston Heart Blood Kit")
      } else if (isInAccessMedicalLabsPanel(orderedTest.lab_test)) {
        instantRequisitionTestNamesSet.add("Access Medical Blood Kit")
      } else {
        instantRequisitionTestNamesSet.add(orderedTest.lab_test.name)
      }
    } else {
      dropShipTestNamesSet.add(orderedTest.lab_test.name)
    }
  })

  const instantRequisitionTestNames = Array.from(instantRequisitionTestNamesSet)
  instantRequisitionTestNames.sort()

  const dropShipTestNames = Array.from(dropShipTestNamesSet)
  dropShipTestNames.sort()

  return { instantRequisitionTestNames, dropShipTestNames }
}

export function InstantRequisitionModal({ order, open, onClose, onConfirm }) {
  const classes = useStyles()
  const { instantRequisitionTestNames, dropShipTestNames } = useMemo(
    () => getInstantRequisitionTestNames(order),
    [order]
  )

  const title = !!dropShipTestNames.length
    ? "Are you sure? This order contains a mix of drop ship and in office kits."
    : "Are You Sure?"

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="instant-requisition-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      classes={{
        root: classes.dialog,
      }}
    >
      <div className={classes.root}>
        <img
          src={InstantReqCheckbox}
          alt="Instant req checkbox"
          className={classes.checkbox}
        />
        <Typography
          color="textPrimary"
          variant="h5"
          className="mt-8 mb-6 font-semibold px-8 text-center"
          id="instant-requisition-titls"
        >
          {title}
        </Typography>

        <Typography
          color="textPrimary"
          className="fs-exclude max-w-lg text-center whitespace-pre-wrap font-bold text-base15"
        >
          The following{" "}
          {instantRequisitionTestNames.length === 1 ? "kit" : "kits"} will not
          be shipped to {order?.patient?.first_name}:
        </Typography>

        <ul className="pl-5">
          {instantRequisitionTestNames &&
            instantRequisitionTestNames.map((testName) => (
              <li key={testName}>
                <Typography
                  color="textPrimary"
                  className="fs-exclude text-left whitespace-pre-wrap mt-2 text-sm"
                >
                  {testName}
                </Typography>
              </li>
            ))}
        </ul>

        {!!dropShipTestNames.length && (
          <>
            <Typography
              color="textPrimary"
              className="fs-exclude max-w-lg text-center whitespace-pre-wrap font-bold text-base15 mt-4"
            >
              The following {dropShipTestNames.length === 1 ? "kit" : "kits"}{" "}
              will be shipped to {order?.patient?.first_name}:
            </Typography>

            <ul className="pl-5">
              {dropShipTestNames.map((testName) => (
                <li key={testName}>
                  <Typography
                    color="textPrimary"
                    className="fs-exclude text-left whitespace-pre-wrap mt-2 text-sm"
                  >
                    {testName}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}

        <DesignSystemButton
          className={classes.button}
          color="primary"
          onClick={onConfirm}
          size="medium"
        >
          Yes, I Have{" "}
          {instantRequisitionTestNames.length === 1 ? "This Kit" : "These Kits"}{" "}
          In-Office
        </DesignSystemButton>

        <DesignSystemButton
          className={classes.button}
          color="secondary"
          onClick={onClose}
          size="medium"
        >
          No, Take Me Back to the Order
        </DesignSystemButton>

        <Link
          href="https://www.rupahealth.com/in-office-kits"
          target="_blank"
          className={classes.link}
        >
          Learn more about In Office Kits
          <LearnMoreIcon src={ArrowTopRight} alt="" />
        </Link>
      </div>
    </Dialog>
  )
}
