import { styled } from "@material-ui/core"

import BundleBloodGreySvg from "app/assets/icons/bundle-blood-grey.svg"
import BundleBloodWhiteSvg from "app/assets/icons/bundle-blood-white.svg"
import BundleGreySvg from "app/assets/icons/bundle-grey.svg"
import BundleWhiteSvg from "app/assets/icons/bundle-white.svg"
import {
  LabTestBundle,
  LabTestBundleType,
  LabTestBundleWithoutPractitioner,
} from "app/types"

interface Props {
  bundle: LabTestBundle | LabTestBundleWithoutPractitioner
  isSelected: boolean
}

const IconImage = styled("img")({
  width: 17.42,
  marginRight: 3,
  marginTop: 3,
})

const BundleIcon = ({ isSelected, bundle }: Props) => {
  if (!bundle) {
    return null
  }
  if (bundle.bundle_type === LabTestBundleType.PANEL) {
    return (
      <IconImage
        src={isSelected ? BundleBloodWhiteSvg : BundleBloodGreySvg}
        alt="bundle-icon"
      />
    )
  }
  return (
    <IconImage
      src={isSelected ? BundleWhiteSvg : BundleGreySvg}
      alt="bundle-icon"
    />
  )
}

export default BundleIcon
