import { ORDER_STATUS } from "app/constants.typed"
import { getOrderTypeLabel } from "app/utils/order-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ order, orderedTest }: StatusConfigurationProps) => {
  if (!orderedTest.lab_test.is_available) {
    return (
      <span>
        {`This ${getOrderTypeLabel(
          order.requires_vendor_physician_authorization
        ).toLowerCase()} has been canceled due to ${
          orderedTest.lab_test.name
        } being unavailable.`}
      </span>
    )
  }

  return undefined
}

const canceledStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderStatus: ORDER_STATUS.CANCELED },
    progressPercentage: PROGRESS_PERCENTAGES.CANCELED,
    title: "Order Canceled",
    subTitle: SubTitle,
  }

export default canceledStatusConfiguration
