import { API } from "app/api"
import { BUNDLE_TYPES } from "app/constants"

export async function createPanel(
  name: string,
  labTestIds: string[],
  biomarkerIds: string[],
  biomarkerGroupingIds: string[]
) {
  return await API.Bundle.create({
    name,
    lab_tests: labTestIds,
    bundle_type: BUNDLE_TYPES.PANEL,
    requested_biomarkers: biomarkerIds,
    requested_biomarker_groupings: biomarkerGroupingIds,
  })
}

export async function deletePanel(panelId: string) {
  return await API.Bundle.delete(panelId)
}

export async function replacePanel(
  existingPanelId: string,
  name: string,
  labTestIds: string[],
  biomarkerIds: string[],
  biomarkerGroupingIds: string[]
) {
  return await Promise.all([
    createPanel(name, labTestIds, biomarkerIds, biomarkerGroupingIds),
    deletePanel(existingPanelId),
  ])
}
