import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { colors, Paper, styled, useMediaQuery } from "@material-ui/core"

import { Dialog, ModalContent, ModalHeader } from "app/components/modals"
import NewPatientModal from "app/components/modals/NewPatientModal"
import StartNewOrder from "app/main/dashboard/StartNewOrder"
import { getPatients } from "app/main/dashboard/store/actions"
import { getOrderPatientLabel } from "app/utils/order-utils"

const StartOrderModal = ({
  open,
  onClose,
  shouldHideCreatePatientButton = false,
  practitioner,
  onSelectPatient = () => {},
}) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false)
  const [newPatientModalName, setNewPatientModalName] = useState("")

  // During the product tour, Hide the existing list of patients for that clinic, So
  // that the staff/practitioner will not select an existing patient. (Selecting an
  // existing patient will prevent the tour from happening.)
  const patients = useSelector(({ dashboard }) =>
    dashboard ? dashboard.dashboard.patients : []
  )

  useEffect(() => {
    // Get patients if we don't have any fetched
    if (patients.length === 0) {
      dispatch(getPatients())
    }
  }, [])

  const handleOpenNewPatientModal = useCallback(
    ({ searchText }) => {
      setNewPatientModalName(searchText)
      setIsNewPatientModalOpen(true)
    },
    [setNewPatientModalName, setIsNewPatientModalOpen]
  )

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby={`start-order-modal-title`}
        open={open}
        maxWidth="md"
        PaperProps={{
          style: {
            margin: 15,
            borderRadius: 7,
          },
        }}
      >
        <ModalHeader
          onClose={onClose}
          id="start-order-modal-title"
          title={`Choose or Create a ${getOrderPatientLabel(
            practitioner?.default_physician_authorization
          )}`}
        />
        <StyledModalContent
          style={{
            backgroundColor: "rgba(43, 69, 95, 0.05)",
            borderTop: colors.blueGrey[300],
            borderBottom: colors.blueGrey[300],
          }}
          className="tour-onboarding-add-patient"
          isMobile={isMobile}
        >
          <StyledPaper isMobile={isMobile}>
            <StartNewOrder
              shouldHideTitle
              shouldHideCancelButton
              patients={patients}
              handleOpenNewPatientModal={handleOpenNewPatientModal}
              onSelectPatient={onSelectPatient}
              defaultPhysicianServices={
                practitioner?.default_physician_authorization
              }
            />
          </StyledPaper>
        </StyledModalContent>
      </Dialog>
      <NewPatientModal
        open={isNewPatientModalOpen}
        messages={{
          patientTerm: getOrderPatientLabel(
            practitioner?.default_physician_authorization
          ),
        }}
        onClose={(closeStartOrder) => {
          setIsNewPatientModalOpen(false)

          if (closeStartOrder) {
            onClose()
          }
        }}
        name={newPatientModalName}
        shouldHideCreatePatientButton={shouldHideCreatePatientButton}
        practitioner={practitioner}
      />
    </>
  )
}

const StyledModalContent = styled(ModalContent)({
  padding: (props) => (props.isMobile ? "initial" : 35),
})
const StyledPaper = styled(Paper)({
  backgroundColor: "white",
  minWidth: (props) => (props.isMobile ? "80%" : 405),
  minHeight: 85,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})

export default StartOrderModal
