import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceCollection } from "app/swr/types"
import { BloodLabDashboardReportSnapshot } from "types/blood-lab-dashboard-report-snapshot"
import { BloodReportResult } from "types/blood-report-result"

import useBloodLabDashboardsSnapshotHelpers from "./use-blood-lab-dashboard-snapshot-helpers"

export default function useBloodLabDashboardsSnapshot(
  snapshotId: string,
  search?: string
) {
  const {
    data: snapshot,
    error: snapshotError,
    isLoading: isSnapshotLoading,
    isValidating: isSnapshotValidating,
    mutate: mutateSnapshot,
  } = useResourceSWR<BloodLabDashboardReportSnapshot>(
    `/blood_lab_dashboard_report_snapshots/${snapshotId}/`,
    {
      include: [
        "patient.practitioner.clinic",
        "ordered_result.kit.ordered_tests.lab_test.biomarkers.body_systems",
        "ordered_result.lab_company",
        "user_results.lab_company",
      ],
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  )

  const {
    data: bloodReportResultIdentifiers,
    isLoading: isBloodReportResultsLoading,
    isValidating: isBloodReportResultsValidating,
    error: bloodReportResultsError,
    mutate: mutateBloodReportResults,
  } = useCollectionSWR<ResourceCollection<BloodReportResult>>(
    `/blood_lab_dashboard_report_snapshots/${snapshotId}/results/`,
    {},
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  )

  const mutateBloodReportResultsAndSnapshot = () => {
    mutateBloodReportResults()
    mutateSnapshot(undefined, { revalidate: true, populateCache: false })
  }
  const bloodReportResults = useCachedCollection<BloodReportResult>(
    bloodReportResultIdentifiers
  )

  const {
    userResults,
    filteredBloodReportResults,
    allBiomarkers,
    orderedResult,
    labTests,
    sortedBodySystems,
    missingBiomarkers,
    labCompany,
    usesClinicOptimalRange,
    getBloodReportResultsByLabTest,
    getBloodReportResultsByBodySystem,
    getMatchingBloodReportResultByBiomarkerId,
  } = useBloodLabDashboardsSnapshotHelpers(snapshot, bloodReportResults, search)

  return {
    snapshot,
    userResults,
    bloodReportResults: filteredBloodReportResults,
    allBiomarkers,
    orderedResult,
    labTests,
    sortedBodySystems,
    missingBiomarkers,
    isSnapshotLoading,
    isBloodReportResultsLoading,
    isBloodReportResultsValidating,
    isSnapshotValidating,
    snapshotError,
    bloodReportResultsError,
    labCompany,
    usesClinicOptimalRange,
    mutateBloodReportResultsAndSnapshot,
    getBloodReportResultsByLabTest,
    getBloodReportResultsByBodySystem,
    getMatchingBloodReportResultByBiomarkerId,
  }
}
