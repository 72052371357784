import { IconButton } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"

import { BUNDLE_TYPES } from "app/constants"
import { colors, favoritedColor } from "app/theme"
import { LabTestBundleWithoutPractitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ isFavorited: boolean }>({
  favoriteIconButton: {
    padding: 0,
    borderRadius: 4,
  },
  favoriteIcon: {
    color: (props) =>
      props.isFavorited ? favoritedColor : colors.blueGray[200],
  },
  panelFavoriteIcon: {
    color: (props) => (props.isFavorited ? "white" : colors.blueGray[200]),
  },
})

interface Props {
  bundle?: LabTestBundleWithoutPractitioner
  onFavoriteClick: (e: any) => void
  isSelected: boolean
}

const BundleFavoriteIcon = ({ bundle, onFavoriteClick, isSelected }: Props) => {
  const styles = useStyles({ isFavorited: Boolean(bundle?.is_favorited) })

  return (
    <IconButton
      className={styles.favoriteIconButton}
      onClick={onFavoriteClick}
      aria-label="Favorite test"
    >
      <FavoriteIcon
        fontSize="small"
        className={
          bundle?.bundle_type === BUNDLE_TYPES.PANEL && isSelected
            ? styles.panelFavoriteIcon
            : styles.favoriteIcon
        }
      />
    </IconButton>
  )
}

export default BundleFavoriteIcon
