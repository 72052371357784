import { useCallback, useMemo, useRef, useState } from "react"

import SearchIcon from "app/assets/images/search-icon-blue.svg"
import AutoSuggest, { useAutoSuggest } from "app/components/search/AutoSuggest"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { INTERCOM_REQUEST_LAB_TEST_SURVEY_ID } from "app/settings"
import { colors, primaryColor, shadows, textPrimaryColor } from "app/theme"
import { SearchSuggestion } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import AutoSuggestSurveyRequest from "../search/AutoSuggest/AutoSuggestSurveyRequest"
import OrderBy from "./OrderBy"
import QueryTokenList from "./QueryTokenList"
import useTokenFocusControls from "./hooks/use-token-focus-controls"
import {
  FILTER_NAMES,
  FilterNames,
  OnSelectFilterOption,
  OnSetQueryParams,
  Query,
  QueryMap,
  QueryParam,
} from "./types"

const useStyles = makeAppStyles((theme) => ({
  container: {
    background: "white",
    border: `2px solid ${colors.blueGray[300]}`,
    borderRadius: 8,
    boxShadow: shadows.default,
    padding: 12,
    transition: "0.08s border-color, 0.16s box-shadow",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    borderColor: primaryColor,
    "-webkit-appearance": "none !important",

    "&:focus-within": {
      // Deliberately not from the design system as this shadow is custom
      boxShadow: "0px 0px 4px #0075CD",
    },
  },
  searchIconContainer: {
    marginRight: 11,
    background: colors.blueGray[100],
    padding: 8,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchIcon: {
    width: 18,
    height: 18,
  },
  inputContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1.5),
    minHeight: 34,
  },
  input: {
    flex: 1,
    color: textPrimaryColor,
    fontSize: 16,
    lineHeight: 1.25,
    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },

    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: 600,
    },
  },
}))

export interface SearchProps {
  autoFocus?: boolean
  query: Query
  queryMap: QueryMap
  onSelectFilterOption: OnSelectFilterOption
  onSetQueryParams: OnSetQueryParams
}

export default function Search({
  autoFocus,
  query,
  queryMap,
  onSelectFilterOption,
  onSetQueryParams,
}: SearchProps) {
  const styles = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState<string>("")

  const handleSearchSelect = useCallback(
    (search?: string) => {
      onSetQueryParams([
        {
          // Always reset the page
          queryName: "page",
          value: "1",
        },
        {
          queryName: "search",
          value: search || "",
        },
      ])
      setSearch("")
    },
    [onSetQueryParams]
  )

  const handleSuggestionSelect = useCallback(
    (suggestion: SearchSuggestion) => {
      const filterMap = queryMap.filters[suggestion.type as FilterNames]
      if (!filterMap.has(suggestion.object_id)) {
        onSelectFilterOption(
          suggestion.type as FilterNames,
          { label: suggestion.label, value: suggestion.object_id },
          false
        )
      }
      setSearch("")
    },
    [queryMap.filters, onSelectFilterOption]
  )

  const [isCatalogSearchRelevancyUpdatesEnabled] = useFeatureFlag(
    FeatureFlag.CatalogSearchRelevancyUpdates
  )

  const autoSuggest = useAutoSuggest({
    onSearchSelect: handleSearchSelect,
    onSuggestionSelect: handleSuggestionSelect,
    search,
    isCatalogSearchRelevancyUpdatesEnabled,
  })

  const queryTokens = useMemo(() => {
    return query.filter((param) => {
      return (
        param.queryName === "search" ||
        FILTER_NAMES.has(param.queryName as FilterNames)
      )
    })
  }, [query])
  const { blurToken, focusNextToken, focusPrevToken, focusToken, tokenCursor } =
    useTokenFocusControls({ inputRef, length: queryTokens.length })

  const removeToken = useCallback(
    (token: QueryParam) => {
      if (token.queryName === "search") {
        onSetQueryParams([
          {
            // Always reset the page
            queryName: "page",
            value: "1",
          },
          {
            queryName: "search",
            value: "",
          },
        ])
      } else if (FILTER_NAMES.has(token.queryName as FilterNames)) {
        onSelectFilterOption(
          token.queryName as FilterNames,
          { label: token.label || token.value, value: token.value },
          false
        )
      } else {
        // unknown query param, do nothing
        return
      }

      if (queryTokens.length === 1) {
        focusToken(-1)

        // if there are no tokens left after removal, focus the input
        inputRef.current && inputRef.current.focus()
      } else if (tokenCursor > -1) {
        // otherwise, focus the previous token if already focusing a token
        focusPrevToken(tokenCursor)
      }
    },
    [
      focusPrevToken,
      focusToken,
      onSetQueryParams,
      onSelectFilterOption,
      queryTokens.length,
      tokenCursor,
    ]
  )

  const handleKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (e) => {
      // keyboard actions for auto-suggest only available while searching
      if (!!search) {
        if (e.key === "ArrowUp") {
          // disable moving text cursor to start
          e.preventDefault()

          autoSuggest.moveCursorUp()
        } else if (e.key === "ArrowDown") {
          // disable moving text cursor to end
          e.preventDefault()

          autoSuggest.moveCursorDown()
        } else if (e.key === "Enter") {
          // disable enter behavior
          e.preventDefault()

          autoSuggest.selectCursor()
        }
      }

      if (!search && e.key === "Backspace") {
        // disable backspace behavior
        e.preventDefault()

        // remove last token if any exist
        if (queryTokens.length) {
          const tokenToRemove = queryTokens[queryTokens.length - 1]
          removeToken(tokenToRemove)
        }
      }

      if (
        e.key === "ArrowLeft" &&
        e.currentTarget.selectionStart === 0 &&
        e.currentTarget.selectionEnd === 0
      ) {
        // move focus to last token if any exist
        if (queryTokens.length) {
          focusToken(queryTokens.length - 1)
        }
      }
    },
    [
      autoSuggest.moveCursorUp,
      autoSuggest.moveCursorDown,
      autoSuggest.selectCursor,
      focusToken,
      queryTokens,
      removeToken,
      search,
    ]
  )

  return (
    <AutoSuggest
      {...autoSuggest}
      contentChildren={
        <AutoSuggestSurveyRequest
          surveyId={INTERCOM_REQUEST_LAB_TEST_SURVEY_ID}
          beforeButtonText="No labs or tests match your search?"
          buttonText="Submit a request to us"
          afterButtonText="for the test or lab to be added!"
        />
      }
    >
      <div className={styles.container} tabIndex={-1}>
        <div className={styles.searchIconContainer}>
          <img src={SearchIcon} alt="Search" className={styles.searchIcon} />
        </div>
        <div className={styles.inputContainer}>
          <QueryTokenList
            blurToken={blurToken}
            focusToken={focusToken}
            focusNextToken={focusNextToken}
            focusPrevToken={focusPrevToken}
            queryTokens={queryTokens}
            removeToken={removeToken}
            tokenCursor={tokenCursor}
          />
          <input
            aria-label="Search for tests, categories, biomarkers & more"
            autoFocus={autoFocus}
            className={styles.input}
            onChange={(event) => setSearch(event.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={
              queryTokens.length
                ? "Search"
                : "Search for tests, categories, biomarkers & more"
            }
            ref={inputRef}
            value={search}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
        <OrderBy queryMap={queryMap} onSetQueryParams={onSetQueryParams} />
      </div>
    </AutoSuggest>
  )
}
