import { useRef, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { styled, useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { FormTextField, SelectFormField } from "app/components/forms/fields"
import { Dialog } from "app/components/modals"
import { OTHER_PRACTITIONER_TYPE } from "app/constants"
import { usePractitionerTypeOptions } from "app/hooks/usePractitionerTypes"
import * as Actions from "app/store/actions"
import { colors, navy } from "app/theme"

const ActionsContainer = styled("div")({
  display: "flex",
  padding: "12px 20px",
  justifyContent: "flex-end",
})

const PatientInfoWrapper = styled("div")({
  backgroundColor: colors.blueGray[100],
  padding: 24,
})

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ isMobile }) => ({
  color: navy,
  position: isMobile ? "absolute" : "relative",
  top: 1,
  right: isMobile ? 12 : 0,
  marginRight: -12,
}))

const StyledMuiDialogTitle = styled("div")(({ isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: isMobile ? "wrap" : "nowrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
  padding: isMobile ? "16px 24px" : "8px 24px",
}))

const TeamMemberModal = ({ open, onClose, onAddTeamMember }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="team-member-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <Form onClose={onClose} onAddTeamMember={onAddTeamMember} />
    </Dialog>
  )
}

export default TeamMemberModal

function Form({ onClose, onAddTeamMember }) {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isClinicStaff, setIsClinicStaff] = useState(true)
  const [isCustomPractitionerType, setIsCustomPractitionerType] =
    useState(false)

  const handleClose = () => {
    setIsSubmitting(false)
    onClose()
  }

  const stopSubmitting = () => {
    setIsSubmitting(false)
  }

  const formSubmit = (data) => {
    setIsSubmitting(true)
    if (onAddTeamMember) {
      onAddTeamMember(data)
    }
    dispatch(
      Actions.createTeamMember(
        data.first_name,
        data.last_name,
        data.email,
        data.is_clinic_staff === "false" ? false : true,
        data.primary_practitioner_type,
        data.custom_practitioner_type,
        data.npi_number,
        handleClose,
        stopSubmitting
      )
    )
  }

  const formChange = (data) => {
    if (
      (!data.is_clinic_staff || data.is_clinic_staff === "true") &&
      !isClinicStaff
    ) {
      setIsClinicStaff(true)
    } else if (data.is_clinic_staff === "false" && isClinicStaff) {
      setIsClinicStaff(false)
    }

    // To toggle custom practitioner type input box
    if (
      !isCustomPractitionerType &&
      data.primary_practitioner_type === OTHER_PRACTITIONER_TYPE
    ) {
      setIsCustomPractitionerType(true)
    } else if (
      isCustomPractitionerType &&
      data.primary_practitioner_type !== OTHER_PRACTITIONER_TYPE
    ) {
      setIsCustomPractitionerType(false)
    }
  }

  return (
    <Formsy
      onSubmit={formSubmit}
      ref={formRef}
      onValid={() => setIsFormValid(true)}
      onInvalid={() => setIsFormValid(false)}
      onChange={formChange}
    >
      <TitleSection onClose={onClose} />

      <PatientInfoWrapper>
        <PatientInfoFields
          isClinicStaff={isClinicStaff}
          isCustomPractitionerType={isCustomPractitionerType}
        />
      </PatientInfoWrapper>

      <ActionsContainer>
        <DesignSystemButton color="noaction" onClick={onClose} className="mr-3">
          Cancel
        </DesignSystemButton>
        <DesignSystemButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isFormValid}
          loading={isSubmitting}
        >
          Invite Team Member
        </DesignSystemButton>
      </ActionsContainer>
    </Formsy>
  )
}

const TitleSection = ({ onClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const closeButton = onClose && (
    <CloseButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      isMobile={isMobile}
    >
      <CloseIcon />
    </CloseButton>
  )

  return (
    <StyledMuiDialogTitle disableTypography isMobile={isMobile}>
      <TitleAndInfoContainer>
        <DisplayText weight="semibold" size="lg">
          Invite a Team Member
        </DisplayText>
      </TitleAndInfoContainer>
      {closeButton}
    </StyledMuiDialogTitle>
  )
}

function PatientInfoFields({ isClinicStaff, isCustomPractitionerType }) {
  const { practitionerTypeOptions, isLoading: pracTypesLoading } =
    usePractitionerTypeOptions()

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="First Name"
            name="first_name"
            required={true}
            validations={{
              maxLength: 30,
            }}
            validationErrors={{
              maxLength:
                "First name is too long, please use a shorter first name.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Last Name"
            name="last_name"
            required={true}
            validations={{
              maxLength: 30,
            }}
            validationErrors={{
              maxLength:
                "Last name is too long, please use a shorter last name.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            label="Email"
            name="email"
            required={true}
            validations={{
              isEmail: true,
              maxLength: 60,
            }}
            validationErrors={{
              isEmail: "Please enter a valid email.",
              maxLength: "Email is too long, please use a shorter email.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectFormField
            label="Practitioner or Staff"
            name="is_clinic_staff"
            required={true}
          >
            <option value="false">Practitioner</option>
            <option value="true">Staff</option>
          </SelectFormField>
        </Grid>
        {!isClinicStaff && (
          <>
            <Grid item xs={12} md={6}>
              <SelectFormField
                label="Practitioner Type"
                name="primary_practitioner_type"
                required={false}
              >
                {practitionerTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <option value={OTHER_PRACTITIONER_TYPE}>Other</option>
              </SelectFormField>
            </Grid>
            {isCustomPractitionerType && (
              <Grid item xs={12} md={6}>
                <FormTextField
                  label="Custom Practitioner Type"
                  name="custom_practitioner_type"
                  required={true}
                  validations={{
                    maxLength: 100,
                  }}
                  validationErrors={{
                    maxLength:
                      "Custom practitioner type is too long, please use a shorter custom practitioner type.",
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="NPI Number"
                name="npi_number"
                required={false}
                validations={{
                  isLength: 10,
                }}
                validationErrors={{
                  isLength:
                    "NPI number is invalid, please use a valid NPI number with 10 characters.",
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
