import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

import {
  InRangeOption,
  ResultsOverTimeData,
  ResultsOverTimeDateGrouping,
} from "../types/types"

export default function useResultsOverTime(
  userId: string,
  primaryBodySystemId: string,
  timeSeriesGrouping: ResultsOverTimeDateGrouping,
  inRangeValue: InRangeOption,
  bodySystemId: string
) {
  // Need to establish a unique key here to ensure that the SWR cache is
  // invalidated when one or more of the dropdowns change.
  const key = [
    `/results_over_time/${userId}/`,
    timeSeriesGrouping,
    inRangeValue,
    primaryBodySystemId,
    bodySystemId,
  ]

  const {
    data: resultsOverTime,
    error,
    isLoading,
    isValidating,
  } = useSWR<ResultsOverTimeData>(key, {
    async fetcher(key) {
      const url = key[0]

      const response = await axios.request<ResultsOverTimeData>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "get",
        params: {
          body_system_id: primaryBodySystemId,
          group_by: timeSeriesGrouping,
          results_range_status: inRangeValue,
          use_all_body_systems: Boolean(bodySystemId === "all"),
          primary_body_system_only: Boolean(bodySystemId === "all"),
        },
      })

      return response.data
    },
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  return {
    resultsOverTime,
    error,
    isLoading,
    isValidating,
  }
}
