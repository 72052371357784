// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import { Typography } from "@material-ui/core"

function ErrorLabelFormsy({ align, className, getErrorMessage }) {
  const errorMessage = getErrorMessage()

  return (
    <Typography align={align} color="error" className={className}>
      {errorMessage}
    </Typography>
  )
}

export default withFormsy(ErrorLabelFormsy)
