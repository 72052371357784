import { useMemo } from "react"

import clsx from "classnames"
import { useHistory } from "react-router-dom"

import useBanners from "app/banners/use-banners"
import useDashboardOrderStatuses from "app/dashboard/hooks/use-dashboard-order-statuses"
import { AnyBanner, SpecimenIssueBanner } from "app/main/banner/types"
import { bannerLink } from "app/main/dashboard/Alerts/banner-utils"

import BannerComponent from "./Banner"

const BannerList: React.FC = () => {
  const history = useHistory()

  const { mutate, banners } = useBanners()
  const { data: orderStatusesState } = useDashboardOrderStatuses()

  // Add in the specimen issue banner dynamically
  const showSpecimenIssueBanner = useMemo(() => {
    return orderStatusesState?.has_specimen_issue_orders
  }, [orderStatusesState])

  const allBanners: AnyBanner[] = useMemo(() => {
    if (showSpecimenIssueBanner) {
      return [...banners, SpecimenIssueBanner]
    }
    return banners
  }, [banners, showSpecimenIssueBanner])

  if (!allBanners?.length) {
    return null
  }

  return (
    <div className="mt-16 sm:mt-6">
      <div className="flex flex-col bg-white border border-slate-200 shadow rounded-md">
        {allBanners.map((banner, index) => (
          <div
            key={`banner-${index}`}
            className={clsx(
              "mx-2",
              { "border-b": index < banners.length - 1 },
              "border-slate-200"
            )}
          >
            <BannerComponent
              banner={banner}
              link={bannerLink(history, banner)}
              // After banner is dismissed, refetch the list of banners
              onDismiss={mutate}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default BannerList
