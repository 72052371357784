import axios from "axios"

import createAuth0Client from "@auth0/auth0-spa-js"
import * as Sentry from "@sentry/react"

import { API } from "app/api"
import authService from "app/auth/services/simplejwt-auth-service"
import { isUserGuest } from "app/auth/util"
import { isUserPractitioner } from "app/auth/util"
import { loadIntercom, shutdownIntercom } from "app/services/intercom"
import * as refiner from "app/services/refiner"
import { analyticsIdentify, analyticsReset } from "app/services/segment"
import { buildAuth0Config } from "app/settings"
import * as Actions from "app/store/actions"
import { passwordUpdateComplete } from "app/store/actions"
import { showMessage } from "app/store/actions/fuse"
import {
  getApiBaseUrl,
  getBaseUrl,
  handleApiError,
  handleApiSuccess,
} from "app/utils"

import { submitLogin } from "./login.actions"

export const SET_USER_DATA = "[USER] SET DATA"
export const REMOVE_USER_DATA = "[USER] REMOVE DATA"
export const USER_LOGGED_OUT = "[USER] LOGGED OUT"

export const USER_SET_PASSWORD = "[USER] SET PASSWORD"
export const USER_SET_INITIAL_PASSWORD = "[USER] SET INITIAL PASSWORD"
export const USER_SHOWN_INITIAL_PASSWORD_SET_MESSAGE =
  "[USER] SHOWN INITIAL PASSWORD SET MESSAGE"
export const USER_SHOWN_EMAIL_VERIFIED_MESSAGE =
  "[USER] SHOWN EMAIL VERIFIED MESSAGE"
export const USER_VERIFIED_EMAIL = "[USER] VERIFIED EMAIL"

/**
 * Set User Data
 */
export function setUserData(user) {
  return async (dispatch) => {
    // This seems inelegant, maybe there's a better way based on incoming roles
    const role = user.is_provider
      ? "provider"
      : user.is_staff
      ? "staff"
      : "patient"

    const updatedUser = {
      ...user,
      // TODO: May also want to remove depending on role of cookie for webflow
      role: role,
      data: {
        settings: null,
      },
    }

    Sentry.configureScope(function (scope) {
      scope.setUser({ id: user.id })
    })

    analyticsIdentify(user.id)

    loadIntercom(user)

    if (process.env.NODE_ENV !== "development") {
      refiner.identify(user)
    }

    /*
        Set User Settings
         */
    if (isUserPractitioner(user)) {
      dispatch(Actions.getPractitioner())
    }
    dispatch(Actions.getUserData(updatedUser.id))

    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: updatedUser,
    })
  }
}

export function retrieveUserData() {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(getApiBaseUrl() + `/api/user/`)
      return dispatch(setUserData(response.data.user))
    } catch (error) {
      return dispatch(handleApiError(error))
    }
  }
}

export function updateUser(user) {
  return (dispatch, getState) => {
    const request = axios.put(getApiBaseUrl() + `/api/user/`, {
      user,
    })

    return request
      .then((response) => {
        dispatch(handleApiSuccess("Update successful"))
        dispatch({
          type: SET_USER_DATA,
          payload: user,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function requestPasswordReset(email) {
  return (dispatch, getState) => {
    const request = axios.post(getApiBaseUrl() + `/api/password_reset/`, {
      email,
    })

    return request
      .then((response) => {
        dispatch(
          handleApiSuccess("Password request email sent -- check your inbox")
        )
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function resendRegistrationEmail({ email }) {
  return async (dispatch, getState) => {
    try {
      await API.ResendRegistrationEmail.post()
      dispatch(
        showMessage({
          message: `Verification email sent to ${email}.`,
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        })
      )
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

export class ChangePasswordError extends Error {
  constructor(errors) {
    super()
    this.errors = errors
  }
}

/**
 * Action update a user's password, either through a forced reset
 * or on first login.
 */
export function updatePassword(password) {
  return async (dispatch, getState) => {
    try {
      await API.Auth.updatePassword(password)

      dispatch({
        type: USER_SET_INITIAL_PASSWORD,
      })
      dispatch(passwordUpdateComplete())
    } catch (error) {
      const errorData = error?.response?.data
      const passwordErrors = errorData?.errors?.password
      if (passwordErrors) {
        throw new ChangePasswordError(passwordErrors)
      } else {
        // Generic error handler for unknown failure scenarios.
        dispatch(
          showMessage({
            message: "Unable to update your password. Please try again.",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )
        throw error
      }
    }
  }
}

export function resetPassword(
  history,
  token,
  email,
  password,
  isSetPassword,
  role
) {
  return (dispatch, getState) => {
    const request = axios.post(
      getApiBaseUrl() + `/api/password_reset/confirm/`,
      {
        token,
        password,
      }
    )

    return request
      .then(async (response) => {
        // Perform a logout of the user before logging them in.
        // This is required due to how the new signup without email verification flow works.
        // A user could land on the setpassword modal whilst still having a valid JWT from the magic link login they
        // used from signup.
        // However, as soon as the change password request completes this token will be invalid as is_verified on the
        // token (value=False) will not match is_verified on the user (value=True). This will cause initial calls
        // on the dashboard to fail with 403 errors. To prevent this, we logout the user to ensure a clean slate.
        // However, we do not do the Auth0 logout as that would cause the page to refresh and isn't necessary for this
        // flow.
        await authService.logout()

        // Automatically log the user in after successfully setting their password.
        dispatch(submitLogin({ email, password }, role))

        if (isSetPassword) {
          dispatch({
            type: USER_SET_PASSWORD,
          })
        }
      })
      .catch((error) => {
        const errorData = error && error.response && error.response.data
        const passwordErrors = errorData.errors && errorData.errors.password

        if (passwordErrors) {
          throw new ChangePasswordError(passwordErrors)
        } else {
          dispatch(handleApiError(error))
          throw error
        }
      })
  }
}

/**
 * Clears user data after a logout
 */
export function clearUserData() {
  return async (dispatch, getState) => {
    const user = getState().auth.user

    if (isUserGuest(user)) {
      // is guest
      return null
    }

    Sentry.configureScope(function (scope) {
      scope.setUser({})
    })

    analyticsReset(user.id)

    // Used by LabOrderingAccess to ensure no state persists between logins
    if (sessionStorage.getItem("practitionerId")) {
      window.sessionStorage.removeItem("practitionerId")
    }

    await dispatch({
      type: USER_LOGGED_OUT,
    })

    shutdownIntercom()

    if (user.auth_provider === "Auth0") {
      const auth0Config = buildAuth0Config(getBaseUrl(), getApiBaseUrl())
      const auth0 = await createAuth0Client(auth0Config)
      auth0.logout({
        returnTo: getBaseUrl(),
      })
    }
  }
}
