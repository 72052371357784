import React, { useMemo } from "react"

import { cn } from "@rupahealth/design"

import CircularCheckFilled from "app/assets/images/circular-check-filled-success.svg"
import UploadDisabled from "app/assets/images/upload-disabled.svg"
import Upload from "app/assets/images/upload.svg"

interface Props {
  isDragging: boolean
  children?: React.ReactNode
  disabled?: boolean
  success?: boolean
  className?: string
  hideUploadLogo?: boolean
  onRemove?: () => void
}

const Dropzone: React.FC<Props> = ({
  children,
  disabled,
  success,
  hideUploadLogo,
  className,
  isDragging,
}) => {
  const Icon = useMemo(() => {
    if (success) return CircularCheckFilled
    if (disabled) return UploadDisabled
    return Upload
  }, [success, disabled])

  return (
    <div
      className={cn(
        "border-2 border-dashed transition-colors py-8 rounded-lg",
        "h-full w-full flex items-center content-center",
        isDragging && !disabled
          ? "border-primary bg-blue-50"
          : "border-slate-300 bg-white",
        {
          "cursor-pointer": !disabled,
          "border-solid": success,
          "text-primary": !disabled && !success,
          "border-gray-200": disabled,
          "text-emerald-500 border-emerald-500": success,
        },
        className
      )}
    >
      <div className="text-center w-full">
        {!hideUploadLogo && (
          <img src={Icon} alt="Upload Icon" className="relative top-1 mr-2" />
        )}
        {children}
      </div>
    </div>
  )
}

export default Dropzone
