// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import DateInput from "app/main/patient-checkout/fields/DateInput"

import BodyText from "../design-system/BodyText"

function DatePickerFormsy({ fullWidth, InputProps, ...props }) {
  const errorMessage = props.getErrorMessage()
  const selectedDate = props.getValue() || null

  function handleDateChange(date) {
    props.setValue(date)

    if (props.onChange) {
      props.onChange(date)
    }
  }

  return (
    <div>
      <DateInput value={selectedDate} onChange={handleDateChange} />
      {errorMessage && (
        <BodyText className="text-red-500 text-xs px-2 pt-1" weight="semibold">
          {errorMessage}
        </BodyText>
      )}
    </div>
  )
}

export default withFormsy(DatePickerFormsy)
