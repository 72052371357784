import { CircularProgress } from "@material-ui/core"

import { ReactComponent as PrintBlueIcon } from "app/assets/images/print-blue.svg"
import useOpenRequisition from "app/dashboard/hooks/use-open-requisition"
import useEventCallback from "app/hooks/use-event-callback"
import { PatientOrderEvent } from "app/main/patient-orders/constants"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardLabCompany } from "types/dashboard/dashboard-lab-company"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardPatient } from "types/dashboard/dashboard-patient"
import { SpecimenIssueNormalized } from "types/specimen-issue"
import { denormalizeResource } from "types/utils"

import ActionButton from "./ActionButton"

export interface ViewRequisitionActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function ViewRequisitionAction({
  kit,
  order,
}: ViewRequisitionActionProps) {
  const labCompany = useCachedResource<DashboardLabCompany>(
    kit.relationships.lab_company.data
  )
  const specimenIssue = useCachedResource<SpecimenIssueNormalized>(
    kit.relationships.specimen_issue?.data
  )

  const patient = useCachedResource<DashboardPatient>(
    order.relationships.patient.data
  )

  const { disabled, loading, openRequisition } = useOpenRequisition({
    sampleCollectionCoordinator: kit.attributes.sample_collection_coordinator,
    order: denormalizeResource(order),
    patient: denormalizeResource(patient),
    labCompany: denormalizeResource(labCompany),
    orderedTestId: kit.relationships.primary_ordered_test?.data.id,
    requisitionUrl: kit.attributes.requisition_url,
    recollectionRequisitionUrl:
      specimenIssue?.attributes.recollection_requisition,
  })

  const onClick = useEventCallback(async () => {
    openRequisition()

    trackPractitionerDashboardAction(
      PatientOrderEvent.DOWNLOAD_REQUISTION,
      order.relationships.practitioner?.data.id as string
    )
  })

  return (
    <ActionButton
      disabled={disabled}
      onClick={onClick}
      tooltipTitle={
        disabled ? "No Digital Requisition Available" : "View Requisition"
      }
    >
      {loading ? (
        <CircularProgress size={15} aria-label="Loading the requisition form" />
      ) : (
        <PrintBlueIcon
          fill="currentColor"
          height="100%"
          width="100%"
          viewBox="0 0 20 19"
        />
      )}
    </ActionButton>
  )
}
