import { TextField as MuiTextField } from "@material-ui/core"

const TextField = (props) => (
  <MuiTextField
    {...props}
    inputProps={{
      ...props.inputProps,
      "aria-label": props.label,
    }}
  />
)

export default TextField
