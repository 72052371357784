import { makeStyles, Theme } from "@material-ui/core"

import DesignSystemButton from "app/components/design-system/Button"
import ClipboardContainer from "app/components/modals/PhysicianServicesOptInModal/ClipboardContainer"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { colors, navy } from "app/theme"
import { getOrderPatientLabel } from "app/utils/order-utils"

const styles = (theme: Theme) => ({
  continueButton: {
    marginTop: "32px",
    marginBottom: "72px",
    width: 500,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
  },
  separator: {
    height: 1,
    backgroundColor: colors.coolGray[200],
    marginTop: 16,
    marginBottom: 16,
  },
  mainText: {
    paddingLeft: 16,
    paddingRight: 16,
    color: navy,
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    textAlign: "left" as "left",
  },
  listItem: {
    marginBottom: 16,
    display: "flex",
  },
  bulletPoint: {
    marginLeft: 8,
    marginRight: 8,
  },
  title: {
    marginBottom: 16,
    fontWeight: 600,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  onSuccess: () => void
}

/**
 * How it works step for Physician Authorization Application Modal
 * @param onSuccess - Action to take on step submit
 */
const WhatIsStep = ({ onSuccess }: Props) => {
  const classes = useStyles(styles)

  const patientLabel = getOrderPatientLabel(true).toLowerCase()

  return (
    <div>
      <ClipboardContainer
        title={`What is ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}?`}
      >
        <div className={classes.mainText}>
          <div>
            With {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, you're partnering with
            a physician who can order labs for your {patientLabel}s. You can
            request a physician to order a set of labs and review results for
            any critical values. <br />
            <br />
            You and your {patientLabel} will receive results at the same time.
            In the case of a critical result, the signing physician will reach
            out to your {patientLabel}, and the Rupa team will let you know.
            <br />
            <br />
            There's a $10 fee per test or panel when using{" "}
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.
            <br />
          </div>
          <div className={classes.separator}></div>
          <div className={classes.title}>
            What isn't included with {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}?
          </div>
          <div className={classes.listItem}>
            <div className={classes.bulletPoint}>•</div>
            <div>
              Medicare, Superbills, In-Office Kits, and 1:1 consultations with
              the labs are unavailable with this service
            </div>
          </div>
          <br />
        </div>
      </ClipboardContainer>
      <DesignSystemButton
        color="primary"
        onClick={onSuccess}
        className={classes.continueButton}
      >
        Got It. Next!
      </DesignSystemButton>
    </div>
  )
}

export default WhatIsStep
