import { OutlinedInput, Typography, withStyles } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { cn } from "@rupahealth/design"

import Tooltip from "app/components/Tooltip"

import { SelectFormsy, TextFieldFormsy } from "../../../@fuse/components/formsy"
import { veryLightGray } from "../../theme"
import AutocompleteFormsy from "../formsy/AutocompleteFormsy"
import DatePickerFormsy from "../formsy/DatePickerFormsy"

const useInputStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "white",
    fontSize: 14,
    padding: 12,
  },
  multiline: {
    padding: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  labelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 2,
  },
}))

const useStylesAutocomplete = makeStyles((theme) => ({
  input: {
    backgroundColor: veryLightGray,
    fontSize: 14,
    padding: "1px !important",
  },
}))

export const FormFieldLabel = ({ label, labelClass = "", required = true }) => (
  <div className="mb-1 fs-unmask">
    <Typography
      color="textPrimary"
      className={cn("font-semibold inline", labelClass)}
    >
      {label}
    </Typography>
    {required && (
      <Typography color="error" className="font-semibold inline">
        {" *"}
      </Typography>
    )}
  </div>
)

export const FormTextField = ({
  name,
  label,
  helperText,
  children,
  type,
  tooltipTitle = null,
  secondaryElement = null,
  required = true,
  disabled = false,
  ...props
}) => {
  const { labelRow } = useStyles({})
  const classes = useInputStyles({})

  const disableTooltip = !disabled || !tooltipTitle

  return (
    <div>
      <div className={labelRow}>
        <FormFieldLabel label={label} required={required} />
        {secondaryElement}
      </div>
      <Tooltip
        arrow
        interactive
        placement="bottom"
        disableHoverListener={disableTooltip}
        disableFocusListener={disableTooltip}
        disableTouchListener={disableTooltip}
        title={tooltipTitle ?? ""}
      >
        <div>
          <TextFieldFormsy
            className="fs-exclude"
            InputProps={{
              classes,
              inputProps: {
                "aria-label": label,
                style: {
                  padding: props.noPadding ? 0 : "auto",
                },
                ...props.inputProps,
              },
            }}
            type={type || "text"}
            name={name}
            required={required}
            fullWidth
            variant="outlined"
            disabled={disabled}
            {...props}
          >
            {children}
          </TextFieldFormsy>
        </div>
      </Tooltip>
    </div>
  )
}

export const AutocompleteFormField = ({
  name,
  label,
  options,
  value,
  getOptionLabel,
  freeSolo,
  groupBy,
  disabled,
  optionFromValue,
  valueFromOption,
}) => {
  const classes = useStylesAutocomplete({})

  return (
    <div>
      <FormFieldLabel label={label} />
      <AutocompleteFormsy
        InputProps={{ classes }}
        name={name}
        disabled={disabled}
        required
        fullWidth
        variant="outlined"
        options={options}
        value={value}
        getOptionLabel={getOptionLabel}
        optionFromValue={optionFromValue || ((value) => value)}
        valueFromOption={valueFromOption || ((option) => option)}
        groupBy={groupBy}
        freeSolo={freeSolo}
      />
    </div>
  )
}

export const FormDateField = ({ name, label }) => {
  const classes = useStyles({})

  return (
    <div>
      <FormFieldLabel label={label} />
      <DatePickerFormsy name={name} fullWidth InputProps={{ classes }} />
    </div>
  )
}

const SelectInput = withStyles((theme) => ({
  root: {
    backgroundColor: veryLightGray,
    fontSize: 14,
  },
  input: {
    padding: "10px 12px",
  },
}))(OutlinedInput)

export const SelectFormField = ({
  name,
  label,
  children,
  className,
  value,
  hideEmptyOption = false,
  labelClass = "",
  required = true,
  ...props
}) => {
  return (
    <div className={className}>
      <FormFieldLabel
        label={label}
        required={required}
        labelClass={labelClass}
      />
      <SelectFormsy
        className="fs-exclude w-full"
        input={<SelectInput />}
        name={name}
        required={required}
        label=""
        value={value}
        variant="outlined"
        native
        {...props}
        inputProps={{
          "aria-label": label,
        }}
      >
        {!hideEmptyOption && <option value="">Select</option>}
        {children}
      </SelectFormsy>
    </div>
  )
}
