import * as React from "react"

import { range } from "lodash"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { cn } from "@rupahealth/design"

import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { ComboGroupConfigurationPayload } from "app/main/combo-groups/constants"
import { AnyLimitedLabTest, Practitioner, labTestLocation } from "app/types"

import LabTestListDesktopRow from "./LabTestListDesktopRow"
import { SelectedLabTests } from "./types"

const HeaderCell = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => (
  <TableCell className={className}>
    <span className="font-semibold text-sm13 uppercase text-slate-500">
      {children}
    </span>
  </TableCell>
)

interface Props {
  practitioner?: Practitioner
  signingPractitioner?: Practitioner
  labTests: AnyLimitedLabTest[] | null
  setLabTestInModal: (labTest: AnyLimitedLabTest, index: number) => void
  onToggleFavorite: (labTest: AnyLimitedLabTest) => void
  isPending: boolean
  selectedLabTests?: SelectedLabTests
  onAddLabTest?: (labTest: AnyLimitedLabTest, index: number) => void
  onRemoveLabTest?: (labTest: AnyLimitedLabTest) => void
  location: labTestLocation
  createBundleClick?: () => void
  vendorPhysicianServicesActivated?: boolean
  onAddComboGroup?: (
    labTest: AnyLimitedLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
}

/*
 * Renders the list of lab tests for desktop sizes.
 */
function LabTestListDesktop({
  practitioner,
  signingPractitioner,
  labTests,
  setLabTestInModal,
  onToggleFavorite,
  isPending,
  selectedLabTests,
  onAddLabTest,
  onRemoveLabTest,
  location,
  createBundleClick,
  vendorPhysicianServicesActivated,
  onAddComboGroup,
}: Props) {
  const isAuthenticated = Boolean(practitioner)

  return (
    <div
      className="border border-slate-300 shadow overflow-hidden rounded-lg"
      aria-label="Lab tests"
    >
      <Table>
        <TableHead className="bg-slate-50">
          <TableRow>
            {isAuthenticated && <TableCell className="p-0 w-[2%]" />}
            <HeaderCell className="w-[35%]">Lab Test</HeaderCell>
            <HeaderCell>Sample Type</HeaderCell>
            <HeaderCell>
              <div className="flex items-center gap-1">
                Price{" "}
                <InfoTextTooltip className="inline-flex opacity-60">
                  Individual prices listed don’t include the 7% service fee.
                </InfoTextTooltip>
              </div>
            </HeaderCell>
            <TableCell
              className={cn("w-80", {
                "w-32": location === labTestLocation.CATALOG,
              })}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {labTests
            ? labTests.map((labTest, index) => (
                <LabTestListDesktopRow
                  key={labTest.id}
                  labTest={labTest}
                  practitioner={practitioner}
                  signingPractitioner={signingPractitioner}
                  onToggleFavorite={onToggleFavorite}
                  isPending={isPending}
                  setLabTestInModal={(labTest: AnyLimitedLabTest) => {
                    setLabTestInModal && setLabTestInModal(labTest, index)
                  }}
                  selectedLabTests={selectedLabTests}
                  onAddLabTest={(labTest: AnyLimitedLabTest) => {
                    onAddLabTest && onAddLabTest(labTest, index)
                  }}
                  onRemoveLabTest={onRemoveLabTest}
                  location={location}
                  createBundleClick={createBundleClick}
                  vendorPhysicianServicesActivated={
                    vendorPhysicianServicesActivated
                  }
                  onAddComboGroup={onAddComboGroup}
                />
              ))
            : range(5).map((number) => (
                <LabTestListDesktopRow
                  key={number}
                  isPending
                  location={location}
                  practitioner={practitioner}
                  createBundleClick={createBundleClick}
                  onAddComboGroup={onAddComboGroup}
                />
              ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default React.memo(LabTestListDesktop)
