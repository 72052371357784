import { useEffect, useMemo, useState } from "react"

import { faPerson } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress } from "@material-ui/core"

import TrendsContainer from "./TrendsContainer"
import TrendsDisclaimer from "./components/TrendsDisclaimer"
import BiomarkerSearchBar from "./filter-components/BiomarkerSearchBar"
import BodySystemFilter from "./filter-components/BodySystemFilter"
import InRangeFilter from "./filter-components/InRangeFilter"
import TimeSeriesGroupingToggle from "./filter-components/TimeSeriesGroupingToggle"
import useAvailableTrendsDates from "./hooks/use-available-results-over-time-dates"
import useBodySystems from "./hooks/use-body-systems"
import useTrendsOptimalRanges from "./hooks/use-trends-optimal-ranges"
import {
  DropDownOption,
  InRangeOption,
  ResultsOverTimeDateGrouping,
} from "./types/types"

const BODY_SYSTEM_ALL = "all"

const BODY_SYSTEM_OPTION_STARTER: DropDownOption[] = [
  {
    key: BODY_SYSTEM_ALL,
    label: <>Body System</>,
    icon: (
      <FontAwesomeIcon
        className="text-slate-500 text-[14px]"
        fill="currentColor"
        icon={faPerson}
      />
    ),
  },
]

interface Props {
  userId: string
  onLoading: (loading: boolean) => void
  onEmpty: (empty: boolean) => void
  onOptimalRanges: (optimalRanges: string[]) => void
  showHighLowDescriptions: boolean
  enableContainerAroundTable?: boolean
}

const Trends = ({
  userId,
  onEmpty,
  onLoading,
  onOptimalRanges,
  showHighLowDescriptions,
  enableContainerAroundTable,
}: Props) => {
  const [timeSeriesGrouping, setTimeSeriesGrouping] =
    useState<ResultsOverTimeDateGrouping>(ResultsOverTimeDateGrouping.MONTH)
  const [inRangeValue, setInRangeValue] = useState<InRangeOption>(
    InRangeOption.ALL
  )
  const [bodySystemId, setBodySystemId] = useState<string>(BODY_SYSTEM_ALL)
  const [biomarkerSearchValue, setBiomarkerSearchValue] = useState<string>("")

  const {
    bodySystemsForDropdown,
    bodySystemsForDropdownError,
    bodySystemsForTable,
    bodySystemsForTableLoading,
    bodySystemsForTableValidating,
    bodySystemsForTableError,
  } = useBodySystems(userId)

  const {
    availableDates,
    error: availableDatesError,
    isLoading: isAvailableDatesLoading,
    isValidating: isAvailableDatesValidating,
  } = useAvailableTrendsDates(
    userId,
    timeSeriesGrouping,
    inRangeValue,
    bodySystemId
  )

  const {
    data: optimalRanges,
    error: optimalRangesError,
    isLoading: isOptimalRangesLoading,
    isValidating: isOptimalRangesValidating,
  } = useTrendsOptimalRanges(
    userId,
    timeSeriesGrouping,
    inRangeValue,
    bodySystemId
  )

  const buildBodySystemOptions = () => {
    return bodySystemsForDropdown.map((bodySystem) => {
      return {
        key: bodySystem.id,
        label: <>{bodySystem.attributes.name}</>,
        icon: <img src={bodySystem.attributes.logo} alt="" width={15} />,
      }
    })
  }

  const availableBodySystemOptions = useMemo(() => {
    const newBodySystems = buildBodySystemOptions()
    return [...BODY_SYSTEM_OPTION_STARTER, ...newBodySystems]
  }, [bodySystemsForDropdown])

  // We want to clear out the search query when the body system or in range dropdowns change
  useEffect(() => {
    setBiomarkerSearchValue("")
  }, [bodySystemId, inRangeValue])

  useEffect(() => {
    onOptimalRanges(optimalRanges || [])
  }, [optimalRanges])

  useEffect(() => {
    onLoading(
      bodySystemsForTableLoading ||
        isAvailableDatesLoading ||
        bodySystemsForTableValidating ||
        isAvailableDatesValidating ||
        isOptimalRangesLoading ||
        isOptimalRangesValidating
    )
  }, [
    bodySystemsForTableLoading,
    isAvailableDatesLoading,
    bodySystemsForTableValidating,
    isAvailableDatesValidating,
    isOptimalRangesLoading,
    isOptimalRangesValidating,
  ])

  useEffect(() => {
    const showEmptyState =
      !Boolean(availableDates?.length) &&
      !bodySystemsForTableLoading &&
      !isAvailableDatesLoading &&
      inRangeValue === InRangeOption.ALL &&
      bodySystemId === BODY_SYSTEM_ALL &&
      biomarkerSearchValue === ""
    onEmpty(showEmptyState)
  }, [
    availableDates,
    bodySystemsForTableLoading,
    isAvailableDatesLoading,
    inRangeValue,
    bodySystemId,
    biomarkerSearchValue,
  ])

  // TODO: Get designs from Jordan on what this behavior should look like: https://linear.app/rupa-health/issue/PROD-664/handle-results-over-time-api-errors
  if (
    bodySystemsForTableError ||
    bodySystemsForDropdownError ||
    availableDatesError ||
    optimalRangesError
  ) {
    return <div>Something went wrong</div>
  }

  return (
    <div className="flex w-full flex-col gap-7">
      <div className="flex flex-col lg:flex-row gap-3 lg:gap-2 lg:items-center">
        <BiomarkerSearchBar
          handleBiomarkerSearch={(value) =>
            setBiomarkerSearchValue(value.toLowerCase())
          }
          biomarkerSearchValue={biomarkerSearchValue}
        />

        <BodySystemFilter
          availableBodySystemOptions={availableBodySystemOptions}
          bodySystemId={bodySystemId}
          handleBodySystemIdChange={setBodySystemId}
        />

        <InRangeFilter
          inRangeValue={inRangeValue}
          handleInRangeValueChange={setInRangeValue}
        />

        <TimeSeriesGroupingToggle
          currTimeSeriesGrouping={timeSeriesGrouping}
          handleSetTimeSeriesGrouping={setTimeSeriesGrouping}
        />
      </div>

      {Boolean(bodySystemsForTableLoading) && (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )}

      {Boolean(bodySystemsForTable.length) && !bodySystemsForTableLoading && (
        <div
          className={
            enableContainerAroundTable ? "bg-white p-3 pl-5 rounded-lg" : ""
          }
        >
          <TrendsContainer
            primaryBodySystems={bodySystemsForTable}
            availableDates={availableDates || []}
            userId={userId}
            timeSeriesGrouping={timeSeriesGrouping}
            inRangeValue={inRangeValue}
            bodySystemId={bodySystemId}
            isAvailableDatesLoading={isAvailableDatesLoading}
            biomarkerSearchValue={biomarkerSearchValue}
            showHighLowDescriptions={showHighLowDescriptions}
          />
        </div>
      )}
      {Boolean(bodySystemsForTable.length) && !bodySystemsForTableLoading && (
        <TrendsDisclaimer />
      )}
    </div>
  )
}

export default Trends
