import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { Location } from "history"
import { useHistory } from "react-router-dom"

import { SearchSuggestion } from "app/types"

import {
  SEARCH_TEXT_SUGGESTION_TYPES,
  SEARCH_TEXT_PLACEHOLDER,
} from "../state/autosuggest.reducer"
import useSearchSuggestions, {
  SearchSuggestionsState,
} from "./use-search-suggestions"

const SMART_ORDERING_BLOCKLIST = ["hormone"]

export interface UseAutoSuggestProps {
  /**
   * The debounce delay. Defaults to 150ms if not provided.
   */
  debounceDelay?: number
  /**
   * Callback used to get the location with the given search param, e.g. `/discover-labs/search?search=${search}`.
   * If provided, the search and suggestion actions will render as links instead of buttons.
   *
   * @return the location with the search param
   */
  getSearchLocation?: (search?: string) => Location<unknown>
  /**
   * Callback used to get the location with the given suggestion param, e.g. `/discover-labs/search?${suggestion.type}=${suggestion.object_id}|${suggestion.label}`.
   * If provided, the search and suggestion actions will render as links instead of buttons.
   *
   * @return the location with the suggestion param
   */
  getSuggestionLocation?: (suggestion: SearchSuggestion) => Location<unknown>
  /**
   * A limit of the maximum number of suggestions to include. Defaults to 15.
   */
  limit?: number
  /**
   * Callback called when the search is selected by the user. If not provided, will fallback on redirecting to {@link getSearchLocation}.
   */
  onSearchSelect?: (search?: string) => void
  /**
   * Callback called when a suggestion is selected by the user. If not provided, will fallback on redirecting to {@link getSuggestionLocation}.
   */
  onSuggestionSelect?: (suggestion: SearchSuggestion) => void
  /**
   * The current search text.
   */
  search?: string
  /**
   * Whether or not catalog search relevancy updates are enabled
   */
  isCatalogSearchRelevancyUpdatesEnabled?: boolean
}

export interface UseAutoSuggestHook {
  /**
   * Callback used to get the location with the given search param, e.g. `/discover-labs/search?search=${search}`.
   * If provided, the search and suggestion actions will render as links instead of buttons.
   *
   * @return the location with the search param
   */
  getSearchLocation?: (search?: string) => Location<unknown>
  /**
   * Callback used to get the location with the given suggestion param, e.g. `/discover-labs/search?${suggestion.type}=${suggestion.object_id}|${suggestion.label}`.
   * If provided, the search and suggestion actions will render as links instead of buttons.
   *
   * @return the location with the suggestion param
   */
  getSuggestionLocation?: (suggestion: SearchSuggestion) => Location<unknown>
  highlightedSuggestion?: SearchSuggestion
  moveCursorDown: () => void
  moveCursorToSuggestion: (suggestion: SearchSuggestion) => void
  moveCursorToSearch: () => void
  moveCursorUp: () => void
  open: boolean
  search?: string
  selectCursor: () => void
  selectSearch: (search?: string) => void
  selectSuggestion: (suggestion: SearchSuggestion) => void
  setOpen: Dispatch<SetStateAction<boolean>>
  smartOrdering: boolean
  state: SearchSuggestionsState
}

export default function useAutoSuggest({
  debounceDelay = 150,
  getSearchLocation,
  getSuggestionLocation,
  limit = 7,
  onSearchSelect,
  onSuggestionSelect,
  search,
  isCatalogSearchRelevancyUpdatesEnabled,
}: UseAutoSuggestProps) {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [cursor, setCursor] = useState<number>(0)

  // ensures the auto suggest is open while the user is searching
  useEffect(() => {
    setCursor(0)
    setOpen(!!search)
  }, [search])

  const state = useSearchSuggestions(search, { debounceDelay, limit })

  const nonSearchTextSuggestions = state.rawSuggestions?.filter(
    (suggestion) => {
      return !SEARCH_TEXT_SUGGESTION_TYPES.includes(suggestion?.type)
    }
  )

  // If there is a search text match that is significantly better than the non-search text match, we should use the search text match
  // and not do smart ordering.
  const searchTextBetterMatch =
    state.rawSuggestions?.[0]?.score &&
    nonSearchTextSuggestions?.[0]?.score &&
    nonSearchTextSuggestions?.[0] !== state.rawSuggestions?.[0] &&
    state.rawSuggestions?.[0]?.score - nonSearchTextSuggestions?.[0]?.score >
      0.3

  const smartOrdering = Boolean(
    isCatalogSearchRelevancyUpdatesEnabled &&
      search?.length &&
      search?.length > 3 &&
      !SMART_ORDERING_BLOCKLIST.includes(search.toLowerCase()) &&
      nonSearchTextSuggestions.length > 0 &&
      nonSearchTextSuggestions[0].score &&
      nonSearchTextSuggestions[0].score > 0.2 &&
      !searchTextBetterMatch
  )

  let orderedSuggestions: SearchSuggestion[] = []

  // Did not use useMemo for this as it was going to slow for typing fast
  if (search?.length && search?.length > 0 && state.rawSuggestions.length > 0) {
    if (smartOrdering) {
      orderedSuggestions = [
        ...[
          ...new Set(state.rawSuggestions.map((suggestion) => suggestion.type)),
        ].reduce<SearchSuggestion[]>((result, type) => {
          const suggestionsForType = state.filterSuggestionsByType[type] || []

          result.push(...suggestionsForType)

          return result
        }, []),
        {
          type: SEARCH_TEXT_PLACEHOLDER, // this is a placeholder to represent the search text
          label: search,
          score: 0,
        } as SearchSuggestion,
        ...state.searchTextSuggestions,
      ]
    } else {
      orderedSuggestions = [
        {
          type: SEARCH_TEXT_PLACEHOLDER, // this is a placeholder to represent the search text
          label: search,
          score: 0,
        } as SearchSuggestion,
        ...state.searchTextSuggestions,
        ...state.filterSuggestionTypes.reduce<SearchSuggestion[]>(
          (result, type) => {
            const suggestionsForType = state.filterSuggestionsByType[type] || []

            result.push(...suggestionsForType)

            return result
          },
          []
        ),
      ]
    }
  }

  const highlightedSuggestion = useMemo(() => {
    if (cursor >= orderedSuggestions.length) {
      return undefined
    }

    // return the suggestion at the index
    return orderedSuggestions[cursor]
  }, [cursor, orderedSuggestions])

  const selectSearch = useCallback(
    (searchText?: string) => {
      setOpen(false)

      if (onSearchSelect) {
        onSearchSelect(searchText)
      } else if (getSearchLocation) {
        history.push(getSearchLocation(searchText))
      }
    },
    [getSearchLocation, history.push, onSearchSelect]
  )

  const selectSuggestion = useCallback(
    (suggestion: SearchSuggestion) => {
      setOpen(false)

      if (onSuggestionSelect) {
        onSuggestionSelect(suggestion)
      } else if (getSuggestionLocation) {
        history.push(getSuggestionLocation(suggestion))
      }
    },
    [getSuggestionLocation, history.push, onSuggestionSelect]
  )

  const selectCursor = useCallback(() => {
    if (highlightedSuggestion) {
      if (SEARCH_TEXT_SUGGESTION_TYPES.includes(highlightedSuggestion.type)) {
        selectSearch(highlightedSuggestion.label)
      } else {
        selectSuggestion(highlightedSuggestion)
      }
    } else {
      selectSearch(search)
    }
  }, [
    highlightedSuggestion,
    search,
    selectSearch,
    selectSuggestion,
    state.searchTextSuggestions,
  ])

  const moveCursorUp = useCallback(() => {
    if (state.typing || state.loading) {
      // skip while the user is typing or we are waiting for results
      return
    }

    if (!open) {
      setOpen(!!search)
    } else {
      setCursor((prevIndex) => {
        if (prevIndex === 0) {
          return state.suggestionsCount || 0
        }
        return prevIndex - 1
      })
    }
  }, [state.loading, open, search, state.suggestionsCount, state.typing])

  const moveCursorDown = useCallback(() => {
    if (state.typing || state.loading) {
      // skip while the user is typing or we are waiting for results
      return
    }

    if (!open) {
      setOpen(!!search)
    } else {
      setCursor((prevIndex) => {
        if (!state.suggestionsCount || prevIndex === state.suggestionsCount) {
          return 0
        }
        return prevIndex + 1
      })
    }
  }, [state.loading, open, search, state.suggestionsCount, state.typing])

  const moveCursorToSearch = useCallback(
    () =>
      setCursor(
        orderedSuggestions.findIndex(
          (suggestion) => suggestion.type === SEARCH_TEXT_PLACEHOLDER
        )
      ),
    [orderedSuggestions]
  )

  const moveCursorToSuggestion = useCallback(
    (suggestion: SearchSuggestion) => {
      const suggestionIndex = orderedSuggestions.indexOf(suggestion)

      setCursor(suggestionIndex)
    },
    [orderedSuggestions]
  )

  return {
    getSearchLocation,
    getSuggestionLocation,
    highlightedSuggestion,
    moveCursorDown,
    moveCursorToSearch,
    moveCursorToSuggestion,
    moveCursorUp,
    open,
    search,
    selectCursor,
    selectSearch,
    selectSuggestion,
    setOpen,
    setCursor,
    smartOrdering,
    state,
  }
}
