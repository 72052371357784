import { isRequisitionAvailable } from "app/dashboard/hooks/use-open-requisition"
import { denormalizeResource } from "types/utils"

import { STATUS_CONFIGURATION_TYPES } from "./consts"
import { UnionStatusConfigurationProps } from "./types/types"

export function maybeSetRequisitionAvailable<
  T extends UnionStatusConfigurationProps
>(props: T): T {
  if (props.type === STATUS_CONFIGURATION_TYPES.DASHBOARD) {
    const { canDownload, canGenerate } = isRequisitionAvailable({
      sampleCollectionCoordinator:
        props.kit.attributes.sample_collection_coordinator,
      order: denormalizeResource(props.order),
      patient: denormalizeResource(props.patient),
      labCompany: denormalizeResource(props.labCompany),
      requisitionUrl: props.kit.attributes.requisition_url,
      recollectionRequisitionUrl: props.specimenIssue?.recollection_requisition,
    })
    return { ...props, requisitionAvailable: canDownload || canGenerate }
  } else if (props.type === STATUS_CONFIGURATION_TYPES.PATIENT_ORDERS) {
    const { canDownload, canGenerate } = isRequisitionAvailable({
      sampleCollectionCoordinator:
        props.orderedTest.sample_collection_coordinator,
      order: props.order,
      patient: props.patient,
      labCompany: props.orderedTest.lab_test.lab_company,
      requisitionUrl: props.orderedTest.requisition,
      recollectionRequisitionUrl:
        props.orderedTest.specimen_issue?.recollection_requisition,
    })
    return { ...props, requisitionAvailable: canDownload || canGenerate }
  }

  return props
}
