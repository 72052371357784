import { ORDER_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ kit }: StatusConfigurationProps) => {
  if (kit.attributes.has_unavailable_test) {
    return (
      <span>
        {`This has been canceled due to ${kit.attributes.display_name} being unavailable.`}
      </span>
    )
  }

  return undefined
}

const canceledStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderStatus: ORDER_STATUS.CANCELED },
    progressPercentage: PROGRESS_PERCENTAGES.CANCELED,
    title: "Order Canceled",
    subTitle: SubTitle,
  }

export default canceledStatusConfiguration
