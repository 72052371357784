import * as React from "react"

import { NavyTooltip } from "app/components/NavyTooltip"
import Button from "app/components/design-system/Button"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    width: 36,
    padding: "5px 8px",
    minWidth: 0,
    "&:disabled": {
      opacity: 0.5,
    },
  },
}))

export type ActionButtonProps = {
  children: NonNullable<React.ReactNode>
  disabled?: boolean
  tooltipTitle: NonNullable<React.ReactNode>
} & React.ComponentProps<typeof Button>

export default React.forwardRef<HTMLButtonElement, ActionButtonProps>(
  function ActionButton(
    { children, disabled, tooltipTitle, ...buttonProps },
    ref
  ) {
    const classes = useStyles()
    return (
      <NavyTooltip arrow placement="top" title={tooltipTitle}>
        <div>
          <Button
            color="secondary"
            className={classes.button}
            disabled={disabled}
            ref={ref}
            {...buttonProps}
          >
            {children}
          </Button>
        </div>
      </NavyTooltip>
    )
  }
)
