import React, { useEffect, useMemo, useState } from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { AlertDialog, AlertDialogContent, cn } from "@rupahealth/design"

import Step1Image from "app/assets/images/catalog-welcome-modal-step-1.jpg"
import Step2Image from "app/assets/images/catalog-welcome-modal-step-2.gif"
import LoadingWrapper from "app/components/LoadingWrapper"
import RupaLoadingButton from "app/components/RupaLoadingButton"
import useFeatureFlag from "app/hooks/use-feature-flag"
import usePreloadImages from "app/hooks/use-preload-image"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import {
  FeaturePromotions,
  useFeaturePromotion,
} from "app/providers/FeaturePromotionProvider"

interface Props {
  onComplete: () => Promise<void>
}

interface ModalStep {
  step: number
  title: string
  description: string
  image: string
  buttonText: string
}

const steps: ModalStep[] = [
  {
    step: 0,
    image: Step1Image,
    title: "Welcome to the Catalog",
    description:
      "Search for and compare over 3000+ lab tests from 35+ lab companies.",
    buttonText: "Continue",
  },
  {
    step: 1,
    image: Step2Image,
    title: "Choose a Signing Practitioner to filter available labs.",
    description:
      "Lab test access is determined by the Signing Practitioner’s credentials.",
    buttonText: "Got it!",
  },
]

const CatalogWelcomeModal: React.FC<Props> = ({ onComplete }) => {
  const modal = useModal()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const imagesLoaded = usePreloadImages(steps.map((s) => s.image))
  const { image, title, description, buttonText } = useMemo(() => {
    return steps[currentStepIndex]
  }, [currentStepIndex])

  const onOpenChange = async (open: boolean) => {
    if (!open) {
      setIsSubmitting(true)
      await onComplete()
      setIsSubmitting(false)
      modal.hide()
    }
  }

  const advanceStep = () => {
    setCurrentStepIndex((prev) => {
      const newIdx = prev + 1
      if (newIdx >= steps.length) {
        onOpenChange(false)
        return prev
      }
      return newIdx
    })
  }

  return (
    <AlertDialog open={modal.visible}>
      <AlertDialogContent className="bg-slate-100 p-0 max-h-[90vh] overflow-y-auto max-w-[90vw] sm:max-w-lg border-[4px]">
        <LoadingWrapper loading={!imagesLoaded} className="h-60">
          <div className="h-full">
            <img
              alt={title}
              src={image}
              className="border-b border-slate-200 w-full rounded-t-lg"
            />
            <div className="py-6 px-8 flex flex-col gap-[27px] text-center">
              <div className="flex flex-col gap-[6px]">
                <p className="text-2xl font-semibold font-title">{title}</p>
                <p className="text-base15">{description}</p>
              </div>
              <RupaLoadingButton
                variant="primary"
                className="w-full shadow-sm py-[10px] text-base15 font-semibold text-white transition-colors duration-200"
                wrapperClassName="w-full"
                onClick={advanceStep}
                loading={isSubmitting}
              >
                {buttonText}
              </RupaLoadingButton>

              <div className="flex justify-center space-x-[5px]">
                {steps.map((_, idx) => (
                  <button
                    key={`dot-${idx}`}
                    className={cn("w-2 h-2 rounded-full bg-slate-300", {
                      "bg-primary": idx === currentStepIndex,
                    })}
                    onClick={() => setCurrentStepIndex(idx)}
                  />
                ))}
              </div>
            </div>
          </div>
        </LoadingWrapper>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const CatalogWelcomeNiceModal = NiceModal.create<Props>((props) => {
  return <CatalogWelcomeModal {...props} />
})

/**
 * Shows the catalog welcome modal if the current user is a practitioner
 * with the feature promotion that has not dismissed it yet
 */
export function useCatalogWelcomeModal() {
  const modal = useModal(CatalogWelcomeNiceModal)
  const { isActive, onAcknowledge } = useFeaturePromotion(
    FeaturePromotions.CatalogWelcomeModal
  )
  const [showSigningPractitionerFilter] = useFeatureFlag(
    FeatureFlag.CatalogSigningPractitionerFilter
  )

  useEffect(() => {
    if (showSigningPractitionerFilter && isActive && !modal.visible) {
      modal.show({ onComplete: onAcknowledge })
    }
  }, [showSigningPractitionerFilter, isActive, modal.visible, onAcknowledge])
}
