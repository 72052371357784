import { isEmpty } from "lodash"

import { ReactComponent as DownloadBlueWithNotificationIcon } from "app/assets/icons/dashboard/download-with-notification.svg"
import { ReactComponent as DownloadBlueIcon } from "app/assets/images/download-blue.svg"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"

import ActionDropdownMenuButton from "./ActionDropdownMenuButton"
import DownloadResultsDropdownMenuAction from "./DownloadResultsDropdownMenuAction"
import ResultsSummaryDropdownMenuAction from "./ResultsSummaryDropdownMenuAction"

export interface DownloadResultsActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function DownloadResultsAction({
  kit,
  order,
}: DownloadResultsActionProps) {
  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )

  const { downloadable_result_urls } = kit.attributes
  const hasResultsToDownload = !isEmpty(downloadable_result_urls)
  const hasAnyUnreadNotification =
    latestOrderedResult?.attributes.has_unread_notification

  return (
    <ActionDropdownMenuButton
      disabled={!hasResultsToDownload}
      buttonProps={{
        "data-cy": "download-button",
      }}
      icon={
        hasAnyUnreadNotification ? (
          <DownloadBlueWithNotificationIcon
            data-cy="download-button-notification"
            fill="currentColor"
            viewBox="0 0 20 23"
          />
        ) : (
          <DownloadBlueIcon
            data-cy="download-button-cleared"
            fill="currentColor"
            viewBox="0 0 18 17"
          />
        )
      }
      tooltipTitle={
        !hasResultsToDownload ? (
          <>
            Download Results <br />
            (Not Available Yet)
          </>
        ) : (
          "Download Results"
        )
      }
    >
      <DownloadResultsDropdownMenuAction kit={kit} order={order} />

      <ResultsSummaryDropdownMenuAction kit={kit} order={order} />
    </ActionDropdownMenuButton>
  )
}
