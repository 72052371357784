import { useState } from "react"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
} from "@material-ui/core"

import { ReactComponent as RightCarrotIcon } from "app/assets/icons/labshops/carrot-right.svg"
import ClipboardNavyIcon from "app/assets/icons/rupa-blood-dashboards/clipboard-navy.svg"
import ClipboardYellowIcon from "app/assets/icons/rupa-blood-dashboards/clipboard-yellow.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

import { BiomarkerStatus } from "../constants"

const useStyles = makeAppStyles((theme: Theme) => ({
  warningDescriptionCard: {
    background: colors.yellow[50],
    border: `1.517px solid ${colors.yellow[200]}`,
    borderRadius: 9,
    display: "flex",
    flexDirection: "column",
    gap: 15,
    padding: 13,
    marginTop: 16,
  },
  optimalWarningDescriptionCard: {
    background: colors.blueGray[50],
    border: `1.517px solid ${colors.blueGray[200]}`,
    borderRadius: 9,
    display: "flex",
    flexDirection: "column",
    gap: 15,
    padding: 13,
    marginTop: 16,
    height: "auto",
  },
  yellowText: {
    color: colors.yellow[800],
  },
  highLowArrow: {
    color: colors.yellow[800],
    fontSize: 16,
    fontWeight: 700,
  },
  highLowOptimalArrow: {
    color: navy,
    fontSize: 16,
    fontWeight: 700,
  },
  iconContainer: {
    float: "left",
    marginRight: 7,
    height: 16,
  },
  symptomsIcon: {
    width: 13,
  },
  optimalTitle: {
    fontSize: 15,
    fontWeight: 600,
    color: navy,
  },
  navyText: {
    color: navy,
  },
  toggleBtn: {
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      display: "inline-block",
      "& .MuiButton-endIcon": {
        display: "inline-block",
        position: "relative",
        top: 3,
      },
    },
  },
  toggleAccordion: {
    background: "transparent",
    boxShadow: "none",
    color: primaryColor,
    fontSize: 15,
    fontWeight: 600,
    margin: 0,
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "&:before": {
      backgroundColor: "transparent",
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      display: "block",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      marginBottom: 0,
      display: "inline-block",
    },
  },
  arrowIcon: {
    position: "relative",
    top: 1,
    transform: "rotate(90deg)",
    marginLeft: 7,
    transition: "transform 0.2s ease-in-out",
  },
  italicizedBtnText: {
    fontStyle: "italic",
    marginLeft: 3,
    marginRight: 3,
    display: "inline-block",
  },
  accordionContentContainer: {
    marginTop: 12,
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
}))

const OUT_OF_RANGE_STATUSES = [BiomarkerStatus.HIGH, BiomarkerStatus.LOW]
const SUB_OPTIMAL_STATUSES = [
  BiomarkerStatus.ABOVE_OPTIMAL,
  BiomarkerStatus.BELOW_OPTIMAL,
]

interface Props {
  biomarkerStatus: BiomarkerStatus
  biomarkerCustomDescription?: BiomarkerCustomDescription
  biomarkerShortName?: string
  biomarkerLowDescription?: string
  biomarkerHighDescription?: string
  biomarkerLowSymptoms?: string
  biomarkerHighSymptoms?: string
}

const HighLowDescriptionSymptomCard = ({
  biomarkerStatus,
  biomarkerCustomDescription,
  biomarkerShortName,
  biomarkerLowDescription,
  biomarkerHighDescription,
  biomarkerLowSymptoms,
  biomarkerHighSymptoms,
}: Props) => {
  const classes = useStyles()

  let highLowDescriptionText = ""
  let highLowDescriptionIcon = <></>
  let highLowSymptomText = ""
  if (
    biomarkerStatus === BiomarkerStatus.LOW ||
    biomarkerStatus === BiomarkerStatus.BELOW_OPTIMAL
  ) {
    if (biomarkerCustomDescription?.attributes.low_description) {
      highLowDescriptionText =
        biomarkerCustomDescription.attributes.low_description
    } else if (biomarkerLowDescription) {
      highLowDescriptionText = biomarkerLowDescription
    }

    highLowDescriptionIcon = (
      <span
        className={
          OUT_OF_RANGE_STATUSES.includes(biomarkerStatus)
            ? classes.highLowArrow
            : classes.highLowOptimalArrow
        }
      >
        ▼
      </span>
    )

    if (biomarkerLowSymptoms) {
      highLowSymptomText = biomarkerLowSymptoms
    }
  } else if (
    biomarkerStatus === BiomarkerStatus.HIGH ||
    biomarkerStatus === BiomarkerStatus.ABOVE_OPTIMAL
  ) {
    if (biomarkerCustomDescription?.attributes.high_description) {
      highLowDescriptionText =
        biomarkerCustomDescription.attributes.high_description
    } else if (biomarkerHighDescription) {
      highLowDescriptionText = biomarkerHighDescription
    }

    highLowDescriptionIcon = (
      <span
        className={
          OUT_OF_RANGE_STATUSES.includes(biomarkerStatus)
            ? classes.highLowArrow
            : classes.highLowOptimalArrow
        }
      >
        ▲
      </span>
    )

    if (biomarkerHighSymptoms) {
      highLowSymptomText = biomarkerHighSymptoms
    }
  }

  const isHighLowTextContentPresent =
    Boolean(highLowDescriptionText) || Boolean(highLowSymptomText)

  const [showOptimalContent, setShowOptimalContent] = useState(false)

  if (
    isHighLowTextContentPresent &&
    OUT_OF_RANGE_STATUSES.includes(biomarkerStatus)
  ) {
    return (
      <div className={classes.warningDescriptionCard}>
        {Boolean(highLowDescriptionText) && (
          <BodyText className={classes.yellowText}>
            {highLowDescriptionIcon} {highLowDescriptionText}
          </BodyText>
        )}
        {Boolean(highLowSymptomText) && (
          <div>
            <div className={classes.iconContainer}>
              <img
                src={ClipboardYellowIcon}
                alt="clipboard-icon"
                className={classes.symptomsIcon}
              />{" "}
            </div>

            <BodyText className={classes.yellowText}>
              {highLowSymptomText}
            </BodyText>
          </div>
        )}
      </div>
    )
  }

  if (
    isHighLowTextContentPresent &&
    SUB_OPTIMAL_STATUSES.includes(biomarkerStatus)
  ) {
    return (
      <div className={classes.optimalWarningDescriptionCard}>
        <BodyText className={classes.optimalTitle}>
          Your levels of {biomarkerShortName} are normal, but{" "}
          {biomarkerStatus.replace("_", " ")}
        </BodyText>
        <Accordion
          className={classes.toggleAccordion}
          expanded={showOptimalContent}
          onChange={() => setShowOptimalContent(!showOptimalContent)}
        >
          <AccordionSummary>
            <>
              See what
              <span className={classes.italicizedBtnText}>
                {biomarkerStatus === BiomarkerStatus.BELOW_OPTIMAL
                  ? "low"
                  : "high"}
              </span>
              levels of {biomarkerShortName} could mean if your levels were{" "}
              {biomarkerStatus === BiomarkerStatus.BELOW_OPTIMAL
                ? "below"
                : "above"}{" "}
              normal
              <RightCarrotIcon
                fill={primaryColor}
                width={8}
                height={13}
                viewBox="0 0 8 15"
                className={classes.arrowIcon}
                style={{
                  transform: `rotate(${showOptimalContent ? "-90" : "90"}deg)`,
                }}
              />
            </>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordionContentContainer}>
              {Boolean(highLowDescriptionText) && (
                <BodyText className={classes.navyText}>
                  {highLowDescriptionIcon} {highLowDescriptionText}
                </BodyText>
              )}
              {Boolean(highLowSymptomText) && (
                <div>
                  <div className={classes.iconContainer}>
                    <img
                      src={ClipboardNavyIcon}
                      alt="clipboard-icon"
                      className={classes.symptomsIcon}
                    />{" "}
                  </div>

                  <BodyText>{highLowSymptomText}</BodyText>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  return <></>
}

export default HighLowDescriptionSymptomCard
