import { faSquareQuestion } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import useTrendsAboutOptimalRangesModal from "../hooks/use-trends-about-optimal-ranges-modal"

const TrendsAboutOptimalRangesButton = ({
  optimalRanges,
  practitionerView = false,
}: {
  optimalRanges: string[]
  practitionerView?: boolean
}) => {
  const trendsAboutOptimalRangesModal = useTrendsAboutOptimalRangesModal()

  return (
    <Button
      variant={null}
      className="text-primary max-md:px-0 max-md:justify-start hover:bg-slate-100 py-1 px-2 h-auto text-[15px]"
      onClick={() => {
        trendsAboutOptimalRangesModal.show({
          onClose: () => trendsAboutOptimalRangesModal.remove(),
          optimalRanges: optimalRanges,
          practitionerView: practitionerView,
        })
      }}
    >
      <FontAwesomeIcon className="mr-2 text-lg" icon={faSquareQuestion} />{" "}
      Optimal Ranges
    </Button>
  )
}

export default TrendsAboutOptimalRangesButton
