import { Dispatch } from "redux"

import { API } from "app/api"
import { BUNDLE_TYPES } from "app/constants"
import {
  LabTest,
  LabTestBundleType,
  LabTestBundleWithoutPractitioner,
  LabTestBundleWithoutPractitionerAndWithLabTestIds,
  RootState,
} from "app/types"
import { handleApiError, handleApiSuccess } from "app/utils"

import { shareBundleWithClinic } from "./shareBundles.actions"

export const REQUEST_PRACTITIONER_BUNDLES = "[PRACTITIONER BUNDLES] REQUEST"
export const RECEIVE_PRACTITIONER_BUNDLES = "[PRACTITIONER BUNDLES] RECEIVE"
export const FAILED_PRACTITIONER_BUNDLES = "[PRACTITIONER BUNDLES] FAILED"
export const PRACTITIONER_BUNDLE_CREATED = "[PRACTITIONER BUNDLES] CREATED"
export const PRACTITIONER_BUNDLE_DELETED = "[PRACTITIONER BUNDLES] DELETED"
export const PRACTITIONER_BUNDLE_UPDATED = "[PRACTITIONER BUNDLES] UPDATED"

export interface PractitionerBundleAction<T = string> {
  type: T
}

export type RequestPractitionerBundlesAction = PractitionerBundleAction<
  typeof REQUEST_PRACTITIONER_BUNDLES
>
export interface FailedPractitionerBundlesAction
  extends PractitionerBundleAction<typeof FAILED_PRACTITIONER_BUNDLES> {
  payload: Error
}
export interface ReceivePractitionerBundlesAction
  extends PractitionerBundleAction<typeof RECEIVE_PRACTITIONER_BUNDLES> {
  payload: LabTestBundleWithoutPractitioner[]
}
export interface PractitionerBundleCreatedAction
  extends PractitionerBundleAction<typeof PRACTITIONER_BUNDLE_CREATED> {
  payload: LabTestBundleWithoutPractitioner
}
export interface PractitionerBundleDeletedAction
  extends PractitionerBundleAction<typeof PRACTITIONER_BUNDLE_DELETED> {
  payload: string
}
export interface PractitionerBundleUpdatedAction
  extends PractitionerBundleAction<typeof PRACTITIONER_BUNDLE_UPDATED> {
  payload: {
    id: string
    name: string
    lab_tests: LabTest[]
    is_shared_bundle: boolean
    is_favorited: boolean
    shared_by: string
    requested_biomarkers: string[]
    requested_biomarker_groupings: string[]
    bundle_type: LabTestBundleType
  }
}

export function requestPractitionerBundlesAction(): RequestPractitionerBundlesAction {
  return {
    type: REQUEST_PRACTITIONER_BUNDLES,
  }
}

export function failedPractitionerBundlesAction(
  error: Error
): FailedPractitionerBundlesAction {
  return {
    type: FAILED_PRACTITIONER_BUNDLES,
    payload: error,
  }
}

export function receivePractitionerBundlesAction(
  payload: LabTestBundleWithoutPractitioner[]
): ReceivePractitionerBundlesAction {
  return {
    type: RECEIVE_PRACTITIONER_BUNDLES,
    payload,
  }
}

export function practitionerBundleCreatedAction(
  bundle: LabTestBundleWithoutPractitioner
): PractitionerBundleCreatedAction {
  return {
    type: PRACTITIONER_BUNDLE_CREATED,
    payload: bundle,
  }
}
export function practitionerBundleDeletedAction(
  id: string
): PractitionerBundleDeletedAction {
  return {
    type: PRACTITIONER_BUNDLE_DELETED,
    payload: id,
  }
}

export function practitionerBundleUpdatedAction(
  bundle: LabTestBundleWithoutPractitioner
): PractitionerBundleUpdatedAction {
  return {
    type: PRACTITIONER_BUNDLE_UPDATED,
    payload: bundle,
  }
}

export function fetchPractitionerBundles(
  orderId?: string,
  action?: string,
  storefrontId?: string
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (getState().practitionerBundles.pending && !orderId) {
      // skip if currently pending and no orderId passed in
      return
    }

    dispatch(requestPractitionerBundlesAction())

    try {
      const response = await API.PractitionerBundles.get(
        orderId,
        action,
        storefrontId
      )

      dispatch(receivePractitionerBundlesAction(response.data.results))
    } catch (error: any) {
      dispatch(failedPractitionerBundlesAction(error))
      dispatch(handleApiError(error))
    }
  }
}

export function createBundle(
  name: string,
  labTests: LabTest[],
  onClose: () => void,
  shareWithClinic: boolean,
  bundle_type: string = BUNDLE_TYPES.BASIC,
  requested_biomarker_ids: string[] = [],
  requested_biomarker_grouping_ids: string[] = []
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const response = await API.Bundle.create({
        name,
        lab_tests: labTests.map(function (labTest) {
          return labTest.id
        }),
        bundle_type: bundle_type,
        requested_biomarkers: requested_biomarker_ids,
        requested_biomarker_groupings: requested_biomarker_grouping_ids,
      })

      if (shareWithClinic) {
        dispatch(
          shareBundleWithClinic(response.data.id, (bundle) => {
            dispatch(
              practitionerBundleCreatedAction({
                ...bundle,
                lab_tests: labTests,
              })
            )
          })
        )
      } else {
        dispatch(
          practitionerBundleCreatedAction({
            ...response.data,
            lab_tests: labTests,
          })
        )
      }

      dispatch(handleApiSuccess("Bundle created"))

      // Close Bundle
      onClose()
    } catch (error: any) {
      dispatch(handleApiError(error))
    }
  }
}

export function deleteBundle(bundleId: string, onClose: () => void) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      await API.Bundle.delete(bundleId)

      dispatch(handleApiSuccess("Bundle deleted"))

      dispatch(practitionerBundleDeletedAction(bundleId))

      // Close Bundle
      onClose()
    } catch (error: any) {
      dispatch(handleApiError(error))
    }
  }
}

export function updateBundle(
  bundleId: string,
  name: string,
  labTests: LabTest[],
  onClose: () => void
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const response = await API.Bundle.update(bundleId, {
        name,
        lab_tests: labTests.map(function (labTest) {
          return labTest.id
        }),
      })

      dispatch(handleApiSuccess("Bundle updated"))

      dispatch(
        practitionerBundleUpdatedAction({
          ...response.data,
          lab_tests: labTests,
        })
      )

      // Close Bundle
      onClose()
    } catch (error: any) {
      dispatch(handleApiError(error))
    }
  }
}

export function replaceBundle(
  bundle: LabTestBundleWithoutPractitionerAndWithLabTestIds,
  labTests: LabTest[]
) {
  return (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(
        practitionerBundleUpdatedAction({
          ...bundle,
          lab_tests: labTests,
        })
      )
    } catch (error: any) {
      dispatch(handleApiError(error))
    }
  }
}
