import clsx from "clsx"

import {
  FormControl,
  FormHelperText,
  OutlinedTextFieldProps,
  TextField,
} from "@material-ui/core"

import ClickToEditTextField from "app/components/forms/ClickToEditTextField"

import useInputStyles from "./use-input-styles"

export type TextInputProps = Omit<
  OutlinedTextFieldProps,
  "type" | "variant"
> & {
  clickToEdit?: boolean
}

const TextInput = ({
  InputProps,
  className,
  error,
  helperText,
  clickToEdit,
  ...fieldProps
}: TextInputProps) => {
  const inputClasses = useInputStyles()
  const InputComponent = !clickToEdit ? TextField : ClickToEditTextField
  return (
    <FormControl error={error} fullWidth>
      <InputComponent
        InputProps={{
          classes: { ...InputProps?.classes, ...inputClasses },
          ...InputProps,
        }}
        className={clsx(className, "fs-exclude")}
        error={error}
        fullWidth
        type="text"
        variant="outlined"
        {...fieldProps}
      />
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default TextInput
