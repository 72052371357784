import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import { KIT_STATUS } from "app/constants.typed"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { KitStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({
  kit,
  labCompany,
  requisitionAvailable,
}: StatusConfigurationProps) => {
  switch (kit.attributes.sample_collection_coordinator) {
    case "LAB":
      if (requisitionAvailable) {
        return "Requisition is Ready"
      } else {
        const name = getRequisitionLabCompanyName(labCompany?.attributes.key)
        return `Awaiting requisition from ${name}`
      }
    case "CLINIC":
      if (requisitionAvailable) {
        return "Requisition is Ready"
      } else {
        // Requisition should always be available for clinic orders
        return "Requisition is Processing"
      }
    default:
      return "Kit Shipped to Patient"
  }
}

const SubTitle = ({ kit, labCompany }: StatusConfigurationProps) => {
  switch (kit.attributes.sample_collection_coordinator) {
    // Leaving comments for PROD-2070
    // case "LAB":
    //   if (requisitionAvailable) {
    //     // patient can go to lab any time.  do we know if they have yet?
    //   } else {
    //     // patient shouldn't go to lab location yet.
    //   }
    // case "CLINIC":
    //   if (requisitionAvailable) {
    //     // patient can come to the clinic any time. then what? do we know if they have yet?
    //   } else {
    //     // should never happen
    //   }
    case "PATIENT":
      return (
        <KitShipmentTrackingDescription
          estimated_arrival_date={kit.attributes.estimated_arrival_date}
          shipment_tracking_link={kit.attributes.shipment_tracking_link}
          lab_company_notifies_sample_at_lab={
            labCompany?.attributes.notifies_sample_at_lab
          }
          lab_company_short_name={labCompany?.attributes.short_name}
        />
      )
  }
}

const orderedWithLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({ kitStatus }: KitStatuses) =>
      kitStatus === KIT_STATUS.ORDERED_WITH_LAB ||
      kitStatus === KIT_STATUS.AT_LAB,
    progressPercentage: PROGRESS_PERCENTAGES.ORDERED_WITH_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default orderedWithLabStatusConfiguration
