import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import _ from "lodash"

import {
  makeStyles,
  styled,
  useMediaQuery,
  ClickAwayListener,
} from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DeleteIcon from "app/assets/icons/bundles/tooltip-nav-icons/delete-action.svg"
import CopyLinkIcon from "app/assets/icons/bundles/tooltip-nav-icons/link-action.svg"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import useFeatureFlag from "app/hooks/use-feature-flag"
import BundleSidebar from "app/main/checkout/BundleSidebar"
import Favorites from "app/main/checkout/Favorites"
import useBundleFavorites from "app/main/checkout/hooks/use-bundle-favorites"
import BundleCardIcons from "app/main/settings/Bundles/BundleCardIcons"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { trackBundleAccordionClick } from "app/services/segment"
import { trackBundleFavoriteClick } from "app/services/segment"
import * as Actions from "app/store/actions"
import { navy, colors, shadows, cancelRed } from "app/theme"
import { labTestLocation } from "app/types"

import LabTests from "../LabTests/LabTests"
import LabTestModal from "./LabTestModal"

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
})

const useStyles = makeStyles(styles)

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "stretch",
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    flexFlow: "row",
  },

  // As the checkout stretches the entire width of the screen, we need
  // an extra breakpoint for the lab test cards.
  "& .labTestCardGridItem": {
    "@media (min-width: 2000px)": {
      flexBasis: "20%",
      width: "20%",
    },
  },
}))

const TestsContainer = styled("div")({
  flex: 1,
  paddingRight: 24,
  position: "relative",
})

const SidebarContainer = styled("div")(({ theme }) => ({
  background: "white",
  padding: 24,
  boxShadow: shadows.md,
  width: 420,
  borderRadius: "10px",

  [theme.breakpoints.down("sm")]: {
    width: "94%",
    marginTop: "20px",
  },
}))

const StyledContent = styled(MuiDialogContent)(({ isMobile }) => ({
  padding: isMobile ? 12 : 24,
  backgroundColor: colors.coolGray[100],
}))

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: isMobile ? "wrap" : "nowrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
}))

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ isMobile }) => ({
  color: navy,
  position: isMobile ? "absolute" : "relative",
  top: 1,
  right: isMobile ? 12 : 0,
  marginRight: -12,
}))

const BundleModal = ({
  open,
  practitioner,
  practitionerBundles,
  onClose,
  handleToggleLabTestFavorite,
  // bundle is empty for create
  bundle,
  // Optional, determines if bundle modal is in checkout or in settings for override modal
  order,
  usePhysicianAuthorizationForOrderingAccess,
  // Last two are optional and used only for Ecommerce LabSHops
  ecommerceOrderingPractitioner,
  location,
  // optional, storefrontId if a storefront should be used in the context for calculating ordering rights
  storefrontId,
}) => {
  const classes = useStyles(styles)
  const dispatch = useDispatch()

  const [enableBundlesSharingV2] = useFeatureFlag(FeatureFlag.BundlesSharingV2)

  const { favoriteBundle, unfavoriteBundle } = useBundleFavorites()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const [selectedLabTests, setSelectedLabTests] = useState({})

  const [labTestModalIsOpen, setLabTestModalIsOpen] = useState(false)
  const [labTestOpenInModal, setLabTestOpenInModal] = useState(null)

  const [bundleConfirmationModalIsOpen, setBundleConfirmationModalIsOpen] =
    useState(false)

  const [
    closeBundleConfirmationModalIsOpen,
    setCloseBundleConfirmationModalIsOpen,
  ] = useState(false)

  const [bundleIsSubmitting, setBundleIsSubmitting] = useState(false)

  const [tooltipMenuIsOpen, setTooltipMenuIsOpen] = useState(false)

  const [hasBundleBeenEdited, setHasBundleBeenEdited] = useState(false)

  const openModalWithLabTest = useCallback((labTest) => {
    setLabTestOpenInModal(labTest)
    setLabTestModalIsOpen(true)
  }, [])

  // Set selected lab tests when edit bundle modal is opened
  useEffect(() => {
    if (!_.isEmpty(bundle)) {
      setSelectedLabTests(
        Object.fromEntries(
          bundle.lab_tests.map((labTest) => [labTest.id, labTest])
        )
      )
    } else {
      setSelectedLabTests({})
    }
  }, [bundle])

  function handleAdd(labTest) {
    setHasBundleBeenEdited(true)
    setSelectedLabTests({ ...selectedLabTests, [labTest.id]: labTest })
  }

  function handleRemove(labTest) {
    setHasBundleBeenEdited(true)
    const filteredSelectedLabTests = { ...selectedLabTests }

    // If its a parent test, First remove the selected addons
    if (labTest.add_ons.length) {
      labTest.add_ons.forEach((addonLabTest) => {
        delete filteredSelectedLabTests[addonLabTest.id]
      })
    }

    delete filteredSelectedLabTests[labTest.id]

    setSelectedLabTests(filteredSelectedLabTests)
  }

  const handleClose = () => {
    setSelectedLabTests({})
    setBundleIsSubmitting(false)
    setHasBundleBeenEdited(false)
    onClose()
  }

  function handleRemovePanel(labTests) {
    const filteredSelectedLabTests = { ...selectedLabTests }

    labTests.forEach((labTest) => {
      delete filteredSelectedLabTests[labTest.id]
    })

    setSelectedLabTests(filteredSelectedLabTests)
  }

  function closeBundleAndBundeConfirmationModal() {
    setBundleConfirmationModalIsOpen(false)
    setCloseBundleConfirmationModalIsOpen(false)
    handleClose()
  }

  function closeBundleHandler() {
    if (
      (_.isEmpty(bundle) && !selectedLabTests.length) ||
      !hasBundleBeenEdited
    ) {
      handleClose()
    } else {
      // Show confirm dialog before closing edit bundle and create bundle (if tests are selected)
      setCloseBundleConfirmationModalIsOpen(true)
    }
  }

  function handleDeleteBundle() {
    setBundleIsSubmitting(true)

    if (_.isEmpty(bundle)) {
      closeBundleAndBundeConfirmationModal()
    } else {
      dispatch(
        Actions.deleteBundle(bundle.id, closeBundleAndBundeConfirmationModal)
      )
    }
  }

  function handleTooltipMenuClick(e) {
    e.stopPropagation()
    trackBundleAccordionClick(
      practitioner.id,
      practitioner.clinic.id,
      bundle.id
    )
    setTooltipMenuIsOpen(!tooltipMenuIsOpen)
  }

  function handleCopyLinkClick(e) {
    e.stopPropagation()
    dispatch(Actions.copyBundleLink(bundle.id))
    setTooltipMenuIsOpen(false)
  }

  function handleDeleteBundleClick(e) {
    e.stopPropagation()
    setTooltipMenuIsOpen(false)
    setBundleConfirmationModalIsOpen(true)
  }

  async function handleFavoriteClick(e) {
    e.stopPropagation()

    trackBundleFavoriteClick(
      practitioner.id,
      practitioner?.clinic?.id,
      bundle.id,
      bundle.is_shared_bundle,
      bundle.is_favorited,
      location
    )

    if (bundle.is_favorited) {
      await unfavoriteBundle(bundle)

      if (location === labTestLocation.CHECKOUT) {
        handleClose()
      }
    } else {
      await favoriteBundle(bundle)
    }
  }

  const navItems = [
    {
      id: "copy-link",
      icon: <img src={CopyLinkIcon} alt="" />,
      name: "Copy Link",
      onClick: handleCopyLinkClick,
    },
    {
      id: "delete-bundle",
      icon: <img src={DeleteIcon} alt="" />,
      name: "Delete Bundle",
      onClick: handleDeleteBundleClick,
      color: cancelRed,
      background: colors.red[50],
    },
  ]

  return (
    <>
      <Dialog
        onClose={closeBundleHandler}
        aria-labelledby="lab-test-bundle-title"
        open={open}
        className={"fs-unmask"}
        maxWidth="xl"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <TitleSection
          title={
            _.isEmpty(bundle)
              ? "Create a Lab Test Bundle"
              : enableBundlesSharingV2
              ? bundle.name
              : "Edit Your Lab Test Bundle"
          }
          icons={
            enableBundlesSharingV2 && !_.isEmpty(bundle) ? (
              <ClickAwayListener
                onClickAway={() => setTooltipMenuIsOpen(false)}
              >
                <BundleCardIcons
                  bundle={bundle}
                  tooltipMenuIsOpen={tooltipMenuIsOpen}
                  closeMenu={() => setTooltipMenuIsOpen(false)}
                  handleTooltipMenuClick={handleTooltipMenuClick}
                  navItems={navItems}
                  handleFavoriteClick={handleFavoriteClick}
                />
              </ClickAwayListener>
            ) : null
          }
          onClose={closeBundleHandler}
          isMobile={isMobile}
        />

        <StyledContent isMobile={isMobile}>
          <Container>
            <TestsContainer>
              {/* Without `&& open`, we fetch favorite lab tests unnecessarily */}
              {practitioner?.favorite_lab_test_ids?.length > 0 && open && (
                <Favorites
                  modalPage={labTestLocation.BUNDLES}
                  selectedLabTests={selectedLabTests}
                  handleAdd={handleAdd}
                  handleRemove={handleRemove}
                  handleToggleFavorite={handleToggleLabTestFavorite}
                  openModalWithLabTest={openModalWithLabTest}
                  signingPractitioner={practitioner}
                  practitioner={practitioner}
                  practitionerBundles={practitionerBundles}
                  orderRequiresVendorPhysicianAuthorization={
                    usePhysicianAuthorizationForOrderingAccess
                  }
                  storefrontId={storefrontId}
                />
              )}
              <LabTests
                location={location ? location : labTestLocation.BUNDLES}
                practitioner={practitioner}
                signingPractitioner={
                  ecommerceOrderingPractitioner
                    ? ecommerceOrderingPractitioner
                    : practitioner
                }
                onAddLabTest={handleAdd}
                onRemoveLabTest={handleRemove}
                selectedLabTests={selectedLabTests}
                order={order}
                vendorPhysicianServicesActivated={
                  usePhysicianAuthorizationForOrderingAccess
                }
                storefrontId={storefrontId}
              />
            </TestsContainer>
            <SidebarContainer>
              <BundleSidebar
                selectedLabTests={selectedLabTests}
                openModalWithLabTest={openModalWithLabTest}
                handleRemove={handleRemove}
                handleRemovePanel={handleRemovePanel}
                onClose={handleClose}
                onDelete={() => setBundleConfirmationModalIsOpen(true)}
                bundle={bundle}
                handleBundleChange={() => setHasBundleBeenEdited(true)}
                location={location ? location : labTestLocation.BUNDLES}
              />
            </SidebarContainer>
          </Container>
        </StyledContent>
      </Dialog>
      {labTestOpenInModal && (
        <LabTestModal
          labTest={labTestOpenInModal}
          isOpen={labTestModalIsOpen}
          practitioner={
            ecommerceOrderingPractitioner
              ? ecommerceOrderingPractitioner
              : practitioner
          }
          signingPractitioner={
            ecommerceOrderingPractitioner
              ? ecommerceOrderingPractitioner
              : practitioner
          }
          onAddLabTest={handleAdd}
          onRemoveLabTest={handleRemove}
          selectedLabTests={selectedLabTests}
          onClose={() => setLabTestModalIsOpen(false)}
          location={location ? location : labTestLocation.BUNDLES}
          orderId={order?.id}
        />
      )}
      <ConfirmationModal
        open={bundleConfirmationModalIsOpen}
        message="This will delete this bundle from your account. This action cannot be reversed."
        backButtonTitle="Actually, Let’s Keep It"
        confirmButtonTitle="Delete Bundle"
        onClose={() => setBundleConfirmationModalIsOpen(false)}
        loading={bundleIsSubmitting}
        handleConfirm={handleDeleteBundle}
      />
      <ConfirmationModal
        open={closeBundleConfirmationModalIsOpen}
        message={
          _.isEmpty(bundle)
            ? "This bundle will not be saved."
            : "You’ll lose any edits you made to this bundle."
        }
        backButtonTitle="Return to Bundle"
        confirmButtonTitle={_.isEmpty(bundle) ? "Discard" : "Cancel Edits"}
        confirmButtonColor="destructiveSecondary"
        onClose={() => setCloseBundleConfirmationModalIsOpen(false)}
        loading={bundleIsSubmitting}
        handleConfirm={closeBundleAndBundeConfirmationModal}
      />
    </>
  )
}

const TitleSection = ({ title, onClose, isMobile, icons }) => {
  const closeButton = onClose && (
    <CloseButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      isMobile={isMobile}
    >
      <CloseIcon />
    </CloseButton>
  )

  return (
    <StyledMuiDialogTitle disableTypography isMobile={isMobile}>
      <TitleAndInfoContainer>
        <DisplayText
          weight="semibold"
          size="lg"
          style={{ display: "flex", width: "-webkit-fill-available" }}
        >
          <div>{title}</div>
          <div style={{ width: "auto", position: "relative", top: -4 }}>
            {icons}
          </div>
        </DisplayText>
        <BodyText size="base">
          Bundles let you save a group of lab tests for easy 1-click ordering,
          right from your Favorites. These can be custom blood panels or a mix
          of tests from different lab companies.
        </BodyText>
      </TitleAndInfoContainer>
      {closeButton}
    </StyledMuiDialogTitle>
  )
}

export default BundleModal
