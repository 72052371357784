import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"
import _ from "lodash"

import ClickToEditTextField from "app/components/forms/ClickToEditTextField"
import TextField from "app/components/forms/TextField"

function TextFieldFormsy(props) {
  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "className",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "helperText",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "multiline",
    "noPadding",
    "name",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
  ])
  const clickToEdit = props.clickToEdit

  const errorMessage = props.getErrorMessage()
  const value = props.getValue() || ""

  function changeValue(event) {
    props.setValue(event.currentTarget.value)
    if (props.onChange) {
      props.onChange(event)
    }
  }

  let TextFieldComponent = clickToEdit ? ClickToEditTextField : TextField

  return (
    <TextFieldComponent
      {...importedProps}
      onChange={changeValue}
      value={value}
      error={Boolean(errorMessage)}
      helperText={errorMessage || importedProps.helperText}
    />
  )
}

export default memo(withFormsy(TextFieldFormsy))
