import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { Theme } from "@material-ui/core"

import BloodLabDashboardNavBar from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardNavBar"
import useBundleResultsPdfPayload from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/use-bundle-results-pdf-payload"
import Error404Page from "app/main/errors/404/Error404Page"
import Error500Page from "app/main/errors/500/Error500Page"
import PatientPortalBloodDashboardBackground from "app/patient-portal/PatientPortalBloodDashboardBackground"
import { sortBloodReportResultValues } from "app/patient-portal/blood-lab-dashboard/utils"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"
import { Clinic } from "types/clinic"
import { Patient, PatientSettings } from "types/patient"
import { Practitioner } from "types/practitioner"

import BloodLabDashboardsPatientHero from "../BloodLabDashboardsPatientPreview/BloodLabDashboardsPatientHero"
import useBiomarkerCustomDescriptions from "../hooks/use-biomarker-custom-descriptions"
import useBloodLabDashboardsSnapshot from "../hooks/use-blood-lab-dashboards-snapshot"
import BloodLabsDashboardsSnapshotPatientReportContainer from "./BloodLabDashboardsSnapshotPatientReportContainer"

const HEIGHT_OF_NAVBAR = 80

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1500,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingTop: HEIGHT_OF_NAVBAR + 57, // 57px is to offset the padding of the hero component
    paddingLeft: 42,
    paddingRight: 42,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
  },
}))

const BloodLabDashboardsPatientPreview = () => {
  const classes = useStyles()

  const { snapshotId } = useParams<{
    snapshotId: string
  }>()

  const {
    bloodReportResults,
    allBiomarkers,
    isBloodReportResultsLoading,
    bloodReportResultsError,
    getMatchingBloodReportResultByBiomarkerId,
    snapshot,
    isSnapshotLoading,
    snapshotError,
    orderedResult,
    sortedBodySystems,
    missingBiomarkers,
    labCompany,
    userResults,
    usesClinicOptimalRange,
    labTests,
    getBloodReportResultsByLabTest,
  } = useBloodLabDashboardsSnapshot(snapshotId)

  const isLoading = isBloodReportResultsLoading || isSnapshotLoading

  const abnormalBloodReportResults = useMemo(() => {
    return bloodReportResults
      .filter(
        (bloodReportResult) =>
          bloodReportResult.attributes.out_of_standard_range
      )
      .sort((a, b) => sortBloodReportResultValues(a, b))
  }, [bloodReportResults])

  const bloodReportResultsByLabTest = useMemo(() => {
    return labTests.map((labTest) => ({
      labTestName: labTest.attributes.name,
      bloodReportResults: getBloodReportResultsByLabTest(labTest),
    }))
  }, [labTests])

  const patient = useCachedResource<Patient>(
    snapshot?.relationships.patient.data
  )

  const practitioner = useCachedResource<Practitioner>(
    patient?.relationships.practitioner.data
  )

  const clinic = useCachedResource<Clinic>(
    practitioner?.relationships.clinic.data
  )

  const {
    biomarkerCustomDescriptionsMapping,
    isLoading: isBiomarkerCustomDescriptionsLoading,
  } = useBiomarkerCustomDescriptions(
    allBiomarkers.map((biomarker) => biomarker.id)
  )

  const showHighLowDescriptions = useMemo(() => {
    return patient?.attributes.patient_settings.includes(
      PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
    )
  }, [patient])

  const pdfPayload = useBundleResultsPdfPayload({
    outOfRangeBloodReportResults: abnormalBloodReportResults,
    bloodReportResultsByLabTest: bloodReportResultsByLabTest,
    userResults: userResults,
    orderedResult: orderedResult,
    labCompany: labCompany,
    patient: patient,
    practitioner: practitioner,
    clinic: clinic,
    usesClinicOptimalRange,
    snapshotId,
    biomarkerCustomDescriptionsMapping,
    isLoading: isLoading || isBiomarkerCustomDescriptionsLoading,
  })

  if (snapshotError || bloodReportResultsError) {
    if (
      snapshotError?.response?.status === 404 ||
      bloodReportResultsError?.response?.status === 404 ||
      snapshotError?.response?.status === 403 ||
      bloodReportResultsError?.response?.status === 403
    ) {
      return <Error404Page />
    } else {
      return <Error500Page />
    }
  }

  return (
    <div className="fs-unmask">
      <BloodLabDashboardNavBar />
      <PatientPortalBloodDashboardBackground>
        <div className={classes.container}>
          <BloodLabDashboardsPatientHero
            orderedResult={orderedResult}
            patient={patient}
            missingBiomarkers={missingBiomarkers}
            clinicLogoUrl={clinic?.attributes.logo || ""}
            practitionerTitledName={
              practitioner?.attributes.titled_full_name || ""
            }
            isLoading={isLoading}
            isPatientPreview={true}
            pdfPayload={pdfPayload}
          />

          <BloodLabsDashboardsSnapshotPatientReportContainer
            bloodReportResults={bloodReportResults}
            abnormalBloodReportResults={abnormalBloodReportResults}
            bodySystems={sortedBodySystems}
            isLoading={isLoading}
            isPatientPreview={true}
            clinicName={clinic?.attributes.name || ""}
            practitioner={practitioner}
            biomarkers={allBiomarkers}
            orderedResultFile={orderedResult?.attributes.result_file || ""}
            snapshotCreationDate={snapshot?.attributes.created_at || ""}
            labCompanyName={labCompany?.attributes.name}
            getMatchingBloodReportResultByBiomarkerId={
              getMatchingBloodReportResultByBiomarkerId
            }
            showHighLowDescriptions={showHighLowDescriptions || false}
          />
        </div>
      </PatientPortalBloodDashboardBackground>
    </div>
  )
}

export default BloodLabDashboardsPatientPreview
