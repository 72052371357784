import { useCallback, useMemo, useRef, useState } from "react"

import clsx from "clsx"
import { HotKeys } from "react-hotkeys"
import { useHistory } from "react-router-dom"

import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import InputBase from "@material-ui/core/InputBase"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn"
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"
import SearchIcon from "@material-ui/icons/Search"

import { getOrderPatientLabel } from "app/utils/order-utils"

import useInitialFocus from "../../hooks/use-initial-focus"

const useStyles = makeStyles((theme) => ({
  searchBox: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    "& input:focus": {
      background: "#EBF0F0",
    },
    marginLeft: 0,
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      width: "auto",
    },
  },
  searchIcon: {
    color: "#BEC1CB",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "3px",
    zIndex: 99,
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
  },
  inputInput: {
    borderRadius: "10px",
    color: "#4A5568",
    fontWeight: 400,
    border: `1px solid #F4F5F7`,
    backgroundColor: "#F4F5F7",
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}))

export default function StartNewOrder({
  patients,
  defaultPhysicianServices,
  shouldHideTitle,
  shouldHideCancelButton,
  handleOpenNewPatientModal,
  onSelectPatient = () => {},
}) {
  const [searchText, setSearchText] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(0)

  const inputRef = useRef(null)
  useInitialFocus(inputRef)

  const matches = useMemo(
    () =>
      patients.filter((p) => {
        const search = searchText.toLowerCase()
        return `${p.full_name}`.toLowerCase().includes(search)
      }),
    [patients, searchText]
  )

  const moveDown = useCallback(() => {
    if (selectedIndex < matches.length) {
      setSelectedIndex(selectedIndex + 1)
    }
  }, [selectedIndex, matches.length])

  const moveUp = useCallback(() => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    }
  }, [selectedIndex])

  const history = useHistory()

  const selectPatient = useCallback(() => {
    const selectedPatient = matches[selectedIndex]
    if (selectedPatient) {
      if (onSelectPatient) onSelectPatient()
      history.push(`/checkout/create/${selectedPatient.id}`)
    } else {
      _handleOpenNewPatientModal()
    }
  }, [selectedIndex, history, matches])

  const _handleOpenNewPatientModal = () => {
    handleOpenNewPatientModal({ searchText })
  }

  const handlers = {
    SELECT_PATIENT: selectPatient,
    ADD_PATIENT: _handleOpenNewPatientModal,
    MOVE_UP: moveUp,
    MOVE_DOWN: moveDown,
  }

  const keyMap = {
    SELECT_PATIENT: "enter",
    ADD_PATIENT: "ctrl+n",
    MOVE_UP: "up",
    MOVE_DOWN: "down",
  }

  const handleKeyDown = (e) => {
    // Up arrow
    if (e.keyCode === 38) {
      moveUp()
      // Down Arrow
    } else if (e.keyCode === 40) {
      moveDown()
      // Enter Key
    } else if (e.keyCode === 13) {
      selectPatient(selectedIndex)
      // Ctrl+n
    } else if (e.ctrlKey && e.keyCode === 78) {
      _handleOpenNewPatientModal()
    }
  }

  const classes = useStyles()

  const patientLabel = getOrderPatientLabel(defaultPhysicianServices)

  return (
    <HotKeys handlers={handlers} keyMap={keyMap}>
      {!shouldHideTitle && (
        <div className="flex items-center pt-4 px-6 rounded-t-lg">
          <Typography variant="h5" className="mb-0 pt-1 font-semibold">
            Start a New Order
          </Typography>
        </div>
      )}
      <div className="py-4 px-6">
        <div className="flex items-center justify-between">
          <div className={classes.searchBox}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              onKeyDown={handleKeyDown}
              placeholder={`${patientLabel}'s Name`}
              className="fs-exclude"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search", ref: inputRef }}
            />
          </div>
          {!shouldHideCancelButton && (
            <Button
              variant="text"
              onClick={() => setSearchText("")}
              className="text-gray-700 hover:text-gray-800 font-500 text-base normal-case tracking-wider px-4 py-0.5 ml-4"
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
      <Collapse in={searchText.length > 0}>
        <div>
          <Divider />
          <div className="py-4 px-6">
            <Typography
              variant="body1"
              className="text-sm font-500 text-gray-700 mb-2"
            >
              Existing {patientLabel}s
            </Typography>
            {searchText && matches.length === 0 && (
              <div className="flex items-center justify-center w-full border-dashed border-gray-300 border-2 text-gray-700 text-lg p-4">
                No {patientLabel.toLowerCase()}s match this search.
              </div>
            )}
            {searchText &&
              matches.map((match, index) => {
                return (
                  <div
                    key={match.id}
                    className={clsx(
                      "flex items-center justify-between",
                      "rounded w-full text-sm",
                      "px-2 py-1.5 fs-exclude",
                      "cursor-pointer",
                      selectedIndex === index && "bg-gray-100"
                    )}
                    onMouseEnter={() => setSelectedIndex(index)}
                    onClick={() => selectPatient(index)}
                  >
                    <div className="flex items-center">
                      <PersonOutlineIcon className="mr-2 text-xl fs-exclude" />
                      {match.full_name}
                    </div>
                    {selectedIndex === index && (
                      <div className="flex items-center text-xs text-gray-600">
                        Select{" "}
                        <KeyboardReturnIcon className="text-gray-100 bg-gray-500 text-sm ml-2 rounded w-5" />
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </Collapse>

      {searchText && (
        <>
          <Divider />
          <div
            className={clsx(
              "py-4 pl-7",
              (selectedIndex === matches.length ||
                (searchText && matches.length === 0)) &&
                "bg-blue-50"
            )}
          >
            <div
              className="cursor-pointer text-grey-800 font-semibold text-sm flex items-center fs-exclude"
              onClick={_handleOpenNewPatientModal}
              role="button"
              tabIndex={0}
            >
              <PersonAddOutlinedIcon className="mr-2 text-xl" />
              Add {searchText} as a New {patientLabel}
              <ChevronRightIcon />
            </div>
          </div>
        </>
      )}
    </HotKeys>
  )
}
