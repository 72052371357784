import * as React from "react"
import { useMemo, useState } from "react"

import clsx from "clsx"
import _ from "lodash"

import { fade, makeStyles } from "@material-ui/core"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Skeleton } from "@material-ui/lab"

import { ORDER_STATUS } from "app/constants"
import { IN_OFFICE_KIT_STATUS } from "app/constants.typed"
import { hasInstantRequisitionTests } from "app/dataServices/orderDataService"
import { isOrderedTestOrderable } from "app/dataServices/orderingRights"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import theme, { cancelRed, colors, rupaRed } from "app/theme"
import {
  LabCompany,
  LabTest,
  Order,
  OrderTestWarningTypes,
  OrderedTest,
  Practitioner,
  SigningPractitioner,
  labTestLocation,
} from "app/types"
import { handleApiError } from "app/utils"
import { getMinimumAgeAndWarning } from "app/utils/order-utils"

import { InOfficeKitType } from "../in-office-kits/types"
import { requiresActivation } from "../in-office-kits/utils"
import { DeliveryMethod } from "./DeliveryMethod"
import IOKActivationDropdown from "./IOKActivationDropdown"
import { OrderTestWarning } from "./OrderTestWarning"
import { PhlebotomyRequired } from "./PhlebotomyRequired"
import useCheckoutDraft from "./hooks/use-checkout-draft"

const useStyles = makeStyles({
  labTestTitle: {
    display: "flex",
    alignItems: "flex-start",
  },
  labTestPriceWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 5,
  },
  orderTestWarningsWrapper: {
    display: "flex",
    "flex-wrap": "wrap",
  },
  labTestError: {
    background: fade(rupaRed, 0.14),
    width: "100%",
  },
  labTestWarning: {
    background: fade(colors.blueGray[400], 0.14),
    width: "100%",
  },
  labTestErrorText: {
    color: cancelRed,
  },
  labTestPricePlaceholder: {
    backgroundColor: colors.blueGray[200],
    borderRadius: 12,
    marginLeft: "auto",
    height: 14,
    marginTop: 5,
    marginBottom: 5,
    width: 60,
  },
})

interface Props {
  labTest: LabTest
  order?: Order
  orderedTest?: OrderedTest
  status: Order["status"]
  edit: boolean
  onRemoveTest: () => void
  title: string
  cost: string
  discountedCost: string
  labCompany: LabCompany
  onInstantRequisitionChange: (enabled: boolean) => void
  orderSigningPractitioner?: Practitioner | SigningPractitioner
  insideBundleModal?: boolean
  location?: labTestLocation
  setKitActivationIdSelectedFor: (setter: (prev: string[]) => string[]) => void
}

export function LabTestItem({
  order,
  labTest,
  orderedTest,
  status,
  edit,
  onRemoveTest,
  title,
  cost,
  discountedCost,
  labCompany,
  onInstantRequisitionChange,
  orderSigningPractitioner,
  insideBundleModal = false,
  location,
  setKitActivationIdSelectedFor,
}: Props) {
  const classes = useStyles()

  const [inOfficeKitsV2] = useFeatureFlag(FeatureFlag.InOfficeKitsV2)

  const hasIOKRequiringActivation = requiresActivation(labCompany.key)

  const { values } = useCheckoutDraft()

  const timeoutDuration = 250

  const [visible, setVisible] = useState(true)

  const { data: inStockInOfficeKitIdentifiers } = useCollectionSWR<
    ResourceCollection<InOfficeKitType>
  >(
    orderedTest?.id ? `/in_office_kit/` : null,
    {
      params: {
        ordered_tests: orderedTest?.id,
        status: IN_OFFICE_KIT_STATUS.IN_STOCK,
      },
    },
    {
      onError: (error) => {
        if ((error as any).response?.status !== 404) {
          handleApiError(error)
        }
      },
      shouldHandleApiError: false,
    }
  )

  const inStockInOfficeKits = useCachedCollection<InOfficeKitType>(
    inStockInOfficeKitIdentifiers
  )

  const handleRemove = () => {
    setVisible(false)
    setTimeout(() => onRemoveTest(), timeoutDuration)
  }

  const phlebotomyRequired = _.some(
    labTest.lab_test_types.map((t) => t.phlebotomy_required)
  )

  const { error, errorText, warningOnly } = useMemo(() => {
    return location !== labTestLocation.BUNDLES
      ? isOrderedTestOrderable(orderedTest || labTest)
      : // Don't show an ordering rights issues in the standard bundle modal cart
        { error: false, errorText: "", warningOnly: false }
  }, [
    labTest,
    orderedTest,
    values,
    order?.shipping_state,
    order?.requires_vendor_physician_authorization,
  ])

  // Show delivery method if allow_in_office_kits is in clinic_features or if the order has any
  // instant_requisition_tests (This is to handle existing orders).
  // Don't show the delivery method inside the Bundle Modal
  // Don't show while using vendor physician authorization

  const showDeliveryMethod = inOfficeKitsV2
    ? !insideBundleModal &&
      !order?.requires_vendor_physician_authorization &&
      (order?.practitioner?.clinic?.clinic_features?.includes(
        "allow_in_office_kits"
      ) ||
        hasInstantRequisitionTests(order))
    : !insideBundleModal &&
      !order?.requires_vendor_physician_authorization &&
      (order?.practitioner?.allow_in_office_kits ||
        hasInstantRequisitionTests(order))

  const { minAge, minAgeWarning } = getMinimumAgeAndWarning(order, labTest)

  const supportsInstantRequisition = labTest?.has_instant_requisition
  const instantRequisitionEnabled = orderedTest?.instant_requisition
  const hasInStockInOfficeKits = !!inStockInOfficeKits?.length

  return (
    <Grow
      in={visible}
      style={{ transformOrigin: "50% 0 0" }}
      timeout={timeoutDuration}
    >
      <Paper
        style={
          error || !labTest.is_available
            ? {
                border: `1px solid ${
                  warningOnly ? colors.blueGray[400] : cancelRed
                }`,
              }
            : undefined
        }
        className="bg-gray-100 rounded-lg flex flex-col mb-4 border-b-4 border-gray-400"
        elevation={0}
      >
        {error && (
          <LabTestError errorText={errorText} warningOnly={warningOnly} />
        )}

        {!error && !labTest.is_available && (
          <LabTestError
            errorText={`This test is no longer available. ${labTest.unavailable_reason}`}
          />
        )}

        <div className="px-4 py-3">
          <div className="flex items-start">
            <div>
              <div className={classes.labTestTitle}>
                <Typography className="text-base15 font-bold text-gray-800">
                  {title}
                </Typography>
                {phlebotomyRequired && (
                  <PhlebotomyRequired labCompany={labCompany} />
                )}
              </div>
              <Typography className="text-base15 text-gray-700">
                {labCompany.name}
              </Typography>
            </div>
            <div className={classes.labTestPriceWrapper}>
              <LabTestItemPrice cost={cost} discountedCost={discountedCost} />
              {(status === ORDER_STATUS.DRAFT || edit || insideBundleModal) && (
                <div
                  onClick={handleRemove}
                  className="cursor-pointer font-semibold text-base15 text-right"
                  // @ts-ignore
                  style={{ color: theme?.palette?.primary?.main }}
                >
                  Remove
                </div>
              )}
            </div>
          </div>
          {(Boolean(minAge) || labTest.active_shipping_alert) && (
            <div className={classes.orderTestWarningsWrapper}>
              {minAge && (
                <OrderTestWarning
                  type={OrderTestWarningTypes.AGE_RESTRICTION}
                  title={`Age ${minAge}+`}
                  warning={minAgeWarning}
                />
              )}
              {labTest.active_shipping_alert && (
                <OrderTestWarning
                  type={OrderTestWarningTypes.SHIPPING_DELAY}
                  title="Shipping Delay"
                  warning={`${labTest.active_shipping_alert.title}. ${labTest.active_shipping_alert.subtitle}`}
                />
              )}
            </div>
          )}
          {showDeliveryMethod ? (
            <DeliveryMethod
              order={order}
              disabled={status !== ORDER_STATUS.DRAFT || edit}
              orderedTests={[orderedTest]}
              onInstantRequisitionChange={onInstantRequisitionChange}
              orderSigningPractitioner={orderSigningPractitioner}
              supportsInstantRequisition={supportsInstantRequisition}
              requiresStockedKitForOrderedTest={hasIOKRequiringActivation}
              hasInStockInOfficeKits={hasInStockInOfficeKits}
            />
          ) : null}
        </div>

        {hasIOKRequiringActivation &&
          hasInStockInOfficeKits &&
          instantRequisitionEnabled && (
            <IOKActivationDropdown
              inStockInOfficeKits={inStockInOfficeKits}
              orderedTestIds={orderedTest ? [orderedTest.id] : []}
              labCompanyKey={labCompany.key}
              setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
            />
          )}
      </Paper>
    </Grow>
  )
}

const LabTestItemPrice = ({ cost, discountedCost }) => {
  const classes = useStyles()
  return cost ? (
    <Typography className="font-bold text-lg" align="right">
      {discountedCost ? (
        <>
          <span className="text-gray-500 line-through">${cost}</span>
          <span className="ml-2">${discountedCost}</span>
        </>
      ) : (
        <span className="ml-2">${cost}</span>
      )}
    </Typography>
  ) : (
    <Skeleton
      className={classes.labTestPricePlaceholder}
      variant="rect"
      animation="wave"
    />
  )
}

export function LabTestError({
  errorText,
  warningOnly = false,
}: {
  errorText: React.ReactNode
  warningOnly?: boolean
}) {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        warningOnly ? classes.labTestWarning : classes.labTestError,
        "flex items-start px-4 py-3"
      )}
    >
      <Typography
        className={clsx(
          warningOnly ? "" : classes.labTestErrorText,
          "text-base15"
        )}
      >
        {errorText}
      </Typography>
    </div>
  )
}
