export default function DuplicateBiomarkersListItem({
  testName,
  biomarkersList,
}: {
  testName: string
  biomarkersList: string[]
}) {
  return (
    <div>
      <div className="font-semibold">{testName}</div>
      <div className="ml-2 flex">
        <div>-</div>
        <div className="ml-1">{biomarkersList.join(", ")}</div>
      </div>
    </div>
  )
}
