import { useState } from "react"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles,
} from "@material-ui/core"
import { cn } from "@rupahealth/design"

import expandPanelIcon from "app/assets/images/expand-panel-icon.svg"
import { CHECKOUT_WARNINGS } from "app/constants"
import { CheckoutWarningSeverities, CheckoutWarningTypes } from "app/types"

interface WarningProps {
  type: CheckoutWarningTypes
  bulletedInfo?: string[] | React.ReactNode[]
  postTitleText?: string
  collapsible?: boolean
}

const WarningAccordionSummary = withStyles({
  root: {
    minHeight: "0 !important",
    padding: "0 !important",
    margin: "9px 16px !important",
  },
  content: { margin: "0 !important" },
  expandIcon: { padding: "0 !important", margin: "0 !important" },
})(AccordionSummary)

const CheckoutWarning = ({
  type,
  bulletedInfo,
  postTitleText,
  collapsible = true,
}: WarningProps) => {
  const [expanded, setExpanded] = useState(!Boolean(collapsible))
  const handleChange = (event, isExpanded) => {
    if (collapsible) {
      setExpanded(isExpanded)
    }
  }

  const checkoutWarning = CHECKOUT_WARNINGS[type]
  if (!checkoutWarning) {
    return null
  }
  const { title, warning, severity, icon } = checkoutWarning

  return (
    <div
      className={cn("my-[18px] rounded-md shadow-sm", {
        "border-2 border-l-8": [
          CheckoutWarningSeverities.ERROR,
          CheckoutWarningSeverities.WARNING,
          CheckoutWarningSeverities.HIGHLIGHT,
        ].includes(severity),
        "border-rose-800": severity === CheckoutWarningSeverities.ERROR,
        "border-yellow-400": severity === CheckoutWarningSeverities.WARNING,
        "border-primary": severity === CheckoutWarningSeverities.HIGHLIGHT,
        "border border-slate-200": severity === CheckoutWarningSeverities.INFO,
      })}
    >
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        square={true}
        className="rounded-md overflow-hidden"
      >
        {(title || postTitleText || collapsible) && (
          <WarningAccordionSummary
            expandIcon={
              !collapsible ? (
                <></>
              ) : (
                <img
                  src={expandPanelIcon}
                  alt="Expand Panel Icon"
                  width={19}
                  height={19}
                />
              )
            }
          >
            <div
              className={cn("text-body text-sm font-semibold", {
                "text-rose-800": severity === CheckoutWarningSeverities.ERROR,
                "text-yellow-900":
                  severity === CheckoutWarningSeverities.WARNING,
              })}
            >
              {title}
              {postTitleText && ` ${postTitleText}`}
            </div>
          </WarningAccordionSummary>
        )}
        <AccordionDetails className="p-0 flex flex-row">
          {icon && (
            <div className="basis-10 shrink-0 flex items-center justify-center bg-red-50 rounded-l-md">
              {icon}
            </div>
          )}
          <div
            className={cn("text-body text-sm px-4 pb-2", {
              "text-rose-800": severity === CheckoutWarningSeverities.ERROR,
              "text-yellow-900": severity === CheckoutWarningSeverities.WARNING,
              "pt-2": !title,
            })}
          >
            {warning}
            {bulletedInfo?.length && (
              <ul>
                {bulletedInfo.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default CheckoutWarning
