import classNames from "classnames"

import { IconButton, Theme, Zoom } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"

import { ReactComponent as EllipsesIcon } from "app/assets/icons/bundles/ellipses.svg"
import { ReactComponent as SharedBundleIcon } from "app/assets/icons/bundles/synced.svg"
import Tooltip from "app/components/Tooltip"
import TooltipNav from "app/components/design-system/TooltipNav"
import { NavItem } from "app/components/design-system/TooltipNav/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { primaryColor, white } from "app/theme"
import { LabTestBundleWithoutPractitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import BundleFavoriteIcon from "./BundleFavoriteIcon"

const useStyles = makeAppStyles((theme: Theme) => ({
  iconsContainer: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    gap: 0,
    alignItems: "center",
    marginLeft: 5,
    position: "relative",
    top: 2,
    width: "85%",
    [theme.breakpoints.up("lg")]: {
      width: "85%",
    },
  },
  iconContainer: {
    marginRight: 3,
  },
}))

interface Props {
  tooltipMenuIsOpen: boolean
  closeMenu: () => void
  isSelected: boolean
  bundle?: LabTestBundleWithoutPractitioner
  isAvailable: boolean
  handleInfoClick: () => void
  handleTooltipMenuClick: (e: any) => void
  navItems: NavItem[]
  handleFavoriteClick: (e: any) => void
}

const BundleCardIcons = ({
  tooltipMenuIsOpen,
  closeMenu,
  isSelected,
  bundle,
  isAvailable,
  handleInfoClick,
  handleTooltipMenuClick,
  navItems,
  handleFavoriteClick,
}: Props) => {
  const classes = useStyles()
  const [useBundlesSharingV2] = useFeatureFlag(FeatureFlag.BundlesSharingV2)

  const navItemsWithBundle = navItems?.map((item) => {
    return { ...item, metadata: { bundle } }
  })

  return (
    <div className={classes.iconsContainer}>
      {bundle?.is_shared_bundle && useBundlesSharingV2 && (
        <SharedBundleIcon
          fill={isSelected ? white : primaryColor}
          viewBox="0 0 32 52"
          width={20}
          height={20}
          className={classes.iconContainer}
        />
      )}
      {useBundlesSharingV2 && (
        <div className={classes.iconContainer}>
          <BundleFavoriteIcon
            bundle={bundle}
            onFavoriteClick={handleFavoriteClick}
            isSelected={isSelected}
          />
        </div>
      )}
      <Tooltip
        open={tooltipMenuIsOpen}
        onClose={closeMenu}
        title={<TooltipNav children={navItemsWithBundle} />}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Zoom}
        arrow
        interactive
      >
        <IconButton
          className={classNames("p-0 rounded-4")}
          onClick={
            useBundlesSharingV2 ? handleTooltipMenuClick : handleInfoClick
          }
          aria-label="Bundle info"
        >
          {!useBundlesSharingV2 ? (
            <InfoIcon
              fontSize="small"
              style={{
                color: isSelected
                  ? white
                  : isAvailable
                  ? primaryColor
                  : "rgba(0, 0, 0, 0.26)",
              }}
            />
          ) : (
            <EllipsesIcon
              fill={isSelected ? white : primaryColor}
              viewBox="0 0 15 15"
              width={20}
              height={20}
            />
          )}
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default BundleCardIcons
