import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import { parsePhoneNumberFromString } from "libphonenumber-js"

import { Collapse } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import InputAdornment from "@material-ui/core/InputAdornment"
import { withStyles } from "@material-ui/core/styles"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"

import envelopeOutlinedIcon from "app/assets/icons/envelope/envelope-outlined.svg"
import messageBubbleOutlinedIcon from "app/assets/icons/message-bubble-outlined.svg"
import Tooltip from "app/components/Tooltip"
import TextField from "app/components/forms/TextField"
import { validatePhone } from "app/components/forms/validators"
import * as Actions from "app/main/checkout/store/actions"
import { formatPhone } from "app/utils"
import isEmail from "validator/lib/isEmail"

import { ORDER_NOTIFICATION_METHOD } from "../../../constants"
import { veryLightBlue } from "../../../theme"
import useCheckoutDraft from "../hooks/use-checkout-draft"

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: "#f3f3f3",
    width: "100%",
  },
  grouped: {
    flex: 1,
    margin: theme.spacing(0.75),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

const StyledToggleButton = withStyles((theme) => ({
  selected: {
    boxShadow: "2px 2px 10px rgba(0, 36, 57, 0.17)",
    backgroundColor: "white !important",
    borderRadius: 4,
    color: `${theme.palette.text.primary} !important`,
  },
  root: {
    color: "#4a5568",
  },
}))(ToggleButton)

export default function NotificationToggleButton({ patient, className }) {
  const dispatch = useDispatch()

  const patientUser = patient?.user
  const patientFirstName = patient?.first_name

  const { updateValue, values } = useCheckoutDraft()

  const handleChange = (event, notificationChosen) => {
    if (notificationChosen !== null) {
      updateValue("notification_method", notificationChosen)
    }
  }

  const updatePatient = (fields) => {
    dispatch(Actions.updatePatient(patient.id, fields))
  }

  return (
    <div
      className={clsx("shadow p-2 rounded border", className)}
      style={{ borderColor: veryLightBlue }}
    >
      <StyledToggleButtonGroup
        value={values.notification_method}
        exclusive
        onChange={handleChange}
      >
        <StyledToggleButton value={ORDER_NOTIFICATION_METHOD.EMAIL}>
          Email Only
        </StyledToggleButton>
        <StyledToggleButton value={ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT}>
          Email + Text
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      <EditTextField
        icon={envelopeOutlinedIcon}
        iconAlt="Envelope"
        value={patientUser && patientUser.email}
        placeholder={`${patientFirstName}'s Email`}
        onSave={(value) => {
          updatePatient({ email: value })
        }}
        validator={(value) =>
          isEmail(value) ? null : "Please enter a valid email address"
        }
        fullWidth
        className="mt-4"
        editDisabled={!patientUser.can_modify_email}
        tooltipTitle={
          "Please reach out to support through chat if you need to modify this email."
        }
      />

      <Collapse
        in={
          values.notification_method ===
          ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT
        }
      >
        <EditTextField
          icon={messageBubbleOutlinedIcon}
          iconAlt="Message Bubble"
          value={formatPhone(patient?.phone_number)}
          placeholder={`${patientFirstName}'s Phone Number *`}
          onSave={(value) => {
            const phone_number = parsePhoneNumberFromString(value, "US").number
            updatePatient({ phone_number })
          }}
          validator={validatePhone}
          fullWidth
          className="mt-2"
        />
      </Collapse>
    </div>
  )
}

const EditTextField = ({
  icon,
  iconAlt,
  value,
  placeholder,
  onSave,
  validator,
  fullWidth,
  className,
  editDisabled = false,
  tooltipTitle = null,
}) => {
  const [editing, setEditing] = useState(false)
  const [internalValue, setInternalValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState(null)

  // If the value changes outside of this component then update the internal state to reflect that.
  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const onSaveClick = () => {
    const validationResult = validator(internalValue)
    setErrorMessage(validationResult)

    if (!validationResult) {
      setEditing(false)
      onSave(internalValue)
    }
  }

  const disableTooltip = !editDisabled || !tooltipTitle

  return (
    <TextField
      className={clsx(className, "fs-exclude")}
      value={internalValue}
      disabled={!editing}
      placeholder={placeholder}
      variant={"outlined"}
      onChange={(event) => setInternalValue(event.target.value)}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      multiline
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={icon} alt={iconAlt} className="mr-2" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {editing ? (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onSaveClick()}
              >
                Save
              </Button>
            ) : (
              <Tooltip
                arrow
                interactive
                placement="bottom"
                disableHoverListener={disableTooltip}
                disableFocusListener={disableTooltip}
                disableTouchListener={disableTooltip}
                title={tooltipTitle ?? ""}
              >
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      setEditing(true)
                    }}
                    disabled={editDisabled}
                  >
                    Edit
                  </Button>
                </div>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
      fullWidth={fullWidth}
    />
  )
}

EditTextField.muiName = TextField.muiName
