import { useState } from "react"

import { useParams } from "react-router-dom"

import { CircularProgress } from "@material-ui/core"

import EmptyTrendsIcon from "app/assets/images/results-over-time/trends-empty.png"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import Trends from "app/main/patient-orders/trends/Trends"
import TrendsAboutOptimalRangesButton from "app/main/patient-orders/trends/components/TrendsAboutOptimalRangesButton"
import Card from "app/patient-portal/Card"

import PatientPortalUserEmptyState from "./PatientPortalUserEmptyState"

const PatientPortalUserTrends = () => {
  const [patientPortalTrendsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalTrends
  )

  const { userId } = useParams<{
    userId: string
  }>()

  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [optimalRanges, setOptimalRanges] = useState<string[]>([])

  if (!patientPortalTrendsEnabled) {
    return <></>
  }

  return (
    <Card className="h-full max-w-full">
      <div className="text-navy text-lg font-semibold flex">
        Trends
        {isLoading && (
          <div className="flex flex-col items-center justify-center ml-2">
            <CircularProgress size={18} />
          </div>
        )}
        <div className="flex items-center gap-2 ml-auto">
          <TrendsAboutOptimalRangesButton
            optimalRanges={optimalRanges}
            practitionerView={false}
          />
        </div>
      </div>
      <div className="h-[1px] w-full bg-slate-200"></div>
      <Trends
        userId={userId}
        onLoading={setIsLoading}
        onEmpty={setIsEmpty}
        onOptimalRanges={setOptimalRanges}
        showHighLowDescriptions={true}
        enableContainerAroundTable={true}
      />
      {isEmpty && (
        <PatientPortalUserEmptyState
          mainText="Trends helps you track results over time."
          supplementalText="If you have results from compatible labs, they’ll show up here."
          imageNode={
            <div className="w-[225px]">
              <img src={EmptyTrendsIcon} alt="no trends" />
            </div>
          }
        />
      )}
    </Card>
  )
}

export default PatientPortalUserTrends
