import { useMemo } from "react"

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceArea,
  ReferenceLine,
} from "recharts"

import { Theme, useMediaQuery } from "@material-ui/core"

import { colors } from "app/theme"

import {
  MAX_DATA_COLUMNS,
  STICKY_TABLE_COLUMN_WIDTH_MOBILE,
} from "../patient-orders/trends/constants/constants"
import {
  ResultsOverTimeDateGrouping,
  ResultsOverTimeResultData,
  ResultsOverTimeResultSimpleChartData,
} from "../patient-orders/trends/types/types"
import { parseStringValueToFloat, renderSimpleDot } from "./util"

const REFERENCE_AREA_BACKGROUND = colors.green[100]
const CHART_HEIGHT_PX = 60

interface Props {
  data: ResultsOverTimeResultData[]
  availableDates: string[]
  groupedBy: ResultsOverTimeDateGrouping
}

const ResultsOverTimeChartSimple = ({
  data,
  groupedBy,
  availableDates,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(640))

  const chartData: ResultsOverTimeResultSimpleChartData[] = useMemo(() => {
    const newResults: ResultsOverTimeResultSimpleChartData[] = []

    const startingIndex =
      data.length > MAX_DATA_COLUMNS ? MAX_DATA_COLUMNS - 1 : data.length - 1

    // Iterate backwards as first result is most recent, and only the first five
    for (let index = startingIndex; index >= 0; index--) {
      const result = data[index]

      if (result.value) {
        newResults.push({
          id: result.id,
          value: parseStringValueToFloat(result.value),
          created_at: result.created_at,
          status: result.status,
          standardRangeMin: parseStringValueToFloat(result.standard_range_min),
          standardRangeMax: parseStringValueToFloat(result.standard_range_max),
          optimalRangeMin: parseStringValueToFloat(result.optimal_range_min),
          optimalRangeMax: parseStringValueToFloat(result.optimal_range_max),
        })
      }
    }

    return newResults
  }, [data, groupedBy, availableDates])

  const domainY = useMemo(() => {
    const standardRangeMinParsed = parseStringValueToFloat(
      data[0]?.standard_range_min
    )
    const standardRangeMaxParsed = parseStringValueToFloat(
      data[0]?.standard_range_max
    )

    let domainMin = 0
    let domainMax = 0

    const minResultValue = Math.min(
      ...data.map((result) => parseStringValueToFloat(result.value || ""))
    )
    const maxResultValue = Math.max(
      ...data.map((result) => parseStringValueToFloat(result.value || ""))
    )

    // This small block calculates the pixel difference in the top and bottom
    // directions of the chart based upon the min and max values of our results.
    const diffBetweenMinMax = maxResultValue - minResultValue
    const pixelsPerUnit = diffBetweenMinMax / CHART_HEIGHT_PX
    const offset = pixelsPerUnit * 2

    // Need an offset so charts aren't squished on the top and bottom
    if (minResultValue < standardRangeMinParsed) {
      domainMin = minResultValue - offset
    } else {
      domainMin = standardRangeMinParsed - offset
    }

    if (maxResultValue > standardRangeMaxParsed) {
      domainMax = maxResultValue + offset
    } else {
      domainMax = standardRangeMaxParsed + offset
    }

    return [domainMin, domainMax]
  }, [data])

  // If results belong to multiple lab companies, we should ignore their standard range
  // and not show it on the graph
  const hasMultipleLabCompanies = useMemo(() => {
    const firstLabCompanyId = data[0]?.lab_company_id

    return data.some((result) => result.lab_company_id !== firstLabCompanyId)
  }, [data])

  return (
    <div>
      <LineChart
        width={isMobile ? STICKY_TABLE_COLUMN_WIDTH_MOBILE : 100}
        height={CHART_HEIGHT_PX}
        data={chartData}
      >
        <XAxis hide padding={{ left: 3 }} />
        <YAxis hide domain={domainY} padding={{ top: 10, bottom: 10 }} />

        {!hasMultipleLabCompanies && (
          <ReferenceArea
            y1={parseStringValueToFloat(data[0]?.standard_range_min)}
            y2={parseStringValueToFloat(data[0]?.standard_range_max)}
            strokeOpacity={0}
            fill={REFERENCE_AREA_BACKGROUND}
          />
        )}

        {chartData.length === 1 && (
          <ReferenceLine
            x={0}
            stroke={colors.gray[400]}
            strokeDasharray="3 3"
            strokeWidth={2}
          />
        )}

        <Line
          type="monotone"
          isAnimationActive={false}
          dataKey="value"
          stroke={colors.blueGray[300]}
          strokeWidth={2}
          connectNulls
          dot={(props) =>
            renderSimpleDot({
              ...props,
              id: props.payload.id,
            })
          }
        />
      </LineChart>
    </div>
  )
}

export default ResultsOverTimeChartSimple
