import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import { labCompanyScheduleBloodDrawUrl } from "app/utils/lab-company-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { OrderedTestStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ orderedTest }: StatusConfigurationProps) => {
  switch (orderedTest.attributes.sample_collection_coordinator) {
    case "LAB":
      if (orderedTest.attributes.requisition) {
        return "Requisition is Ready"
      } else {
        return "Requisition is Processing"
      }
    case "CLINIC":
      // PROD-2070: We don't allow patients to generate requisitions for clinic orders, but we still
      // say Requisition is Ready here. Could be confusing.
      return "Requisition is Ready"
    default:
      return "Kit Shipped to You"
  }
}

const SubTitle = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  switch (orderedTest.attributes.sample_collection_coordinator) {
    case "LAB":
      // Should always be defined
      const scheduleUrl = labCompanyScheduleBloodDrawUrl(
        labCompany?.attributes.key
      )

      if (orderedTest.attributes.requisition && scheduleUrl) {
        return (
          <span>
            Print out your requisition form and schedule your appointment{" "}
            <a
              className="text-body font-semibold !underline"
              href={scheduleUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
            .
          </span>
        )
      }
      return "Your requisition form can be downloaded from this page once it's ready."
    case "CLINIC":
      return null
    case "PATIENT":
      return (
        <KitShipmentTrackingDescription
          estimated_arrival_date={orderedTest.attributes.estimated_arrival_date}
          shipment_tracking_link={orderedTest.attributes.shipment_tracking_link}
          lab_company_notifies_sample_at_lab={
            labCompany?.attributes.notifies_sample_at_lab
          }
          lab_company_short_name={labCompany?.attributes.short_name}
        />
      )
    default:
      return null
  }
}

const orderedWithLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({ orderedTestStatus }: OrderedTestStatuses) =>
      orderedTestStatus === ORDERED_TEST_STATUS.ORDERED_WITH_LAB,
    progressPercentage: PROGRESS_PERCENTAGES.ORDERED_WITH_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default orderedWithLabStatusConfiguration
