import React from "react"

import { Link as MuiLink } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import PhysicianAuthorizationTooltip from "./PhysicianAuthorizationTooltip"

const useStyles = makeAppStyles((theme) => ({
  buttonStyle: {
    display: "block",
    width: "100%",
    marginTop: 10,
  },
  linkStyle: {
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

const PractitionerTypeIssueTooltip = ({
  allowOverride,
  message,
  signingPractitionerAndOrderingPractitionerSame,
  labTestVendorPhysAuthApproved,
  signingPractitioner,
  overrideAccessAction,
  showClinicState,
}: {
  allowOverride: boolean
  message: string
  signingPractitionerAndOrderingPractitionerSame: boolean
  labTestVendorPhysAuthApproved?: boolean
  signingPractitioner?: Practitioner
  overrideAccessAction?: (showClinicState: boolean) => void
  showClinicState: boolean
}) => {
  const classes = useStyles()

  return (
    <div>
      <BodyText>
        {message}
        {!allowOverride && (
          <>
            {` Please `}
            <MuiLink
              className={classes.linkStyle}
              onClick={() => window.Intercom("showNewMessage")}
            >
              message our team
            </MuiLink>
            {` if you think this is not accurate for you.`}
          </>
        )}
      </BodyText>
      {allowOverride && (
        <DesignSystemButton
          color="secondary"
          className={classes.buttonStyle}
          onClick={() =>
            overrideAccessAction && overrideAccessAction(showClinicState)
          }
        >
          Enable Access to Lab
        </DesignSystemButton>
      )}
      {signingPractitioner && (
        <PhysicianAuthorizationTooltip
          physicianAuthorizationEnabled={
            signingPractitioner.vendor_physician_authorization_enabled
          }
          labTestVendorPhysAuthApproved={!!labTestVendorPhysAuthApproved}
          isPracDifferentFromActivePrac={
            !signingPractitionerAndOrderingPractitionerSame
          }
        />
      )}
    </div>
  )
}

export default PractitionerTypeIssueTooltip
