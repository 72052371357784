import cx from "classnames"
import clsx from "clsx"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { ReactComponent as ArrowTopRightIcon } from "app/assets/icons/arrow-top-right.svg"
import { ReactComponent as BloodIcon } from "app/assets/icons/blood-outline.svg"
import { ReactComponent as BundleIcon } from "app/assets/icons/bundle-outline.svg"
import { ReactComponent as DownArrow } from "app/assets/icons/carets/down-caret-dark-blue.svg"
import { ReactComponent as ExploreBundleIcon } from "app/assets/icons/explore-bundle.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import usePanelBuilderModal from "app/main/panel-builder/use-panel-builder-modal"
import {
  trackPanelBuilderEvent,
  PANEL_BUILDER_TRACKING_EVENTS,
} from "app/services/segment"
import {
  colors,
  primaryColor,
  shadows,
  maroon,
  secondaryColor,
} from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    [theme.breakpoints.up("md")]: {
      width: 275,
    },
  },
  buttonPrimaryColorBorder: {
    borderColor: primaryColor,
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  dropdownContent: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    padding: "0 12px",
    marginTop: 5,
    overflowY: "auto",
    width: 275,
    zIndex: 1300,
  },
  item: {
    display: "flex",
    padding: 8,
    marginTop: 12,
    marginBottom: 12,
    borderRadius: 4,
    cursor: "pointer",

    "&:focus, &:hover": {
      background: colors.blueGray[100],
    },
  },
  itemLabelPanel: {
    color: maroon,
  },
  iconBackgroundPanel: {
    background: "rgba(206, 74, 161, 0.14)",
  },
  itemLabelBundle: {
    color: secondaryColor,
  },
  iconBackgroundBundle: {
    background: "rgba(18, 149, 171, 0.14)",
  },
  itemBundleLibrary: {
    color: primaryColor,
  },
  iconBackgroundBundleLibrary: {
    background: "rgba(0, 117, 205, 0.14)",
  },
  iconBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    minWidth: 30,
    height: 30,
    marginRight: 10,
    borderRadius: 6,
  },
  separator: {
    background: colors.blueGray[200],
    height: 1,
    width: "100%",
  },
}))

export const CreateBundleOrPanelButton = ({
  color,
  onCreateBundle,
  defaultBorderColor = false,
}: {
  color: "primary" | "secondary"
  onCreateBundle
  defaultBorderColor?: boolean
}) => {
  const classes = useStyles()

  const panelBuilderModal = usePanelBuilderModal()

  function openPanelBuilderModal() {
    trackPanelBuilderEvent(
      PANEL_BUILDER_TRACKING_EVENTS.CREATE_BASIC_BLOOD_PANEL,
      {}
    )
    panelBuilderModal.show({
      onClose: () => {
        panelBuilderModal.remove()
      },
    })
  }

  function createBundleAction() {
    trackPanelBuilderEvent(
      PANEL_BUILDER_TRACKING_EVENTS.CREATE_CUSTOM_BUNDLE,
      {}
    )
    onCreateBundle()
  }

  const handleBundleLibraryClick = () => {
    window.open(`https://www.rupahealth.com/bundle-library`, "_blank")
  }

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <Button
          className={cx({
            [classes.button]: true,
            [classes.buttonPrimaryColorBorder]: !defaultBorderColor,
          })}
          color={color}
          shadow="sm"
          aria-label="Create a Panel or Bundle"
          onClick={() => {
            trackPanelBuilderEvent(
              PANEL_BUILDER_TRACKING_EVENTS.CREATE_BUNDLE_OR_PANEL,
              {}
            )
          }}
        >
          <div className={classes.buttonContent}>
            <BundleIcon fill={color === "primary" ? "white" : primaryColor} />
            <div>Create a Panel or Bundle</div>
            <DownArrow fill={color === "primary" ? "white" : primaryColor} />
          </div>
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={classes.dropdownContent}
          avoidCollisions={false}
        >
          <DropdownMenu.Item
            className={classes.item}
            onSelect={openPanelBuilderModal}
            disabled={false}
          >
            <div
              className={clsx(
                classes.iconBackground,
                classes.iconBackgroundPanel
              )}
            >
              <BloodIcon fill={maroon} />
            </div>
            <div>
              <BodyText className={classes.itemLabelPanel} weight="semibold">
                Blood Panel
              </BodyText>
              <BodyText>
                Select biomarkers and compare panels from several labs.
              </BodyText>
            </div>
          </DropdownMenu.Item>

          <DropdownMenu.Separator className={classes.separator} />

          <DropdownMenu.Item
            className={classes.item}
            onSelect={createBundleAction}
            disabled={false}
          >
            <div
              className={clsx(
                classes.iconBackground,
                classes.iconBackgroundBundle
              )}
            >
              <BundleIcon fill={secondaryColor} />
            </div>
            <div>
              <BodyText className={classes.itemLabelBundle} weight="semibold">
                Lab Test Bundle
              </BodyText>
              <BodyText>
                Bundles let you save a group of lab tests from our full lab
                catalog.
              </BodyText>
            </div>
          </DropdownMenu.Item>

          <DropdownMenu.Item
            className={classes.item}
            onSelect={handleBundleLibraryClick}
            disabled={false}
          >
            <div
              className={clsx(
                classes.iconBackground,
                classes.iconBackgroundBundleLibrary
              )}
            >
              <ExploreBundleIcon fill={primaryColor} />
            </div>
            <div>
              <BodyText className={classes.itemBundleLibrary} weight="semibold">
                <div className="inline">Explore Bundle Library</div>
                <ArrowTopRightIcon className="inline ml-1" />
              </BodyText>
              <BodyText>Browse popular bundles from our collection.</BodyText>
            </div>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
