import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { ClinicFeatures } from "app/types"

interface Props {
  supportsBloodReport: boolean | undefined
  hasSnapshots: boolean | undefined
  clinicFeatures: string[] | undefined
}

export default function useBloodReportActionsEnabledForOrderedResult({
  supportsBloodReport,
  hasSnapshots,
  clinicFeatures,
}: Props) {
  const [isBloodReportsEnabled] = useFeatureFlag(FeatureFlag.RupaBloodReports)
  const [isBloodLabDashboardSnapshotOn] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )

  const clinicHasBloodReportsEnabled = clinicFeatures?.includes(
    ClinicFeatures.RUPA_BLOOD_REPORTS
  )

  const isBloodReportActionLegacyEnabled =
    isBloodReportsEnabled &&
    !isBloodLabDashboardSnapshotOn &&
    clinicHasBloodReportsEnabled &&
    supportsBloodReport

  const isBloodReportSnapshotActionEnabled =
    isBloodReportsEnabled &&
    isBloodLabDashboardSnapshotOn &&
    clinicHasBloodReportsEnabled &&
    supportsBloodReport &&
    hasSnapshots

  return {
    isBloodReportActionLegacyEnabled,
    isBloodReportSnapshotActionEnabled,
    isBloodReportActionEnabled:
      isBloodReportActionLegacyEnabled || isBloodReportSnapshotActionEnabled,
  }
}
