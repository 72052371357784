import * as React from "react"
import { useRef } from "react"

import * as Popover from "@radix-ui/react-popover"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useResizeObserver from "app/hooks/use-resize-observer"
import { SearchSuggestion } from "app/types"

import AutoSuggestAction from "./AutoSuggestAction"
import AutoSuggestContent from "./AutoSuggestContent"
import AutoSuggestGrouping from "./AutoSuggestGrouping"
import AutoSuggestSuggestionGroup from "./AutoSuggestSuggestionGroup"
import AutoSuggestSuggestionsLoading from "./AutoSuggestSuggestionsLoading"
import { UseAutoSuggestHook } from "./hooks/use-auto-suggest"
import { SEARCH_TEXT_PLACEHOLDER } from "./state/autosuggest.reducer"

export interface AutoSuggestProps extends UseAutoSuggestHook {
  children: React.ReactNode
  contentChildren?: React.ReactNode
}

export default function AutoSuggest({
  children,
  contentChildren,
  getSearchLocation,
  getSuggestionLocation,
  highlightedSuggestion,
  moveCursorToSearch,
  moveCursorToSuggestion,
  open,
  search,
  selectSearch,
  selectSuggestion,
  setOpen,
  smartOrdering,
  state,
}: AutoSuggestProps) {
  const triggerRef = useRef<any>()
  const { width } = useResizeObserver({
    ref: triggerRef,
    box: "border-box",
  })
  const {
    filterSuggestionTypes,
    filterSuggestionsByType,
    searchTextSuggestions,
    rawSuggestions,
    loading,
    typing,
  } = state

  const renderLoading = loading || typing
  const renderSearchTextSuggestions =
    !renderLoading && !!searchTextSuggestions.length
  const renderFilterSuggestions =
    !renderLoading && !!filterSuggestionTypes.length

  const [isCatalogSearchRelevancyUpdatesEnabled] = useFeatureFlag(
    FeatureFlag.CatalogSearchRelevancyUpdates
  )

  let content = (
    <AutoSuggestContent width={width || "auto"}>
      <AutoSuggestGeneralSearch
        getSearchLocation={getSearchLocation}
        highlightedSuggestion={highlightedSuggestion}
        moveCursorToSearch={moveCursorToSearch}
        moveCursorToSuggestion={moveCursorToSuggestion}
        search={search ?? ""}
        selectSearch={selectSearch}
        renderSearchTextSuggestions={renderSearchTextSuggestions}
        searchTextSuggestions={searchTextSuggestions}
        showSeparator={
          !!contentChildren || renderLoading || renderFilterSuggestions
        }
      />

      {renderFilterSuggestions && (
        <AutoSuggestFilterSuggestions
          filterSuggestionsByType={filterSuggestionsByType}
          filterSuggestionTypes={filterSuggestionTypes}
          getSearchLocation={getSuggestionLocation}
          highlightedSuggestion={highlightedSuggestion}
          moveCursorToSuggestion={moveCursorToSuggestion}
          selectSuggestion={selectSuggestion}
          showSeparator={!!contentChildren}
        />
      )}
      {renderLoading && <AutoSuggestSuggestionsLoading />}
      {contentChildren}
    </AutoSuggestContent>
  )

  // Smart ordering for search suggestions
  if (isCatalogSearchRelevancyUpdatesEnabled && smartOrdering) {
    const orderedFilterSuggestionTypes = [
      ...new Set(rawSuggestions.map((suggestion) => suggestion.type)),
    ]
    content = (
      <AutoSuggestContent width={width || "auto"}>
        {renderFilterSuggestions && (
          <AutoSuggestFilterSuggestions
            filterSuggestionsByType={filterSuggestionsByType}
            filterSuggestionTypes={orderedFilterSuggestionTypes}
            getSearchLocation={getSuggestionLocation}
            highlightedSuggestion={highlightedSuggestion}
            moveCursorToSuggestion={moveCursorToSuggestion}
            selectSuggestion={selectSuggestion}
            showSeparator={true}
          />
        )}
        <AutoSuggestGeneralSearch
          getSearchLocation={getSearchLocation}
          highlightedSuggestion={highlightedSuggestion}
          moveCursorToSearch={moveCursorToSearch}
          moveCursorToSuggestion={moveCursorToSuggestion}
          search={search ?? ""}
          selectSearch={selectSearch}
          renderSearchTextSuggestions={renderSearchTextSuggestions}
          searchTextSuggestions={searchTextSuggestions}
          showSeparator={!!contentChildren}
        />
        {renderLoading && <AutoSuggestSuggestionsLoading />}
        {contentChildren}
      </AutoSuggestContent>
    )
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal={false}>
      <Popover.Trigger
        asChild
        onClick={(e) => {
          if (open) {
            // disable closing on click
            e.preventDefault()
          } else {
            setOpen(true)
          }
        }}
        ref={triggerRef}
      >
        {children}
      </Popover.Trigger>

      {open && search ? content : null}
    </Popover.Root>
  )
}

function AutoSuggestGeneralSearch({
  getSearchLocation,
  highlightedSuggestion,
  moveCursorToSearch,
  moveCursorToSuggestion,
  search,
  selectSearch,
  searchTextSuggestions,
  renderSearchTextSuggestions,
  showSeparator,
}: {
  getSearchLocation?: (search: string) => string
  highlightedSuggestion?: SearchSuggestion
  moveCursorToSearch: () => void
  moveCursorToSuggestion: (suggestion: SearchSuggestion) => void
  search: string
  selectSearch: (search: string) => void
  renderSearchTextSuggestions: boolean
  searchTextSuggestions: SearchSuggestion[]
  showSeparator: boolean
}) {
  if (!search) {
    return null
  }

  return (
    <AutoSuggestGrouping label="General Search" separator={showSeparator}>
      <AutoSuggestAction
        highlighted={
          !highlightedSuggestion ||
          highlightedSuggestion?.type === SEARCH_TEXT_PLACEHOLDER
        }
        icon="search"
        label={`"${search}"`}
        onHover={moveCursorToSearch}
        onSelect={() => selectSearch(search)}
        to={getSearchLocation ? getSearchLocation(search) : undefined}
      />
      {renderSearchTextSuggestions &&
        searchTextSuggestions.map((suggestion) => {
          return (
            // Exclude suggestions that precisely match the current search text to avoid duplicates
            suggestion.search_text !== search && (
              <AutoSuggestAction
                key={suggestion.label}
                label={`"${suggestion.label}"`}
                highlighted={highlightedSuggestion === suggestion}
                icon="search"
                onHover={() => moveCursorToSuggestion(suggestion)}
                onSelect={() => selectSearch(suggestion.label)}
                to={
                  getSearchLocation
                    ? getSearchLocation(suggestion.label)
                    : undefined
                }
              />
            )
          )
        })}
    </AutoSuggestGrouping>
  )
}

function AutoSuggestFilterSuggestions({
  filterSuggestionsByType,
  filterSuggestionTypes,
  getSearchLocation,
  highlightedSuggestion,
  moveCursorToSuggestion,
  selectSuggestion,
  showSeparator,
}: {
  filterSuggestionsByType: Record<string, SearchSuggestion[]>
  filterSuggestionTypes: string[]
  getSearchLocation?: (search: SearchSuggestion) => string
  highlightedSuggestion?: SearchSuggestion
  moveCursorToSuggestion: (suggestion: SearchSuggestion) => void
  selectSuggestion: (suggestion: SearchSuggestion) => void
  showSeparator?: boolean
}) {
  return (
    <>
      {filterSuggestionTypes.map((type, index) => {
        const suggestions = filterSuggestionsByType[type]
        if (!suggestions?.length) {
          return null
        }

        return (
          <AutoSuggestSuggestionGroup
            key={type}
            highlightedSuggestion={highlightedSuggestion}
            getSuggestionLocation={getSearchLocation}
            separator={
              showSeparator ? true : index === filterSuggestionTypes.length - 1
            }
            moveCursorToSuggestion={moveCursorToSuggestion}
            selectSuggestion={selectSuggestion}
            suggestions={suggestions}
            type={type}
          />
        )
      })}
    </>
  )
}
