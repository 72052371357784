import { useState } from "react"

import { AdBlockDetectedWrapper } from "adblock-detect-react"

import { makeStyles, styled } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import useTimeout from "app/hooks/use-timeout"
import Banner from "app/main/dashboard/Banner"
import { colors } from "app/theme"

const BannerContainer = styled("div")({
  width: "100%",
})

const EmailUs = styled("a")({
  color: `${colors.yellow[900]}`,
  textDecoration: "underline !important",
  fontWeight: 600,
})

const bannerStyle = {
  alignItems: "center",
  borderWidth: "5px 0 0 0",
  borderRadius: 0,
  cursor: "auto",
  height: "80px",
  justifyContent: "center",
  left: "0px",
  marginBottom: "0px",
  padding: "20px",
  textAlign: "center",
  top: "5px",
}

export default function AdblockBanner() {
  const [isIntercomBlocked, setIsIntercomBlocked] = useState(false)
  // When checking for whether or not Intercom's Messenger widget has
  // loaded, we rely on the custom `loading` flag set within Intercom's
  // script found in the `IntercomProvider`.
  const checkIntercomLoadingStatus = () =>
    setIsIntercomBlocked(!window.Intercom || window.Intercom?.loading)
  useTimeout(checkIntercomLoadingStatus, 5000)

  const styles = useStyles()

  if (!isIntercomBlocked || process.env.NODE_ENV === "test") {
    return null
  }

  return (
    <AdBlockDetectedWrapper>
      <BannerContainer>
        <Banner
          backgroundColor={colors.yellow[50]}
          borderColor={colors.yellow[400]}
          style={bannerStyle}
        >
          <div>
            <BodyText weight="semibold" size="md" className={styles.bannerText}>
              Rupa uses Intercom to bring you HIPAA compliant support.
            </BodyText>
            <BodyText className={styles.bannerText}>
              {
                "Intercom is currently being blocked by an ad-blocker or by your network’s firewall. Please email us at "
              }
              <EmailUs href="mailto:hello@rupahealth.com?subject=Intercom%20Blocked">
                hello@rupahealth.com
              </EmailUs>
              {" so we can help you access Portal Messages."}
            </BodyText>
          </div>
        </Banner>
      </BannerContainer>
    </AdBlockDetectedWrapper>
  )
}

const useStyles = makeStyles(() => ({
  bannerText: {
    color: `${colors.yellow[900]}`,
  },
}))
