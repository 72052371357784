import { isEmpty } from "lodash"

import { useModal } from "@ebay/nice-modal-react"

import { ReactComponent as SettingsRoundedSquareWithNotificationIcon } from "app/assets/icons/rupa-blood-dashboards/settings-rounded-square-notification.svg"
import { ReactComponent as SettingsRoundedSquareIcon } from "app/assets/icons/rupa-blood-dashboards/settings-rounded-square.svg"
import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import BloodLabDashboardsModal from "app/main/blood-lab-dashboards/BloodLabDashboardsModal/BloodLabDashboardsModal"
import BloodLabDashboardsSnapshotModal from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotModal/BloodLabDashboardsSnapshotModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"

import ActionButton from "./ActionButton"

export interface BloodReportActionProps {
  kit: DashboardKit
}

export default function BloodReportAction({ kit }: BloodReportActionProps) {
  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )
  const hasAnyUnreadNotification =
    latestOrderedResult?.attributes.has_unread_notification

  const { downloadable_result_urls } = kit.attributes
  const hasResultsToDownload = !isEmpty(downloadable_result_urls)

  const [isBloodLabDashboardSnapshotOn] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )

  const bloodReportModalLegacy = useModal(BloodLabDashboardsModal)
  const bloodReportModal = useModal(BloodLabDashboardsSnapshotModal)

  const onClick = useEventCallback(async () => {
    if (isBloodLabDashboardSnapshotOn) {
      if (
        latestOrderedResult?.relationships.blood_lab_dashboard_report_snapshots
          .data?.length
      ) {
        bloodReportModal.show({
          onClose: () => {
            bloodReportModal.remove()
          },
          snapshotId:
            latestOrderedResult?.relationships
              .blood_lab_dashboard_report_snapshots.data[0].id,
          kitSentToPatient: Boolean(
            kit.attributes.date_results_sent_to_patient
          ),
        })
      }
    } else {
      if (latestOrderedResult?.id) {
        bloodReportModalLegacy.show({
          onClose: () => {
            bloodReportModalLegacy.remove()
          },
          orderedResultId: latestOrderedResult.id,
          kitSentToPatient: Boolean(
            kit.attributes.date_results_sent_to_patient
          ),
        })
      }
    }
  })

  return (
    <ActionButton
      disabled={!hasResultsToDownload}
      onClick={onClick}
      tooltipTitle={"See Results"}
      data-cy="blood-lab-dashboard-action-button"
    >
      {hasAnyUnreadNotification ? (
        <SettingsRoundedSquareWithNotificationIcon
          data-cy="download-button-notification"
          fill="currentColor"
          viewBox="-2 0 20 24"
        />
      ) : (
        <SettingsRoundedSquareIcon
          data-cy="download-button-cleared"
          fill="currentColor"
        />
      )}
    </ActionButton>
  )
}
