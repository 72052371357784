import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { TextFieldFormsy } from "@fuse"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"
import _ from "lodash"

import { Button, Typography } from "@material-ui/core"

import BundlesIcon from "app/assets/icons/bundles.svg"
import DesignSystemButton from "app/components/design-system/Button"
import GenericChoiceModal from "app/components/modals/GenericChoiceModal"
import { CHECKOUT_WARNINGS } from "app/constants"
import { hasDHAAndLabcorpPhlebTests } from "app/dataServices/labTestDataService"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { trackBundleCreateFromModalClick } from "app/services/segment"
import * as Actions from "app/store/actions"
import { CheckoutWarningTypes } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { CheckboxFormsy } from "../../../@fuse/components/formsy"
import Tooltip from "../../components/Tooltip"
import VibrantLeavingWarning from "../warnings/VibrantLeavingWarning"
import { anyLabTestIsVibrant } from "../warnings/utils"
import BundleTestList from "./BundleTestList"

const useStyles = makeAppStyles((theme) => ({
  iconImage: {
    width: 20,
    marginBottom: 5,
  },
  checkbox: {
    marginTop: 10,
  },
  vibrantWarningContainer: {
    marginTop: 20,
  },
}))

function BundleSidebar({
  selectedLabTests,
  openModalWithLabTest,
  handleRemove,
  handleRemovePanel,
  onClose,
  onDelete,
  // bundle is empty for create
  bundle,
  handleBundleChange,
  location,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [enableBundlesSharingV2] = useFeatureFlag(FeatureFlag.BundlesSharingV2)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [isFormValid, setIsFormValid] = useState(false)
  const [bundleIsSubmitting, setBundleIsSubmitting] = useState(false)
  const [updatedBundle, setUpdatedBundle] = useState(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const selectedLabTestsArray = useMemo(
    () => Object.values(selectedLabTests),
    [selectedLabTests]
  )

  const { disableSaveButton, errorMessage } = useMemo(() => {
    if (!isFormValid || !selectedLabTestsArray.length) {
      return {
        disableSaveButton: true,
        errorMessage:
          "To submit you must select at least one test and provide a name for your bundle.",
      }
    }

    if (hasDHAAndLabcorpPhlebTests(selectedLabTests)) {
      return {
        disableSaveButton: true,
        errorMessage:
          CHECKOUT_WARNINGS[CheckoutWarningTypes.MIXED_DHA_LABCORP_ORDER]
            .warning,
      }
    }

    return {
      disableSaveButton: false,
      errorMessage: "",
    }
  }, [selectedLabTestsArray, isFormValid])

  function createBundle(data, name) {
    setBundleIsSubmitting(true)
    dispatch(
      Actions.createBundle(
        name,
        selectedLabTestsArray,
        onClose,
        data.shareWithClinic
      )
    )
    trackBundleCreateFromModalClick(
      practitioner.id,
      practitioner.clinic ? practitioner.clinic.id : null,
      Boolean(data.shareWithClinic)
    )
    setBundleIsSubmitting(false)
  }

  function updateBundle(data) {
    setBundleIsSubmitting(true)
    dispatch(
      Actions.updateBundle(bundle.id, data.name, selectedLabTestsArray, onClose)
    )
    setBundleIsSubmitting(false)
  }

  function updateOrCreateBundle(data) {
    // Create new bundle if it doesn't already exist
    if (_.isEmpty(bundle)) {
      createBundle(data, data.name)
    } else {
      updateBundle(data)
    }
  }

  function handleSubmit(data) {
    setUpdatedBundle(data)
    // If the bundle is a shared bundle, open the confirmation modal
    // Logic for updating shared bundles is handled in the modal
    // otherwise just save/update as needed
    if (enableBundlesSharingV2 && bundle && bundle.is_shared_bundle) {
      setIsConfirmationModalOpen(true)
    } else {
      updateOrCreateBundle(data)
    }
  }

  const hasVibrantTests = useMemo(
    () => anyLabTestIsVibrant(selectedLabTestsArray),
    [selectedLabTestsArray]
  )

  return (
    <>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={() => setIsFormValid(true)}
        onInvalid={() => setIsFormValid(false)}
        className="flex flex-col justify-center w-full"
      >
        <TextFieldFormsy
          className="w-full mb-6 mt-6"
          type="text"
          name="name"
          label="Bundle Name"
          value={_.isEmpty(bundle) ? "" : bundle.name}
          validations={{
            minLength: 1,
            maxLength: 40,
          }}
          validationErrors={{
            minLength:
              "Your bundle name is too short, please use a longer name.",
            maxLength:
              "Your bundle name is too long, please use a shorter name.",
          }}
          variant="outlined"
          onChange={handleBundleChange}
          required
        />
        {selectedLabTestsArray.length === 0 && (
          <div className="rounded-lg flex flex-col items-center justify-center bg-gray-50 border-dashed border-2 border-gray-300 w-full px-16 py-6">
            <img
              className={classes.iconImage}
              src={BundlesIcon}
              alt="bundles-icon"
            />
            <Typography className="text-xl text-gray-800" align="center">
              Select tests to start creating your bundle.
            </Typography>
          </div>
        )}
        <BundleTestList
          selectedLabTests={selectedLabTestsArray}
          openModalWithLabTest={openModalWithLabTest}
          handleRemove={handleRemove}
          handleRemovePanel={handleRemovePanel}
          location={location}
        />
        <div className="flex flex-col mb-6">
          {hasVibrantTests && (
            <div className={classes.vibrantWarningContainer}>
              <VibrantLeavingWarning />
            </div>
          )}
          {enableBundlesSharingV2 && _.isEmpty(bundle) && (
            <CheckboxFormsy
              className={classes.checkbox}
              color="primary"
              name={"shareWithClinic"}
              id={"shareWithClinic"}
              label={"Share with Clinic"}
            />
          )}
          <Tooltip
            title={errorMessage}
            placement="left"
            disableHoverListener={!disableSaveButton}
            arrow
          >
            <div>
              <DesignSystemButton
                variant="contained"
                color="primary"
                className="my-3 fs-unmask"
                type="submit"
                disabled={disableSaveButton}
                loading={bundleIsSubmitting}
                fullWidth
              >
                {_.isEmpty(bundle) ? "Create" : "Save"} Bundle
              </DesignSystemButton>
            </div>
          </Tooltip>
          <Button
            variant="text"
            className="text-red-700 hover:bg-red-50 fs-unmask"
            onClick={onDelete}
          >
            Delete Bundle
          </Button>
        </div>
      </Formsy>
      {enableBundlesSharingV2 && !_.isEmpty(bundle) && bundle.is_shared_bundle && (
        <GenericChoiceModal
          open={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          data={{
            title: "Apply these changes to everyone in your clinic?",
            text: "The changes you made to this panel will be saved for everyone in your clinic who uses this Shared Bundle.",
            primaryButtonText: "Yes, Apply Changes for Everyone",
            secondaryButtonText: "No, Save This as a New Bundle",
          }}
          primaryActionButtonColor="primary"
          onPrimaryClick={() => {
            updateBundle(updatedBundle)
          }}
          secondaryActionButtonColor="noaction"
          onSecondaryClick={() => {
            createBundle(updatedBundle, `${bundle.name} - Copy`)
          }}
        />
      )}
    </>
  )
}

export default BundleSidebar
