import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"
import _ from "lodash"

import {
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  Select,
} from "@material-ui/core"

function SelectFormsy(props) {
  const importedProps = _.pick(props, [
    "autoWidth",
    "children",
    "classes",
    "displayEmpty",
    "input",
    "inputProps",
    "MenuProps",
    "multiple",
    "native",
    "onChange",
    "onClose",
    "onOpen",
    "open",
    "required",
    "renderValue",
    "SelectDisplayProps",
    "value",
    "variant",
    "disabled",
  ])

  // An error message is returned only if the component is invalid
  const errorMessage = props.getErrorMessage()
  const value = props.getValue()

  function input() {
    switch (importedProps.variant) {
      case "outlined":
        return (
          <OutlinedInput labelWidth={props.label.length * 8} id={props.name} />
        )
      case "filled":
        return <FilledInput id={props.name} />
      default:
        return <Input id={props.name} />
    }
  }

  function changeValue(event) {
    props.setValue(event.target.value)
    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <FormControl
      error={Boolean(errorMessage)}
      className={props.className}
      variant={importedProps.variant}
    >
      {props.label && (
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      )}
      <Select
        {...importedProps}
        value={value}
        onChange={changeValue}
        // FUSE-MODIFICATION: fixed to allow custom input
        input={importedProps.input || input()}
      />
      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default memo(withFormsy(SelectFormsy))
