import { useMemo } from "react"

import AutoSuggestAction from "app/components/search/AutoSuggest/AutoSuggestAction"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { BiomarkerIdentifier } from "types/biomarker"
import {
  BiomarkerGrouping,
  BiomarkerGroupingIdentifier,
} from "types/biomarker_grouping"

export default function BiomarkerGroupingAutoSuggestAction({
  biomarkerGroupingIdentifier,
  handleSelectBiomarkerGrouping,
  highlightedItem,
  moveCursorToItem,
  selectedBiomarkerGroupingsIdentifiers,
}: {
  biomarkerGroupingIdentifier: BiomarkerGroupingIdentifier
  handleSelectBiomarkerGrouping: (
    suggestion: BiomarkerGroupingIdentifier | BiomarkerIdentifier
  ) => void
  highlightedItem: BiomarkerGroupingIdentifier | BiomarkerIdentifier | undefined
  moveCursorToItem: (
    suggestion: BiomarkerGroupingIdentifier | BiomarkerIdentifier
  ) => void
  selectedBiomarkerGroupingsIdentifiers: BiomarkerGroupingIdentifier[]
}) {
  const biomarkerGrouping = useCachedResource<BiomarkerGrouping>(
    biomarkerGroupingIdentifier
  )

  const addedMessage = useMemo(() => {
    return selectedBiomarkerGroupingsIdentifiers.some(
      (obj) => obj.id === biomarkerGroupingIdentifier.id
    )
      ? "Added"
      : undefined
  }, [biomarkerGroupingIdentifier, selectedBiomarkerGroupingsIdentifiers])

  if (!biomarkerGrouping) {
    return null
  }

  return (
    <AutoSuggestAction
      key={`${"Biomarkers_Groupings"}_${biomarkerGrouping.id}`}
      label={biomarkerGrouping.attributes.name}
      highlighted={highlightedItem === biomarkerGroupingIdentifier}
      icon={"biomarker_grouping"}
      onHover={() => {
        moveCursorToItem(biomarkerGroupingIdentifier)
      }}
      onSelect={() => {
        handleSelectBiomarkerGrouping(biomarkerGroupingIdentifier)
      }}
      addedMessage={addedMessage}
    />
  )
}
