import { Typography } from "@material-ui/core"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"

export default function PatientOrdersOrderedTestPanelTooltip({
  panelLabTestNames,
}: {
  panelLabTestNames: string[]
}) {
  return (
    <Tooltip
      arrow
      title={
        <div className="flex flex-col items-start justify-start gap-[10px]">
          {panelLabTestNames.map((name, idx) => (
            <div
              key={idx}
              className="flex items-center justify-start gap-[10px]"
            >
              <BiomarkerIcon
                fill={colors.emerald[500]}
                width={15}
                height={14}
                viewBox="-1 -1 12 12"
              />
              <BodyText size="sm" weight="semibold" className="text-left">
                {name}
              </BodyText>
            </div>
          ))}
        </div>
      }
      placement="bottom-start"
      className="cursor-default"
      interactive
    >
      <div className="flex flex-row items-center w-fit">
        <BiomarkerIcon
          fill={primaryColor}
          width={15}
          height={14}
          viewBox="-1 -1 12 12"
        />
        <Typography
          className="font-semibold ml-1"
          style={{ color: primaryColor }}
        >
          See Biomarkers
        </Typography>
      </div>
    </Tooltip>
  )
}
