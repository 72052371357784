import { useState, useMemo } from "react"

import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import { ReactComponent as FlaskIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle-outlined.svg"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { primaryColor } from "app/theme"
import { Patient } from "app/types"
import { PatientSettings } from "types/patient"

import Trends from "./Trends"
import TrendsEmptyState from "./TrendsEmptyState"
import TrendsAboutOptimalRangesButton from "./components/TrendsAboutOptimalRangesButton"
import TrendsImportButton from "./components/TrendsImportButton"
import {
  IMPORT_RESULTS_INTERCOM_SURVEY_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_URL,
} from "./constants/constants"

interface Props {
  patient: Patient
}

const PractitionerTrends = ({ patient }: Props) => {
  const handleImportResultsClick = () => {
    window.Intercom("startSurvey", IMPORT_RESULTS_INTERCOM_SURVEY_ID)
  }

  const [isTrendsManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const [optimalRanges, setOptimalRanges] = useState<string[]>([])

  const showHighLowDescriptions = useMemo(() => {
    return patient?.patient_settings.includes(
      PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
    )
  }, [patient])

  return (
    <div className="flex flex-col gap-5">
      <div className="rounded-lg p-7 bg-white shadow-md mb-2">
        <div className="flex justify-between items-center mb-4 pb-2 border-b-[1px] border-slate-200">
          <div>
            <BodyText
              size="lg"
              weight="semibold"
              className="flex items-center justify-center"
            >
              Trends{" "}
              <Button
                variant={null}
                className="text-primary pl-[6px]"
                onClick={() =>
                  window.Intercom
                    ? window.Intercom(
                        "showArticle",
                        SUPPORTED_LABS_INTERCOM_ARTICLE_ID
                      )
                    : window.open(SUPPORTED_LABS_INTERCOM_ARTICLE_URL, "_blank")
                }
              >
                <QuestionCircleIcon
                  fill={primaryColor}
                  width={15}
                  height={15}
                />
              </Button>
              {isLoading && (
                <div className="flex flex-col items-center justify-center ml-2">
                  <CircularProgress size={18} />
                </div>
              )}
            </BodyText>
          </div>
          {isTrendsManualUploadEnabled ? (
            <div className="flex">
              <TrendsAboutOptimalRangesButton
                optimalRanges={optimalRanges}
                practitionerView={true}
              />
              <div className="bg-slate-200 h-[25px] w-[2px] mx-2"></div>
              {patient ? <TrendsImportButton patient={patient} /> : <></>}
            </div>
          ) : (
            <Button
              variant={null}
              className="text-primary max-md:px-0 max-md:justify-start"
              onClick={handleImportResultsClick}
            >
              <FlaskIcon className="mr-2" fill={primaryColor} /> Import Results
              (Coming Soon)
            </Button>
          )}
        </div>

        {patient.user.id && (
          <Trends
            userId={patient.user.id}
            onLoading={setIsLoading}
            onEmpty={setIsEmpty}
            showHighLowDescriptions={showHighLowDescriptions}
            onOptimalRanges={setOptimalRanges}
          />
        )}

        {isEmpty && patient && <TrendsEmptyState patient={patient} />}
      </div>
    </div>
  )
}

export default PractitionerTrends
