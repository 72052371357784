import { useSWRConfig } from "swr"

import { getResourceCacheKey } from "app/swr/helpers/keys"
import { Resource } from "app/swr/types"

/**
 * Write the given resources to the cache without revalidation.
 *
 * Used after fetches and mutations to keep the resource cache up to date.
 *
 * @param config the full swr config
 * @param resources the resource to write to cache
 * @returns the mutation promises
 */
export async function writeToCache(
  config: ReturnType<typeof useSWRConfig>,
  ...resources: Resource[]
) {
  return await Promise.all(
    resources.map(async (resource) =>
      config.mutate(getResourceCacheKey(resource), resource, {
        revalidate: false,
      })
    )
  )
}
