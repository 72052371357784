// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import { createFilterOptions } from "@material-ui/lab"
import Autocomplete from "@material-ui/lab/Autocomplete"

import TextField from "app/components/forms/TextField"

function AutocompleteFormsy({
  fullWidth,
  options,
  getOptionLabel,
  label,
  helperText,
  className,
  errorIfPristine,
  disableClearable,
  freeSolo,
  InputProps,
  required,
  disabled,
  groupBy,
  optionFromValue,
  valueFromOption,
  renderOption,
  ...props
}) {
  const errorMessage =
    errorIfPristine || !props.isPristine() ? props.getErrorMessage() : ""

  // If the value is not in the provided options, set value to null.
  let value = props.getValue() || null
  if (
    options &&
    !options
      .map((option) => valueFromOption(option))
      .includes(valueFromOption(value))
  ) {
    value = null
  }

  const filterOptions = createFilterOptions({
    stringify: (option) => getOptionLabel(option) || "",
  })

  const onChange = (event, newOption) => {
    props.setValue(valueFromOption(newOption))

    if (props.onChange) {
      props.onChange(event, newOption)
    }
  }

  return (
    <Autocomplete
      className={className}
      freeSolo={freeSolo}
      value={optionFromValue(value, options) || value}
      onChange={onChange}
      onBlur={(event) => {
        // In freeSolo mode ensure that the value is set in formsy when the component blurs. Otherwise the user would
        // have to press the enter key to cause the onChange to trigger, which they likely would not know to do.
        if (freeSolo) {
          onChange(event, event.target.value)
        }
      }}
      options={options || []}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      groupBy={groupBy}
      disabled={disabled}
      disableClearable={disableClearable}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          label={label}
          InputProps={{ ...InputProps, ...params.InputProps }}
          variant="outlined"
          required={required}
          className="fs-exclude"
          error={Boolean(errorMessage)}
          helperText={errorMessage || helperText}
        />
      )}
    />
  )
}

export default withFormsy(AutocompleteFormsy)
