import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

import { InRangeOption, ResultsOverTimeDateGrouping } from "../types/types"

// This hook fetches the optimal ranges for the trends page.
// This is its own hook because this data is needed at the top level of the trends page.
// The trends table data is requested in chunks in lower tree components of the trends page
// and challenging to wrangle and pass up to the top level so we have this hook to fetch the optimal ranges.
export default function useTrendsOptimalRanges(
  userId: string,
  timeSeriesGrouping: ResultsOverTimeDateGrouping,
  inRangeValue: InRangeOption,
  bodySystemId: string
) {
  // Need to establish a unique key here to ensure that the SWR cache is
  // invalidated when one or more of the dropdowns change.
  const key = [
    `/results_over_time/${userId}/optimal_ranges/`,
    timeSeriesGrouping,
    inRangeValue,
    bodySystemId,
  ]

  const { data, error, isLoading, isValidating } = useSWR(
    key,
    async (key) => {
      const url = key[0]

      const response = await axios.request<string[]>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "get",
        params: {
          group_by: timeSeriesGrouping,
          results_range_status: inRangeValue,
          body_system_id: bodySystemId === "all" ? null : bodySystemId,
          primary_body_system_only: Boolean(bodySystemId === "all"),
        },
      })

      return response.data
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  return {
    data,
    error,
    isLoading,
    isValidating,
  }
}
