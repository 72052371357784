import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import { usePrevious } from "react-use"

import {
  ClickAwayListener,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core"

import Loading from "app/components/Loading"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import GenericChoiceModal from "app/components/modals/GenericChoiceModal"
import { RenameBundleModal } from "app/components/modals/RenameBundleModal"
import { getUnavailableReasonForBundle as getUnavailableReasonForBundleV2 } from "app/dataServices/orderingRights"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useBundleFavorites from "app/main/checkout/hooks/use-bundle-favorites"
import useClinicSharedBundles from "app/main/checkout/hooks/use-clinic-shared-bundles"
import usePanelBuilderModal from "app/main/panel-builder/use-panel-builder-modal"
import BundleIcon from "app/main/settings/Bundles/BundleIcon"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import {
  PANEL_BUILDER_TRACKING_EVENTS,
  trackBundleAccordionClick,
  trackBundleCopyLinkClick,
  trackBundleDeleteClick,
  trackBundleDeleteConfirmationClick,
  trackBundleFavoriteClick,
  trackBundleHideForMeClick,
  trackBundleMakeCopyClick,
  trackPanelBuilderEvent,
} from "app/services/segment"
import { deleteBundle } from "app/store/actions"
import { createBundle } from "app/store/actions/practitionerBundles.actions"
import { copyBundleLink } from "app/store/actions/shareBundles.actions"
import { darkBlue, maroon, white } from "app/theme"
import { LabTestBundleType, labTestLocation } from "app/types"
import { getBundleTypeLabel } from "app/utils/bundle-utils"

import Tooltip from "../../components/Tooltip"
import BundleCardIcons from "../settings/Bundles/BundleCardIcons"
import { useBundleNavItems } from "../settings/Bundles/use-bundle-nav-items"
import { anyLabTestIsVibrant } from "../warnings/utils"
import VibrantBundleCardWarning from "./VibrantBundleCardWarning"
import * as constants from "./constants"

const useStyles = makeStyles((theme) => ({
  panelCardBg: ({ isSelected }) => ({
    background: isSelected
      ? `linear-gradient(172deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%), ${maroon}`
      : `linear-gradient(165.38deg, rgba(255, 255, 255, 0.8) 11.16%, rgba(255, 255, 255, 0) 84.19%), #F4F5F7`,
    backgroundBlendMode: isSelected && "overlay, normal",
  }),
  basicCardBg: ({ isSelected }) => ({
    background: isSelected
      ? "linear-gradient(165.38deg, rgba(255, 255, 255, 0.2) 11.16%, rgba(255, 255, 255, 0) 84.19%), #1B476F"
      : "linear-gradient(165.38deg, rgba(255, 255, 255, 0.8) 11.16%, rgba(255, 255, 255, 0) 84.19%), #F4F5F7",
  }),
  panelSubtextSelected: {
    color: white,
  },
  basicSubtextSelected: {
    color: darkBlue,
  },
  testWrapper: () => ({
    borderRadius: "10px",
    padding: "12px",
    minHeight: "112px",
    position: "relative",
    overflow: "hidden",
  }),
  iconHover: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
  },
  iconHoverSelected: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.15)",
    },
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default function BundleCard({
  bundle,
  orderedBundleIds,
  onAdd,
  onRemove,
  openModal,
  practitioner,
  isInfoCard = false,
  location,
  disableTooltip = false,
}) {
  const dispatch = useDispatch()

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [renameBundleModalOpen, setRenameBundleModalOpen] = useState(false)
  const [isDeleteBundleModalOpen, setIsDeleteBundleModalOpen] = useState(false)

  const { shareWithClinic, unshareWithClinic, clinicShareIsLoading } =
    useClinicSharedBundles()
  const { favoriteBundle, unfavoriteBundle, isBundleFavoriteLoading } =
    useBundleFavorites()

  const isSelected = useMemo(
    () =>
      orderedBundleIds &&
      orderedBundleIds.some((orderedBundleId) => orderedBundleId === bundle.id),
    [bundle, orderedBundleIds]
  )
  const wasSelected = usePrevious(isSelected)

  const classes = useStyles({ isSelected })

  const unavailableReason = useMemo(
    () => getUnavailableReasonForBundleV2(bundle),
    [bundle]
  )

  const isAvailable = useMemo(() => !unavailableReason, [unavailableReason])

  const handleInfoClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (panelBuilderEnabled && bundle.bundle_type === LabTestBundleType.PANEL) {
      openPanelBuilderModal()
    } else {
      openModal()
    }
  }

  const handleActionClick = async (e, clickAction) => {
    e.stopPropagation()
    await clickAction(bundle)
    setTooltipMenuIsOpen(!tooltipMenuIsOpen)
  }

  const [isUpdating, setIsUpdating] = useState(false)
  const [isConfirmUnfavoriteModalOpen, setIsConfirmUnfavoriteModalOpen] =
    useState(false)
  const handleClick = useCallback(
    (e) => {
      // We only show the updating spinner when the card is rendered within
      // the a component that supports adding bundles (just the Checkout at
      // the moment).
      if (orderedBundleIds) {
        if (isUpdating) {
          return false
        }

        // Prevent infinite loader
        if (!unavailableReason) {
          setIsUpdating(true)
        }
      }

      if (isInfoCard) {
        handleInfoClick(e)
        return
      }
      if (isSelected) {
        onRemove(bundle)
      } else if (isAvailable) {
        onAdd(bundle)
      }
    },
    [isInfoCard, isSelected, isAvailable, isUpdating]
  )

  const [tooltipMenuIsOpen, setTooltipMenuIsOpen] = useState(false)

  useEffect(() => {
    const isSelectedChanged = isSelected !== wasSelected
    if (isUpdating && isSelectedChanged) {
      setIsUpdating(false)
    }
  }, [isUpdating, setIsUpdating, isSelected, wasSelected])

  useEffect(() => {
    setIsUpdating(clinicShareIsLoading)
  }, [clinicShareIsLoading])

  const handleTooltipOpen = () => {
    if (!disableTooltip && !isAvailable) {
      setTooltipOpen(true)
    }
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleNavItemClick = (e, clickAction) => {
    e.stopPropagation()
    clickAction(bundle)
  }

  const closeDeleteBundleModal = () => {
    setIsDeleteBundleModalOpen(false)
  }

  const handleRemoveBundle = (bundle) => {
    trackBundleDeleteClick(
      practitioner.id,
      practitioner?.clinic?.id,
      bundle.id,
      bundle.is_shared_bundle
    )
    setIsDeleteBundleModalOpen(true)
  }

  const removeBundle = async () => {
    await dispatch(deleteBundle(bundle.id, closeDeleteBundleModal))
  }

  const handleCopyLinkClick = async (bundle) => {
    setIsUpdating(true)
    trackBundleCopyLinkClick(
      practitioner.id,
      practitioner?.clinic?.id,
      bundle.id
    )
    await dispatch(copyBundleLink(bundle.id))
    setIsUpdating(false)
  }

  const createBundleCopy = async (bundle) => {
    setIsUpdating(true)
    trackBundleMakeCopyClick(
      practitioner.id,
      practitioner?.clinic?.id,
      bundle.id
    )

    const copiedBundleName = `${bundle.name} - Copy`

    await dispatch(
      createBundle(
        copiedBundleName,
        bundle.lab_tests,
        () => setIsUpdating(false),
        bundle.is_shared_bundle,
        bundle.bundle_type,
        bundle.requested_biomarkers,
        bundle.requested_biomarker_groupings
      )
    )
  }

  const handleTooltipMenuClick = (e) => {
    e.stopPropagation()
    trackBundleAccordionClick(
      practitioner.id,
      practitioner?.clinic?.id,
      bundle.id
    )
    setTooltipMenuIsOpen(!tooltipMenuIsOpen)
  }

  const handleFavoriteClick = async (e) => {
    e.stopPropagation()

    setIsUpdating(true)

    trackBundleFavoriteClick(
      practitioner.id,
      practitioner?.clinic?.id,
      bundle.id,
      bundle.is_shared_bundle,
      bundle.is_favorited,
      location
    )

    if (bundle.is_favorited) {
      if (location === labTestLocation.CHECKOUT) {
        setIsConfirmUnfavoriteModalOpen(true)
      } else {
        await unfavoriteBundle(bundle)
      }
    } else {
      await favoriteBundle(bundle)
    }

    setIsUpdating(false)
  }

  const confirmUnfavorite = async () => {
    await unfavoriteBundle(bundle)
    setIsConfirmUnfavoriteModalOpen(false)
    setIsDeleteBundleModalOpen(false)
  }
  const [panelBuilderEnabled] = useFeatureFlag(FeatureFlag.PanelBuilder)

  const panelBuilderModal = usePanelBuilderModal()

  function openPanelBuilderModal() {
    trackPanelBuilderEvent(PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_EDIT, {
      bundle_id: bundle.id,
    })
    panelBuilderModal.show({
      onClose: () => {
        panelBuilderModal.remove()
      },
      existingBundleId: bundle.id,
      existingBundleName: bundle.name,
      existingBundleRequestedBiomarkerIds: bundle.requested_biomarkers,
      existingBundleRequestedBiomarkerGroupingIds:
        bundle.requested_biomarker_groupings,
      existingPanelLabCompanyKey: bundle.panel_lab_company_key,
    })
  }

  const hasVibrantTests = useMemo(() => {
    return anyLabTestIsVibrant(bundle?.lab_tests)
  }, [bundle?.lab_tests])

  const { navItems } = useBundleNavItems({
    panelBuilderEnabled,
    bundle,
    location,
    practitioner,
    handleNavItemClick,
    openModal,
    openPanelBuilderModal,
    setRenameBundleModalOpen,
    createBundleCopy,
    handleActionClick,
    handleCopyLinkClick,
    handleRemoveBundle,
    shareWithClinic,
    unshareWithClinic,
  })

  // So warning tooltip can disable the main tooltip, preventing multiple tooltips
  const [disableHover, setDisableHover] = useState(false)
  const onDisableHover = () => {
    setDisableHover(true)
    setTooltipOpen(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setTooltipMenuIsOpen(false)}>
        <Tooltip
          open={tooltipOpen}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          title={unavailableReason}
          interactive
          placement="top"
          arrow
          disableHoverListener={disableHover}
        >
          <Paper
            className={clsx(
              {
                [classes.panelCardBg]:
                  bundle?.bundle_type === LabTestBundleType.PANEL,
                // Fall back to basic card
                [classes.basicCardBg]:
                  bundle?.bundle_type !== LabTestBundleType.PANEL,
                "cursor-pointer": isAvailable || isSelected,
              },
              classes.testWrapper,
              "fs-unmask",
              "transition-150 transition-bg transition-shadow transition-ease-in",
              "flex flex-col justify-between",
              "shadow-md hover:shadow-lg"
            )}
            elevation={0}
            onClick={handleClick}
          >
            {isUpdating && (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            )}
            <div className="flex justify-between">
              <BundleIcon bundle={bundle} isSelected={isSelected} />

              <div className="flex items-center justify-end">
                <BundleCardIcons
                  tooltipMenuIsOpen={tooltipMenuIsOpen}
                  closeMenu={() => setTooltipMenuIsOpen(false)}
                  isSelected={isSelected}
                  bundle={bundle}
                  isAvailable={isAvailable}
                  handleInfoClick={handleInfoClick}
                  handleTooltipMenuClick={handleTooltipMenuClick}
                  navItems={navItems}
                  handleFavoriteClick={handleFavoriteClick}
                />
              </div>
            </div>

            <div>
              <Typography
                variant="body1"
                className={clsx("font-semibold", {
                  "text-white": isSelected,
                  "text-gray-800": !isSelected,
                })}
              >
                {bundle.name}
              </Typography>
              {hasVibrantTests && (
                <VibrantBundleCardWarning
                  handleUpdateClick={handleInfoClick}
                  onOpen={onDisableHover}
                  onClose={() => setDisableHover(false)}
                />
              )}

              <Typography variant="body1">
                <span
                  className={
                    isSelected
                      ? [
                          classes[
                            `${bundle?.bundle_type?.toLowerCase()}SubtextSelected`
                          ],
                        ]
                      : "text-gray-600"
                  }
                >
                  {getBundleTypeLabel(bundle.bundle_type)}
                </span>
              </Typography>
            </div>
          </Paper>
        </Tooltip>
      </ClickAwayListener>
      <ConfirmationModal
        open={isConfirmUnfavoriteModalOpen}
        title={constants.CONFIRM_UNFAVORITE_BUNDLE_MODAL_TITLE}
        message={constants.CONFIRM_UNFAVORITE_BUNDLE_MODAL_TEXT}
        confirmButtonTitle={constants.CONFIRM_UNFAVORITE_BUNDLE_MODAL_BTN_TEXT}
        confirmButtonColor="secondary"
        showBackButton={false}
        onClose={() => setIsConfirmUnfavoriteModalOpen(false)}
        loading={isBundleFavoriteLoading}
        handleConfirm={confirmUnfavorite}
      />
      <RenameBundleModal
        bundle={bundle}
        open={renameBundleModalOpen}
        onClose={() => setRenameBundleModalOpen(false)}
      />
      <GenericChoiceModal
        open={isDeleteBundleModalOpen}
        onClose={() => setIsDeleteBundleModalOpen(false)}
        data={{
          title: bundle.is_shared_bundle
            ? constants.DELETE_SHARED_BUNDLE_TITLE
            : constants.DELETE_BUNDLE_TITLE,
          text: bundle.is_shared_bundle
            ? constants.DELETE_SHARED_BUNDLE_TEXT
            : constants.DELETE_BUNDLE_TEXT,
          primaryButtonText: bundle.is_shared_bundle
            ? constants.DELETE_SHARED_BUNDLE_PRIMARY_BTN_TEXT
            : constants.DELETE_BUNDLE_PRIMARY_BTN_TEXT,
          secondaryButtonText: bundle.is_shared_bundle
            ? constants.DELETE_SHARED_BUNDLE_SECONDARY_BTN_TEXT
            : constants.DELETE_BUNDLE_SECONDARY_BTN_TEXT,
        }}
        primaryActionButtonColor="destructiveSecondary"
        onPrimaryClick={() => {
          trackBundleDeleteConfirmationClick(
            practitioner.id,
            practitioner?.clinic?.id,
            bundle.id,
            bundle.is_shared_bundle
          )
          removeBundle()
        }}
        secondaryActionButtonColor="noaction"
        onSecondaryClick={() => {
          if (bundle.is_shared_bundle) {
            trackBundleHideForMeClick(
              practitioner.id,
              practitioner?.clinic?.id,
              bundle.id,
              bundle.is_shared_bundle
            )
            confirmUnfavorite()
          } else {
            setIsDeleteBundleModalOpen(false)
          }
        }}
      />
    </>
  )
}
