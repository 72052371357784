import { useEffect, useMemo, useRef, useState } from "react"

import _, { isEmpty, orderBy } from "lodash"

import { Grid, Typography } from "@material-ui/core"

import { API } from "app/api"
import BundleBlueIcon from "app/assets/icons/bundle-blue.svg"
import { CreateBundleOrPanelButton } from "app/components/CreateBundleOrPanelButton"
import Loading from "app/components/Loading"
import Panel from "app/components/Panel"
import DesignSystemButton from "app/components/design-system/Button"
import { ORDERING_RIGHTS_ACTIONS } from "app/constants"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { colors, navy } from "app/theme"
import {
  AnyLabTest,
  LabTest,
  LabTestBundle,
  Order,
  Practitioner,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { ComboGroupConfigurationPayload } from "../combo-groups/constants"
import BundleCard from "./BundleCard"
import LabTestCard from "./LabTestCard"
import FavoritesEmptyState from "./components/FavoritesEmptyState"
import useRefreshOrderingRights from "./hooks/use-refresh-ordering-rights"

const useStyles = makeAppStyles((theme) => ({
  separator: {
    height: 1,
    backgroundColor: colors.coolGray[200],
    width: "100%",
  },
  favoritesPlaceholderImage: {
    margin: "auto",
    width: "100%",
    maxWidth: 750,
  },
  loadingContainer: {
    margin: "auto",
  },
}))

interface Props {
  practitioner: Practitioner
  practitionerBundles: LabTestBundle[]
  signingPractitioner: Practitioner
  selectedLabTests: { [labTestId: string]: LabTest }
  selectedBundleIds?: string[]
  handleAdd: (labTest: AnyLabTest) => void
  handleRemove: (labTest: AnyLabTest) => void
  handleToggleFavorite: (labTest: AnyLabTest, isFavorite: boolean) => void
  openModalWithLabTest: (labTest: AnyLabTest) => void
  handleAddBundle: (bundle: LabTestBundle) => void
  handleRemoveBundle: (bundle: LabTestBundle) => void
  setBundleModalIsOpen: (isOpen: boolean) => void
  handleOpenBundleInfoModal: (bundle: LabTestBundle) => void
  showBundles: boolean
  modalPage?: labTestLocation
  orderRequiresVendorPhysicianAuthorization: boolean
  onAddComboGroup: (
    labTest: AnyLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
  order?: Order
  storefrontId?: string
}

export default function Favorites({
  practitioner,
  practitionerBundles,
  signingPractitioner,
  selectedLabTests,
  selectedBundleIds,
  handleAdd,
  handleRemove,
  handleToggleFavorite,
  openModalWithLabTest,
  handleAddBundle,
  handleRemoveBundle,
  setBundleModalIsOpen,
  handleOpenBundleInfoModal,
  showBundles = false,
  modalPage,
  orderRequiresVendorPhysicianAuthorization,
  onAddComboGroup,
  order,
  storefrontId,
}: Props) {
  const styles = useStyles()

  const [bundles, setBundles] = useState<LabTestBundle[]>([])
  const [loadingTests, setLoadingTests] = useState(false)
  const [favoriteLabTests, setFavoriteLabTests] = useState<AnyLabTest[]>([])

  const previousFavoriteLabTestsRef = useRef<string[]>()
  const {
    useRefreshOrderingRightsBlocking,
    useRefreshOrderingRightsBackground,
  } = useRefreshOrderingRights({ order })

  const loadLabTests = (labTestIds: string[], replaceExisting: boolean) => {
    setLoadingTests(true)
    const orderingRightsAction =
      modalPage === labTestLocation.BUNDLES
        ? ORDERING_RIGHTS_ACTIONS.ADD_TO_BUNDLE
        : ORDERING_RIGHTS_ACTIONS.ADD_TO_CART
    const labTestBatches = _.chunk(labTestIds, 20)
    const labTestPromises = labTestBatches.map((batch) => {
      let query = batch.map((id) => `id=${id}`).join("&")
      if (order) {
        query += `&order_id=${order.id}`
      }
      if (storefrontId) {
        query += `&storefront_id=${storefrontId}`
      }
      query += `&action=${orderingRightsAction}`
      return API.LabTest.getList(query)
    })

    Promise.all(labTestPromises).then((responses) => {
      const newlyLoadedLabTests = responses.reduce((allResults, response) => {
        return [...allResults, ...response.data.results]
      }, [] as AnyLabTest[])

      // when refreshing ordering rights, we want to replace the entire lab test object
      if (replaceExisting) {
        setFavoriteLabTests(newlyLoadedLabTests)
      } else {
        setFavoriteLabTests((currentLabTests) => {
          return _.uniqBy([...currentLabTests, ...newlyLoadedLabTests], "id")
        })
      }
      setLoadingTests(false)
    })
  }

  useEffect(() => {
    // We've had client errors where this is undefined, so skip if that's the case
    if (!practitioner?.favorite_lab_test_ids) {
      return
    }

    // Avoid fetching if the array reference has changed but the actual tests haven't.
    // This happens on load because there are multiple practitioner fetches updating store.
    if (
      previousFavoriteLabTestsRef.current &&
      previousFavoriteLabTestsRef.current.join(".") ===
        practitioner.favorite_lab_test_ids.join(".")
    ) {
      return
    }

    // Update the ref for future comparison
    previousFavoriteLabTestsRef.current = practitioner.favorite_lab_test_ids

    const loadedFavorites = favoriteLabTests.filter((test) =>
      practitioner.favorite_lab_test_id_set.has(test.id)
    )
    const loadedFavoritesIds = new Set(loadedFavorites.map((test) => test.id))

    // Find the tests that we have favorited but don't have in memory
    const unloadedLabTestIds = practitioner.favorite_lab_test_ids.filter(
      (id) => !loadedFavoritesIds.has(id)
    )

    if (!unloadedLabTestIds.length) {
      // If we're here, we removed a favorite and no fetching is required.
      // Filter out the removed test and trigger a rerender
      setFavoriteLabTests((currentLabTests) => {
        return currentLabTests.filter((labTest) =>
          practitioner.favorite_lab_test_id_set.has(labTest.id)
        )
      })
      return
    }

    loadLabTests(unloadedLabTestIds, false)
  }, [practitioner.favorite_lab_test_ids])

  useRefreshOrderingRightsBlocking(() => {
    loadLabTests(practitioner.favorite_lab_test_ids, true)
  })

  useRefreshOrderingRightsBackground(() => {
    loadLabTests(practitioner.favorite_lab_test_ids, true)
  })

  const sortedLabTests = useMemo(() => {
    return orderBy(favoriteLabTests, [
      "lab_company.short_name",
      "name",
    ]) as AnyLabTest[]
  }, [favoriteLabTests])

  const [bundlesSharingV2Enabled, bundlesSharingV2FlagLoading] = useFeatureFlag(
    FeatureFlag.BundlesSharingV2
  )

  const [panelBuilderEnabled] = useFeatureFlag(FeatureFlag.PanelBuilder)
  useEffect(() => {
    if (bundlesSharingV2FlagLoading) {
      setBundles([])
    } else if (bundlesSharingV2Enabled) {
      // If the feature flag is enabled nly show favorited bundles
      setBundles(practitionerBundles.filter((bundle) => bundle.is_favorited))
    } else {
      setBundles(practitionerBundles)
    }
  }, [
    bundlesSharingV2Enabled,
    bundlesSharingV2FlagLoading,
    practitionerBundles,
  ])

  return (
    <Panel className="mb-8 rounded-lg shadow-sm py-[17px] px-[29px] flex flex-col gap-[15px]">
      <div className="flex items-center rounded-t-lg justify-between">
        <Typography
          variant="h5"
          className="mb-0 mr-1 pt-1 font-semibold"
          style={{ color: navy }}
        >
          Favorites
        </Typography>
        {showBundles &&
          (panelBuilderEnabled ? (
            <CreateBundleOrPanelButton
              color={"secondary"}
              onCreateBundle={() => setBundleModalIsOpen(true)}
              defaultBorderColor={true}
            />
          ) : (
            <DesignSystemButton
              color="secondary"
              className="border-gray-300 border-t items-end"
              startIcon={BundleBlueIcon}
              onClick={() => setBundleModalIsOpen(true)}
            >
              Create a Custom Bundle
            </DesignSystemButton>
          ))}
      </div>

      {showBundles && practitioner && !isEmpty(bundles) ? (
        <div className="flex flex-col gap-[15px]">
          <hr className={styles.separator} />
          <Grid container className="py-3 mt-0" spacing={2}>
            {bundles.map((bundle) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  key={bundle.id}
                  className="labTestCardGridItem"
                >
                  <BundleCard
                    practitioner={signingPractitioner}
                    bundle={bundle}
                    orderedBundleIds={selectedBundleIds}
                    onAdd={handleAddBundle}
                    onRemove={handleRemoveBundle}
                    openModal={() => handleOpenBundleInfoModal(bundle)}
                    location={labTestLocation.CHECKOUT}
                  />
                </Grid>
              )
            })}
          </Grid>
        </div>
      ) : null}

      {showBundles && <hr className={styles.separator} />}

      {/* Empty State */}
      {practitioner && isEmpty(practitioner.favorite_lab_test_ids) && (
        <FavoritesEmptyState />
      )}

      <Grid container className="tour-onboarding-favorite-tests" spacing={2}>
        {loadingTests && !sortedLabTests.length && (
          <div className={styles.loadingContainer}>
            <Loading ariaLabel="Loading Favorite Lab Tests" />
          </div>
        )}
        {sortedLabTests.map((labTest) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              key={labTest.id}
              className="labTestCardGridItem"
            >
              <LabTestCard
                modalPage={modalPage}
                practitioner={practitioner}
                signingPractitioner={signingPractitioner}
                selectedLabTests={selectedLabTests}
                labTest={labTest}
                isFavorite={true}
                onAdd={handleAdd}
                onAddComboGroup={onAddComboGroup}
                onRemove={handleRemove}
                onToggleFavorite={() => handleToggleFavorite(labTest, true)}
                openModal={() => openModalWithLabTest(labTest)}
                hideFavoritesButton={false}
                hideInfoButton={false}
              />
            </Grid>
          )
        })}
      </Grid>
    </Panel>
  )
}
