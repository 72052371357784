import * as React from "react"

import clsx from "clsx"

import { InputProps, makeStyles } from "@material-ui/core"
import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
} from "@material-ui/lab"

import TextInput from "./TextInput"

export interface AutoCompleteInputProps<O = any, V = any> {
  InputProps?: InputProps
  className?: string
  customFilterOptions?: (
    options: any[],
    state: FilterOptionsState<any>
  ) => any[]
  disableClearable?: boolean
  disabled?: boolean
  error?: boolean
  freeSolo?: boolean
  fullWidth?: boolean
  getOptionLabel: (option: O) => string
  groupBy?: (option: O) => string
  helperText?: React.ReactNode
  inputClassName?: string
  inputRef?: React.Ref<HTMLInputElement>
  onChange: (value: V) => void
  optionFromValue?: (value: V, options: Array<O>) => O
  options: Array<O>
  renderOption?: (option: O) => React.ReactNode
  required?: boolean
  value: V
  valueFromOption?: (value: V) => O
  showErrors?: boolean
  openOnFocus?: boolean
  placeholder?: string
  includeInputTextInOptions?: boolean
  dropdownOnlyWhileTyping?: boolean
}

const AutoCompleteInput = ({
  InputProps,
  className,
  disableClearable,
  disabled,
  error,
  customFilterOptions,
  freeSolo,
  fullWidth = true,
  getOptionLabel,
  groupBy,
  helperText,
  inputClassName,
  inputRef,
  onChange,
  optionFromValue = (value) => value,
  options,
  renderOption,
  required,
  value,
  valueFromOption = (option) => option,
  showErrors,
  openOnFocus,
  placeholder,
  includeInputTextInOptions,
  dropdownOnlyWhileTyping,
}: AutoCompleteInputProps) => {
  const inputClasses = useInputStyles()
  const [inputValue, setInputValue] = React.useState("")
  const [isFocused, setIsFocused] = React.useState(false)
  const [isUserInput, setIsUserInput] = React.useState(false)

  const filterOptions = createFilterOptions({
    stringify: (option) => getOptionLabel(option) || "",
  })

  const enhancedOptions = React.useMemo(() => {
    // Add the typed input value to the options if it's not already present
    const addInputValueToOptions =
      includeInputTextInOptions &&
      freeSolo &&
      inputValue &&
      !options.some(
        (option) =>
          getOptionLabel(option)?.toLowerCase() === inputValue?.toLowerCase()
      )

    if (addInputValueToOptions) {
      return [`"${inputValue}"`, ...options]
    }

    return options
  }, [options, inputValue, freeSolo, getOptionLabel])

  // Open only if focused and there is text in the input
  const open = dropdownOnlyWhileTyping
    ? isFocused && inputValue.length > 0
    : undefined

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserInput(true)
    if (e.target.value !== undefined) {
      setInputValue(e.target.value)
    }
  }

  return (
    <Autocomplete
      className={className}
      disableClearable={disableClearable}
      disabled={disabled}
      filterOptions={customFilterOptions ?? filterOptions}
      freeSolo={freeSolo}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      onBlur={(event: any) => {
        setIsFocused(false)
        // In freeSolo mode ensure that the value is set in formsy when the component blurs. Otherwise the user would
        // have to press the enter key to cause the onChange to trigger, which they likely would not know to do.
        if (freeSolo) {
          onChange(event.target.value)
        }
      }}
      onChange={(event: any, option) => {
        setIsUserInput(false)
        // Remove "" from the typed input value if it is selected because we added the input value to the options with "" around it above
        if (
          includeInputTextInOptions &&
          freeSolo &&
          option === `"${inputValue}"`
        ) {
          onChange(valueFromOption(inputValue))
        } else {
          onChange(valueFromOption(option))
        }
        setIsFocused(false)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        if (isUserInput) {
          setIsFocused(true)
        }
      }}
      onFocus={() => {
        setIsFocused(true)
      }}
      options={enhancedOptions || []}
      renderOption={renderOption}
      open={open}
      renderInput={(params) => {
        return (
          <TextInput
            {...params}
            InputProps={{
              classes: { ...inputClasses, ...InputProps?.classes },
              ...InputProps,
              ...params.InputProps,
            }}
            className={clsx(inputClassName, "fs-exclude")}
            error={showErrors ? error : undefined}
            fullWidth={fullWidth}
            helperText={showErrors ? helperText : undefined}
            inputRef={inputRef}
            autoFocus={false}
            required={required}
            placeholder={placeholder}
            onChange={handleInputChange}
          />
        )
      }}
      value={optionFromValue(value, enhancedOptions) || value}
    />
  )
}

const useInputStyles = makeStyles({
  input: {
    fontSize: 16,
    padding: "1px !important",
  },
})

export default AutoCompleteInput
