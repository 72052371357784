import { useRef } from "react"

import * as Popover from "@radix-ui/react-popover"

import BodyText from "app/components/design-system/BodyText"
import useDebounceState from "app/hooks/use-debounce-state"
import useEventCallback from "app/hooks/use-event-callback"
import useResizeObserver from "app/hooks/use-resize-observer"
import useBiomarkerCursorTracking from "app/results-summary/hooks/use-biomarker-cursor-tracking"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { DiscreteResultIdentifier } from "types/discrete-result"
import { Order } from "types/order"
import { OrderedBiomarkerIdentifier } from "types/ordered-biomarker"
import { ResultsInterpretation } from "types/results-interpretation"

import { AnyBiomarkerResourceIdentifier } from "../../types"
import AddBiomarkerSearchInput from "./AddBiomarkerSearchInput"
import AddBiomarkerSearchLoading from "./AddBiomarkerSearchLoading"
import AddBiomarkerSearchBiomarkerOptions from "./AddBiomarkerSearchOptions"
import AddBiomarkerSearchPopoverContent from "./AddBiomarkerSearchPopoverContent"

function useAllBiomarkersSWR({
  order,
  interpretation,
  search,
}: {
  order: Order
  interpretation: ResultsInterpretation
  search: string
}) {
  /**
   * We use SWR to fetch the biomarkers matching the search input and order.
   */
  const orderedBiomarkerSwr = useCollectionSWR<
    ResourceCollection<OrderedBiomarkerIdentifier>
  >(
    "/ordered_biomarkers/",
    {
      include: ["ordered_lab_tests"],
      params: {
        order_by: "long_name",
        order: order.id,
        interpretation: interpretation.id,
        "filter[search]": search,
        "page[limit]": "50",
      },
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnMount: true,
    }
  )

  /**
   * We use SWR to fetch the discrete results matching the search input and order.
   */
  const discreteResultSwr = useCollectionSWR<
    ResourceCollection<DiscreteResultIdentifier>
  >(
    "/discrete_results/",
    {
      include: ["ordered_result.kit", "biomarker"],
      params: {
        order_by: "name",
        order: order.id,
        interpretation: interpretation.id,
        "filter[search]": search,
        "page[limit]": "50",
      },
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnMount: true,
    }
  )

  return {
    // We want to show all the biomarkers in the list. Discrete results are shown first.
    identifiers: [
      ...discreteResultSwr.data,
      ...orderedBiomarkerSwr.data,
    ] as AnyBiomarkerResourceIdentifier[],
    // We only want to show the loading state if both requests are loading.
    isLoading: orderedBiomarkerSwr.isLoading && discreteResultSwr.isLoading,
    // We want to show the validating state if either requests are validating.
    isValidating:
      orderedBiomarkerSwr.isValidating || discreteResultSwr.isValidating,
    // Used for clearing out the data after a biomarker is created or removed.
    clearCache: () => {
      orderedBiomarkerSwr.mutate(undefined, { revalidate: false })
      discreteResultSwr.mutate(undefined, { revalidate: false })
    },
  }
}

export default function AddBiomarkerSearch({
  interpretation,
  isCreating,
  onCreate,
  onToggleAdding,
  order,
}: {
  interpretation: ResultsInterpretation
  isCreating: boolean
  onCreate: (identifier: AnyBiomarkerResourceIdentifier) => Promise<void>
  onToggleAdding: () => void
  order: Order
}) {
  const triggerRef = useRef<any>()
  const contentRef = useRef<any>()

  /**
   * We use ResizeObserver to get the width of the trigger element so we can set the width of the popover.
   */
  const { width: triggerWidth = "auto" } = useResizeObserver({
    ref: triggerRef,
    box: "border-box",
  })

  /**
   * We debounce the search input to prevent the user from triggering a new request on every key stroke.
   */
  const [debounceSearch, search, setSearch] = useDebounceState("", 300)

  /**
   * We use SWR to fetch the biomarkers matching the search input and order.
   */
  const { identifiers, isLoading, isValidating, clearCache } =
    useAllBiomarkersSWR({
      interpretation,
      order,
      search: debounceSearch,
    })

  /**
   * We use a custom hook to track the cursor position in the list of biomarkers.
   */
  const {
    biomarkerCursor,
    moveBiomarkerCursorDown,
    moveBiomarkerCursorTo,
    moveBiomarkerCursorUp,
  } = useBiomarkerCursorTracking(identifiers)

  const handleCreate: typeof onCreate = useEventCallback(async (identifier) => {
    await onCreate(identifier)

    // Clear the cached swr data so that we force a refetch next time it is opened.
    clearCache()
  })

  return (
    <Popover.Root open onOpenChange={onToggleAdding}>
      <AddBiomarkerSearchInput
        moveBiomarkerCursorDown={moveBiomarkerCursorDown}
        moveBiomarkerCursorUp={moveBiomarkerCursorUp}
        onEnterSelect={() => biomarkerCursor && handleCreate(biomarkerCursor)}
        onToggleAdding={onToggleAdding}
        search={search}
        setSearch={setSearch}
        showLoading={isValidating}
        triggerRef={triggerRef}
      />

      <AddBiomarkerSearchPopoverContent
        contentRef={contentRef}
        width={triggerWidth}
      >
        {identifiers?.length ? (
          <AddBiomarkerSearchBiomarkerOptions
            cursor={biomarkerCursor}
            identifiers={identifiers}
            isCreating={isCreating}
            moveCursorTo={moveBiomarkerCursorTo}
            onSelect={handleCreate}
          />
        ) : isLoading || isValidating ? (
          <AddBiomarkerSearchLoading />
        ) : (
          <BodyText variant="body1">
            {`No biomarkers found${
              search ? ` matching search "${search}"` : ""
            }`}
          </BodyText>
        )}
      </AddBiomarkerSearchPopoverContent>
    </Popover.Root>
  )
}
